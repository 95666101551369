import React, { useEffect, useMemo } from "react";

import { Box, Breadcrumbs, Button } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { getSelectOptions } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
	addOneMoreQuestionAnswerOption,
	clearArrayOfQuestionAnswerOption,
	createGroupAnswerOptionItem,
	createQuestionAnswerOptionItem,
	createQuestionGap,
	createQuestionGroup,
	createSingleMatrixQuestionAnswerOptions,
	createTab,
	fetchQuestion,
	fetchQuestionFormData,
	getFullState,
	initBowtieGroups,
	initCaseStudyQuestion,
	reduceAnswerOptsLength,
	removeGap,
	removeGapOption,
	removeQuestionAnswerOptionItem,
	removeQuestionAttachment,
	removeQuestionData,
	removeQuestionGroup,
	removeTab,
	resetState,
	save,
	setArrayOfQuestionAnswerOption,
	setLoading,
	setStateValue,
	updateAnswerOptsFlag,
	updateCorrectFlag,
	updateMatrixQuestionGroup,
	updateValidationCriteria,
	uploadQuestionMaterial,
	validateForm
} from "store/features/AddQuestion/addQuestion.slice";
import { _emit } from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";

import theme from "theme/default";

import { BowTieQuestion } from "./BowTieQuestion";
import { CaseStudyQuestion, isFormValid as isCaseStudyFormValid } from "./CaseStudyQuestion";
import { ClozeDropDownQuestion } from "./ClozeDropDownQuestion";
import { DragAndDropQuestion } from "./DragAndDropQuestion";
import { DropDownTableQuestion } from "./DropDownTableQuestion";
import { HotspotHighlightQuestion } from "./HotspotHighlight";
import { HotspotHighlightTableQuestion } from "./HotspotHighlightTableQuestion";
import { MatrixMultipleChoiceQuestion } from "./MatrixMultipleChoiceQuestion";
import { MatrixSingleChoiceQuestion } from "./MatrixSingleChoiceQuestion";
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";
import { MultipleResponseGroupQuestion } from "./MultipleResponseGroupQuestion";
import { SingleChoiceQuestion } from "./SingleChoiceQuestion";
import {
	CancelContainer,
	HeaderTitle,
	HeaderWrapper,
	InputsWrapper,
	QuestionInputLabel,
	QuestionInputWrapper,
	StyledButton,
	CustomInput as StyledInput,
	useStyles
} from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";
import { ActiveLink, CircularProgress, Link } from "../../FileVault/styles";
import { Container, Header, HeaderContainer } from "../../ManageStudents/styles";

const questionInputLabels = [
	{ label: "Course" },
	{ label: "Question Type" },
	{ label: "Subject" },
	{ label: "Lesson" },
	{ label: "Difficulty Level" },
	{ label: "CAT Question" }
];

const AddQuestion = () => {
	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const { addNewQuestionForm, courses, questionTypeOptions, difficultyLevels, subjects, isLoading } =
		useSelector(getFullState);
	const { questionId } = useParams<{ questionId: string }>();

	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };

	const sourtedCourses = useMemo(
		() =>
			[...(courses ?? [])].sort((a, b) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
			),
		[courses?.length]
	);

	useEffect(() => {
		if (!questionId) {
			dispatch(fetchQuestionFormData(() => dispatch(setLoading(false))));
		} else {
			(async () => {
				await dispatch(setLoading(true));
				await dispatch(fetchQuestionFormData(() => {}));
				await dispatch(fetchQuestion(questionId));
				await dispatch(setLoading(false));
			})();
		}
		return () => {
			dispatch(resetState());
		};
	}, [questionId]);

	const {
		valid,
		inputs: {
			typeId: { value: typeId }
		}
	} = addNewQuestionForm;

	const isFormValid = typeId === QuestionTypes.CaseStudy ? isCaseStudyFormValid(addNewQuestionForm) : valid;

	const getLessons = () =>
		subjects
			?.find(subject => addNewQuestionForm.inputs.subjectId.value === subject.id)
			?.lessons?.map(({ id, name }) => ({ value: id, text: name }));

	const getDropdownOptions = (inputId, selectOptions?) => ({
		dropdownType: inputId,
		options: {
			...defaultCustomInputOptions,
			inputData: {
				...addNewQuestionForm.inputs[inputId],
				...(selectOptions && { selectOptions: selectOptions })
			}
		}
	});

	const history = useHistory();

	const dropdowns = useMemo(
		() => [
			getDropdownOptions("courseIds", getSelectOptions(sourtedCourses, "id", "name")),
			getDropdownOptions("typeId"),
			getDropdownOptions("subjectId"),
			getDropdownOptions("lessonSubjectId", getLessons()),
			getDropdownOptions("difficultyLevelId")
		],
		[sourtedCourses, questionTypeOptions, subjects, difficultyLevels, addNewQuestionForm]
	);

	const getQuestionBody = (typeId: QuestionTypes) => {
		switch (typeId) {
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return (
					<MultipleChoiceQuestion
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return (
					<DragAndDropQuestion
						addOneMoreQuestionAnswerOption={addOneMoreQuestionAnswerOption}
						createQuestionGap={createQuestionGap}
						getFullState={getFullState}
						removeGap={removeGap}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return (
					<ClozeDropDownQuestion
						createGroupAnswerOptionItem={createGroupAnswerOptionItem}
						createQuestionGap={createQuestionGap}
						getFullState={getFullState}
						removeGap={removeGap}
						removeGapOption={removeGapOption}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						updateCorrectFlag={updateCorrectFlag}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			case QuestionTypes.CaseStudy:
				return (
					<CaseStudyQuestion
						addOneMoreQuestionAnswerOption={addOneMoreQuestionAnswerOption}
						clearArrayOfQuestionAnswerOption={clearArrayOfQuestionAnswerOption}
						createGroupAnswerOptionItem={createGroupAnswerOptionItem}
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						createQuestionGap={createQuestionGap}
						createQuestionGroup={createQuestionGroup}
						createSingleMatrixQuestionAnswerOptions={createSingleMatrixQuestionAnswerOptions}
						createTab={createTab}
						getFullState={getFullState}
						initCaseStudyQuestion={initCaseStudyQuestion}
						reduceAnswerOptsLength={reduceAnswerOptsLength}
						removeGap={removeGap}
						removeGapOption={removeGapOption}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						removeQuestionData={removeQuestionData}
						removeQuestionGroup={removeQuestionGroup}
						removeTab={removeTab}
						setArrayOfQuestionAnswerOption={setArrayOfQuestionAnswerOption}
						setStateValue={setStateValue}
						updateAnswerOptsFlag={updateAnswerOptsFlag}
						updateCorrectFlag={updateCorrectFlag}
						updateMatrixQuestionGroup={updateMatrixQuestionGroup}
						updateValidationCriteria={updateValidationCriteria}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						initGroupingQuestion={undefined}
						moveGroupQuestionAnswerOption={undefined}
					/>
				);
			case QuestionTypes.MatrixSingleChoice:
				return (
					<MatrixSingleChoiceQuestion
						createSingleMatrixQuestionAnswerOptions={createSingleMatrixQuestionAnswerOptions}
						reduceAnswerOptsLength={reduceAnswerOptsLength}
						createQuestionGroup={createQuestionGroup}
						getFullState={getFullState}
						removeQuestionGroup={removeQuestionGroup}
						setStateValue={setStateValue}
						validateForm={validateForm}
						uploadQuestionMaterial={uploadQuestionMaterial}
						removeQuestionAttachment={removeQuestionAttachment}
					/>
				);
			case QuestionTypes.MatrixMultipleChoice:
				return (
					<MatrixMultipleChoiceQuestion
						addOneMoreQuestionAnswerOption={addOneMoreQuestionAnswerOption}
						createQuestionGroup={createQuestionGroup}
						createSingleMatrixQuestionAnswerOptions={createSingleMatrixQuestionAnswerOptions}
						getFullState={getFullState}
						reduceAnswerOptsLength={reduceAnswerOptsLength}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						removeQuestionGroup={removeQuestionGroup}
						setStateValue={setStateValue}
						updateMatrixQuestionGroup={updateMatrixQuestionGroup}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			case QuestionTypes.DropDownTable:
				return (
					<DropDownTableQuestion
						createGroupAnswerOptionItem={createGroupAnswerOptionItem}
						createQuestionGroup={createQuestionGroup}
						getFullState={getFullState}
						removeGap={removeGap}
						removeGapOption={removeGapOption}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						updateCorrectFlag={updateCorrectFlag}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			case QuestionTypes.SingleChoice:
				return (
					<SingleChoiceQuestion
						initSingleChoiceQuestion={null}
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						updateAnswerOptsFlag={updateAnswerOptsFlag}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			case QuestionTypes.BowTie:
				return (
					<BowTieQuestion
						createGroupAnswerOptionItem={createGroupAnswerOptionItem}
						getFullState={getFullState}
						initBowtieGroups={initBowtieGroups}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			case QuestionTypes.MultipleResponseGroup:
				return (
					<MultipleResponseGroupQuestion
						createGroupAnswerOptionItem={createGroupAnswerOptionItem}
						createQuestionGap={createQuestionGap}
						getFullState={getFullState}
						removeGap={removeGap}
						removeGapOption={removeGapOption}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			case QuestionTypes.HighlightTable:
				return (
					<HotspotHighlightTableQuestion
						clearArrayOfQuestionAnswerOption={clearArrayOfQuestionAnswerOption}
						createQuestionGap={createQuestionGap}
						getFullState={getFullState}
						removeGap={removeGap}
						removeQuestionAttachment={removeQuestionAttachment}
						setArrayOfQuestionAnswerOption={setArrayOfQuestionAnswerOption}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			case QuestionTypes.HotspotHighlight:
				return (
					<HotspotHighlightQuestion
						clearArrayOfQuestionAnswerOption={clearArrayOfQuestionAnswerOption}
						getFullState={getFullState}
						removeQuestionAttachment={removeQuestionAttachment}
						setArrayOfQuestionAnswerOption={setArrayOfQuestionAnswerOption}
						setStateValue={setStateValue}
						updateValidationCriteria={updateValidationCriteria}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
					/>
				);
			default:
				return;
		}
	};

	return (
		<Container>
			<HeaderContainer>
				<Header>{`${questionId ? "Edit" : "Add New"} Question`}</Header>
				<CancelContainer>
					<Button variant={"contained"} color={"primary"} onClick={() => history.goBack()}>
						Cancel
					</Button>
				</CancelContainer>
				<StyledButton
					variant={"contained"}
					color={"primary"}
					disabled={!isFormValid}
					onClick={() => {
						dispatch(save({ questionId, success: () => history.push(routes.questionbank.getPath()) }));
					}}
				>
					{questionId ? "Save" : "Publish"} Question to Question Bank
				</StyledButton>
			</HeaderContainer>
			<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
				<Link to={routes.questionbank.getPath()}>Question Bank</Link>
				<ActiveLink to={`${routes.questionbank.getPath()}/add-new-question`}>{`${
					questionId ? "Edit" : "Add New"
				} Question`}</ActiveLink>
			</Breadcrumbs>
			{isLoading ? (
				<Box display="flex" alignItems="center" height={450} width="100%">
					<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
				</Box>
			) : (
				<>
					<Box mr={4} mt={2}>
						<HeaderWrapper>
							<HeaderTitle>New Question Configuration</HeaderTitle>
						</HeaderWrapper>
						<InputsWrapper>
							<Box display="flex" flexDirection={"column"} flex={0.5} justifyContent={"end"} alignItems={"flex-end"}>
								{questionInputLabels.map(({ label }, i) => (
									<QuestionInputLabel key={`question-label-${i}`} index={i}>
										{label}
									</QuestionInputLabel>
								))}
							</Box>
							<Box display="flex" flexDirection={"column"} flex={3}>
								{dropdowns.map(({ options, dropdownType }, i) => (
									<QuestionInputWrapper key={`question-wrapper-${i}`}>
										<StyledInput
											{...(dropdownType === "typeId" && {
												inputProps: {
													onChange: () => {
														dispatch(
															removeQuestionData({
																statePath: "addNewQuestionForm.inputs"
															})
														);
													}
												}
											})}
											mr={2}
											theme={theme}
											options={options}
										/>
									</QuestionInputWrapper>
								))}
								<QuestionInputWrapper>
									<StyledInput
										mr={2}
										theme={theme}
										options={{
											...defaultCustomInputOptions,
											inputData: {
												...addNewQuestionForm.inputs.forCAT
											}
										}}
									/>
								</QuestionInputWrapper>
							</Box>
						</InputsWrapper>
					</Box>
					{typeId && <div className={classes.container}>{getQuestionBody(typeId)}</div>}
				</>
			)}
		</Container>
	);
};

export default AddQuestion;
