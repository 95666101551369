import React, { useState } from "react";

import { Radio } from "@material-ui/core";

import { QuizQuestionAnswerOption, StyledText, TestQuizQuestionText } from "./style";

const SingleChoiceQuestionPreview = ({ question }) => {
	const answerOptions = question.data.answerOptions;
	const [userAnswer, setUserAnswer] = useState<number | null>(null);

	return (
		<>
			<TestQuizQuestionText>{question?.text}</TestQuizQuestionText>
			{answerOptions?.map(({ id, text }) => (
				<QuizQuestionAnswerOption key={id}>
					<Radio checked={userAnswer == id} onChange={() => setUserAnswer(id)} />
					<StyledText>{text}</StyledText>
				</QuizQuestionAnswerOption>
			))}
		</>
	);
};

export default SingleChoiceQuestionPreview;
