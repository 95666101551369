import React, { useState } from "react";

import { Box, Button, CircularProgress, Container, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { getRemainingDays } from "@remar/shared/dist/utils/myAccountUtils";

import { useDispatch, useSelector } from "react-redux";

import { addDaysInSubscription, getAllStudents, getFullState } from "store/features/Students/students.slice";
import { _emit } from "store/features/notifications/notifications.slice";

import { AddDaysDto } from "store/services";

import { ChangeEmailHeading, EmailInput, StyledCard, useStyles } from "../../styles";

const AddDaysInSubscription = ({ fullName, userId, userEmail, subscription, onClose }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [days, setDays] = useState("0");
	const { addDaysLoading } = useSelector(getFullState);

	const closeModal = () => {
		if (!addDaysLoading) {
			onClose();
		}
	};

	const handleAddDaysChange = (event: React.ChangeEvent<{ value: string }>) => {
		setDays(event.target.value);
	};

	const sideEffect = (e, notificationType) => {
		if (!addDaysLoading) {
			notificationType !== "error" && onClose();
			dispatch(_emit(notificationType === "error" ? e.message : e, notificationType));
			notificationType === "success" && dispatch(getAllStudents({}));
		}
	};

	const handleSubmit = () => {
		const payload = { userId, numberOfDays: +days } as AddDaysDto;
		dispatch(addDaysInSubscription({ payload, sideEffect }));
	};
	const subscriptionEndDate =
		getRemainingDays(new Date(subscription.expiresOn)) > 1
			? `${getRemainingDays(new Date(subscription.expiresOn))} Days`
			: (subscription.expiresOn && "1 Day") || "-";
	return (
		<Container maxWidth="md">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
				<StyledCard>
					<Box height={"85%"}>
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<Typography className={classes.title} variant="h6">
								Add days to the subscription
							</Typography>
							<IconButton onClick={onClose}>
								<CloseIcon color="disabled" />
							</IconButton>
						</Box>
						<Box>
							<Box mt={3}>
								<ChangeEmailHeading variant="caption">Student`s Name</ChangeEmailHeading>
								<Typography>{fullName}</Typography>
							</Box>
							<Box mt={3}>
								<ChangeEmailHeading variant="caption">Student`s Email</ChangeEmailHeading>
								<Typography>{userEmail}</Typography>
							</Box>
							<Box mt={3}>
								<ChangeEmailHeading variant="caption">Subscription expires in</ChangeEmailHeading>
								<Typography>{subscriptionEndDate}</Typography>
							</Box>
							<Box mt={3}>
								<ChangeEmailHeading variant="caption">Number of days</ChangeEmailHeading>
								<EmailInput
									onChange={handleAddDaysChange}
									value={days}
									type="number"
									placeholder="Enter number of days"
								/>
							</Box>
						</Box>
					</Box>
					<Box display="flex" justifyContent="flex-end" mt={3}>
						<Button variant="outlined" className={classes.cancelBtn} onClick={closeModal}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							className={classes.save}
							onClick={handleSubmit}
							disabled={!days}
							startIcon={
								addDaysLoading && <CircularProgress size="1rem" color="inherit" thickness={5} variant="indeterminate" />
							}
						>
							Add
						</Button>
					</Box>
				</StyledCard>
			</Box>
		</Container>
	);
};

export default AddDaysInSubscription;
