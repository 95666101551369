import React from "react";

import { Box, Radio } from "@material-ui/core";

import { MatrixTableBody, MatrixTableHeader, RadioWrapper, StyledText, TestQuizQuestionText } from "./style";

const MatrixSingleChoiceQuestionPreview = ({ question }) => {
	const { answerOptions, tableLabel, groups } = question.data;

	return (
		<Box ml={2}>
			<TestQuizQuestionText>{question?.text}</TestQuizQuestionText>
			<MatrixTableHeader display="flex" maxWidth={"649px"} flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<Box
						display="flex"
						borderRight={"solid 1px rgba(151, 151, 151, 0.3)"}
						flexDirection="row"
						width="50%"
						height={"100%"}
						alignItems="center"
						pl={"22px"}
					>
						<StyledText>{tableLabel}</StyledText>
					</Box>

					<Box height={"100%"} display="flex" flexDirection="row" width="50%">
						{answerOptions.map(({ text }, answerOptionIndex) => (
							<Box
								key={answerOptionIndex}
								justifyContent="center"
								display="flex"
								alignItems="center"
								height={"100%"}
								flexDirection="row"
								width={`${100 / answerOptions.length}%`}
								{...(answerOptionIndex === 0 && {
									borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
								})}
							>
								<StyledText>{text}</StyledText>
							</Box>
						))}
					</Box>
				</Box>
			</MatrixTableHeader>
			<MatrixTableBody>
				{groups.map(({ text }, gIndex) => (
					<Box
						key={gIndex}
						borderBottom="solid 1px rgba(151, 151, 151, 0.3)"
						height={"51px"}
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%">
							<Box
								display="flex"
								flexDirection="row"
								borderRight="solid 1px rgba(151, 151, 151, 0.3)"
								height={"100%"}
								width="50%"
								pl={"22px"}
								alignItems="center"
							>
								<StyledText>{text}</StyledText>
							</Box>
							<Box display="flex" flexDirection="row" width="50%">
								<RadioWrapper>
									{answerOptions.map(({ id: optId }, _index) => (
										<Box
											justifyContent="center"
											key={_index}
											height={"46px"}
											display="flex"
											width={`${100 / answerOptions.length}%`}
											{...(_index === 0 && {
												borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
											})}
										>
											<Radio color="primary" value={optId} />
										</Box>
									))}
								</RadioWrapper>
							</Box>
						</Box>
					</Box>
				))}
			</MatrixTableBody>
		</Box>
	);
};

export default MatrixSingleChoiceQuestionPreview;
