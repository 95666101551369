/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, CircularProgress, FormControl, MenuItem, Select } from "@material-ui/core";

import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { Avatar } from "@remar/shared/dist/components/Avatar";
import HeaderWrapper from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";

import {
	ColumnHeader,
	StyledCellText,
	StyledCellWrapper,
	THeaderTitle
} from "@remar/shared/dist/components/Table/styles";

import { TablePagination } from "@remar/shared/dist/components/TablePagination";

import { truncate } from "lodash";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { fetchAllCourses, getFullState as getFullCourseState } from "store/features/Course/course.slice";
import { fetchLocations, fullState } from "store/features/ManageLocations/manageLocations.slice";

import styled from "styled-components";

import { IconListSvg as ListIconSvg } from "assets/icons";

import { routes } from "core/constants";

import { Container, NameWrapper, THeaderWrapper } from "./styles";

export const StyledCellTextCentered = styled(StyledCellText)`
	justify-content: center;
`;

const tableColumns = (currentPage, ITEMS_PER_PAGE) => {
	const baseCount = ITEMS_PER_PAGE * (currentPage - 1);
	return [
		{
			alignment: "left",
			label: <ColumnHeader>#</ColumnHeader>,
			Cell: ({ rowIndex }) => {
				return <StyledCellText>{baseCount + rowIndex + 1}</StyledCellText>;
			},
			width: 50,
			dataKey: "#"
		},
		{
			alignment: "left",
			label: <ColumnHeader>Name</ColumnHeader>,
			width: 350,
			Cell: ({ rowData: { admins, name } }) => (
				<StyledCellWrapper>
					<NameWrapper>
						<div className={"left_section"}>
							{admins[0].admin.logo ? (
								<img alt="logo" className={"logo"} src={admins[0]?.admin.logo} />
							) : (
								<Avatar className={"logo"} variant="rounded" style={{ color: "#1998d5" }}>
									{name.trim()[0].toUpperCase()}
								</Avatar>
							)}
						</div>
						<div className={"right_section"}>
							<StyledCellText>{truncate(`${name}`, { length: 40, omission: "..." })}</StyledCellText>
							<StyledCellText>{admins[0]?.admin.email || "N/A"}</StyledCellText>
						</div>
					</NameWrapper>
				</StyledCellWrapper>
			),
			dataKey: "name"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Total Questions</ColumnHeader>,
			Cell: ({ rowData: { totalQuestions } }) => <StyledCellTextCentered>{totalQuestions}</StyledCellTextCentered>,
			dataKey: "total-questions"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Number Of Students</ColumnHeader>,
			Cell: ({ rowData: { totalStudents } }) => <StyledCellTextCentered>{totalStudents ?? 0}</StyledCellTextCentered>,
			dataKey: "number-of-students"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Course</ColumnHeader>,
			Cell: ({ rowData: { locationPackage } }) => (
				<StyledCellTextCentered>{locationPackage?.course.name || "N/A"}</StyledCellTextCentered>
			),
			dataKey: "course"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Package Type</ColumnHeader>,
			Cell: ({ rowData: { locationPackage } }) => (
				<StyledCellTextCentered>{locationPackage?.locationPackageType.name || "N/A"}</StyledCellTextCentered>
			),
			dataKey: "pkg-type"
		},
		{
			alignment: "right",
			label: "",
			width: 100,
			Cell: () => {
				return <ActionMenu customMenuItems={[]} />;
			},
			dataKey: "menu"
		}
	];
};

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Manage Locations", key: 1 }
];

const ITEMS_PER_PAGE = 10;
const COURSE_FILTER_KEY = "admins.admin.subscriptions.type.allowedLocationPackages.courseId";

const ManageLocations = () => {
	const [textSearch, setSearchText] = useState<string>("");
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [selectedCourse, setSelectedCourse] = useState<number | string>("");
	const [filtersState, setFiltersState] = useState({});
	const history = useHistory();
	const dispatch = useDispatch();
	const { locations, totalItems, loading } = useSelector(fullState);
	const { courses } = useSelector(getFullCourseState);
	const handleSearchBarChange = useCallback(
		searchText => {
			setCurrentPage(1);
			dispatch(
				fetchLocations({
					perPage: ITEMS_PER_PAGE,
					page: 1,
					searchKeyword: searchText,
					filters: filtersState
				})
			);
		},
		[currentPage]
	);

	useEffect(() => {
		dispatch(
			fetchLocations({ perPage: ITEMS_PER_PAGE, page: currentPage, searchKeyword: textSearch, filters: filtersState })
		);
		dispatch(fetchAllCourses(true));
	}, []);

	const handleCourseFilter = event => {
		setSelectedCourse(event.target.value);
		let filters: Record<string, string> = { [COURSE_FILTER_KEY]: event.target.value };
		setCurrentPage(1);
		if (event.target.value === "") {
			filters = {};
			setFiltersState({});
		} else {
			setFiltersState(filters);
		}
		dispatch(fetchLocations({ perPage: ITEMS_PER_PAGE, page: 1, searchKeyword: textSearch, filters: filters }));
	};
	const handlePageChange = (event, page) => {
		setCurrentPage(page);
		dispatch(fetchLocations({ perPage: ITEMS_PER_PAGE, page: page, searchKeyword: textSearch, filters: filtersState }));
	};

	const actions = (
		<>
			<Button
				variant={"contained"}
				color={"primary"}
				onClick={() => history.push(`${routes.manageLocations.getPath()}/package-overview`)}
			>
				Manage Packages
			</Button>
		</>
	);
	return (
		<Container>
			<HeaderWrapper heading={"Manage Locations"} breadcrumb={breadcrumb} actions={actions} />
			<Box pr={3}>
				<THeaderWrapper>
					<Box display={"flex"} flex={1}>
						<THeaderTitle>Location Accounts ({totalItems})</THeaderTitle>
					</Box>
					<Box mr={1}>
						<ListIconSvg />
					</Box>
					<Box mr={2}>
						<FormControl hiddenLabel size="small" variant="filled">
							<Select
								disableUnderline
								value={selectedCourse}
								onChange={handleCourseFilter}
								displayEmpty
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
							>
								<MenuItem value={""}>All</MenuItem>
								{(courses || []).map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<SearchBarComponent
						inputValue={textSearch}
						setInputValue={setSearchText}
						onChange={handleSearchBarChange}
						isDebounced={true}
						debounceTime={1000}
						placeHolder={"Type to filter by keywords"}
					/>
				</THeaderWrapper>
				{loading ? (
					<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
						<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
					</Box>
				) : (
					<>
						<MaterialTable
							columns={tableColumns(currentPage, ITEMS_PER_PAGE) as Array<IColumn<unknown>>} // todo: refactor - setup correct type
							data={locations}
							height={700}
						/>
						<TablePagination
							count={totalItems}
							page={currentPage}
							onChange={handlePageChange}
							rowsPerPage={ITEMS_PER_PAGE}
						/>
					</>
				)}
			</Box>
		</Container>
	);
};

export default ManageLocations;
