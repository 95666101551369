import React, { useEffect, useState } from "react";

import { Box, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Subject } from "rxjs";

import {
	deleteAttachment,
	fetchAttachments,
	getFullState,
	uploadAttachment
} from "store/features/CreateLesson/createLesson.slice";
import { _emit, dismissAll } from "store/features/notifications/notifications.slice";

import InteractiveBlocks from "./InteractiveBlocks";
import InteractiveQuestion from "./InteractiveQuestion";
import VideoActions from "./VideoActions";
import VideoContainer from "./VideoContainer";

import { AttachmentUploader } from "../components/AttachmentUploader/AttachmentUploader";
import { useCreateLessonStyles } from "../createLesson.styles";

const VitCreation = () => {
	const dispatch = useDispatch();
	const classes = useCreateLessonStyles();
	const { isLoading, lessonAttachments, lesson } = useSelector(getFullState);
	const [mainVideoProgress, setMainVideoProgress] = useState<number>(0);
	const [mainVideoUploadError, setMainVideoUploadError] = useState(false);
	const clearFileInputSubject = new Subject<void>();

	// Close all sticky notifications for this form when the user leaves this form
	useEffect(
		() => () => {
			dispatch(dismissAll());
		},
		[]
	);

	useEffect(() => {
		lesson?.id && dispatch(fetchAttachments({ lessonId: lesson?.id, statePath: "lessonAttachments" }));
	}, []);

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={450} m={20}>
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	const handleUpload = async (file: Partial<File>, statePath: string): Promise<void> => {
		const methods = {
			clearFileInput: () => clearFileInputSubject.next(),
			progress: setMainVideoProgress,
			uploadError: setMainVideoUploadError
		};
		const allowedFileType = [
			"jpeg",
			"jpg",
			"vnd.ms-powerpoint",
			"vnd.openxmlformats-officedocument.presentationml.presentation",
			"pdf"
		].includes(file.type?.split("/")[1] as string);
		if (!allowedFileType) {
			dispatch(_emit("File Type not allowed", "error"));
			methods.clearFileInput();
			return;
		}
		dispatch(
			uploadAttachment({
				file,
				lessonId: lesson?.id,
				statePath,
				options: {
					onError: () => {
						methods.uploadError(true);
					},
					onProgress: ({ loaded, total }) => {
						methods.progress((loaded / total) * 100);
					},
					onUploaded: () => {
						methods.clearFileInput();
					}
				}
			})
		);
		methods.uploadError(false);
	};

	const handleDelete = (id: number | undefined, statePath: string, sideEffect): void => {
		dispatch(deleteAttachment({ id, statePath, sideEffect }));
	};

	return (
		<Box pt={3} pr={4} display="flex">
			<Box width="74%" mr="2%">
				<VideoContainer />
				<VideoActions />
				<InteractiveQuestion />
			</Box>
			<Box width="24%">
				<InteractiveBlocks />
				<div className={classes.spacer}></div>
				<AttachmentUploader
					{...{
						handleDelete: handleDelete,
						handleUpload: handleUpload,
						statePath: "lessonAttachments",
						uploadError: mainVideoUploadError,
						lessonAttachments: lessonAttachments,
						uploaderProgress: mainVideoProgress,
						uploaderProps: { clearFileInputSubject: clearFileInputSubject, accept: "application/pdf,.pptx,.jpg" }
					}}
				/>
			</Box>
		</Box>
	);
};

export default VitCreation;
