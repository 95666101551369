import React from "react";

import { ScrollToTop } from "@remar/shared/dist/components/ScrollToTop";
import {
	AddQuestion,
	CATAvailability,
	Chapters,
	CommunityPool,
	CommunityPoolManageSubjects,
	Content,
	CourseAddEdit,
	CreateLesson,
	FileVault,
	FileVaultSubFolders,
	FileVaultSubFoldersFiles,
	Home,
	IndividualCourse,
	ManageLocations,
	ManagePackage,
	ManageSchools,
	ManageStudents,
	ManageSubjects,
	NewReports,
	PackageOverview,
	QuestionBank,
	Reports,
	Settings,
	SignIn
} from "Pages";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { routes } from "core/constants";
import BaseLayout from "modules/Layout/BaseLayout";

import AuthRoute from "./AuthRoute";
import Notifications from "./Notifications";

import { LessonDetails } from "../modules/App/View";
import FileVaultPersonalStorageFiles from "../modules/App/View/Container/FileVault/FileVaultPersonalStorageFiles";

const PREFIX = "/";

{
	/* TODO: add Not found page*/
}
const Routes = () => {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Route path={PREFIX}>
				<Switch>
					<AuthRoute exact key={routes.signIn.getElementKey()} path={routes.signIn.getPath()} component={SignIn} />
					<BaseLayout>
						{" "}
						<Switch>
							<AuthRoute exact key={"/"} path={"/"} component={Home} />
							<AuthRoute exact key={routes.course.getElementKey()} path={routes.course.getPath()} component={Content} />
							<AuthRoute
								exact
								key={`${routes.course.getElementKey()}-content`}
								path={`${routes.course.getPath()}/:courseId`}
								component={IndividualCourse}
							/>
							<AuthRoute
								exact
								key={`${routes.courseAddEdit.getElementKey()}-add`}
								path={`${routes.courseAddEdit.getPath()}`}
								component={CourseAddEdit}
							/>
							<AuthRoute
								exact
								key={`${routes.courseAddEdit.getElementKey()}-edit`}
								path={`${routes.courseAddEdit.getPath()}/:id`}
								component={CourseAddEdit}
							/>
							<AuthRoute
								exact
								key={`${routes.course.getElementKey()}chapters`}
								path={`${routes.course.getPath()}/:courseId/chapters/:id`}
								component={Chapters}
							/>
							<AuthRoute
								exact
								key={routes.questionbank.getElementKey()}
								path={routes.questionbank.getPath()}
								component={QuestionBank}
							/>
							<AuthRoute
								exact
								key={`${routes.questionbank.getElementKey()}-add`}
								path={`${routes.questionbank.getPath()}/add-new-question`}
								component={AddQuestion}
							/>
							<AuthRoute
								exact
								key={`${routes.questionbank.getElementKey()}-edit`}
								path={`${routes.questionbank.getPath()}/:questionId`}
								component={AddQuestion}
							/>
							<AuthRoute
								exact
								key={routes.manageSubjects.getElementKey()}
								path={routes.manageSubjects.getPath()}
								component={ManageSubjects}
							/>
							<AuthRoute
								exact
								key={routes.CreateLesson.getElementKey()}
								path={`${routes.CreateLesson.getPath()}/:lessonId?`}
								component={CreateLesson}
							/>
							<AuthRoute
								exact
								key={routes.settings.getElementKey()}
								path={routes.settings.getPath()}
								component={Settings}
							/>
							<AuthRoute
								exact
								key={routes.manageStudents.getElementKey()}
								path={routes.manageStudents.getPath()}
								component={ManageStudents}
							/>
							<AuthRoute
								exact
								key={routes.catAvailability.getElementKey()}
								path={routes.catAvailability.getPath()}
								component={CATAvailability}
							/>
							<AuthRoute
								exact
								key={routes.manageTutorial.getElementKey()}
								path={routes.manageTutorial.getPath()}
								component={CreateLesson}
							/>
							<AuthRoute
								exact
								key={routes.manageSchools.getElementKey()}
								path={routes.manageSchools.getPath()}
								component={ManageSchools}
							/>
							<AuthRoute
								exact
								key={routes.manageLocations.getElementKey()}
								path={routes.manageLocations.getPath()}
								component={ManageLocations}
							/>
							<AuthRoute
								exact
								key={`${routes.manageLocations.getElementKey()}-package-overview`}
								path={`${routes.manageLocations.getPath()}/package-overview`}
								component={PackageOverview}
							/>
							<AuthRoute
								exact
								key={`${routes.manageLocations.getElementKey()}-package-overview/manage-package`}
								path={`${routes.manageLocations.getPath()}/package-overview/manage-package/:courseId/:locationPackageTypeId/:action`}
								component={ManagePackage}
							/>
							<AuthRoute
								exact
								key={routes.fileVault.getElementKey()}
								path={routes.fileVault.getPath()}
								component={FileVault}
							/>
							<AuthRoute
								exact
								key={`${routes.fileVault.getElementKey()}-content`}
								path={`${routes.fileVault.getPath()}/:folderId`}
								component={FileVaultSubFolders}
							/>
							<AuthRoute
								exact
								key={`${routes.fileVault.getElementKey()}-personal`}
								path={`${routes.fileVault.getPath()}/personal/:folderId`}
								component={FileVaultPersonalStorageFiles}
							/>
							<AuthRoute
								exact
								key={`${routes.fileVault.getElementKey()}-subfolder`}
								path={`${routes.fileVault.getPath()}/:folderId/subFolders/:subFolderId/files`}
								component={FileVaultSubFoldersFiles}
							/>
							<AuthRoute
								exact
								key={`${routes.communityPool.getElementKey()}`}
								path={`${routes.communityPool.getPath()}`}
								component={CommunityPool}
							/>
							<AuthRoute
								exact
								key={`${routes.communityPool.getElementKey()}-manageSubjects`}
								path={`${routes.communityPool.getPath()}/manageSubjects`}
								component={CommunityPoolManageSubjects}
							/>
							<AuthRoute
								exact
								key={routes.reports.getElementKey()}
								path={routes.reports.getPath()}
								component={Reports}
							/>
							<AuthRoute
								exact
								key={routes.newReports.getElementKey()}
								path={routes.newReports.getPath()}
								component={NewReports}
							/>
							<AuthRoute
								exact
								key={`${routes.lesson.getElementKey()}-details`}
								path={`${routes.lesson.getPath()}/:lessonId`}
								component={LessonDetails}
							/>
						</Switch>
						<Notifications />
					</BaseLayout>
				</Switch>
			</Route>
		</BrowserRouter>
	);
};

export default Routes;
