import React from "react";

import { Box, Card, Typography } from "@material-ui/core";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { useDraggedScroll } from "@remar/shared/dist/utils/useDraggedScroll";
import { useDispatch, useSelector } from "react-redux";

import {
	createQuestionAnswerOptionItem,
	getFullState,
	initGroupingQuestion,
	initSingleChoiceQuestion,
	moveGroupQuestionAnswerOption,
	removeQuestionAnserOptionItem,
	removeQuestionData,
	setStateValue,
	updateOptionOrder,
	validateForm
} from "store/features/CreateLesson/createLesson.slice";
import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import {
	VITGroupingQuestion,
	VITMultipleChoiceQuestion,
	VITSequencingQuestion,
	VITSingleChoiceQuestion
} from "./VITQuestions";
import { useInteractiveQuestionStyles } from "./interactiveQuestion.style";

export default () => {
	const classes = useInteractiveQuestionStyles();
	const dispatch = useDispatch();
	const { interactiveLessonFormGroup } = useSelector(getFullState);
	const { interactiveBlocks: interactiveBlocksInputs } = interactiveLessonFormGroup.inputs;
	const { selectedInteractiveBlockIndex } = interactiveLessonFormGroup.rawData;
	const selectedInteractiveBlock = interactiveBlocksInputs[selectedInteractiveBlockIndex] ?? interactiveBlocksInputs[0];
	const { text, typeId } = selectedInteractiveBlock?.question;
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	useDraggedScroll();

	const getLessonQuestion = typeId => {
		switch (typeId) {
			case QuestionTypes.Grouping:
				return (
					<VITGroupingQuestion
						moveGroupQuestionAnswerOption={moveGroupQuestionAnswerOption}
						initGroupingQuestion={initGroupingQuestion}
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						setStateValue={setStateValue}
						validateForm={validateForm}
						key={`questions.${selectedInteractiveBlockIndex}`}
						statePath={`interactiveBlocks.${selectedInteractiveBlockIndex}.question`}
						templatePath="interactiveBlocks.question.answerOptions"
						groupTemplatePath="interactiveBlocks.question.groups"
					/>
				);
			case QuestionTypes.Sequencing:
				return (
					<VITSequencingQuestion
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						setStateValue={setStateValue}
						templatePath="interactiveBlocks.question.answerOptions"
						updateOptionOrder={updateOptionOrder}
						validateForm={validateForm}
						key={`questions.${selectedInteractiveBlockIndex}`}
						statePath={`interactiveBlocks.${selectedInteractiveBlockIndex}.question`}
					/>
				);
			case QuestionTypes.SingleChoice:
				return (
					<VITSingleChoiceQuestion
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						initSingleChoiceQuestion={initSingleChoiceQuestion}
						setStateValue={setStateValue}
						validateForm={validateForm}
						templatePath="interactiveBlocks.question.answerOptions"
						statePath={`interactiveBlocks.${selectedInteractiveBlockIndex}.question`}
						key={`questions.${selectedInteractiveBlockIndex}`}
						removeQuestionAnswerOptionItem={removeQuestionAnserOptionItem}
					/>
				);

			case QuestionTypes.MultipleChoice:
				return (
					<VITMultipleChoiceQuestion
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						setStateValue={setStateValue}
						validateForm={validateForm}
						templatePath="interactiveBlocks.question.answerOptions"
						statePath={`interactiveBlocks.${selectedInteractiveBlockIndex}.question`}
						key={`questions.${selectedInteractiveBlockIndex}`}
						removeQuestionAnswerOptionItem={removeQuestionAnserOptionItem}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<Box mt={3}>
			<Card className={classes.card}>
				<Typography className={classes.addOptionHeader}>QUESTION</Typography>
				<Box display="flex" flexDirection="row" alignItems="center">
					<CustomInput style={{ flex: 1 }} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
					<CustomInput
						className={classes.questionTypeInput}
						width={300}
						ml={2}
						theme={theme}
						inputProps={{
							onChange: () => {
								dispatch(
									removeQuestionData({
										VITQuestion: true,
										statePath: `interactiveLessonFormGroup.inputs.interactiveBlocks.${selectedInteractiveBlockIndex}.question`
									})
								);
							}
						}}
						options={{ ...defaultCustomInputOptions, inputData: typeId }}
					/>
				</Box>

				{getLessonQuestion(typeId.value)}
			</Card>
		</Box>
	);
};
