import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { AssignCourse, CancelSubscription, User } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";
import { AddDaysDto, UserChangeEmailDto, UserChangeEmailResponseDto } from "../students";

export class StudentsService extends BaseApiService<
	User,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
	cancelSubscription(data: CancelSubscription): Promise<Record<string, unknown>> {
		return request(
			`${this.baseUrl}/subscription/cancel`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<Record<string, unknown>>;
	}

	subscriptionAssignCourse(data: AssignCourse): Promise<Record<string, unknown>> {
		return request(
			`${this.baseUrl}/subscription/assign-course`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<Record<string, unknown>>;
	}
	changeEmail(data: UserChangeEmailDto): Promise<UserChangeEmailResponseDto> {
		return request(
			`${this.baseUrl}/change-email`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserChangeEmailResponseDto>;
	}
	addDays(data: AddDaysDto): Promise<UserChangeEmailResponseDto> {
		return request(
			`${this.baseUrl}/add-days-subscription`,
			"POST",
			this.emptyStringToNull(data) as Record<number, unknown>
		) as Promise<UserChangeEmailResponseDto>;
	}
}

export const studentsService = new StudentsService(URLS.USERS);
