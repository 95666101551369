import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Modal } from "@material-ui/core";

import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { Avatar } from "@remar/shared/dist/components/Avatar";
import HeaderContainer from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";

import {
	ColumnHeader,
	Container,
	StyledCellText,
	StyledCellWrapper,
	THeaderTitle,
	THeaderWrapper
} from "@remar/shared/dist/components/Table/styles";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Course, User, UserAllowedCourse, UserSubscription } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";

import { formatAdminDate } from "@remar/shared/dist/utils/myAccountUtils";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";

import { useDispatch, useSelector } from "react-redux";

import { fetchAllCourses, getFullState as getFullCourseState } from "store/features/Course/course.slice";
import { getAllStudents, getFullState, resetState } from "store/features/Students/students.slice";

import AddDaysInSubscription from "./components/AddDaysInSubscription";
import ChangeEmail from "./components/ChangeEmail";
import ManageSubscription from "./components/ManageSubscription";
import SubscriptionStatus from "./components/SubscriptionStatus";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Manage Students", key: 1 }
];

interface IAddDays {
	fullName: string;
	userId: number;
	userEmail: string;
	subscription: UserSubscription;
}
interface IChangeEmail {
	fullName: string;
	userEmail: string;
	userId: number;
}
interface IChangeSubscription {
	fullName: string;
	userId: number;
	subscriptionId: number;
	hasExpired: boolean;
	isCancelled: boolean;
	isTrial: boolean | undefined;
	allowedCourses: UserAllowedCourse[] | undefined;
	notAllowedCourses: Course[] | null;
	subscription: UserSubscription;
}

const AddDaysModal = ({
	setShowAddDaysModal,
	showAddDaysModal: { fullName, userId, userEmail, subscription }
}: {
	setShowAddDaysModal: React.Dispatch<React.SetStateAction<IAddDays | null>>;
	showAddDaysModal: IAddDays;
}) => {
	const classes = useStyles();
	return (
		<Modal
			className={classes.modal}
			disableEnforceFocus
			disableAutoFocus
			open={true}
			onClose={() => setShowAddDaysModal(null)}
		>
			<AddDaysInSubscription
				fullName={fullName}
				userId={userId}
				userEmail={userEmail}
				subscription={subscription}
				onClose={() => setShowAddDaysModal(null)}
			/>
		</Modal>
	);
};

const ChangeSubscriptionModal = ({
	setShowChangeSubscriptionModal,
	showChangeSubscriptionModal: {
		allowedCourses,
		notAllowedCourses,
		userId,
		fullName,
		subscription,
		subscriptionId,
		hasExpired,
		isCancelled,
		isTrial
	}
}: {
	setShowChangeSubscriptionModal: React.Dispatch<React.SetStateAction<number | null>>;
	showChangeSubscriptionModal: IChangeSubscription;
}) => {
	const classes = useStyles();
	return (
		<Modal
			className={classes.modal}
			disableEnforceFocus
			disableAutoFocus
			open={true}
			onClose={() => setShowChangeSubscriptionModal(null)}
		>
			<ManageSubscription
				onClose={() => setShowChangeSubscriptionModal(null)}
				allowedCourses={allowedCourses}
				notAllowedCourses={notAllowedCourses}
				fullName={fullName}
				subscription={subscription}
				subscriptionId={subscriptionId}
				disabled={hasExpired || isCancelled || isTrial || isTrial === undefined}
				userId={userId}
			/>
		</Modal>
	);
};
const ManageStudents = () => {
	const dispatch = useDispatch();

	const [searchText, setSearchText] = useState("");
	const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
	const [showChangeEmailModal, setShowChangeEmailModal] = useState<IChangeEmail | null>(null);
	const [showAddDaysModal, setShowAddDaysModal] = useState<IAddDays | null>(null);

	const { isLoading, students, page, perPage, totalItems } = useSelector(getFullState);
	const { courses } = useSelector(getFullCourseState);

	const handleSearchBarChange = useCallback(
		searchText => {
			return dispatch(getAllStudents({ searchText, page: 1 }));
		},
		[dispatch]
	);

	useEffect(() => {
		dispatch(getAllStudents({}));
		dispatch(fetchAllCourses(true));
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	const tableColumns: Array<IColumn<User>> = useMemo(
		() => [
			{
				alignment: "left",
				width: 400,
				label: <ColumnHeader>Students</ColumnHeader>,
				Cell: ({ rowData: { firstName, lastName, profileImageUrl, email } }) => {
					const fullName = [firstName, lastName].join(" ");
					return (
						<StyledCellWrapper>
							<Avatar variant="rounded" fullName={fullName} profileImageUrl={profileImageUrl} />
							<Box>
								<StyledCellText margin="5px 0 0 0">{fullName}</StyledCellText>
								<StyledCellText margin="0 0 5px 0">{email}</StyledCellText>
							</Box>
						</StyledCellWrapper>
					);
				},
				dataKey: "name"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Subscription</ColumnHeader>,
				Cell: ({ rowData: { subscriptions } }) => (
					<StyledCellWrapper>
						<StyledCellText>
							<SubscriptionStatus subscriptions={subscriptions} />
						</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "subscription"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Enrolled Course</ColumnHeader>,
				Cell: ({ rowData: { subscriptions } }) => {
					const type = getCurrentSubscription(subscriptions).type;
					const allowedCourseName = type ? type.allowedCourses![0].name : "-";
					return (
						<StyledCellWrapper>
							<StyledCellText>{allowedCourseName.toUpperCase()}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "course"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Role</ColumnHeader>,
				Cell: ({}) => (
					<StyledCellWrapper>
						<StyledCellText>Student</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "role"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Enrollment Date</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => {
					return (
						<StyledCellWrapper>
							<StyledCellText>{createdAt && formatAdminDate(createdAt)}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "day"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Subscription Start Date</ColumnHeader>,
				Cell: ({ rowData: { subscriptions } }) => {
					const { createdAt, data } = getCurrentSubscription(subscriptions);
					const subStartDate = data?.subscriptionStartDate ?? createdAt;
					return (
						<StyledCellWrapper>
							<StyledCellText>{subStartDate ? formatAdminDate(subStartDate) : "-"}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "startDate"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData: { subscriptions, firstName, lastName, id, email } }) => {
					const fullName = [firstName, lastName].join(" ");
					const subscription = getCurrentSubscription(subscriptions);
					const { type } = subscription;
					return (
						<ActionMenu
							customMenuItems={[
								{
									label: "Change Subscription",
									onClick: () => setSelectedUserId(id),
									visible: !!type,
									disabled: false
								},
								{
									label: "Change Email",
									onClick: () => setShowChangeEmailModal({ fullName, userEmail: email, userId: id }),
									visible: true,
									disabled: false
								},
								{
									label: "Add Days",
									onClick: () =>
										setShowAddDaysModal({
											fullName,
											userId: id,
											userEmail: email,
											subscription
										}),
									visible: !!type,
									disabled: false
								}
							]}
						/>
					);
				},
				dataKey: "menu"
			}
		],
		[]
	);

	const showChangeSubscriptionModal = useMemo(() => {
		if (!selectedUserId) return null;
		const selectedUser = students?.find(s => s.id === selectedUserId);
		if (!selectedUser) return null;
		const { subscriptions, firstName, lastName, id } = selectedUser;
		const fullName = [firstName, lastName].join(" ");
		const subscription = getCurrentSubscription(subscriptions);
		const { id: subscriptionId, hasExpired, isCancelled, type } = subscription;
		const { isTrial, allowedCourses } = type ?? {};
		const notAllowedCourses = allowedCourses
			? courses?.filter(course => course.id !== allowedCourses![0].id) || []
			: courses;
		return {
			fullName,
			userId: id,
			subscriptionId,
			hasExpired,
			isCancelled,
			isTrial,
			allowedCourses,
			notAllowedCourses,
			subscription
		};
	}, [courses, selectedUserId, students]);

	return (
		<Container>
			<HeaderContainer heading={"Manage Students"} breadcrumb={breadcrumb} />
			<Box mr={4} mt={2}>
				<THeaderWrapper>
					<THeaderTitle>Student Accounts</THeaderTitle>
					<SearchBarComponent
						inputValue={searchText}
						setInputValue={setSearchText}
						onChange={handleSearchBarChange}
						isDebounced={true}
						debounceTime={1000}
						placeHolder={"Type to filter by keywords"}
					/>
				</THeaderWrapper>
				{isLoading ? (
					<ContentLoader height={500} />
				) : (
					<>
						<MaterialTable columns={tableColumns} data={students || []} height={800} />
						<TablePagination
							count={totalItems}
							page={page}
							onChange={(_, page) =>
								dispatch(
									getAllStudents({
										page,
										...(searchText && { searchText })
									})
								)
							}
							rowsPerPage={perPage}
						/>
					</>
				)}
			</Box>
			{!!showChangeEmailModal && (
				<ChangeEmail showChangeEmailModal={showChangeEmailModal} onClose={() => setShowChangeEmailModal(null)} />
			)}
			{!!showChangeSubscriptionModal && (
				<ChangeSubscriptionModal
					setShowChangeSubscriptionModal={setSelectedUserId}
					showChangeSubscriptionModal={showChangeSubscriptionModal}
				/>
			)}
			{!!showAddDaysModal && (
				<AddDaysModal setShowAddDaysModal={setShowAddDaysModal} showAddDaysModal={showAddDaysModal} />
			)}
		</Container>
	);
};

export default ManageStudents;
