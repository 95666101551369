import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { User } from "@remar/shared/dist/models";

import { UserLoginDto, UserLoginResponseDto } from "./dto";

import { URLS } from "../services.constants";

export class UsersService extends BaseApiService<
	User,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	login(data: UserLoginDto): Promise<UserLoginResponseDto> {
		return request(`${this.baseUrl}/login`, "POST", this.emptyStringToNull(data) as Record<string, unknown>, {
			credentials: "include"
		}) as Promise<UserLoginResponseDto>;
	}

	logout(): Promise<void> {
		return request(`${this.baseUrl}/logout`, "POST", {}, { credentials: "include" }, false, true) as Promise<void>;
	}

	whoami(): Promise<{ user: User }> {
		return request(`${this.baseUrl}/whoami`, "GET") as Promise<{ user: User }>;
	}
}

export const usersService = new UsersService(URLS.USERS);
