import React, { useEffect, useState } from "react";

import { Box, Button, Typography, createStyles, makeStyles } from "@material-ui/core";
import { CloudUploadRounded } from "@material-ui/icons";
import { ReactComponent as DeleteIconSvg } from "@remar/shared/dist/assets/icons/icon-trash-filled.svg";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { FilesUploaded } from "@remar/shared/dist/components/FilesUploaded";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";
import Uploader from "@remar/shared/dist/components/Uploader";

import { useDispatch, useSelector } from "react-redux";
import { Subject } from "rxjs";

import {
	bulkUploadFiles,
	deleteFileFromModal,
	getFullState,
	loadLessons,
	setStateValue,
	uploadFileVaultFiles,
	validateForm
} from "store/features/FileVault/FileVault.slice";
import { _emit, emit } from "store/features/notifications/notifications.slice";

import AttachmentDeleteModal from "modules/Components/AttachmentDeleteModal";
import theme from "theme/default";

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			marginBottom: 25
		},
		fileName: {
			fontSize: 12
		},
		fileSize: {
			fontSize: 10,
			color: "#8f9bb3"
		},
		cancelBtn: {
			borderRadius: "4px !important",
			borderColor: "#94979f !important",
			backgroundColor: "transparent !important",
			color: "rgba(255, 255, 255, 0.6) !important",
			borderWidth: "1px !important",
			borderStyle: "solid !important",
			marginRight: "15px !important"
		},
		subTitle: {
			color: "rgba(255, 255, 255, 0.6)",
			marginBottom: "15px"
		}
	})
);

export const FileUploadModal = ({
	modal,
	onCloseModal,
	folderId,
	sideEffect: fetchUploadedFiles,
	isPersonalFolder = false
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { fileVaultUploadForm, uploadFiles } = useSelector(getFullState);
	const { inputs: fileVaultSubFolderModalFormInputs } = fileVaultUploadForm;
	const [mainVideoUploadError, setMainVideoUploadError] = useState(false);
	const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
	const [mainVideoProgress, setMainVideoProgress] = useState<number>(0);
	const [attachment, setAttachment] = useState<Record<string, unknown>>({});
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

	const clearFileInputSubject = new Subject<void>();
	const handleDelete = (fileName: string | undefined, sideEffect): void => {
		dispatch(deleteFileFromModal({ fileName, sideEffect }));
	};

	useEffect(() => {
		modal && dispatch(loadLessons());
	}, [dispatch, modal]);

	const sideEffect = () => {
		setShowDeleteModal(false);
	};

	const handleUpload = async (file: Partial<File>, statePath: string): Promise<void> => {
		const methods = {
			clearFileInput: () => clearFileInputSubject.next(),
			progress: setMainVideoProgress,
			uploadError: setMainVideoUploadError
		};
		setAttachmentLoading(true);
		dispatch(
			uploadFileVaultFiles({
				file,
				statePath,
				options: {
					onError: () => {
						setAttachmentLoading(false);
						methods.uploadError(true);
					},
					onProgress: ({ loaded, total }) => {
						methods.progress((loaded / total) * 100);
					},
					onUploaded: () => {
						methods.clearFileInput();
						setAttachmentLoading(false);
					}
				}
			})
		);
		methods.uploadError(false);
	};

	const onDone = () => {
		dispatch(bulkUploadFiles({ folderId, sideEffect: fetchUploadedFiles }));
		dispatch(
			emit({
				message: ` ${uploadFiles.files.length > 1 ? "Files" : "File"} added successfully.`,
				color: "success"
			})
		);
		onCloseModal();
	};

	return (
		<>
			<SimpleModal
				theme={theme}
				title={"Upload Files"}
				open={modal}
				onClose={() => onCloseModal(false)}
				text={""}
				modalWidth={1090}
				extraContent={
					<Box>
						<Box display="flex" width="100%" flexWrap="wrap" justifyContent="space-between" mb={"67px"}>
							<Box display="flex" flexDirection={"column"} width="50%">
								<Box>
									<Typography className={classes.subTitle}>Upload Files</Typography>
								</Box>
								<Uploader
									accept={"application/pdf,.pptx,.jpg,.jpeg,.jpe,.ppt"}
									boxProps={{ maxWidth: "569px" }}
									clearFileInputSubject={clearFileInputSubject}
									error={mainVideoUploadError}
									header="Upload Media"
									icon={<CloudUploadRounded name="cloud-upload" style={{ width: "50px", height: "50px" }} />}
									loading={attachmentLoading}
									onAdd={files => handleUpload(files[0], "uploadFiles")}
									progress={mainVideoProgress}
									title="Drag and Drop File Here or Browse to Choose a File"
									dragFileError="File type is not allowed."
									emit={emit}
								/>
							</Box>

							<Box width="50%" paddingLeft={"30px"}>
								{!isPersonalFolder && (
									<Box width="100%" marginBottom={"16px"}>
										<Box>
											<Typography className={classes.subTitle}>Choose Lesson</Typography>
										</Box>
										<CustomInput
											width={"100%"}
											theme={theme}
											options={{
												...defaultCustomInputOptions,
												inputData: fileVaultSubFolderModalFormInputs.lessonIds
											}}
										/>
									</Box>
								)}
								<Box width="100%">
									<Box width={"100%"}>
										<Typography className={classes.subTitle}>Files Uploaded</Typography>
										<FilesUploaded
											showTitle={false}
											attachments={uploadFiles.files}
											IconSvg={DeleteIconSvg}
											onClick={attachment => {
												setShowDeleteModal(true);
												setAttachment(attachment);
											}}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				}
				footer={
					<>
						<Button
							variant={"contained"}
							className={classes.cancelBtn}
							size="medium"
							onClick={() => onCloseModal(false)}
						>
							Cancel
						</Button>
						<Button
							variant={"contained"}
							color={"primary"}
							size="medium"
							onClick={onDone}
							disabled={!uploadFiles.files.length}
						>
							Done
						</Button>
					</>
				}
			/>
			<AttachmentDeleteModal
				text={`Are you sure you want to delete <span>${attachment?.name}</span> ?`}
				modalOpen={showDeleteModal}
				setModalOpen={setShowDeleteModal}
				onConfirm={() => {
					handleDelete(attachment?.fileName as string, sideEffect);
				}}
			/>
		</>
	);
};
