import React, { useState } from "react";

import HotspotHighlightInput from "@remar/shared/dist/components/HotspotHighlightInput/HotspotHighlightInput";

import { HighlightTestQuizQuestionText, HotspotHighlightQuestionBox } from "./style";

const HotspotHighlightQuestionPreview = ({ question }) => {
	const { data } = question;
	const [value, setValue] = useState<
		{
			highlighted: boolean;
			text: string;
		}[]
	>([{ highlighted: false, text: data.text }]);

	const disableInput = event => {
		event.preventDefault();
	};

	return (
		<>
			<HighlightTestQuizQuestionText>{question?.text}</HighlightTestQuizQuestionText>
			<HotspotHighlightQuestionBox onKeyPress={disableInput} onKeyDown={disableInput} onPaste={disableInput}>
				<HotspotHighlightInput value={value} onChange={newValue => setValue(newValue)} student={true} />
			</HotspotHighlightQuestionBox>
		</>
	);
};

export default HotspotHighlightQuestionPreview;
