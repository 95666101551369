import { Color } from "@material-ui/lab";
import { PayloadAction, createAsyncThunk, createSlice, unwrapResult } from "@reduxjs/toolkit";
import {
	CustomInputModel,
	CustomInputSelectOptionsItem,
	CustomInputType,
	DefaultValueGetter,
	DefaultValueGettersObject
} from "@remar/shared/dist/components/CustomInput/customInput.model";
import { validateInput } from "@remar/shared/dist/components/CustomInput/customInput.utils";
import {
	CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH,
	LessonRanks,
	LessonTypes,
	LessonVideoSegmentTypeIdMap,
	LessonVideoSegmentTypeIds,
	LessonVideoSegmentTypes,
	MAX_GROUP_TEXT_LENGTH,
	MAX_TAB_CHARACTER_LENGTH,
	MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH,
	QuestionDifficultyLevels,
	QuestionTypes
} from "@remar/shared/dist/constants";
import {
	Course,
	CourseChapter,
	CourseChapterSection,
	Lesson,
	LessonDraftData,
	LessonDraftInteractiveBlock,
	LessonDraftQuestionAnswerOptionDraftItem,
	LessonDraftQuestionItem,
	LessonVideo
} from "@remar/shared/dist/models";
import {
	CreateFormOptionsInputItem,
	CreateFormOptionsInputsObject,
	FormInputItemsModel,
	FormStateModel
} from "@remar/shared/dist/utils/form/form.model";
import {
	createForm,
	getPopulateInputsAction,
	parseTemplate,
	updateFormInputsPath,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";
import { getVideoDuration, performFileUpload } from "@remar/shared/dist/utils/serviceUtils/uploaders";
import {
	getResetState,
	populateData as utilsPopulateData,
	setStateValue as utilsSetStateValue
} from "@remar/shared/dist/utils/stateUtils";
import { getRemainingMinutes, toMilliseconds } from "@remar/shared/dist/utils/timeUtils";
import { hoursToMinutes, millisecondsToMinutes, millisecondsToSeconds, minutesToHours } from "date-fns";
import { cloneDeep, get, omit, set } from "lodash";
import { RootState } from "store";
import {
	LessonDraftCreateDataDto,
	LessonDraftUpdateDataObjectDto,
	LessonInteractiveBlockDraftDto,
	QuestionCreateQuestionAnswerOptionsItemDto,
	courseChapterSectionsService,
	coursesService,
	filesService,
	lessonDraftsService,
	lessonRanksService,
	lessonTypesService,
	lessonVideosService,
	lessonsService,
	questionTypesService
} from "store/services";
import { v4 as uuid } from "uuid";

import { CreateLessonSteps } from "./createLesson.constants";
import {
	CreateLessonState,
	Group,
	InteractiveLessonFormInputs,
	InteractiveLessonFormInteractiveBlockRawData,
	InteractiveLessonFormRawData,
	InteractiveLessonFormVideoSegmentRawData,
	LessonDetailsFormInputs,
	LessonDetailsFormRawData,
	LessonDraftDataExtended,
	LessonDraftExtended,
	SectionIdCourseIdMap,
	TestOnlyQuestionsFormAnswerGroupInputs,
	TestOnlyQuestionsFormAnswerGroupOptionInputs,
	TestOnlyQuestionsFormInputs,
	TestOnlyQuestionsFormQuestionItemInputs,
	TestOnlyQuestionsFormQuestionItemRawData,
	TestOnlyQuestionsFormRawData,
	VideoBasicsFormInputs,
	VideoBasicsFormRawData
} from "./models";

import { emit } from "../notifications/notifications.slice";

/*
 * State config setup
 */

const defaultValueGetters: DefaultValueGettersObject = {
	uuidV4: (() => uuid()) as DefaultValueGetter
};

const getBaseVideoSegmentConfig = (typeId: number): CreateFormOptionsInputsObject => ({
	deleted: {
		defaultValue: false,
		type: CustomInputType.Checkbox
	},
	id: { type: CustomInputType.Number },
	lessonVideoSegmentDraftId: {
		type: CustomInputType.Text,
		useDefaultValueGetterName: "uuidV4"
	},
	typeId: {
		defaultValue: typeId,
		type: CustomInputType.Text
	},
	startMin: {
		defaultValue: 0,
		type: CustomInputType.Number,
		validations: { isInteger: true, min: 0, required: true }
	},
	startSec: {
		defaultValue: 0,
		type: CustomInputType.Number,
		validations: { isInteger: true, min: 0, required: true }
	}
});

const getQuestionAnswerOptionConfig = () => ({
	id: { type: CustomInputType.Text, useDefaultValueGetterName: "uuidV4" },
	isCorrect: { type: CustomInputType.Checkbox },
	text: {
		label: "Option",
		placeholder: "Enter Option",
		type: CustomInputType.Text,
		validations: { required: true, maxLength: 40 }
	},
	order: { defaultValue: 1, type: CustomInputType.Number },
	deleted: {
		defaultValue: false,
		type: CustomInputType.Checkbox
	}
});

const getQuestionConfig = (
	options: {
		defaultDifficultyLevelId?: number;
		endOfQuestionSummaryRequired?: boolean;
		textRequired?: boolean;
		questionTextLength?: number;
		skipExtendedQuestions?: boolean;
		nested?: boolean;
		descriptionRequired?: boolean;
	} = {
		defaultDifficultyLevelId: QuestionDifficultyLevels.Normal,
		endOfQuestionSummaryRequired: true,
		textRequired: true,
		questionTextLength: 100,
		nested: false,
		descriptionRequired: false
	}
): CreateFormOptionsInputsObject => ({
	_expanded: {
		defaultValue: true,
		type: CustomInputType.Checkbox
	},
	id: { type: CustomInputType.Number },
	typeId: {
		defaultValue: QuestionTypes.SingleChoice,
		label: "Question Type",
		placeholder: "Question Type",
		selectOptions: [],
		type: CustomInputType.Select,
		validations: { isNumber: true, required: true }
	},
	description: {
		label: "Type question description",
		placeholder: "Type question description",
		type: CustomInputType.Editor,
		defaultValue: "",
		validations: {
			...(options.descriptionRequired && { required: true }),
			maxLength: 5000
		}
	},
	tableLabel: {
		label: "Type your label here",
		placeholder: "Type your label here",
		type: CustomInputType.Text,
		validations: { maxLength: 100 }
	},
	answerOptionLabel: {
		label: "Option Label",
		placeholder: "Enter Option Label",
		type: CustomInputType.Text,
		validations: { maxLength: 100 }
	},
	difficultyLevelId: {
		defaultValue: options.defaultDifficultyLevelId,
		label: "Difficulty Level",
		placeholder: "Difficulty Level",
		selectOptions: [],
		type: CustomInputType.Select,
		validations: { isNumber: true, required: true }
	},
	questionDraftId: {
		type: CustomInputType.Text,
		useDefaultValueGetterName: "uuidV4"
	},
	text: {
		label: "Type your question here",
		placeholder: "Type your question here",
		type: CustomInputType.Text,
		validations: {
			maxLength: options.questionTextLength,
			...(options.textRequired ? { required: true } : {})
		}
	},
	endOfQuestionSummary: {
		label: "Type your rationale here",
		placeholder: "Type your rationale here",
		type: CustomInputType.Multiline,
		validations: {
			...(options.endOfQuestionSummaryRequired ? { required: true } : {}),
			maxLength: 500
		}
	},
	mainImageKey: {
		imageExtensionName: "png",
		imageFileType: "image/png",
		isImageFile: true,
		label: "Upload Photo",
		placeholder: "Upload Photo",
		type: CustomInputType.File,
		uploaderIconName: "cloud-upload",
		uploaderStateLoaderKey: "fileIsUploading"
	},
	isActive: {
		defaultValue: true,
		type: CustomInputType.Checkbox
	},
	deleted: {
		defaultValue: false,
		type: CustomInputType.Checkbox
	},
	attachments: [
		{
			id: { type: CustomInputType.Number },
			fileType: { type: CustomInputType.Text },
			name: { type: CustomInputType.Text },
			fileName: { type: CustomInputType.Text },
			fileSizeInBytes: { type: CustomInputType.Number }
		}
	],
	...(options.skipExtendedQuestions
		? undefined
		: {
				tabs: [
					{
						id: { type: CustomInputType.Text, useDefaultValueGetterName: "uuidV4" },
						text: { defaultValue: "", type: CustomInputType.Editor, validations: { required: true, maxLength: 5000 } },
						title: {
							defaultValue: "New tab",
							type: CustomInputType.Text,
							validations: { required: true, maxLength: MAX_TAB_CHARACTER_LENGTH }
						},
						order: { defaultValue: 1, type: CustomInputType.Number },
						deleted: { defaultValue: false, type: CustomInputType.Checkbox }
					}
				]
		  }),
	groups: [
		{
			id: { type: CustomInputType.Text, useDefaultValueGetterName: "uuidV4" },
			text: {
				label: "Category",
				placeholder: "Enter Category",
				type: CustomInputType.Text,
				validations: { required: true }
			},
			order: { defaultValue: 1, type: CustomInputType.Number },
			deleted: { defaultValue: false, type: CustomInputType.Checkbox },
			selectedAnswerOptions: { type: CustomInputType.Text, defaultValue: [] },
			answerOptions: [getQuestionAnswerOptionConfig()]
		}
	],
	answerOptions: [
		{
			...getQuestionAnswerOptionConfig(),
			_editActive: {
				defaultValue: false,
				type: CustomInputType.Checkbox
			},
			isCorrect: {
				type: CustomInputType.Checkbox,
				selectOptions: [{ text: "", value: true }],
				validations: { required: true }
			} as CreateFormOptionsInputItem<unknown>,
			canAttachFiles: {
				defaultValue: false,
				type: CustomInputType.Checkbox
			},
			note: {
				label: "Note",
				placeholder: "Note",
				type: CustomInputType.Multiline
			}
		}
	],
	...(options.nested && {
		questions: [
			{
				id: { type: CustomInputType.Text, useDefaultValueGetterName: "uuidV4" },
				...getQuestionConfig({
					defaultDifficultyLevelId: QuestionDifficultyLevels.Normal,
					questionTextLength: 300,
					endOfQuestionSummaryRequired: true,
					descriptionRequired: true
				})
			}
		]
	})
});

const getInteractiveBlockConfig = () => {
	return {
		id: { type: CustomInputType.Number },
		questionDraftId: { type: CustomInputType.Text, useDefaultValueGetterName: "uuidV4" },
		questionId: { type: CustomInputType.Number },
		deleted: {
			defaultValue: false,
			type: CustomInputType.Checkbox
		},
		videoId: { type: CustomInputType.Number },
		order: { defaultValue: 1, type: CustomInputType.Number },
		question: getQuestionConfig({
			defaultDifficultyLevelId: QuestionDifficultyLevels.Normal,
			endOfQuestionSummaryRequired: false,
			descriptionRequired: false
		}),
		[LessonVideoSegmentTypes.Start]: getBaseVideoSegmentConfig(LessonVideoSegmentTypeIds.Start),
		[LessonVideoSegmentTypes.Question]: {
			...getBaseVideoSegmentConfig(LessonVideoSegmentTypeIds.Question),
			endMin: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0, required: true }
			},
			endSec: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0, required: true }
			}
		},
		[LessonVideoSegmentTypes.Loop]: {
			...getBaseVideoSegmentConfig(LessonVideoSegmentTypeIds.Loop),
			endMin: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0, required: true }
			},
			endSec: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0, required: true }
			}
		},
		[LessonVideoSegmentTypes.Incorrect]: {
			...getBaseVideoSegmentConfig(LessonVideoSegmentTypeIds.Incorrect),
			endMin: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0, required: true }
			},
			endSec: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0, required: true }
			}
		},
		[LessonVideoSegmentTypes.Correct]: {
			...getBaseVideoSegmentConfig(LessonVideoSegmentTypeIds.Correct),
			endMin: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0, required: true }
			},
			endSec: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0, required: true }
			}
		}
	};
};
export const initialState: CreateLessonState = {
	chapters: [],
	chapterOptions: [],
	currentStep: CreateLessonSteps.LessonDetails,
	courses: [],
	courseOptions: [],
	fileIsUploading: false,
	initialDataLoaded: false,
	lessonAttachments: { attachments: [], isLoading: false, isDeleteLoading: false },
	interactiveLessonFormGroup: createForm<InteractiveLessonFormInputs, InteractiveLessonFormRawData, {}>({
		defaultValueGetters,
		generateTemplatesFromPaths: [
			"interactiveBlocks",
			"interactiveBlocks.0.question.answerOptions",
			"interactiveBlocks.0.question.groups",
			"interactiveBlocks.0.question.groups.answerOptions"
		],
		inputs: {
			interactiveBlocks: [getInteractiveBlockConfig()],
			selectedInteractiveBlockIndex: { type: CustomInputType.Number, defaultValue: 0 },
			videoDuration: { type: CustomInputType.Number },
			selectedSegmentType: { defaultValue: LessonVideoSegmentTypes.Start, type: CustomInputType.Text }
		},
		statePath: "interactiveLessonFormGroup"
	}),
	isIntro: false,
	isLoading: false,
	lesson: undefined,
	lessonDetailsForm: createForm<LessonDetailsFormInputs, LessonDetailsFormRawData, {}>({
		defaultValueGetters,
		inputs: {
			id: {
				type: CustomInputType.Number
			},
			courseIds: {
				label: "Select Course",
				placeholder: "Select Course",
				selectOptions: [],
				type: CustomInputType.Chips,
				validations: { isArray: true }
			},
			chapterIds: {
				label: "Select Chapter",
				placeholder: "Select Chapter",
				selectOptions: [],
				type: CustomInputType.Chips,
				validations: { isArray: true }
			},
			sectionIds: {
				label: "Select Section",
				placeholder: "Select Section",
				selectOptions: [],
				type: CustomInputType.Chips,
				validations: { isArray: true, required: true }
			},
			name: {
				label: "Enter Lesson Name",
				placeholder: "Enter Lesson Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 140 }
			},
			description: {
				label: "Enter Description",
				placeholder: "Enter Description",
				type: CustomInputType.Multiline,
				validations: { required: true }
			},
			rankId: {
				defaultValue: LessonRanks.One,
				label: "Lesson Rank",
				placeholder: "Lesson Rank",
				selectOptions: [],
				type: CustomInputType.Select,
				validations: { isNumber: true, required: true }
			},
			typeId: {
				label: "Lesson Type",
				placeholder: "Lesson Type",
				selectOptions: [],
				type: CustomInputType.Radio,
				validations: { isNumber: true, required: true }
			},
			isActive: {
				defaultValue: false,
				type: CustomInputType.Checkbox
			},
			mainImageKey: {
				imageExtensionName: "png",
				imageFileType: "image/png",
				isImageFile: true,
				label: "Upload Thumbnail",
				placeholder: "Upload Thumbnail",
				type: CustomInputType.File,
				uploaderIconName: "camera",
				uploaderStateLoaderKey: "fileIsUploading"
			}
		},
		statePath: "lessonDetailsForm"
	}),
	lessonDraft: undefined,
	lessonRankOptions: [],
	lessonTypeOptions: [],
	questionTypeOptionsForTestLesson: [],
	lessonVideoMainOptions: [],
	lessonVideoTrailerOptions: [],
	mainLessonVideos: [],
	saveIsLoading: false,
	sectionIdCourseIdMap: {},
	sections: [],
	sectionOptions: [],
	testOnlyPreviewQuestions: [],
	testOnlyQuestionsForm: createForm<TestOnlyQuestionsFormInputs, TestOnlyQuestionsFormRawData, {}>({
		defaultValueGetters,
		generateTemplatesFromPaths: [
			"tabs",
			"questions",
			"questions.0.answerOptions",
			"questions.0.groups",
			"questions.0.groups.answerOptions",
			"attachments"
		],
		inputs: {
			passingThreshold: {
				defaultValue: 95,
				selectOptions: [],
				type: CustomInputType.Number,
				validations: { isNumber: true, max: 100, min: 0, required: true }
			},
			durationHour: {
				type: CustomInputType.Number,
				validations: { isNumber: true, min: 0, max: 10, required: true }
			},
			durationMin: {
				type: CustomInputType.Number,
				validations: { isNumber: true, max: 59, min: 0, required: true }
			},
			questions: [
				getQuestionConfig({
					defaultDifficultyLevelId: QuestionDifficultyLevels.Normal,
					questionTextLength: 300,
					endOfQuestionSummaryRequired: true,
					textRequired: false,
					descriptionRequired: true,
					nested: true
				})
			]
		},
		statePath: "testOnlyQuestionsForm"
	}),
	videoBasicsForm: createForm<VideoBasicsFormInputs, VideoBasicsFormRawData, {}>({
		defaultValueGetters,
		inputs: {
			trailerId: {
				emptySelectOption: { text: "No video selected", value: "" },
				hasClearButton: true,
				label: "Trailer",
				placeholder: "Trailer",
				selectOptions: [],
				type: CustomInputType.Select,
				validations: { isNumber: true }
			},
			videoId: {
				emptySelectOption: { text: "No video selected", value: "" },
				hasClearButton: true,
				label: "Main Video",
				placeholder: "Main Video",
				selectOptions: [],
				type: CustomInputType.Select,
				validations: { isNumber: true, required: true }
			}
		},
		statePath: "videoBasicsForm"
	}),
	vitPreviewIsOpen: false,
	videoLoaded: false
};

/*
 * End of state config setup
 */

/*
 * Utility functions
 */

const fixSubItemsPathsAfterInsert = (
	data:
		| CustomInputModel<unknown>
		| Record<string, CustomInputModel<unknown>>
		| Record<string, CustomInputModel<unknown>>[],
	options: {
		pathToReplace: string;
		pathToReplaceWith: string;
	}
): void => {
	const { pathToReplace, pathToReplaceWith } = options;
	if (data instanceof Array) {
		for (const i in data) {
			fixSubItemsPathsAfterInsert(data[i], { pathToReplace, pathToReplaceWith });
		}
		return;
	}
	if (data === null || typeof data !== "object" || data instanceof Date) {
		return;
	}
	for (const key in data) {
		const currentItemData = data[key] as CustomInputModel<unknown>;
		if (currentItemData.statePath) {
			currentItemData.statePath = currentItemData.statePath.replace(pathToReplace, pathToReplaceWith);
			continue;
		}
		fixSubItemsPathsAfterInsert(currentItemData, { pathToReplace, pathToReplaceWith });
	}
};

/*
 * extract the data from all forms, regardless of whether they are valid or not,
 * so we can use it to create a new lessonDraft that contains all the data we need for the UI to work
 * properly for existing, fully-built lessons, even without a pre-existing drafts for them
 */
const getDataForNewDraft = createAsyncThunk("createLesson/getDataForNewDraft", async (_, { dispatch, getState }) => {
	try {
		dispatch(validateForm({ formStatePath: "lessonDetailsForm", markInputsAsDirty: false }));
		dispatch(validateForm({ formStatePath: "videoBasicsForm", markInputsAsDirty: false }));
		dispatch(validateForm({ formStatePath: "testOnlyQuestionsForm", markInputsAsDirty: false }));
		const { createLesson: state } = getState() as { createLesson: CreateLessonState };
		const { lessonDetailsForm, videoBasicsForm, testOnlyQuestionsForm } = state;
		let data: LessonDraftCreateDataDto = { ...lessonDetailsForm.rawData };
		if (data.typeId === LessonTypes.TestOnly) {
			const questions = testOnlyQuestionsForm.rawData.questions.map(q => {
				const { answerOptions, groups, ...rest } = q;
				return { ...rest, data: { answerOptions, groups } };
			});
			data = { ...data, ...testOnlyQuestionsForm.rawData, questions };
		} else {
			data = {
				...data,
				...videoBasicsForm.rawData
			};
			if (data.typeId === LessonTypes.Interactive) {
				data = {
					...data,
					interactiveBlocks: processInteractiveLessonFormInteractiveBlocks(state) as LessonInteractiveBlockDraftDto[]
				};
			}
		}
		return { data };
	} catch (e) {
		return { error: e };
	}
});

/*
 * check the current index and whether the form is valid before changing the index;
 * if the forms are valid, put the data for the currently selected block into the block list
 */
const performCurrentInteractiveLessonFormValidation = (
	state: CreateLessonState
): { error?: { color: Color; message: string } } | void => {
	const { interactiveLessonFormGroup, selectedMainLessonVideo, currentStep } = state;
	const { interactiveBlocks, selectedInteractiveBlockIndex, videoDuration } = interactiveLessonFormGroup.rawData;
	let currentInteractiveBlock;
	const currentBlockInput = interactiveLessonFormGroup.inputs.interactiveBlocks[selectedInteractiveBlockIndex];
	if (currentBlockInput) {
		const questionDraftId = currentBlockInput.questionDraftId.value;
		const rawSelectedIndex = interactiveBlocks.findIndex(i => i.questionDraftId === questionDraftId);
		currentInteractiveBlock = interactiveBlocks[rawSelectedIndex];
	}
	if (!currentInteractiveBlock) {
		return;
	}
	if (!interactiveLessonFormGroup.valid) {
		return {
			error: {
				message: "Please fill in all inputs for current interactive block correctly and try again.",
				color: "error"
			}
		};
	}
	if (!selectedMainLessonVideo && currentStep === CreateLessonSteps.LessonVIT) {
		return {
			error: {
				message: `Please Select a video from the ${CreateLessonSteps.LessonMaterials} section to proceed`,
				color: "error"
			}
		};
	}
	for (const key in LessonVideoSegmentTypes) {
		const segmentType = LessonVideoSegmentTypes[key];
		const segment = currentInteractiveBlock[segmentType] as InteractiveLessonFormVideoSegmentRawData;
		if (videoDuration && segment) {
			const msVideoDuration = videoDuration * 1000;
			let endTime = transformInputsToDuration(segment.endMin, segment.endSec);
			const startTime = transformInputsToDuration(segment.startMin, segment.startSec);
			if (endTime > msVideoDuration) {
				return {
					error: {
						message: `The end time of the "${key}" video block exceeds the video duration.`,
						color: "error"
					}
				};
			}
			if (segmentType === LessonVideoSegmentTypes.Start) {
				const questionSegment = currentInteractiveBlock[LessonVideoSegmentTypes.Question];
				endTime = transformInputsToDuration(questionSegment.startMin, questionSegment.startSec);
				if (endTime <= startTime) {
					return {
						error: {
							message:
								"The start time of the Question video block must be greater than the start time of Start video block",
							color: "error"
						}
					};
				}
			} else {
				if (endTime <= startTime) {
					return {
						error: {
							message: `The end time of the "${key}" video block equals or exceeds the start time`,
							color: "error"
						}
					};
				}
			}
		}
	}
};

const setGroupAnswerOptionMaxLengthValidation = group => {
	group.text.validations.maxLength = 25;
	group.answerOptions.forEach(answerOption => {
		if (answerOption.text.validations) {
			answerOption.text.validations.maxLength = 25;
		}
	});
	return group;
};

const performQuestionAnswerOptionsInputTypeUpdate = (
	state: CreateLessonState,
	data: { formPath: string; questionPath: string; templatePath: string; typeId?: string }
) => {
	const { formPath, questionPath, templatePath, typeId: incomingTypeId } = data;
	const { answerOptions, groups, typeId: typeIdInput } = get(state, questionPath);
	let { value: typeId } = typeIdInput;
	let answerOptionInputType: CustomInputType | null = null;
	let hasCorrectValue = false;
	let updateIsCorrectValues = false;
	if (incomingTypeId) typeId = incomingTypeId;
	if (typeId === QuestionTypes.SingleChoice) {
		answerOptionInputType = CustomInputType.Radio;
		updateIsCorrectValues = true;
	} else if (typeId === QuestionTypes.MultipleChoice) {
		answerOptionInputType = CustomInputType.Checkbox;
	}
	if (typeId === QuestionTypes.SingleChoice || typeId === QuestionTypes.MultipleChoice) {
		answerOptions.forEach(answerOptionItem => {
			const { statePath, value } = answerOptionItem.isCorrect;
			set(state, `${statePath}.type`, answerOptionInputType);
			if (!updateIsCorrectValues) {
				return;
			}
			if (value) {
				if (hasCorrectValue) {
					set(state, `${statePath}.value`, false);
				} else {
					hasCorrectValue = true;
				}
			}
		});
	} else if (typeId === QuestionTypes.Grouping) {
		answerOptions.forEach(item => {
			set(state, `${item.text.statePath}.validations`, undefined);
			if (item.isCorrect) {
				set(state, `${item.isCorrect.statePath}.validations`, undefined);
			}
		});
		if (!groups.length) {
			const answerOptionGroupItemTemplate = get(state, formPath).templates[templatePath];
			set(state, `${questionPath}.groups`, [
				setGroupAnswerOptionMaxLengthValidation(
					parseTemplate(answerOptionGroupItemTemplate, `${questionPath}.groups.0`, {
						defaultValueGetters
					})
				) as TestOnlyQuestionsFormAnswerGroupInputs,
				setGroupAnswerOptionMaxLengthValidation(
					parseTemplate(answerOptionGroupItemTemplate, `${questionPath}.groups.1`, {
						defaultValueGetters
					})
				) as TestOnlyQuestionsFormAnswerGroupInputs
			]);
			return;
		}
		if (groups.length === 1) {
			const answerOptionGroupItemTemplate = get(state, formPath).templates[templatePath];
			set(state, `${questionPath}.groups`, [
				...get(state, `${questionPath}.groups`),
				setGroupAnswerOptionMaxLengthValidation(
					parseTemplate(answerOptionGroupItemTemplate, `${questionPath}.groups.1`, {
						defaultValueGetters
					})
				) as TestOnlyQuestionsFormAnswerGroupInputs
			]);
		}
		const { groups: groupsConfig } = getQuestionConfig();
		const { validations: groupTextValidations } = groupsConfig![0].text;
		const { validations: questionTextValidations } = groupsConfig![0].answerOptions[0].text;
		groups.forEach(group => {
			set(state, `${group.text.statePath}.validations`, { ...groupTextValidations, maxLength: 25 });
			group.answerOptions.forEach(answerOptionItem => {
				set(state, `${answerOptionItem.text.statePath}.validations`, { ...questionTextValidations, maxLength: 25 });
			});
		});
		return;
	}
	const { answerOptions: answerOptionsConfig } = getQuestionConfig();
	const { validations: answerOptionsTextValidations } = answerOptionsConfig![0].text;
	const { validations: answerOptionsIsCorrectValidations } = answerOptionsConfig![0].isCorrect;
	groups.forEach(group => {
		set(state, `${group.text.statePath}.validations`, undefined);
		group.answerOptions.forEach(answerOptionItem => {
			set(state, `${answerOptionItem.text.statePath}.validations`, undefined);
		});
	});
	answerOptions.forEach(item => {
		set(state, `${item.text.statePath}.validations`, answerOptionsTextValidations);
		if (item.isCorrect) {
			set(state, `${item.isCorrect.statePath}.validations`, answerOptionsIsCorrectValidations);
		}
	});

	answerOptions.forEach(item => {
		set(state, `${item.text.statePath}.validations.maxLength`, 40);
	});
};

const processInteractiveLessonFormInteractiveBlocks = (state: CreateLessonState): LessonDraftInteractiveBlock[] => {
	const { interactiveLessonFormGroup, lessonDraft } = state;
	const videoId = lessonDraft!.data.videoId!;
	const interactiveBlocks: LessonDraftInteractiveBlock[] = [];
	interactiveLessonFormGroup.rawData.interactiveBlocks.forEach(block => {
		const { id, question, deleted } = block;
		if (deleted) {
			return;
		}
		const newQuestionAnswerOptions: LessonDraftQuestionAnswerOptionDraftItem[] = [];
		const { answerOptions, groups, ...rest } = question;
		answerOptions.forEach(a => {
			if (a.deleted) {
				return;
			}
			newQuestionAnswerOptions.push({ ...a, order: +newQuestionAnswerOptions.length + 1 });
		});
		const updatedQuestion = {
			...rest,
			data: {
				answerOptions: newQuestionAnswerOptions,
				groups
			}
		};
		if (updatedQuestion.typeId !== QuestionTypes.Grouping) updatedQuestion.data.groups = [];
		if (updatedQuestion.typeId === QuestionTypes.Grouping) updatedQuestion.data.answerOptions = [];
		updatedQuestion.attachments = [];
		const questionSegment = {
			...block[LessonVideoSegmentTypes.Question],
			startsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Question].startMin,
				block[LessonVideoSegmentTypes.Question].startSec
			),
			endsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Question].endMin,
				block[LessonVideoSegmentTypes.Question].endSec
			)
		};
		const loopSegment = {
			...block[LessonVideoSegmentTypes.Loop],
			startsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Loop].startMin,
				block[LessonVideoSegmentTypes.Loop].startSec
			),
			endsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Loop].endMin,
				block[LessonVideoSegmentTypes.Loop].endSec
			)
		};
		const startSegment = {
			...block[LessonVideoSegmentTypes.Start],
			startsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Start].startMin,
				block[LessonVideoSegmentTypes.Start].startSec
			),
			endsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Question].startMin,
				block[LessonVideoSegmentTypes.Question].startSec
			)
		};
		const correctSegment = {
			...block[LessonVideoSegmentTypes.Correct],
			startsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Correct].startMin,
				block[LessonVideoSegmentTypes.Correct].startSec
			),
			endsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Correct].endMin,
				block[LessonVideoSegmentTypes.Correct].endSec
			)
		};
		const incorrectSegment = {
			...block[LessonVideoSegmentTypes.Incorrect],
			startsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Incorrect].startMin,
				block[LessonVideoSegmentTypes.Incorrect].startSec
			),
			endsAtMilliseconds: transformInputsToDuration(
				block[LessonVideoSegmentTypes.Incorrect].endMin,
				block[LessonVideoSegmentTypes.Incorrect].endSec
			)
		};
		interactiveBlocks.push({
			id,
			question: updatedQuestion,
			questionId: question.id,
			videoId,
			order: +interactiveBlocks.length + 1,
			segments: [questionSegment, loopSegment, startSegment, correctSegment, incorrectSegment]
		});
	});
	return interactiveBlocks;
};

const transformDurationToInputs = (ms: number, prefix: string): { [inputName: string]: number } => {
	const minutes = String(millisecondsToMinutes(ms));
	const seconds = String(millisecondsToSeconds(ms));

	return { [`${prefix}Min`]: parseInt(minutes, 10), [`${prefix}Sec`]: parseInt(seconds, 10) % 60 };
};

export const transformInputsToDuration = (min: number, sec: number): number => toMilliseconds(min, sec);
export const transformInputsToSeconds = (
	segment: FormStateModel<{}, InteractiveLessonFormVideoSegmentRawData, {}>,
	prefix: string
): number => {
	const { inputs } = segment;
	return inputs[`${prefix}Min`].value! * 60 + inputs[`${prefix}Sec`].value!;
};

const utilsPopulateInputs = getPopulateInputsAction<CreateLessonState>({ defaultValueGetters });
const utilsResetState = getResetState<CreateLessonState>(initialState);

/*
 * End of utility functions
 */

export const uploadQuestionMaterial = createAsyncThunk(
	"createLesson/uploadMedia",
	async (
		data: {
			file: Partial<File>;
			fullStatePath: string;
			options: {
				onError: () => void;
				onProgress: ({}: { loaded: number; total: number }) => void;
				onUploaded: () => void;
			};
		},
		{ dispatch }
	) => {
		const { file, options, fullStatePath } = data;
		const attachmentLoaderStatePath = "testOnlyQuestionsForm.isLoading";
		try {
			dispatch(setStateValue({ key: attachmentLoaderStatePath, value: true }));
			const { key } = await performFileUpload({ file }, options);

			await dispatch(
				createQuestionAttachment({
					fullStatePath,
					name: file.name!,
					fileName: key,
					fileType: file.type,
					fileSizeInBytes: file.size
				})
			);

			options.onUploaded();
		} catch (e) {
			console.error(e);
			options.onError();
			dispatch(emit({ message: "An error has occured while uploading.", color: "error" }));
		} finally {
			dispatch(setStateValue({ key: attachmentLoaderStatePath, value: false }));
		}
	}
);

export const uploadLessonMaterial = createAsyncThunk(
	"createLesson/uploadMedia",
	async (
		data: {
			file: Partial<File>;
			lessonId?: number;
			statePath: string;
			options: {
				onError: () => void;
				onProgress: ({}: { loaded: number; total: number }) => void;
				onUploaded: () => void;
			};
		},
		{ dispatch }
	) => {
		const { file, lessonId, statePath, options } = data;
		const attachmentLoaderStatePath = `${statePath}.isLoading`;
		try {
			dispatch(setStateValue({ key: attachmentLoaderStatePath, value: true }));
			const { key } = await performFileUpload({ file }, options);
			await filesService.create({
				name: file.name!,
				fileName: key!,
				fileType: file.type,
				fileSizeInBytes: file.size,
				lessonIds: [{ value: lessonId! }]
			});

			options.onUploaded();

			await dispatch(fetchLessonData({ lessonId }));
		} catch (e) {
			console.error(e);
			options.onError();
			dispatch(emit({ message: "An error has occured while uploading.", color: "error" }));
		} finally {
			dispatch(setStateValue({ key: attachmentLoaderStatePath, value: false }));
		}
	}
);

export const deleteLessonMaterial = createAsyncThunk(
	"createLesson/deleteLessonMaterials",
	async (options: { id?: number; sideEffect: () => null }, { dispatch, getState }) => {
		const { id, sideEffect } = options;
		const { createLesson: state } = getState() as { createLesson: CreateLessonState };
		const { lessonId } = state;
		const attachmentsDeleteLoader = "lessonAttachments.isDeleteLoading";
		try {
			dispatch(setStateValue({ key: attachmentsDeleteLoader, value: true }));
			await filesService.delete({ filters: { id, "lessons.id": lessonId }, deleteFromLesson: true });
			dispatch(setStateValue({ key: attachmentsDeleteLoader, value: false }));
			sideEffect();
			dispatch(
				emit({
					message: "Lesson material deleted successfully.",
					color: "success"
				})
			);
			await dispatch(fetchLessonData({ lessonId }));
		} catch (e) {
			console.error(e);
			dispatch(setStateValue({ key: attachmentsDeleteLoader, value: false }));
			dispatch(emit({ message: "An error has occured while deleting the lesson material.", color: "error" }));
		} finally {
			dispatch(setStateValue({ key: attachmentsDeleteLoader, value: false }));
		}
	}
);

export const deleteLesson = createAsyncThunk(
	"createLesson/deleteLesson",
	async (deleteVideo: boolean, { dispatch, getState }) => {
		try {
			dispatch(setStateValue({ key: "saveIsLoading", value: true }));
			const { createLesson: state } = getState() as { createLesson: CreateLessonState };
			const { lessonId } = state;
			const res = await lessonsService.delete({ filters: { id: lessonId }, deleteVideo });
			dispatch(
				emit({
					message: res.videoDeleted ? "Lesson and video deleted successfully." : "Lesson deleted successfully.",
					color: "success"
				})
			);
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occured while deleting the video.", color: "error" }));
		} finally {
			dispatch(setStateValue({ key: "saveIsLoading", value: false }));
		}
	}
);

export const deleteVideo = createAsyncThunk(
	"createLesson/deleteVideo",
	async (inputStatePath: string, { dispatch, getState }) => {
		try {
			const { createLesson: state } = getState() as { createLesson: CreateLessonState };
			const inputData = get(state, inputStatePath) as CustomInputModel<number>;
			const { value } = inputData;
			if (!!!value) {
				return;
			}
			dispatch(setStateValue({ key: "saveIsLoading", value: true }));
			await lessonVideosService.delete({ filters: { id: value } });
			dispatch(emit({ message: "File deleted successfully.", color: "success" }));
			validateInput("", {
				dispatch,
				setStateValue,
				inputData,
				validateForm
			});
			await dispatch(fetchVideoList());
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occured while deleting the video.", color: "error" }));
		} finally {
			dispatch(setStateValue({ key: "saveIsLoading", value: false }));
		}
	}
);

export const deleteAttachment = createAsyncThunk(
	"createLesson/deleteAttachment",
	async (options: { id?: number; statePath?: string; sideEffect: () => null }, { dispatch, getState }) => {
		const { id, statePath, sideEffect } = options;
		const attachmentsStatePath = `${statePath}.attachments`;
		const attachmentsDeleteLoader = `${statePath}.isDeleteLoading`;
		const { createLesson: state } = getState() as { createLesson: CreateLessonState };
		const currentAttachments = get(state, attachmentsStatePath);
		const { lessonId } = state;
		try {
			dispatch(setStateValue({ key: attachmentsDeleteLoader, value: true }));
			await filesService.delete({ filters: { id, "lessons.id": lessonId }, deleteFromLesson: true });
			const updatedAttachments = currentAttachments.filter(attachment => attachment.id !== id);
			dispatch(setStateValue({ key: attachmentsStatePath, value: updatedAttachments }));
			dispatch(setStateValue({ key: attachmentsDeleteLoader, value: false }));
			sideEffect();
		} catch (e) {
			console.error(e);
			dispatch(setStateValue({ key: attachmentsDeleteLoader, value: false }));
			dispatch(emit({ message: "An error has occurred while deleting the attachment.", color: "error" }));
		}
	}
);

export const fetchAdditionalLists = createAsyncThunk("createLesson/fetchAdditionalLists", async (_, { dispatch }) => {
	try {
		const lessonRankOptions = (await lessonRanksService.find({ findAll: true })).items
			.map(item => ({ text: item.name, value: item.id }))
			.sort((a, b) => (a.text.toLowerCase()[0] > b.text.toLowerCase()[0] ? 1 : -1));
		const lessonTypeOptions = (await lessonTypesService.find({ findAll: true })).items
			.map(item => ({ text: item.name, value: item.id }))
			.sort((a, b) => (a.value > b.value ? 1 : -1));

		const questionTypeOptionsForTestLesson = (
			await questionTypesService.find({
				findAll: true,
				filters: { forTestLessons: true }
			})
		).items
			.map(item => ({ text: item.name, value: item.id }))
			.sort((a, b) => (a.text.toLowerCase()[0] > b.text.toLowerCase()[0] ? 1 : -1));
		const questionTypeOptionsForInteractiveLesson = (
			await questionTypesService.find({
				findAll: true,
				filters: { forInteractiveLessons: true }
			})
		).items
			.map(item => ({ text: item.name, value: item.id }))
			.sort((a, b) => (a.text.toLowerCase()[0] > b.text.toLowerCase()[0] ? 1 : -1));
		dispatch(setStateValue({ key: "questionTypeOptionsForTestLesson", value: questionTypeOptionsForTestLesson }));
		dispatch(
			setStateValue({ key: "questionTypeOptionsForInteractiveLesson", value: questionTypeOptionsForInteractiveLesson })
		);
		dispatch(setStateValue({ key: "lessonRankOptions", value: lessonRankOptions }));
		dispatch(setStateValue({ key: "lessonDetailsForm.inputs.rankId.selectOptions", value: lessonRankOptions }));
		dispatch(setStateValue({ key: "lessonTypeOptions", value: lessonTypeOptions }));
		dispatch(setStateValue({ key: "lessonDetailsForm.inputs.typeId.selectOptions", value: lessonTypeOptions }));

		dispatch(
			setStateValue({
				key: "testOnlyQuestionsForm.templates.questions.typeId.selectOptions",
				value: questionTypeOptionsForTestLesson
			})
		);
		dispatch(
			setStateValue({
				key: "interactiveLessonFormGroup.templates.interactiveBlocks.question.typeId.selectOptions",
				value: questionTypeOptionsForInteractiveLesson
			})
		);
	} catch (e) {
		console.error(e);
		dispatch(emit({ message: "An error has occurred while fetching the additonal data.", color: "error" }));
	}
});

export const fetchCourseChapterSectionList = createAsyncThunk(
	"createLesson/fetchCourseChapterSectionList",
	async (_, { dispatch }) => {
		try {
			const { items: courseItems } = await coursesService.find({ findAll: true, include: ["chapters.sections"] });
			let chapters: CourseChapter[] = [];
			const sectionIdCourseIdMap: SectionIdCourseIdMap = {};
			let sections: CourseChapterSection[] = [];
			const courses: Course[] = [];
			let chapterOptions: CustomInputSelectOptionsItem[] = [];
			let sectionOptions: CustomInputSelectOptionsItem[] = [];
			const courseOptions: CustomInputSelectOptionsItem[] = courseItems
				.map(course => {
					courses.push(omit(course, ["chapters"]) as Course);
					course.chapters!.forEach(chapter => {
						chapters.push(omit(chapter, ["sections"]) as CourseChapter);
						chapterOptions.push({
							subtitle: course.name,
							text: chapter.name,
							value: chapter.id
						});
						chapter.sections!.forEach(section => {
							sections.push(section);
							sectionOptions.push({
								subtitle: `${course.name} - ${chapter.name}`,
								text: section.name,
								value: section.id
							});
							sectionIdCourseIdMap[section.id] = { courseId: course.id };
						});
					});
					return { text: course.name, value: course.id };
				})
				.sort((a, b) => (a.text.toLowerCase()[0] > b.text.toLowerCase()[0] ? 1 : -1));
			chapterOptions = chapterOptions.sort((a, b) => (a.text.toLowerCase()[0] > b.text.toLowerCase()[0] ? 1 : -1));
			sectionOptions = sectionOptions.sort((a, b) => (a.text.toLowerCase()[0] > b.text.toLowerCase()[0] ? 1 : -1));
			chapters = chapters.sort((a, b) => (a.name.toLowerCase()[0] > b.name.toLowerCase()[0] ? 1 : -1));
			sections = sections.sort((a, b) => (a.name.toLowerCase()[0] > b.name.toLowerCase()[0] ? 1 : -1));
			dispatch(setStateValue({ key: "chapters", value: chapters }));
			dispatch(setStateValue({ key: "chapterOptions", value: chapterOptions }));
			dispatch(setStateValue({ key: "courses", value: courses }));
			dispatch(setStateValue({ key: "courseOptions", value: courseOptions }));
			dispatch(setStateValue({ key: "sectionIdCourseIdMap", value: sectionIdCourseIdMap }));
			dispatch(setStateValue({ key: "sections", value: sections }));
			dispatch(setStateValue({ key: "sectionOptions", value: sectionOptions }));
			dispatch(
				setStateValue({
					key: "lessonDetailsForm.inputs.courseIds.selectOptions",
					value: courseOptions
				})
			);
			dispatch(
				setStateValue({
					key: "lessonDetailsForm.inputs.chapterIds.selectOptions",
					value: chapterOptions
				})
			);
			dispatch(
				setStateValue({
					key: "lessonDetailsForm.inputs.sectionIds.selectOptions",
					value: sectionOptions
				})
			);
		} catch (e) {
			console.error(e);
			dispatch(
				emit({ message: "An error has occurred while fetching the courses, chapters and sections.", color: "error" })
			);
		}
	}
);

export const fetchData = createAsyncThunk(
	"createLesson/fetchData",
	async (
		options: {
			currentStep?: CreateLessonSteps;
			isIntro?: boolean;
			lessonId?: number;
			previewOpen?: boolean;
			selectedSectionId?: number;
			stateLoaderKey?: string;
			withAdditionalData?: boolean;
			resetFormValue?: boolean;
		},
		{ dispatch, getState }
	) => {
		const {
			currentStep,
			lessonId,
			previewOpen,
			selectedSectionId,
			stateLoaderKey = "isLoading",
			withAdditionalData = true,
			resetFormValue = true
		} = options;
		try {
			dispatch(setStateValue({ key: stateLoaderKey, value: true }));
			dispatch(setStateValue({ key: "isIntro", value: !!options.isIntro }));
			const { createLesson: state } = getState() as { createLesson: CreateLessonState };
			const { selectedSection } = state;
			dispatch(setStateValue({ key: "lessonId", value: lessonId }));
			if (currentStep) {
				dispatch(setStateValue({ key: "currentStep", value: currentStep }));
			}
			if (withAdditionalData) {
				await dispatch(fetchAdditionalLists());
				await dispatch(fetchCourseChapterSectionList());
				await dispatch(fetchVideoList());
			}
			await dispatch(fetchLessonData({ lessonId }));
			const {
				currentStep: actualCurrentStep,
				isIntro,
				lesson,
				lessonDraft
			} = (
				getState() as {
					createLesson: CreateLessonState;
				}
			).createLesson;
			let actualSelectedSectionId: number | undefined;
			if (selectedSectionId) {
				actualSelectedSectionId = selectedSectionId;
			} else if (lessonDraft) {
				const { sectionIds } = lessonDraft.data;
				if (sectionIds && sectionIds.length) {
					actualSelectedSectionId = sectionIds[0];
				}
			}
			if (actualSelectedSectionId && (!selectedSection || actualSelectedSectionId !== selectedSection.id)) {
				const actualSelectedSectionData = await courseChapterSectionsService.findOne(actualSelectedSectionId, {
					include: ["chapter.course"]
				});
				if (!lessonDraft!.lessonId && actualSelectedSectionId && actualSelectedSectionData) {
					dispatch(
						setStateValue({
							key: "lessonDraft.data",
							value: {
								...lessonDraft!.data,
								selectedChapterIds: [actualSelectedSectionData?.chapterId],
								selectedCourseIds: [actualSelectedSectionData?.chapter?.courseId],
								sectionIds: [actualSelectedSectionData?.id]
							}
						})
					);
				}
				dispatch(
					setStateValue({
						key: "selectedSection",
						value: actualSelectedSectionData
					})
				);
			}
			if (resetFormValue) {
				await dispatch(populateLessonDetailsForm());
			}

			await dispatch(populateVideoBasicsForm());
			await dispatch(populateTestOnlyQuestionForm());
			await dispatch(populateInteractiveLessonFormGroup());
			// overwrite the current step if we're on an intro lesson and it's the first step
			if (isIntro && actualCurrentStep === CreateLessonSteps.LessonDetails) {
				if (!lessonDraft!.id) {
					await dispatch(save({ newStep: CreateLessonSteps.LessonMaterials }));
				} else {
					dispatch(setStateValue({ key: "currentStep", value: CreateLessonSteps.LessonMaterials }));
				}
			}
			if (!!previewOpen && lesson) {
				if (lesson.typeId === LessonTypes.TestOnly) {
					dispatch(openTestOnlyPreview({ validate: true }));
				} else if (lesson.typeId === LessonTypes.Interactive) {
					dispatch(openVITPreview({ validate: true }));
				}
			}
			if (!state.initialDataLoaded) {
				dispatch(setStateValue({ key: "initialDataLoaded", value: true }));
			}
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "Error fetching the lesson data.", color: "error", preventAutoDismiss: true }));
		} finally {
			dispatch(setStateValue({ key: stateLoaderKey, value: false }));
		}
	}
);

export const fetchLessonData = createAsyncThunk(
	"createLesson/fetchLessonData",
	async (data: { lessonId?: number }, { dispatch, getState }) => {
		try {
			const { lessonId } = data;
			let selectedChapterIds: number[] = [];
			let selectedCourseIds: number[] = [];
			let selectedSectionIds: number[] = [];
			let lesson: Lesson | null = null;
			let lessonDraft: LessonDraftExtended = { data: {} } as LessonDraftExtended;
			let selectedMainLessonVideo: LessonVideo | undefined;
			if (lessonId) {
				const { drafts, lessonSections, questions, interactiveBlocks, ...lessonData } = (await lessonsService.findOne(
					lessonId,
					{
						include: [
							"drafts",
							"questions",
							"questions.attachments",
							"lessonSections.section.chapter",
							"interactiveBlocks.segments",
							"interactiveBlocks.question",
							"type",
							"attachments"
						],
						orderBy: { "drafts.id": "desc" }
					}
				))!;
				dispatch(setStateValue({ key: "isIntro", value: !!lessonData.isIntro }));
				lesson = { ...lessonData };
				if (drafts!.length) {
					lessonDraft = drafts![0] as LessonDraftExtended;
				} else {
					lessonDraft = {
						id: 0,
						lessonId: lessonData.id,
						isApplied: false,
						data: { ...lessonData, sectionIds: selectedSectionIds } as LessonDraftDataExtended,
						createdAt: lessonData.createdAt,
						updatedAt: lessonData.updatedAt
					};
					// find the mainVideoId based on the video segments
					if (interactiveBlocks && interactiveBlocks.length) {
						lessonDraft.data.videoId = interactiveBlocks[0].videoId;
					}
				}
				if (!lessonData.isActive) {
					//Populate sections, chapters and courses for unpublished lessons
					if (lessonDraft.data.sectionIds && lessonDraft.data.sectionIds.length) {
						const { items: sections } = await courseChapterSectionsService.find({
							filters: { id: lessonDraft.data.sectionIds },
							include: ["chapter.course"],
							findAll: true
						});
						if (sections.length) {
							for (const section of sections) {
								selectedSectionIds.push(section.id);
								selectedChapterIds.push(section.chapter!.id);
								selectedCourseIds.push(section.chapter!.course!.id);
							}
						}
					} else {
						// if lessondraft.data.sectionIds is undefined
						lessonSections!.forEach(item => {
							if (item.section) {
								selectedSectionIds.push(item.sectionId);
								selectedChapterIds.push(item.section!.chapterId);
								selectedCourseIds.push(item.section!.chapter!.courseId);
							}
						});
					}
				} else {
					//Populate existing sections, chapters and courses based on existing relation
					lessonSections!.forEach(item => {
						if (item.section) {
							selectedSectionIds.push(item.sectionId);
							selectedChapterIds.push(item.section!.chapterId);
							selectedCourseIds.push(item.section!.chapter!.courseId);
						}
					});
				}
				if (!lessonDraft.data.questions && questions) {
					lessonDraft.data.questions = questions as unknown as LessonDraftQuestionItem[];
				}
				if (!lessonDraft.data.interactiveBlocks) {
					if (interactiveBlocks) {
						lessonDraft.data.interactiveBlocks = interactiveBlocks as unknown as LessonDraftInteractiveBlock[];
					} else {
						lessonDraft.data.interactiveBlocks = [];
					}
				}
				const { mainLessonVideos } = (getState() as { createLesson: CreateLessonState }).createLesson;
				const { videoId: mainVideoId } = lessonDraft.data;
				if (mainVideoId) {
					selectedMainLessonVideo = mainLessonVideos.find(item => item.id === mainVideoId);
				}
			} else {
				const { isIntro, selectedSection } = (getState() as { createLesson: CreateLessonState }).createLesson;
				if (selectedSection) {
					selectedSectionIds.push(selectedSection.id);
					selectedChapterIds.push(selectedSection.chapterId);
					selectedCourseIds.push(selectedSection.chapter!.courseId);
				}
				if (isIntro) {
					const { items: existingIntroLessons } = await lessonsService.find({ filters: { isIntro: true } });
					if (existingIntroLessons[0]) {
						await dispatch(fetchLessonData({ lessonId: existingIntroLessons[0].id }));
						return;
					}
					lessonDraft.data = {
						sectionIds: [],
						name: `Intro VIT ${new Date().valueOf()}`,
						description: "Platform introduction VIT",
						typeId: LessonTypes.Interactive,
						rankId: LessonRanks.One
					};
				}
			}
			selectedSectionIds = Array.from(new Set(selectedSectionIds).values());
			selectedChapterIds = Array.from(new Set(selectedChapterIds).values());
			selectedCourseIds = Array.from(new Set(selectedCourseIds).values());
			lessonDraft.data = { ...lessonDraft.data, selectedChapterIds, selectedCourseIds, sectionIds: selectedSectionIds };
			if (lessonDraft.data.questions) {
				lessonDraft.data.questions.sort((a, b) => {
					if (!a.id) {
						return 1;
					}
					if (!b.id) {
						return -1;
					}
					return a.id - b.id;
				});
			}
			dispatch(setStateValue({ key: "lesson", value: lesson }));
			dispatch(setStateValue({ key: "lessonDraft", value: lessonDraft }));
			dispatch(setStateValue({ key: "selectedMainLessonVideo", value: selectedMainLessonVideo }));
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while fetching the lesson data.", color: "error" }));
		}
	}
);

export const fetchVideoList = createAsyncThunk("createLesson/fetchVideoList", async (_, { dispatch }) => {
	try {
		const { items: lessonVideoList } = await lessonVideosService.find({ findAll: true });
		const mainLessonVideos: LessonVideo[] = [];
		let lessonVideoMainOptions: CustomInputSelectOptionsItem[] = [];
		let lessonVideoTrailerOptions: CustomInputSelectOptionsItem[] = [];
		lessonVideoList.forEach(item => {
			if (item.isTrailer) {
				lessonVideoTrailerOptions.push({ text: item.name, value: item.id });
				return;
			}
			lessonVideoMainOptions.push({ text: item.name, value: item.id });
			mainLessonVideos.push(item);
		});
		lessonVideoMainOptions = lessonVideoMainOptions.sort((a, b) =>
			a.text.toLowerCase()[0] > b.text.toLowerCase()[0] ? 1 : -1
		);
		lessonVideoTrailerOptions = lessonVideoTrailerOptions.sort((a, b) =>
			a.text.toLowerCase()[0] > b.text.toLowerCase()[0] ? 1 : -1
		);
		dispatch(setStateValue({ key: "mainLessonVideos", value: mainLessonVideos }));
		dispatch(setStateValue({ key: "lessonVideoMainOptions", value: lessonVideoMainOptions }));
		dispatch(setStateValue({ key: "videoBasicsForm.inputs.videoId.selectOptions", value: lessonVideoMainOptions }));
		dispatch(setStateValue({ key: "lessonVideoTrailerOptions", value: lessonVideoTrailerOptions }));
		dispatch(
			setStateValue({ key: "videoBasicsForm.inputs.trailerId.selectOptions", value: lessonVideoTrailerOptions })
		);
	} catch (e) {
		console.error(e);
		dispatch(emit({ message: "An error has occurred while fetching the video list.", color: "error" }));
	}
});

export const fetchAttachments = createAsyncThunk(
	"createLesson/fetchAttachments",
	async (options: { lessonId?: number | undefined; statePath?: string }, { dispatch }) => {
		const { lessonId, statePath } = options;
		const attachmentsStatePath = `${statePath}.attachments`;
		try {
			const { items: lessonAttachments } = await filesService.find({
				filters: { "lessons.id": lessonId },
				findAll: true
			});
			dispatch(setStateValue({ key: attachmentsStatePath, value: lessonAttachments }));
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while fetching the attachments.", color: "error" }));
		}
	}
);

export const filterChaptersAndSections = createAsyncThunk(
	"createLesson/filterChaptersAndSections",
	async (courseIds: number[], { dispatch, getState }) => {
		try {
			const { createLesson: state } = getState() as { createLesson: CreateLessonState };
			let chapterOptions = cloneDeep(state.chapterOptions);
			let sectionOptions = cloneDeep(state.sectionOptions);
			if (courseIds.length) {
				const { chapters, lessonDetailsForm, sectionIdCourseIdMap } = state;
				const {
					chapterIds: chapterIdsInput,
					courseIds: courseIdsInput,
					sectionIds: sectionIdsInput
				} = lessonDetailsForm.inputs;
				const courseIdsMap: { [courseId: number]: boolean } = {};
				const chapterIdsMap: { [chapterId: number]: boolean } = {};
				const sectionIdsMap: { [sectionId: number]: boolean } = {};
				courseIds.forEach(courseId => (courseIdsMap[courseId] = true));
				chapterOptions = chapterOptions.filter((option, index) => {
					if (courseIdsMap[chapters[index].courseId]) {
						chapterIdsMap[option.value as number] = true;
						return true;
					}
					return false;
				});
				sectionOptions = sectionOptions.filter(option => {
					const value = option.value as number;
					if (courseIdsMap[sectionIdCourseIdMap[value].courseId]) {
						sectionIdsMap[value] = true;
						return true;
					}
					return false;
				});
				validateInput(
					courseIdsInput.value!.filter(item => courseIdsMap[item]),
					{
						dispatch,
						setStateValue,
						inputData: courseIdsInput,
						validateForm
					}
				);
				validateInput(
					chapterIdsInput.value!.filter(item => chapterIdsMap[item]),
					{
						dispatch,
						setStateValue,
						inputData: chapterIdsInput,
						validateForm
					}
				);
				validateInput(
					sectionIdsInput.value!.filter(item => sectionIdsMap[item]),
					{
						dispatch,
						setStateValue,
						inputData: sectionIdsInput,
						validateForm
					}
				);
			}
			dispatch(setStateValue({ key: "lessonDetailsForm.inputs.chapterIds.selectOptions", value: chapterOptions }));
			dispatch(setStateValue({ key: "lessonDetailsForm.inputs.sectionIds.selectOptions", value: sectionOptions }));
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while filtering the chapters and sections.", color: "error" }));
		}
	}
);

export const filterSections = createAsyncThunk(
	"createLesson/filterSections",
	async (chapterIds: number[], { dispatch, getState }) => {
		try {
			const { createLesson: state } = getState() as { createLesson: CreateLessonState };
			let sectionOptions = cloneDeep(state.sectionOptions);
			if (chapterIds.length) {
				const { lessonDetailsForm, sections } = state;
				const { chapterIds: chapterIdsInput, sectionIds: sectionIdsInput } = lessonDetailsForm.inputs;
				const chapterIdsMap: { [chapterId: number]: boolean } = {};
				const sectionIdsMap: { [sectionId: number]: boolean } = {};
				chapterIds.forEach(chapterId => (chapterIdsMap[chapterId] = true));
				sectionOptions = sectionOptions.filter((option, index) => {
					if (chapterIdsMap[sections[index].chapterId]) {
						sectionIdsMap[option.value as number] = true;
						return true;
					}
					return false;
				});
				validateInput(
					chapterIdsInput.value!.filter(item => chapterIdsMap[item]),
					{
						dispatch,
						setStateValue,
						inputData: chapterIdsInput,
						validateForm
					}
				);
				validateInput(
					sectionIdsInput.value!.filter(item => sectionIdsMap[item]),
					{
						dispatch,
						setStateValue,
						inputData: sectionIdsInput,
						validateForm
					}
				);
			}
			dispatch(setStateValue({ key: "lessonDetailsForm.inputs.sectionIds.selectOptions", value: sectionOptions }));
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while filtering the sections.", color: "error" }));
		}
	}
);

export const getCTAButtonData = (state: RootState): { disabled: boolean; label: string } => {
	const { currentStep, isLoading, lesson, saveIsLoading } = state.createLesson;
	const { isActive, typeId } = lesson || {};
	const data = { disabled: saveIsLoading || isLoading, label: "Next" };
	if (typeId === LessonTypes.Interactive) {
		if (currentStep !== CreateLessonSteps.LessonVIT) {
			data.label = "Next";
		} else {
			data.label = isActive ? "Unpublish" : "Publish";
		}
	} else if (typeId === LessonTypes.TestOnly) {
		if (currentStep !== CreateLessonSteps.LessonTest) {
			data.label = "Next";
		} else {
			data.label = isActive ? "Unpublish" : "Publish";
		}
	} else {
		if (currentStep === CreateLessonSteps.LessonMaterials) {
			data.label = isActive ? "Unpublish" : "Publish";
		} else {
			data.label = "Next";
		}
	}
	return data;
};

export const getCurrentForm = (state: RootState): FormStateModel<FormInputItemsModel, unknown, {}> => {
	const { currentStep, lessonDetailsForm, interactiveLessonFormGroup, testOnlyQuestionsForm, videoBasicsForm } =
		state.createLesson;
	if (currentStep === CreateLessonSteps.LessonDetails) {
		return lessonDetailsForm;
	}
	if (currentStep === CreateLessonSteps.LessonMaterials) {
		return videoBasicsForm;
	}
	if (currentStep === CreateLessonSteps.LessonTest) {
		return testOnlyQuestionsForm;
	}
	// getting here means we're on the LessonVIT step
	return interactiveLessonFormGroup;
};

export function getFullState(state: RootState): CreateLessonState {
	return state.createLesson;
}

export const removeInteractiveBlock = createAsyncThunk(
	"createLesson/removeInteractiveBlock",
	async (options: { interactiveBlockIndex: number; sideEffect: () => void }, { dispatch, getState }) => {
		try {
			const { interactiveBlockIndex, sideEffect } = options;
			const { interactiveLessonFormGroup } = (getState() as { createLesson: CreateLessonState }).createLesson;
			const { interactiveBlocks, selectedInteractiveBlockIndex } = interactiveLessonFormGroup.inputs;
			let newInteractiveBlocks = [...interactiveBlocks];
			let lastInteractiveBlockIndex = 0;
			newInteractiveBlocks = newInteractiveBlocks.map((x, i) => {
				if (!x.deleted.value && interactiveBlockIndex !== i) {
					lastInteractiveBlockIndex = i;
				}
				return {
					...x,
					...(interactiveBlockIndex === i && { deleted: { ...x.deleted, value: true } })
				};
			});
			dispatch(
				setStateValue({ key: "interactiveLessonFormGroup.inputs.interactiveBlocks", value: newInteractiveBlocks })
			);

			if (selectedInteractiveBlockIndex.value === interactiveBlockIndex) {
				dispatch(
					setStateValue({
						key: "interactiveLessonFormGroup.inputs.selectedInteractiveBlockIndex.value",
						value: lastInteractiveBlockIndex
					})
				);
			}

			dispatch(
				validateForm({
					formStatePath: "interactiveLessonFormGroup",
					markInputsAsDirty: true
				})
			);
			sideEffect();
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occured while discarding interactive block.", color: "error" }));
		}
	}
);

export const openTestOnlyPreview = createAsyncThunk(
	"createLesson/openTestOnlyPreview",
	async (options: { validate?: boolean }, { dispatch, getState }) => {
		try {
			const { validate = true } = options;
			if (validate) {
				dispatch(validateForm({ formStatePath: "testOnlyQuestionsForm", markInputsAsDirty: true }));
				const { testOnlyQuestionsForm } = (getState() as { createLesson: CreateLessonState }).createLesson;
				if (!testOnlyQuestionsForm.valid) {
					dispatch(setStateValue({ key: "testOnlyPreviewQuestions", value: [] }));
					dispatch(
						emit({
							message: "Cannot open the preview - please fill in all Lesson Test fields correctly and try again.",
							color: "error"
						})
					);
					return;
				}
				const { questions: currentQuestions } = testOnlyQuestionsForm.rawData;

				let nonDeleteQuestion = 0;
				const newQuestions: LessonDraftQuestionItem[] = [];
				for (const i in currentQuestions) {
					const question = { ...currentQuestions[i] };
					if (question.deleted) {
						continue;
					}
					nonDeleteQuestion++;
					const preparedData = prepareQuestion(question, Number(i));
					newQuestions.push(preparedData);
				}

				if (nonDeleteQuestion === 0) {
					dispatch(emit({ message: "Please add at least one question to the test.", color: "error" }));
					return false;
				}

				dispatch(setStateValue({ key: "testOnlyPreviewQuestions", value: newQuestions }));
			}
		} catch (e) {
			const err = e as { message: string };
			console.error(err);
			dispatch(emit({ message: err.message || "An error has occured while loading the preview.", color: "error" }));
		}
	}
);

export const openVITPreview = createAsyncThunk(
	"createLesson/openVITPreview",
	async (options: { validate?: boolean }, { dispatch, getState }) => {
		try {
			const { validate = true } = options;
			if (validate) {
				const { interactiveLessonFormGroup } = (getState() as { createLesson: CreateLessonState }).createLesson;
				const valid = unwrapResult(await dispatch(validateCurrentInteractiveLessonForm({ emitError: true })));
				if (!valid) {
					dispatch(setStateValue({ key: "vitPreviewIsOpen", value: false }));
					dispatch(
						emit({
							message: "Cannot open the preview - please fill in all Lesson VIT fields correctly and try again.",
							color: "error"
						})
					);
					return;
				}
				if (!interactiveLessonFormGroup.rawData.interactiveBlocks.length) {
					dispatch(setStateValue({ key: "vitPreviewIsOpen", value: false }));
					dispatch(
						emit({
							message: "Cannot open the preview - please add at least one interactive block first.",
							color: "error"
						})
					);
					return;
				}
			}
			dispatch(setStateValue({ key: "vitPreviewIsOpen", value: true }));
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occured while loading the preview.", color: "error" }));
		}
	}
);

export const populateLessonDetailsForm = createAsyncThunk(
	"createLesson/populateLessonDetailsForm",
	async (_data, { dispatch, getState }) => {
		try {
			const { isIntro, lessonDraft } = (
				getState() as {
					createLesson: CreateLessonState;
				}
			).createLesson;
			const { lessonId, data: lessonDraftData } = lessonDraft || ({ data: {} } as LessonDraftExtended);
			dispatch(setStateValue({ key: "lessonDetailsForm.inputs.typeId.readonly", value: !!lessonId }));
			dispatch(setStateValue({ key: "lessonDetailsForm.inputs.sectionIds.validations.required", value: !isIntro }));
			dispatch(
				populateInputs({
					inputsStatePath: "lessonDetailsForm.inputs",
					templatesStatePath: "lessonDetailsForm.templates",
					values: {
						...lessonDraftData,
						chapterIds: lessonDraftData.selectedChapterIds || [],
						courseIds: lessonDraftData.selectedCourseIds || []
					}
				})
			);
			dispatch(
				setStateValue({
					key: "lessonDetailsForm.inputs.mainImageKey.imageUrl",
					value: lessonDraftData["mainImageUrl"]
				})
			);
			dispatch(filterSections(lessonDraftData.selectedChapterIds || []));
			dispatch(validateForm({ formStatePath: "lessonDetailsForm", markInputsAsDirty: false }));
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while populating the lesson details form.", color: "error" }));
		}
	}
);

export const populateInteractiveLessonFormGroup = createAsyncThunk(
	"createLesson/populateInteractiveLessonFormGroup",
	async (_, { dispatch, getState }) => {
		try {
			const { createLesson } = getState() as {
				createLesson: CreateLessonState;
			};
			const { inputs } = createLesson.interactiveLessonFormGroup;
			let nonDeleteSelectedIndex = 0;
			const currentSelectedIndex = createLesson.interactiveLessonFormGroup.rawData.selectedInteractiveBlockIndex;
			inputs.interactiveBlocks.forEach((interactiveBlock, index) => {
				if (index < currentSelectedIndex && !interactiveBlock.deleted.value) {
					nonDeleteSelectedIndex += 1;
				}
			});
			const { data: lessonDraftData } = createLesson.lessonDraft || ({ data: {} } as LessonDraftExtended);
			const { typeId, interactiveBlocks } = lessonDraftData;
			if (typeId === LessonTypes.Interactive) {
				if (interactiveBlocks && interactiveBlocks.length) {
					let processedInteractiveBlocks: InteractiveLessonFormInteractiveBlockRawData[] = [];
					for (const interactiveBlock of interactiveBlocks) {
						const { question, ...interactiveBlockData } = interactiveBlock;
						const { data, ...questionData } = question!;
						const processedInteractiveBlock = {
							...interactiveBlockData,
							question: { ...questionData, ...data }
						} as InteractiveLessonFormInteractiveBlockRawData;
						const { segments } = interactiveBlock;
						if (segments && segments.length) {
							for (const segment of segments) {
								const type = LessonVideoSegmentTypeIdMap[segment.typeId];
								processedInteractiveBlock[type] = {
									...segment,
									...transformDurationToInputs(segment.startsAtMilliseconds || 0, "start"),
									...transformDurationToInputs(segment.endsAtMilliseconds || 0, "end")
								};
							}
						}
						processedInteractiveBlocks.push(processedInteractiveBlock);
					}
					processedInteractiveBlocks = processedInteractiveBlocks.sort((a, b) => a.order - b.order);
					dispatch(
						setStateValue({
							key: "interactiveLessonFormGroup.rawData.selectedInteractiveBlockIndex",
							value: nonDeleteSelectedIndex
						})
					);
					dispatch(
						populateInputs({
							inputsStatePath: "interactiveLessonFormGroup.inputs",
							templatesStatePath: "interactiveLessonFormGroup.templates",
							values: {
								interactiveBlocks: processedInteractiveBlocks,
								selectedInteractiveBlockIndex: nonDeleteSelectedIndex || 0,
								selectedSegmentType:
									createLesson.interactiveLessonFormGroup.rawData.selectedSegmentType || LessonVideoSegmentTypes.Start,
								videoDuration: createLesson.interactiveLessonFormGroup.rawData.videoDuration || 0
							}
						})
					);
					const { inputs } = (
						getState() as {
							createLesson: CreateLessonState;
						}
					).createLesson.interactiveLessonFormGroup;
					const { interactiveBlocks: interactiveBlockInputs } = inputs;
					interactiveBlockInputs.forEach((_, index) => {
						dispatch(
							updateQuestionAnswerOptionsInputType({
								formPath: "interactiveLessonFormGroup",
								questionPath: `interactiveLessonFormGroup.inputs.interactiveBlocks.${index}.question`,
								templatePath: "interactiveBlocks.question.groups"
							})
						);
					});
				} else {
					await dispatch(
						setSelectedInteractiveBlock({
							emitErrorOnCurrentFormValidation: false,
							index: 0,
							stopOnValidationError: true
						})
					);
				}
				await dispatch(validateInteractiveLessonFormGroup({ emitError: false }));
			}
		} catch (e) {
			console.error(e);
			dispatch(
				emit({ message: "An error has occurred while populating the interactive lesson form.", color: "error" })
			);
		}
	}
);

export const populateTestOnlyQuestionForm = createAsyncThunk(
	"createLesson/populateTestOnlyQuestionForm",
	async (_data, { dispatch, getState }) => {
		try {
			const { data: lessonDraftData } =
				(
					getState() as {
						createLesson: CreateLessonState;
					}
				).createLesson.lessonDraft || ({ data: {} } as LessonDraftExtended);

			const prepareQuestion = question => {
				const { data, caseStudyQuestions, ...formattedData } = question;

				switch (formattedData.typeId) {
					case QuestionTypes.HotspotHighlight: {
						formattedData.answerOptions = data.answerOptions;
						break;
					}
					case QuestionTypes.MultipleChoiceSN:
					case QuestionTypes.SingleChoice:
					case QuestionTypes.MultipleChoiceSATA: {
						formattedData.answerOptions = data.answerOptions;
						break;
					}

					case QuestionTypes.Grouping: {
						formattedData.groups = data.groups;
						break;
					}

					case QuestionTypes.Sequencing: {
						formattedData.answerOptions = data.answerOptions;
						break;
					}

					case QuestionTypes.MatrixMultipleChoice:
					case QuestionTypes.MatrixSingleChoice: {
						formattedData.tableLabel = data.tableLabel;
						formattedData.answerOptions = data.answerOptions;
						formattedData.groups = data.groups;
						break;
					}
					case QuestionTypes.HighlightTable: {
						formattedData.groups = data.groups.map(group => {
							let content = group.text;

							const answerOptions: { text: string; isCorrect: boolean }[] = [];

							group.answerOptions.forEach(answerOption => {
								const startIndex = content.indexOf(answerOption.text);

								if (startIndex === -1) {
									throw new Error(`Answer option '${answerOption.text}' not found in group text '${group.text}`);
								}

								const start = content.substr(0, startIndex);

								if (start) {
									answerOptions.push({ text: start, isCorrect: false });
								}

								answerOptions.push({ text: answerOption.text, isCorrect: true });

								content = content.substr(startIndex + answerOption.text.length);
							});

							if (content.length > 0) answerOptions.push({ text: content, isCorrect: false });

							return {
								...group,
								text: group.title,
								answerOptions
							};
						});

						formattedData.tableLabel = data.tableLabel;
						formattedData.answerOptionLabel = data.answerOptionLabel;

						break;
					}
					case QuestionTypes.BowTie:
					case QuestionTypes.DragAndDrop:
					case QuestionTypes.RationalScoringDragAndDrop: {
						formattedData.groups = data.groups;
						formattedData.answerOptions = data.answerOptions;
						break;
					}
					case QuestionTypes.MultipleResponseGroup: {
						formattedData.tableLabel = data.tableLabel;
						formattedData.answerOptionLabel = data.answerOptionLabel;
						formattedData.groups = data.groups;
						break;
					}
					case QuestionTypes.ClozeDropDown:
					case QuestionTypes.RationalScoringDropDown: {
						formattedData.groups = data.groups;
						break;
					}
					case QuestionTypes.CaseStudy: {
						formattedData.tabs = data.tabs;
						formattedData.questions = caseStudyQuestions.map(prepareQuestion);
						break;
					}

					case QuestionTypes.DropDownTable: {
						formattedData.tableLabel = data.tableLabel;
						formattedData.answerOptionLabel = data.answerOptionLabel;
						formattedData.groups = data.groups;
						break;
					}
				}

				return formattedData;
			};
			const formattedQuestions = lessonDraftData.questions?.map(prepareQuestion);
			const lessonDraftDataHours = minutesToHours(lessonDraftData.manualTimeLengthComponent || 20);
			const lessonDraftDataHoursString = String(lessonDraftDataHours);

			dispatch(
				populateInputs({
					inputsStatePath: "testOnlyQuestionsForm.inputs",
					templatesStatePath: "testOnlyQuestionsForm.templates",
					values: {
						...{ ...lessonDraftData, questions: formattedQuestions },
						durationHour: parseInt(lessonDraftDataHoursString, 10),
						durationMin: getRemainingMinutes(lessonDraftData.manualTimeLengthComponent || 20)
					}
				})
			);
			const {
				testOnlyQuestionsForm: { inputs },
				questionTypeOptionsForTestLesson
			} = (
				getState() as {
					createLesson: CreateLessonState;
				}
			).createLesson;

			const { questions } = inputs;
			questions.forEach((questionInputs, questionIndex) => {
				if (
					questionInputs.mainImageKey &&
					lessonDraftData?.questions?.length &&
					lessonDraftData?.questions[questionIndex]["mainImageUrl"]
				) {
					dispatch(
						setStateValue({
							key: `testOnlyQuestionsForm.inputs.questions.${questionIndex}.mainImageKey.imageUrl`,
							value: lessonDraftData?.questions[questionIndex]["mainImageUrl"]
						})
					);
				}
				const data = lessonDraftData?.questions?.[questionIndex];
				if (data?.typeId === QuestionTypes.Grouping || data?.typeId === QuestionTypes.Sequencing) {
					dispatch(
						setStateValue({
							key: `testOnlyQuestionsForm.inputs.questions.${questionIndex}.description.validations`,
							value: undefined
						})
					);
				}
				if (data?.typeId === QuestionTypes.CaseStudy) {
					dispatch(
						setStateValue({
							key: `testOnlyQuestionsForm.inputs.questions.${questionIndex}.endOfQuestionSummary.validations`,
							value: undefined
						})
					);
					const selectValues = questionTypeOptionsForTestLesson.filter(
						({ value }) => value !== QuestionTypes.CaseStudy && value !== QuestionTypes.BowTie
					);
					data.caseStudyQuestions?.forEach((question, CsQuestionIndex) => {
						dispatch(
							setStateValue({
								key: `testOnlyQuestionsForm.inputs.questions.${questionIndex}.questions.${CsQuestionIndex}.mainImageKey.imageUrl`,
								value: question["mainImageUrl"]
							})
						);
						dispatch(
							setStateValue({
								key: `testOnlyQuestionsForm.inputs.questions.${questionIndex}.questions.${CsQuestionIndex}.typeId.selectOptions`,
								value: selectValues
							})
						);
					});
				}
				dispatch(
					updateQuestionAnswerOptionsInputType({
						formPath: "testOnlyQuestionsForm",
						questionPath: `testOnlyQuestionsForm.inputs.questions.${questionIndex}`,
						templatePath: "testOnlyQuestionsForm.question.groups"
					})
				);
			});
			dispatch(validateForm({ formStatePath: "testOnlyQuestionsForm", markInputsAsDirty: false }));
		} catch (e) {
			console.error(e);
			dispatch(
				emit({ message: "An error has occurred while populating the test-only questions form.", color: "error" })
			);
		}
	}
);

export const populateVideoBasicsForm = createAsyncThunk(
	"createLesson/populateVideoBasicsForm",
	async (_data, { dispatch, getState }) => {
		try {
			const { lessonDraft, isIntro } = (
				getState() as {
					createLesson: CreateLessonState;
				}
			).createLesson;
			const { data: lessonDraftData } = lessonDraft || ({ data: {} } as LessonDraftExtended);
			dispatch(
				setStateValue({
					key: "videoBasicsForm.inputs.videoId.readonly",
					value:
						lessonDraftData.typeId === LessonTypes.Interactive &&
						lessonDraftData.interactiveBlocks &&
						lessonDraftData.interactiveBlocks.length >= 1 &&
						!isIntro
				})
			);
			dispatch(
				setStateValue({
					key: "videoBasicsForm.inputs.videoId.validations.required",
					value: lessonDraftData.typeId !== LessonTypes.TestOnly
				})
			);
			dispatch(
				populateInputs({
					inputsStatePath: "videoBasicsForm.inputs",
					templatesStatePath: "videoBasicsForm.templates",
					values: { trailerId: lessonDraftData.trailerId || "", videoId: lessonDraftData.videoId || "" }
				})
			);
			dispatch(validateForm({ formStatePath: "videoBasicsForm", markInputsAsDirty: false }));
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while populating the video basics form.", color: "error" }));
		}
	}
);

export const bytesToSize = (x: number): string => {
	const units = ["bytes", "KB", "MB", "GB"];
	let l = 0;
	let n = parseInt(x.toString(), 10) || 0;
	while (n >= 1024 && ++l) {
		n = n / 1024;
	}
	return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

const prepareQuestion = (question, questionIndex) => {
	const data = { ...question, ...(questionIndex ? { order: questionIndex } : undefined) };
	switch (question.typeId) {
		case QuestionTypes.HotspotHighlight: {
			set(data, "data", {
				text: question.answerOptions.map(answerOption => answerOption.text).join(""),
				answerOptions: question.answerOptions
					.filter(({ deleted }) => !deleted)
					.map(answerOption => omit(answerOption, "deleted") as QuestionCreateQuestionAnswerOptionsItemDto)
			});
			const {
				text = "",
				data: { text: highlightedText, answerOptions }
			} = data;
			const highlightedAnswer = answerOptions.some(({ isCorrect }) => isCorrect);
			if (!highlightedAnswer) {
				throw new Error(`Please select the highlighted text for question no. ${+questionIndex + 1}`);
			}
			if (!text.match(/\S/)) {
				throw new Error(`Please fill in the question sentence for question ${(questionIndex ?? 0) + 1}`);
			}
			if (!highlightedText) {
				throw new Error(`Please fill in the text for question ${(questionIndex ?? 0) + 1}`);
			}
			break;
		}

		case QuestionTypes.Grouping: {
			const { groups } = question;
			if (!data.text) {
				throw new Error(`Please fill question text of question for question no. ${+questionIndex + 1}.`);
			}
			const updatedGroups: Group[] = [];
			for (const j in groups) {
				const { answerOptions } = groups[j];
				updatedGroups.push({ ...groups[j], answerOptions: answerOptions.filter(a => !a.deleted) });
				let hasAtLeastOneOption = false;
				for (const k in answerOptions) {
					if (!answerOptions[k].deleted && !hasAtLeastOneOption) {
						hasAtLeastOneOption = true;
						break;
					}
				}
				if (!hasAtLeastOneOption) {
					const errorMessage = `Please add at least one answer option to both groups for question no. ${
						+questionIndex + 1
					}.`;
					throw new Error(errorMessage);
				}
			}
			set(data, "data", {
				groups: updatedGroups
			});
			break;
		}

		case QuestionTypes.Sequencing: {
			if (!data.text) {
				throw new Error(`Please fill question text of question for question no. ${+questionIndex + 1}.`);
			}
			const newAnswerOptions: LessonDraftQuestionAnswerOptionDraftItem[] = [];
			let order = 0;
			question.answerOptions.forEach(answerOption => {
				if (answerOption.deleted) {
					return;
				}
				newAnswerOptions.push({ ...answerOption, order });
				order++;
			});

			set(data, "data", {
				answerOptions: newAnswerOptions
			});

			if (newAnswerOptions.length > MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH) {
				const errorMessage = `Maximum options allowed for sequencing is ${MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH}. It is reached for question no. ${
					+questionIndex + 1
				}.`;
				throw new Error(errorMessage);
			}

			if (newAnswerOptions.length < 2) {
				const errorMessage = `Please add at least two options for question no. ${+questionIndex + 1}.`;
				throw new Error(errorMessage);
			}
			break;
		}

		case QuestionTypes.MultipleChoiceSN:
		case QuestionTypes.SingleChoice:
		case QuestionTypes.MultipleChoiceSATA: {
			set(data, "data", {
				answerOptions: question.answerOptions
					.filter(({ deleted }) => !deleted)
					.map(answerOption => omit(answerOption, "deleted") as QuestionCreateQuestionAnswerOptionsItemDto)
			});

			if (!data.text) {
				throw new Error(`Please fill question text for question ${(questionIndex ?? 0) + 1}`);
			}
			if (data.answerOptions.length < 2) {
				throw new Error(
					`Please add at least 2 answer options${
						questionIndex !== undefined ? ` to question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			if (!data.answerOptions.find(({ isCorrect }) => isCorrect)) {
				throw new Error(
					`Please select at least one correct answerOption${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			break;
		}
		case QuestionTypes.MultipleResponseGroup: {
			const existingGroups = question.groups.filter(({ deleted }) => !deleted);

			set(data, "data", {
				tableLabel: data.tableLabel,
				answerOptionLabel: data.answerOptionLabel,
				groups: existingGroups.map((group, groupIndex) => ({
					...omit(group, ["deleted", "answerOptions"]),
					text: group.text || ".",
					order: groupIndex,
					answerOptions: group.answerOptions
						.filter(({ deleted }) => !deleted)
						.map(answerOption => omit(answerOption, "deleted"))
				}))
			});
			const { text = "" } = data;
			if (!text.match(/\S/)) {
				throw new Error(`Please fill question text for question ${(questionIndex ?? 0) + 1}`);
			}
			if (!data.data.tableLabel) {
				throw new Error(`Please add table label for question ${(questionIndex ?? 0) + 1}`);
			}

			if (!data.data.answerOptionLabel) {
				throw new Error(`Please add answer option label for question ${(questionIndex ?? 0) + 1}`);
			}
			if (data.data.groups.length < 2) {
				throw new Error(
					`Please add at least 2 groups${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			if (data.data.groups.length > 5) {
				throw new Error(
					`Please remove the extra group only 5 are allowed${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			if (data.data.groups.find(({ answerOptions }) => !answerOptions.find(({ isCorrect }) => isCorrect))) {
				throw new Error(
					`Please add correct answer for each group${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			if (
				data.data.groups.find(({ answerOptions }) => {
					return answerOptions.length < 2;
				})
			) {
				throw new Error(
					`Please add more than 1 option for each group${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			break;
		}
		case QuestionTypes.HighlightTable: {
			const existingGroups = question.groups.filter(({ deleted }) => !deleted);

			set(data, "data", {
				groups: existingGroups.map((group, groupIndex) => ({
					...omit(group, ["deleted", "answerOptions"]),
					title: group.text || ".",
					text: group.answerOptions.map(({ text }) => text).join(""),
					order: groupIndex,
					answerOptions: group.answerOptions
						.filter(({ deleted, isCorrect }) => !deleted && isCorrect)
						.map(answerOption => omit(answerOption, "deleted"))
				})),
				tableLabel: data.tableLabel,
				answerOptionLabel: data.answerOptionLabel
			});
			const {
				text = "",
				data: { groups }
			} = data;
			const rowWithoutText = groups.findIndex(({ text }) => !text);
			const rowWithoutSelectedText = groups.findIndex(({ answerOptions }) => !answerOptions.length);
			if (rowWithoutText != -1) {
				throw new Error(
					`Please fill in the text for row no ${rowWithoutText + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			if (rowWithoutSelectedText != -1) {
				throw new Error(
					`Please select the highlighted text for row no ${rowWithoutSelectedText + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			if (!text.match(/\S/)) {
				throw new Error(`Please fill in the question sentence for question ${(questionIndex ?? 0) + 1}`);
			}

			if (!data.data.tableLabel) {
				throw new Error(`Please add table label for question ${(questionIndex ?? 0) + 1}`);
			}

			if (!data.data.answerOptionLabel) {
				throw new Error(`Please add answer option label for question ${(questionIndex ?? 0) + 1}`);
			}

			break;
		}
		case QuestionTypes.ClozeDropDown:
		case QuestionTypes.RationalScoringDropDown: {
			const existingGroups = question.groups.filter(({ deleted }) => !deleted);
			const text = existingGroups.map(({ text }) => text).join(" ");
			set(data, "text", text);

			if (!text.match(/\S/)) {
				throw new Error(
					`Please fill question text${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}

			set(data, "data", {
				groups: existingGroups.map((group, groupIndex) => ({
					...omit(group, ["deleted", "answerOptions"]),
					text: group.text || ".",
					order: groupIndex,
					...(groupIndex !== existingGroups.length - 1 && {
						answerOptions: group.answerOptions
							.filter(({ deleted }) => !deleted)
							.map(answerOption => omit(answerOption, "deleted"))
					})
				}))
			});

			if (question.typeId === QuestionTypes.RationalScoringDropDown && data.data.groups.length < 3) {
				throw new Error(`Question ${(questionIndex ?? 0) + 1} must have at least three sentences.`);
			}

			if (data.data.groups.length < 2) {
				throw new Error(
					`Please add gaps${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}
			const singleAnswerOptionGroupIndex = data.data.groups
				.filter(g => !g.deleted)
				.findIndex(
					({ answerOptions }, groupIndex) => groupIndex < existingGroups.length - 1 && answerOptions.length < 2
				);

			if (singleAnswerOptionGroupIndex !== -1) {
				throw new Error(
					`Please add at least two answer options to the gap ${singleAnswerOptionGroupIndex + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}

			const gapWithoutCorrectAnswer = data.data.groups
				.filter(g => !g.deleted)
				.findIndex(
					({ answerOptions }, groupIndex) =>
						groupIndex < existingGroups.length - 1 && !answerOptions.find(({ isCorrect }) => isCorrect)
				);

			if (gapWithoutCorrectAnswer !== -1) {
				throw new Error(
					`Please select correct option for the gap ${gapWithoutCorrectAnswer + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}`
				);
			}
			break;
		}
		case QuestionTypes.DragAndDrop:
		case QuestionTypes.RationalScoringDragAndDrop: {
			const existingGroups = question.groups.filter(({ deleted }) => !deleted);
			const existingAnswerOptions = question.answerOptions.filter(({ deleted }) => !deleted);
			const text = existingGroups.map(({ text }) => text).join(" ");
			set(data, "text", text);

			if (!text.match(/\S/)) {
				throw new Error(
					`Please fill question text${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}

			set(data, "data", {
				groups: existingGroups.map((group, groupIndex) => ({
					...omit(group, ["deleted", "answerOptions", "selectedAnswerOptions"]),
					text: group.text || ".",
					order: groupIndex,
					...(groupIndex !== existingGroups.length - 1 && {
						selectedAnswerOptions: group.selectedAnswerOptions
					})
				})),
				answerOptions: existingAnswerOptions.map(
					(answerOption, answerOptionIndex) =>
						({
							...omit(answerOption, "deleted"),
							order: answerOptionIndex + 1
						} as QuestionCreateQuestionAnswerOptionsItemDto)
				)
			});

			if (question.typeId === QuestionTypes.RationalScoringDragAndDrop && data.data.groups.length < 3) {
				throw new Error(`Question ${(questionIndex ?? 0) + 1} must have at least three gaps.`);
			}

			if (data.data.groups.length < 2) {
				throw new Error(
					`Please add gaps${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}

			const gapWithoutCorrectAnswer = data.data.groups
				.filter(g => !g.deleted)
				.findIndex(
					({ selectedAnswerOptions }, groupIndex) =>
						groupIndex < existingGroups.length - 1 && selectedAnswerOptions.length == 0
				);

			if (gapWithoutCorrectAnswer !== -1) {
				throw new Error(
					`Please select correct option for the gap ${gapWithoutCorrectAnswer + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}`
				);
			}

			break;
		}
		case QuestionTypes.MatrixSingleChoice: {
			const existingGroups = question.groups.filter(({ deleted }) => !deleted);
			set(data, "data", {
				tableLabel: question.tableLabel,
				answerOptions: question.answerOptions
					.filter(({ deleted }) => !deleted)
					.map(
						(answerOption, answerOptionIndex) =>
							({
								...omit(answerOption, "deleted"),
								order: answerOptionIndex + 1
							} as QuestionCreateQuestionAnswerOptionsItemDto)
					),
				groups: existingGroups.map(group => ({
					...omit(group, ["deleted", "answerOptions"])
				}))
			});
			const { text = "" } = data;
			if (!text.match(/\S/)) {
				throw new Error(`Please fill question text for question ${(questionIndex ?? 0) + 1}`);
			}
			if (!data.data.tableLabel) {
				throw new Error(
					`Please add a table label${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}
			if (data.data.groups.length < 2) {
				throw new Error(
					`Please add at least 2 rows${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}
			const rowWithoutCorrectAnswer = data.data.groups.findIndex(
				({ selectedAnswerOptions }) => !selectedAnswerOptions.length
			);
			if (rowWithoutCorrectAnswer != -1) {
				throw new Error(
					`Please select at least one response option for row no ${rowWithoutCorrectAnswer + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			break;
		}
		case QuestionTypes.BowTie: {
			const { text = "" } = data;
			if (!text.match(/\S/)) {
				throw new Error(
					`Please fill question text${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}

			set(data, "data", {
				groups: data.groups.map((group, groupIndex) => ({
					...omit(group, ["deleted"]),
					text: group.text || ".",
					order: groupIndex,
					answerOptions: group.answerOptions
						.filter(({ deleted }) => !deleted)
						.map(answerOption => omit(answerOption, "deleted"))
				}))
			});

			const gapWithoutCorrectAnswer = data.data.groups.findIndex(({ answerOptions }, groupIndex) => {
				const correctOptions = answerOptions.filter(({ isCorrect }) => isCorrect).length;

				return (groupIndex === 1 && correctOptions !== 1) || (groupIndex !== 1 && correctOptions !== 2);
			});

			const groups = ["Actions To Take", "Conditions Most Likely Experiencing", "Parameters To Monitor"];

			if (gapWithoutCorrectAnswer !== -1) {
				throw new Error(
					`Only ${gapWithoutCorrectAnswer === 1 ? 1 : 2} correct options allowed for group "${
						groups[gapWithoutCorrectAnswer]
					}"${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}`
				);
			}

			break;
		}
		case QuestionTypes.MatrixMultipleChoice: {
			const existingGroups = question.groups.filter(({ deleted }) => !deleted);
			set(data, "data", {
				tableLabel: question.tableLabel,
				answerOptions: question.answerOptions
					.filter(({ deleted }) => !deleted)
					.map(
						(answerOption, answerOptionIndex) =>
							({
								...omit(answerOption, ["deleted"]),
								order: answerOptionIndex + 1
							} as QuestionCreateQuestionAnswerOptionsItemDto)
					),
				groups: existingGroups.map(group => ({
					...omit(group, ["deleted", "answerOptions"])
				}))
			});
			const { text = "" } = data;
			if (!text.match(/\S/)) {
				throw new Error(`Please fill question text for question ${(questionIndex ?? 0) + 1}`);
			}
			if (!data.data.tableLabel) {
				throw new Error(
					`Please add a table label${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}
			if (data.data.groups.length < 2) {
				throw new Error(
					`Please add at least 2 rows${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}
			const rowWithoutText = data.data.answerOptions.findIndex(({ text }) => !text);
			if (rowWithoutText != -1) {
				throw new Error(
					`Please fill the required row no ${rowWithoutText + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			const columnWithoutText = data.data.groups.findIndex(({ text }) => !text);
			if (columnWithoutText != -1) {
				throw new Error(
					`Please fill the required column no ${columnWithoutText + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			const columnWithoutCorrectAnswer = data.data.groups.findIndex(
				({ selectedAnswerOptions }) => !selectedAnswerOptions || !selectedAnswerOptions.length
			);
			if (columnWithoutCorrectAnswer != -1) {
				throw new Error(
					`Please select at least one response option for column no ${columnWithoutCorrectAnswer + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}
			break;
		}

		case QuestionTypes.DropDownTable: {
			const existingGroups = question.groups.filter(({ deleted }) => !deleted);
			const { text = "" } = data;
			if (!text.match(/\S/)) {
				throw new Error(
					`Please fill question text${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
				);
			}
			set(data, "data", {
				tableLabel: data.tableLabel,
				answerOptionLabel: data.answerOptionLabel,
				groups: existingGroups.map((group, groupIndex) => ({
					...omit(group, ["deleted", "answerOptions", "selectedAnswerOptions"]),
					text: group.text || ".",
					order: groupIndex,
					answerOptions: group.answerOptions
						.filter(({ deleted }) => !deleted)
						.map(answerOption => omit(answerOption, "deleted"))
				}))
			});
			if (data.data.groups.length < 2) {
				throw new Error(
					`Please add at least two rows${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}

			if (!data.data.tableLabel) {
				throw new Error("Please add table label");
			}

			if (!data.data.answerOptionLabel) {
				throw new Error(`Please add answer option label for question ${(questionIndex ?? 0) + 1}`);
			}

			const singleAnswerOptionGroupIndex = data.data.groups
				.filter(g => !g.deleted)
				.findIndex(({ answerOptions }) => answerOptions.length < 2);

			if (singleAnswerOptionGroupIndex !== -1) {
				throw new Error(
					`Please add at least two answer options to the row ${singleAnswerOptionGroupIndex + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}.`
				);
			}

			const rowWithoutCorrectAnswer = data.data.groups
				.filter(g => !g.deleted)
				.findIndex(({ answerOptions }) => !answerOptions.find(({ isCorrect }) => isCorrect));

			if (rowWithoutCorrectAnswer !== -1) {
				throw new Error(
					`Please select correct option for the row ${rowWithoutCorrectAnswer + 1}${
						questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
					}`
				);
			}
			break;
		}

		case QuestionTypes.CaseStudy: {
			try {
				set(data, "data", {
					tabs: question.tabs
						.filter(({ deleted }) => !deleted?.value)
						.map((tab, tabIndex) => ({
							id: tab.id,
							text: tab.text,
							order: tabIndex + 1,
							title: tab.title
						}))
				});
				set(
					data,
					"caseStudyQuestions",
					question.questions
						.map(prepareQuestion)
						.map(question => ({ ...question, difficultyLevelId: question.difficultyLevelId }))
				);
				set(data, "text", get(data, "caseStudyQuestions.0.text"));
				omit(data, "answerOptions");
			} catch (e) {
				throw new Error((e as Error).message);
			}
		}
	}

	set(
		data,
		"attachments",
		question.attachments.filter(({ deleted }) => !deleted).map(file => ({ ...file, questionId: 0 }))
	);

	return data;
};

export const save = createAsyncThunk(
	"createLesson/save",
	async (options: { emitSuccessMessage?: boolean; newStep?: CreateLessonSteps }, { dispatch, getState }) => {
		const { emitSuccessMessage, newStep } = options;
		const initialRootState = getState() as { createLesson: CreateLessonState };
		let currentForm = getCurrentForm(initialRootState as RootState);
		dispatch(validateForm({ formStatePath: currentForm.statePath, markInputsAsDirty: true }));

		const rootState = getState() as { createLesson: CreateLessonState };
		currentForm = getCurrentForm(rootState as RootState);
		const { createLesson: state } = rootState;
		// enable this to debug why the form is erroring out despite seeming correct
		// console.log("save:", JSON.parse(JSON.stringify(currentForm)));
		if (!currentForm.valid) {
			dispatch(emit({ message: "Please fill in all fields correctly and try again.", color: "error" }));
			return false;
		}
		let forceUnpublish = false;
		try {
			const { currentStep, isIntro, lesson, lessonDraft, testOnlyQuestionsForm } = state;

			forceUnpublish = !!testOnlyQuestionsForm.inputs.questions?.find(({ deleted }) => deleted.value);
			let lessonId: number | null = null;
			let lessonDraftId: number;
			const submitForm = true;
			dispatch(setStateValue({ key: "saveIsLoading", value: true }));

			const manualTimeLengthComponent = () =>
				Math.floor(
					hoursToMinutes(testOnlyQuestionsForm.rawData.durationHour) + testOnlyQuestionsForm.rawData.durationMin || 0
				);

			if (!lessonDraft || !lessonDraft.id) {
				if (!lesson || !lesson.id) {
					const result = await lessonsService.create({
						...(currentForm.rawData as LessonDetailsFormRawData),
						isIntro: state.isIntro,
						manualTimeLengthComponent: manualTimeLengthComponent()
					});
					lessonId = result.id;
				} else {
					const { data, error } = unwrapResult(await dispatch(getDataForNewDraft()));
					if (error) {
						throw error;
					}
					if (isIntro) {
						data!.sectionIds = [];
					}
					const res = await lessonDraftsService.create({
						data: {
							...data,
							interactiveBlocks: data!.interactiveBlocks?.filter(({ videoId }) => !!videoId)
						} as LessonDraftCreateDataDto,
						lessonId: lesson.id
					});
					lessonId = lesson.id;
					lessonDraftId = res.id;
				}
			} else {
				let innerData: LessonDraftData = {
					...(lessonDraft.data as LessonDetailsFormRawData)
				};
				lessonDraftId = lessonDraft.id;
				if (currentStep === CreateLessonSteps.LessonTest) {
					const rawData = currentForm.rawData as TestOnlyQuestionsFormRawData;
					innerData = {
						...innerData,
						...rawData,
						questions: [],
						manualTimeLengthComponent: manualTimeLengthComponent()
					};
					// remove the deleted questions and answerOptions
					const currentQuestions = rawData.questions as TestOnlyQuestionsFormQuestionItemRawData[];
					let nonDeleteQuestion = 0;
					const newQuestions: LessonDraftQuestionItem[] = [];
					for (const i in currentQuestions) {
						const question = { ...currentQuestions[i] };
						if (question.deleted) {
							continue;
						}
						nonDeleteQuestion++;
						const preparedData = prepareQuestion(question, Number(i));
						newQuestions.push(preparedData);
					}
					if (nonDeleteQuestion === 0 && lessonDraft.data.typeId === LessonTypes.TestOnly) {
						dispatch(emit({ message: "Please add at least one question to the test.", color: "error" }));
						return false;
					}
					innerData.questions = newQuestions;
				} else if (currentStep === CreateLessonSteps.LessonVIT) {
					const valid = !!unwrapResult(await dispatch(validateCurrentInteractiveLessonForm({ emitError: true })));
					if (!valid) {
						return false;
					}
					const { createLesson: updatedState } = getState() as { createLesson: CreateLessonState };
					const { interactiveLessonFormGroup } = updatedState;
					if (!interactiveLessonFormGroup.valid) {
						return false;
					}
					innerData.interactiveBlocks = processInteractiveLessonFormInteractiveBlocks(updatedState);
				} else {
					const newQuestions: LessonDraftQuestionItem[] = [];
					for (const question of innerData.questions!) {
						let caseStudyQuestions = question.caseStudyQuestions as LessonDraftQuestionItem[];
						if (question.typeId === QuestionTypes.CaseStudy) {
							caseStudyQuestions = question.caseStudyQuestions?.map((caseStudyQuestion, index) => {
								return { ...caseStudyQuestion, order: index };
							});
						}
						newQuestions.push({ ...question, caseStudyQuestions: caseStudyQuestions });
					}
					innerData = { ...innerData, questions: newQuestions, ...(currentForm.rawData as LessonDetailsFormRawData) };
				}
				if (submitForm) {
					if (isIntro) {
						if (lessonDraft.data.videoId !== innerData?.videoId) {
							innerData!.interactiveBlocks = [];
							dispatch(setStateValue({ key: "interactiveLessonFormGroup.inputs.interactiveBlocks", value: [] }));
							dispatch(
								validateForm({
									formStatePath: "interactiveLessonFormGroup",
									markInputsAsDirty: true
								})
							);
						}
					}
					await lessonDraftsService.update({
						data: {
							data: { ...innerData, sectionIds: !isIntro ? innerData.sectionIds : [] } as LessonDraftUpdateDataObjectDto
						},
						filters: { id: lessonDraft.id }
					});
					lessonId = lesson!.id;
				}
			}
			if (submitForm) {
				// auto-apply the draft post-save for published lessons
				if (lesson?.isActive) {
					await lessonsService.update({
						data: { draftToApplyId: lessonDraftId! },
						filters: { id: lessonId }
					});
					if (forceUnpublish) {
						await lessonsService.update({ data: { isActive: false }, filters: { id: lessonId } });
					}
				}
				if (newStep) {
					await dispatch(fetchData({ lessonId: lessonId!, isIntro }));
					dispatch(setStateValue({ key: "currentStep", value: newStep }));
				} else {
					await dispatch(fetchData({ lessonId: lessonId!, stateLoaderKey: "saveIsLoading" }));
				}
				if (emitSuccessMessage) {
					dispatch(emit({ message: "Lesson saved successfully.", color: "success" }));
				}
			}
			return submitForm;
		} catch (e) {
			const err = e as { message: string };
			console.error(err);
			dispatch(
				emit({
					message:
						err && err.message && err.message.match(/^[A-Z]/)
							? err.message
							: "An error has occured while saving the data.",
					color: "error"
				})
			);
			return false;
		} finally {
			dispatch(setStateValue({ key: "saveIsLoading", value: false }));
		}
	}
);

export const setSelectedInteractiveBlock = createAsyncThunk(
	"createLesson/setSelectedInteractiveBlock",
	async (
		options: {
			emitErrorOnCurrentFormValidation?: boolean;
			index: number;
			stopOnValidationError?: boolean;
			validateCurrentForm?: boolean;
		},
		{ dispatch, getState }
	) => {
		try {
			const { emitErrorOnCurrentFormValidation, index: newIndex, stopOnValidationError, validateCurrentForm } = options;
			const { interactiveLessonFormGroup } = (getState() as { createLesson: CreateLessonState }).createLesson;
			let valid = interactiveLessonFormGroup.valid;
			if (validateCurrentForm !== false) {
				valid = !!unwrapResult(
					await dispatch(
						validateCurrentInteractiveLessonForm({
							emitError:
								emitErrorOnCurrentFormValidation === true || typeof emitErrorOnCurrentFormValidation === "undefined"
						})
					)
				);
			}
			if (!valid) {
				if (stopOnValidationError) {
					return;
				}
			}
			let indexToSet = newIndex;
			const { interactiveBlocks } = interactiveLessonFormGroup.inputs;
			const selectedBlock = interactiveBlocks[newIndex];
			// reset the forms if there isn't anything at the index
			if (!selectedBlock) {
				//add block
				const existingBlocks = [...get(interactiveLessonFormGroup, "inputs.interactiveBlocks")];
				const newBlock = parseTemplate(
					interactiveLessonFormGroup.templates["interactiveBlocks"],
					`interactiveLessonFormGroup.inputs.interactiveBlocks.${existingBlocks.length}`,
					{
						defaultValueGetters
					}
				);
				newBlock.order.value =
					existingBlocks.length > 0 ? existingBlocks[existingBlocks.length - 1].order.value + 1 : 0;
				existingBlocks.push(newBlock);
				dispatch(setStateValue({ key: "interactiveLessonFormGroup.inputs.interactiveBlocks", value: existingBlocks }));
				indexToSet = existingBlocks.length - 1;
			}
			dispatch(
				setStateValue({
					key: "interactiveLessonFormGroup.inputs.selectedInteractiveBlockIndex.value",
					value: indexToSet
				})
			);
			dispatch(
				validateForm({
					formStatePath: "interactiveLessonFormGroup",
					markInputsAsDirty: true
				})
			);
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while selecting the interactive block.", color: "error" }));
		}
	}
);

export const setSelectedVideoSegment = createAsyncThunk(
	"createLesson/setSelectedVideoSegment",
	async (type: LessonVideoSegmentTypes, { dispatch }) => {
		try {
			dispatch(setStateValue({ key: "interactiveLessonFormGroup.inputs.selectedSegmentType.value", value: type }));
			dispatch(
				validateForm({
					formStatePath: "interactiveLessonFormGroup",
					markInputsAsDirty: true
				})
			);
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while selecting the interactive block.", color: "error" }));
		}
	}
);

export const updatePublishedState = createAsyncThunk(
	"createLesson/updatePublishedState",
	async (data: { lessonIdOverride?: number; newPublishedState: boolean }, { dispatch, getState }) => {
		try {
			const { lessonIdOverride, newPublishedState } = data;
			if (!lessonIdOverride) {
				const res = unwrapResult(await dispatch(save({})));
				if (res === false) {
					return;
				}
			}
			const { createLesson: state } = getState() as {
				createLesson: CreateLessonState;
			};
			const updateData: { isActive: boolean; draftToApplyId?: number } = { isActive: newPublishedState };
			if (newPublishedState) {
				updateData.draftToApplyId = state?.lessonDraft?.id;
			}
			dispatch(setStateValue({ key: "saveIsLoading", value: true }));
			await lessonsService.update({
				data: updateData,
				filters: { id: lessonIdOverride || state.lessonId }
			});
			if (!lessonIdOverride) {
				await dispatch(fetchData({ lessonId: state.lessonId, stateLoaderKey: "saveIsLoading" }));
			}
			dispatch(
				emit({ message: `Lesson ${newPublishedState ? "published" : "unpublished"} successfully`, color: "success" })
			);
		} catch (e) {
			const err = e as { message: string };
			console.error(err);
			dispatch(
				emit({
					message:
						err && err.message && err.message.match(/^[A-Z]/)
							? err.message
							: "An error has occured while saving the data.",
					color: "error"
				})
			);
		} finally {
			dispatch(setStateValue({ key: "saveIsLoading", value: false }));
		}
	}
);

export const uploadVideo = createAsyncThunk(
	"createLesson/uploadVideo",
	async (
		data: {
			file: Partial<File>;
			inputStatePath: string;
			options: { onError: () => void; onProgress: ({}: { loaded: number; total: number }) => void };
		},
		{ dispatch, getState }
	) => {
		const { file, inputStatePath, options } = data;
		const { createLesson: state } = getState() as { createLesson: CreateLessonState };
		const inputData = get(state, inputStatePath);
		const loaderStatePath = `${inputStatePath}.isLoading`;
		try {
			dispatch(setStateValue({ key: loaderStatePath, value: true }));
			let isTrailer = false;
			let newlyUploadedVideoStatePath = "newlyUploadedMainVideo";
			if (!!inputStatePath.match(/trailerId/)) {
				isTrailer = true;
				newlyUploadedVideoStatePath = "newlyUploadedTrailerVideo";
			}
			dispatch(setStateValue({ key: `${inputStatePath}.readonly`, value: true }));
			const { key } = await performFileUpload({ file }, options);
			getVideoDuration({ file }, async (duration: number) => {
				const result = await lessonVideosService.create({
					name: file.name!,
					key,
					totalLengthInSeconds: duration,
					isTrailer
				});
				dispatch(setStateValue({ key: newlyUploadedVideoStatePath, value: result }));
				await dispatch(fetchVideoList());
				validateInput(result.id, {
					dispatch,
					setStateValue,
					inputData,
					validateForm
				});
				if (isTrailer) {
					dispatch(setStateValue({ key: `${inputStatePath}.readonly`, value: false }));
				}
			});
		} catch (e) {
			console.error(e);
			options.onError();
			dispatch(emit({ message: "An error has occured while uploading the video.", color: "error" }));
		} finally {
			dispatch(setStateValue({ key: loaderStatePath, value: false }));
		}
	}
);

export const uploadAttachment = createAsyncThunk(
	"createLesson/uploadAttachment",
	async (
		data: {
			file: Partial<File>;
			lessonId: number | undefined;
			statePath: string;
			options: {
				onError: () => void;
				onProgress: ({}: { loaded: number; total: number }) => void;
				onUploaded: () => void;
			};
		},
		{ dispatch }
	) => {
		const { file, lessonId, statePath, options } = data;
		const attachmentLoaderStatePath = `${statePath}.isLoading`;
		try {
			dispatch(setStateValue({ key: attachmentLoaderStatePath, value: true }));
			const { key } = await performFileUpload({ file }, options);
			await filesService.create({
				name: file.name!,
				fileName: key,
				fileType: file.type,
				fileSizeInBytes: file.size,
				lessonIds: [{ value: lessonId! }]
			});
			await dispatch(fetchAttachments({ lessonId, statePath }));
			options.onUploaded();
		} catch (e) {
			console.error(e);
			options.onError();
			dispatch(emit({ message: "An error has occured while uploading.", color: "error" }));
		} finally {
			dispatch(setStateValue({ key: attachmentLoaderStatePath, value: false }));
		}
	}
);

export const validateCurrentInteractiveLessonForm = createAsyncThunk(
	"createLesson/validateCurrentInteractiveLessonForm",
	async (options: { emitError?: boolean } = { emitError: true }, { dispatch, getState }) => {
		try {
			const { emitError } = options;
			await dispatch(validateInteractiveLessonFormGroup({ emitError }));
			const createLesson = (getState() as { createLesson: CreateLessonState }).createLesson;
			const result = performCurrentInteractiveLessonFormValidation(createLesson);
			const { selectedMainLessonVideo, currentStep } = createLesson;
			if (!selectedMainLessonVideo && currentStep === CreateLessonSteps.LessonVIT && emitError) {
				dispatch(
					setStateValue({
						key: "currentStep",
						value: CreateLessonSteps.LessonMaterials
					})
				);
			}
			if (result && result.error) {
				if (emitError) {
					dispatch(emit(result.error));
				}
				return false;
			}
			return true;
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while validating the form.", color: "error" }));
		}
	}
);

export const validateInteractiveLessonFormGroup = createAsyncThunk(
	"createLesson/validateInteractiveLessonFormGroup",
	async (options: { emitError?: boolean } = { emitError: true }, { dispatch, getState }) => {
		try {
			dispatch(
				validateForm({
					formStatePath: "interactiveLessonFormGroup",
					markInputsAsDirty: true
				})
			);
			// validate that the loop question has at least one correct answer option
			const { emitError } = options;
			const { interactiveLessonFormGroup } = (getState() as { createLesson: CreateLessonState }).createLesson;
			const { rawData, inputs } = interactiveLessonFormGroup;
			const { selectedInteractiveBlockIndex, interactiveBlocks } = rawData;
			let currentBlock;
			const currentBlockInput = inputs.interactiveBlocks[selectedInteractiveBlockIndex];
			if (currentBlockInput) {
				const questionDraftId = currentBlockInput.questionDraftId.value;
				const rawSelectedIndex = interactiveBlocks.findIndex(i => i.questionDraftId === questionDraftId);
				currentBlock = interactiveBlocks[rawSelectedIndex];
			}
			const { question } = currentBlock || {};
			if (question && question.typeId !== QuestionTypes.Grouping) {
				if (!question.answerOptions || !question.answerOptions.length) {
					const errorMessage = "Please select at least one correct option for the question.";
					dispatch(
						setStateValue({
							key: `interactiveLessonFormGroup.inputs.interactiveBlocks.${selectedInteractiveBlockIndex}.question.text.error`,
							value: errorMessage
						})
					);
					dispatch(
						setStateValue({
							key: "interactiveLessonFormGroup.valid",
							value: false
						})
					);
					if (emitError) {
						dispatch(emit({ message: errorMessage, color: "error" }));
						return;
					}
				}
				let hasCorrectOption = false;
				question.answerOptions.map(answerOption => {
					if (answerOption.deleted) {
						return;
					}
					if (answerOption.isCorrect && !hasCorrectOption) {
						hasCorrectOption = true;
					}
				});
				if (hasCorrectOption || question.typeId === QuestionTypes.Sequencing) {
					return;
				}
				const errorMessage = "Please select at least one correct option for the question.";
				dispatch(
					setStateValue({
						key: `interactiveLessonFormGroup.inputs.interactiveBlocks.${selectedInteractiveBlockIndex}.question.text.error`,
						value: errorMessage
					})
				);
				dispatch(
					setStateValue({
						key: "interactiveLessonFormGroup.valid",
						value: false
					})
				);
				if (emitError) {
					dispatch(emit({ message: errorMessage, color: "error" }));
				}
			}
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "An error has occurred while validating the form.", color: "error" }));
		}
	}
);

export const moveGroupQuestionAnswerOption = createAsyncThunk(
	"createLesson/moveGroupQuestionAnswerOption",
	async (
		options: {
			templatePath: string;
			formPath: string;
			groupsPath: string;
			newGroupIndex: number;
			oldGroupIndex: number;
			oldOptionIndex: number;
			newOptionIndex: number;
		},
		{ dispatch }
	) => {
		const { templatePath, formPath, groupsPath, newGroupIndex, oldGroupIndex, oldOptionIndex, newOptionIndex } =
			options;
		if (newGroupIndex === oldGroupIndex) {
			await dispatch(
				updateOptionOrder({
					newOrder: newOptionIndex,
					order: oldOptionIndex,
					parentPath: `${groupsPath}.${newGroupIndex}`,
					formPath
				})
			);
		} else {
			await dispatch(
				changeGroupForQuestionAnswerOption({
					templatePath,
					formPath,
					groupsPath,
					newGroupIndex,
					oldGroupIndex,
					optionIndex: oldOptionIndex
				})
			);
			await dispatch(
				updateOptionOrder({
					newOrder: newOptionIndex,
					order: 0,
					parentPath: `${groupsPath}.${newGroupIndex}`,
					formPath
				})
			);
		}
	}
);

// this has been moved to the bottom, unlike in other slices, so we can potentially handle thunk actions in extraReducers if we need to
export const createLessonSlice = createSlice({
	name: "createLesson",
	initialState,
	reducers: {
		removeQuestionAttachment: (state, action: PayloadAction<{ index: number; fullStatePath: string }>) => {
			const { index, fullStatePath } = action.payload;

			const attachments = get(state, `testOnlyQuestionsForm.${fullStatePath}.attachments`) ?? [];

			set(state, `testOnlyQuestionsForm.${fullStatePath}.attachments`, [
				...attachments.slice(0, index),
				...attachments.slice(index + 1)
			]);
		},
		createQuestionAttachment: (
			state,
			action: PayloadAction<{
				fullStatePath: string;
				name: string;
				fileName: string;
				fileType?: string;
				fileSizeInBytes?: number;
			}>
		) => {
			const { fullStatePath, ...attachment } = action.payload;
			const question = get(state, `testOnlyQuestionsForm.${fullStatePath}`);
			const attachments = question.attachments ?? [];
			const newAttachment = parseTemplate(
				get(state, "testOnlyQuestionsForm").templates["questions.attachments"],
				`testOnlyQuestionsForm.${fullStatePath}.attachments.${attachments.length}`,
				{
					defaultValueGetters
				}
			) as unknown;

			const index = attachments.length;
			attachments.push(newAttachment);
			set(state, `testOnlyQuestionsForm.${fullStatePath}.attachments`, attachments);
			const attachmentPath = `testOnlyQuestionsForm.${fullStatePath}.attachments.${index}`;
			set(state, `${attachmentPath}.fileName.value`, attachment.fileName);
			set(state, `${attachmentPath}.name.value`, attachment.name);
			set(state, `${attachmentPath}.fileType.value`, attachment.fileType);
			set(state, `${attachmentPath}.fileSizeInBytes.value`, attachment.fileSizeInBytes);
		},
		createQuestionAnswerOptionItem: (
			state,
			action: PayloadAction<{
				formName: string;
				initialData?: Record<string, unknown>;
				inputsPath: string;
				parentPath?: string;
				templatePath: string;
				type?: string;
			}>
		) => {
			const { formName, initialData, inputsPath, parentPath, templatePath, type } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath) as { order: { value: number }; deleted: { value: boolean } }[];
			const newItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.${items.length}`, {
				defaultValueGetters
			}) as { order: { value: number }; deleted: { value: boolean } };
			if (type) {
				newItem["isCorrect"].type = type;
			}
			if (items.length > 0) {
				const existingItems = items.filter(i => !i.deleted.value);
				newItem.order.value = existingItems.length ? existingItems[existingItems.length - 1].order.value + 1 : 0;
			}
			if (initialData) {
				for (const key in initialData) {
					set(newItem as Record<string, unknown>, key, initialData);
				}
			}
			items!.push(newItem);
			set(state, itemsPath, items);
			if (parentPath) {
				performQuestionAnswerOptionsInputTypeUpdate(state, {
					formPath: formName,
					questionPath: `${formName}.${parentPath}`,
					templatePath: templatePath
				});
			} else {
				utilsValidateFormAction(state, {
					payload: { formStatePath: formName, markInputsAsDirty: true },
					type: ""
				});
			}
		},
		removeQuestionAnswerOptionItem: (
			state,
			action: PayloadAction<{
				statePath: string;
				formName: string;
				inputsPath: string;
				removeSelectedOpts?: boolean;
				id?: string;
			}>
		) => {
			const { formName, inputsPath, removeSelectedOpts, id, statePath } = action.payload;
			const itemPath = `${formName}.${inputsPath}`;
			set(state, `${itemPath}.deleted.value`, true);
			set(state, `${itemPath}.text.validations`, undefined);
			if (get(state, `${itemPath}.isCorrect`)) {
				set(state, `${itemPath}.isCorrect.validations`, undefined);
			}
			if (removeSelectedOpts) {
				const groups = get(state, `${formName}.${statePath}.groups`);
				groups.forEach(group => {
					if (Array.isArray(group.selectedAnswerOptions.value)) {
						set(
							state,
							`${group.selectedAnswerOptions.statePath}.value`,
							group.selectedAnswerOptions.value.filter(y => y !== id)
						);
					}
				});
			}
			utilsValidateFormAction(state, {
				payload: { formStatePath: formName, markInputsAsDirty: true },
				type: ""
			});
		},

		removeQuestionData: (state, action: PayloadAction<{ statePath: string; VITQuestion?: boolean }>) => {
			const { statePath, VITQuestion = false } = action.payload;
			const typeId = get(state, `${statePath}.typeId.value`);
			if (!VITQuestion && typeId != QuestionTypes.Grouping && typeId != QuestionTypes.Sequencing) {
				set(state, `${statePath}.description.validations`, { maxLength: 5000, required: true });
			} else {
				set(state, `${statePath}.description.validations`, { maxLength: 5000 });
			}
			set(state, `${statePath}.text.value`, "");
			set(state, `${statePath}.tableLabel.value`, "");
			set(state, `${statePath}.answerOptionLabel.value`, "");
			if (!VITQuestion) set(state, `${statePath}.description.value`, "");
			set(state, `${statePath}.answerOptions`, []);
			set(state, `${statePath}.groups`, []);
			set(state, `${statePath}.tabs`, []);
			set(state, `${statePath}.questions`, []);
		},

		initGroupingQuestion: (
			state,
			action: PayloadAction<{ formPath: string; questionPath: string; templatePath: string; typeId?: string }>
		) => {
			const { formPath, questionPath, templatePath } = action.payload;
			const { answerOptions, groups } = get(state, questionPath);
			answerOptions.forEach(item => {
				set(state, `${item.text.statePath}.validations`, undefined);
				if (item.isCorrect) {
					set(state, `${item.isCorrect.statePath}.validations`, undefined);
				}
			});
			set(state, `${questionPath}.tabs`, []);
			set(state, `${questionPath}.questions`, []);
			set(state, `${questionPath}.description.validations`, undefined);

			if (!groups.length) {
				const answerOptionGroupItemTemplate = get(state, formPath).templates[templatePath];
				set(state, `${questionPath}.groups`, [
					setGroupAnswerOptionMaxLengthValidation(
						parseTemplate(answerOptionGroupItemTemplate, `${questionPath}.groups.0`, {
							defaultValueGetters
						})
					) as TestOnlyQuestionsFormAnswerGroupInputs,
					setGroupAnswerOptionMaxLengthValidation(
						parseTemplate(answerOptionGroupItemTemplate, `${questionPath}.groups.1`, {
							defaultValueGetters
						})
					) as TestOnlyQuestionsFormAnswerGroupInputs
				]);
				return;
			}
			if (groups.length === 1) {
				const answerOptionGroupItemTemplate = get(state, formPath).templates[templatePath];
				set(state, `${questionPath}.groups`, [
					...get(state, `${questionPath}.groups`),
					setGroupAnswerOptionMaxLengthValidation(
						parseTemplate(answerOptionGroupItemTemplate, `${questionPath}.groups.1`, {
							defaultValueGetters
						})
					) as TestOnlyQuestionsFormAnswerGroupInputs
				]);
			}
			const { groups: groupsConfig } = getQuestionConfig();
			const { validations: groupTextValidations } = groupsConfig![0].text;
			const { validations: questionTextValidations } = groupsConfig![0].answerOptions[0].text;
			groups.forEach(group => {
				set(state, `${group.text.statePath}.validations`, { ...groupTextValidations, maxLength: 25 });
				group.answerOptions.forEach(answerOptionItem => {
					set(state, `${answerOptionItem.text.statePath}.validations`, { ...questionTextValidations, maxLength: 25 });
				});
			});
		},

		updateAnswerOptsFlag: (
			state,
			action: PayloadAction<{
				formName: string;
				inputsPath: string;
				answerOptionIndex: number;
			}>
		) => {
			const { formName, inputsPath, answerOptionIndex } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const answerOptions = get(state, itemsPath);
			answerOptions.forEach((answerOptionItem, i) => {
				const { statePath } = answerOptionItem.isCorrect;
				if (answerOptionIndex === i) {
					set(state, `${statePath}.value`, true);
				} else {
					set(state, `${statePath}.value`, false);
				}
			});
			set(state, itemsPath, answerOptions);
		},

		clearArrayOfQuestionAnswerOption: (
			state,
			action: PayloadAction<{
				formName: string;
				inputsPath: string;
			}>
		) => {
			const { formName, inputsPath } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath);
			items.forEach(item => (item.deleted.value = true));
			set(state, itemsPath, items);
		},

		updateValidationCriteria: (state, action: PayloadAction<{ statePath: string; newLength?: number }>) => {
			const { statePath, newLength } = action.payload;
			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			const items = get(state, `${fullPath}.answerOptions`);
			items?.forEach((_, index) => {
				set(state, `${fullPath}.answerOptions.${index}.validations.maxLength`, newLength);
			});
		},

		initBowtieGroups: (state, action: PayloadAction<{ statePath: string }>) => {
			const { statePath } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";

			const groups = get(state, `testOnlyQuestionsForm.${fullPath}.groups`);
			for (let i = 0; i < 3; i++) {
				const group = parseTemplate(
					get(state, "testOnlyQuestionsForm").templates["questions.groups"],
					`${statePath}.${i}`,
					{
						defaultValueGetters
					}
				);

				group.answerOptions = [];
				group.text.validations.required = false;
				const optionsAmount = i == 1 ? 2 : 3;

				for (let option = 0; option < optionsAmount; option++) {
					const newOption = parseTemplate(
						get(state, "testOnlyQuestionsForm").templates["questions.groups.answerOptions"],
						`testOnlyQuestionsForm.${fullPath}.groups.${i}.answerOptions.${group.answerOptions.length}`,
						{
							defaultValueGetters
						}
					);
					group.answerOptions.push(newOption);
				}

				groups.push(group);
				group?.answerOptions.forEach(answerOption => {
					if (answerOption.text.validations) {
						answerOption.text.validations.maxLength = 25;
					}
				});
			}
			set(state, `testOnlyQuestionsForm.${fullPath}.groups`, groups);
		},

		setArrayOfQuestionAnswerOption: (
			state,
			action: PayloadAction<{
				formName: string;
				initialData?: Record<string, unknown>;
				inputsPath: string;
				templatePath: string;
				lengthOfOptions?: number;
				optionsArray: {
					highlighted: boolean;
					text: string;
				}[];
			}>
		) => {
			const { formName, inputsPath, templatePath, optionsArray } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath);
			while (items.length < optionsArray.length) {
				const newItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.${items.length}`, {
					defaultValueGetters
				});

				items.push(newItem);
			}
			optionsArray.forEach(({ text, highlighted }, index) => {
				const item = items[index];

				item.text.value = text;
				item.order.value = index;
				item.isCorrect.value = highlighted;
				item.deleted.value = false;
				if (item.text.validations?.maxLength) item.text.validations.maxLength = 5000;
			});
			set(state, itemsPath, items);
		},

		removeGap: (
			state,
			action: PayloadAction<{
				formName: string;
				gapIndex: number;
				statePath: string;
			}>
		) => {
			const { gapIndex, statePath, formName } = action.payload;
			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			set(state, `${formName}.${fullPath}.groups.${gapIndex}.deleted.value`, true);
			utilsValidateFormAction(state, {
				payload: { formStatePath: formName, markInputsAsDirty: true },
				type: ""
			});
		},

		initSingleChoiceQuestion: (
			state,
			action: PayloadAction<{
				statePath: string;
				formName: string;
			}>
		) => {
			const { statePath, formName } = action.payload;
			set(state, `${formName}.${statePath}.groups`, []);
			set(state, `${formName}.${statePath}.tabs`, []);
			set(state, `${formName}.${statePath}.questions`, []);
		},

		initCaseStudyQuestion: (
			state,
			action: PayloadAction<{
				formName: string;
				statePath: string;
			}>
		) => {
			const { statePath: _statePath, formName } = action.payload;

			const statePath = `${formName}.${_statePath}.questions`;
			const questions: FormInputItemsModel[] = [];

			const blackListedTypes = [
				QuestionTypes.Sequencing,
				QuestionTypes.Grouping,
				QuestionTypes.CaseStudy,
				QuestionTypes.BowTie
			];

			const selectValues = get(state, "questionTypeOptionsForTestLesson").filter(
				({ value }) => !blackListedTypes.includes(value as number)
			);
			for (let i = 0; i < 6; i++) {
				const newItem = parseTemplate(get(state, formName).templates["questions"], `${statePath}.${i}`, {
					defaultValueGetters
				});
				newItem.typeId.selectOptions = selectValues;
				newItem.answerOptions = [];
				newItem.attachments = [];
				newItem.groups = [];
				newItem.typeId.value = "";
				questions.push(newItem);
			}
			set(state, statePath, questions);
			set(state, `${formName}.${_statePath}.endOfQuestionSummary.validations`, undefined);
		},

		createTab: (
			state,
			action: PayloadAction<{
				formName: string;
				statePath: string;
			}>
		) => {
			const { formName, statePath: _statePath } = action.payload;
			const statePath = `${formName}.${_statePath}.tabs`;
			const items = get(state, statePath);
			const newItemPath = `${statePath}.${items.length}`;

			const newItem = parseTemplate(get(state, formName).templates["questions.tabs"], newItemPath, {
				defaultValueGetters
			});
			items.push(newItem);
			set(state, statePath, items);
		},
		removeTab: (
			state,
			action: PayloadAction<{
				formName: string;
				tabIndex: number;
				statePath: string;
			}>
		) => {
			const { tabIndex, formName, statePath } = action.payload;
			set(state, `${formName}.${statePath}.tabs.${tabIndex}.deleted.value`, true);
		},

		createQuestionGap: (
			state,
			action: PayloadAction<{
				formName: string;
				statePath: string;
				start?: boolean;
			}>
		) => {
			const { start, formName, statePath } = action.payload;
			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			const itemsPath = `${formName}.${fullPath}.groups`;
			const items = get(state, itemsPath);
			const newItem = parseTemplate(
				get(state, formName).templates["questions.groups"],
				`${itemsPath}.${items.length}`,
				{
					defaultValueGetters
				}
			) as { order: { value: number }; deleted: { value: boolean }; answerOptions: [] };

			newItem.answerOptions = [];

			if (!start) {
				items!.push(newItem);
			} else {
				const lastItem = parseTemplate(get(state, formName).templates["questions.groups"], `${itemsPath}.1`, {
					defaultValueGetters
				}) as { order: { value: number }; deleted: { value: boolean }; answerOptions: [] };
				lastItem.answerOptions = [];

				items!.push(newItem, lastItem);
			}
			const existingItems = items.filter(i => !i.deleted.value);
			newItem.order.value = existingItems.length ? existingItems[existingItems.length - 1].order.value + 1 : 0;
			set(state, itemsPath, items);
		},

		createGroupAnswerOptionItem: (state, action: PayloadAction<{ index: number; statePath: string }>) => {
			const { index, statePath } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			const optionsStatePath = `testOnlyQuestionsForm.${fullPath}.groups.${index}.answerOptions`;

			const items = get(state, optionsStatePath);

			const newOption = parseTemplate(
				get(state, "testOnlyQuestionsForm").templates["questions.groups.answerOptions"],
				`${optionsStatePath}.${items.length}`,
				{
					defaultValueGetters
				}
			);

			items.push(newOption);

			set(state, optionsStatePath, items);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "testOnlyQuestionsForm", markInputsAsDirty: true },
				type: ""
			});
		},

		removeGapOption: (
			state,
			action: PayloadAction<{ gapIndex: number; answerOptionIndex: number; statePath: string }>
		) => {
			const { gapIndex, answerOptionIndex, statePath } = action.payload;
			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			set(
				state,
				`testOnlyQuestionsForm.${fullPath}.groups.${gapIndex}.answerOptions.${answerOptionIndex}.deleted.value`,
				true
			);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "testOnlyQuestionsForm", markInputsAsDirty: true },
				type: ""
			});
		},

		updateCorrectFlag: (
			state,
			action: PayloadAction<{ groupIndex: number; answerOptionIndex: number; statePath: string }>
		) => {
			const { groupIndex, answerOptionIndex, statePath } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";

			const optionsStatePath = `testOnlyQuestionsForm.${fullPath}.groups.${groupIndex}.answerOptions`;
			const items = get(state, optionsStatePath);

			items.forEach(({ isCorrect }, index) => (isCorrect.value = index === answerOptionIndex));

			set(state, optionsStatePath, items);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "testOnlyQuestionsForm", markInputsAsDirty: true },
				type: ""
			});
		},

		updateMatrixQuestionGroup: (
			state,
			action: PayloadAction<{
				key: string;
				value: boolean;
				optId: string;
			}>
		) => {
			const { key, value, optId } = action.payload;
			const item = get(state, key);
			let values: string[] = [];
			values = value ? [...item.value, optId] : item.value.filter(x => x !== optId);
			set(state, `${key}.value`, values);
		},

		addOneMoreQuestionAnswerOption: (
			state,
			action: PayloadAction<{
				formName: string;
				initialData?: Record<string, unknown>;
				inputsPath: string;
				templatePath: string;
				setMaxLengthValidation?: boolean;
			}>
		) => {
			const { formName, inputsPath, templatePath, setMaxLengthValidation } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath);
			const firstItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.${items.length}`, {
				defaultValueGetters
			});
			if (setMaxLengthValidation) firstItem.text.validations.maxLength = MAX_GROUP_TEXT_LENGTH;
			items!.push(firstItem);
			set(state, itemsPath, items);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "testOnlyQuestionsForm", markInputsAsDirty: true },
				type: ""
			});
		},

		createSubItem: (
			state,
			action: PayloadAction<{ formName: string; index?: number; inputsPath: string; templatePath: string }>
		) => {
			const { formName, index, inputsPath, templatePath } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath) as unknown[];
			const oldItemsLength = +items.length;
			const actualIndex = typeof index !== "undefined" ? index : oldItemsLength;
			const newItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.${actualIndex}`, {
				defaultValueGetters
			}) as unknown;
			items!.splice(actualIndex, 0, newItem);
			if (oldItemsLength > actualIndex) {
				for (let i = actualIndex + 1; i <= oldItemsLength; i++) {
					fixSubItemsPathsAfterInsert(items[i] as Record<string, CustomInputModel<unknown>>, {
						pathToReplace: `${itemsPath}.${i - 1}`,
						pathToReplaceWith: `${itemsPath}.${i}`
					});
				}
			}
			set(state, itemsPath, items);
			set(state, `${itemsPath}.${actualIndex}.attachments`, []);
			set(state, `${itemsPath}.${actualIndex}.answerOptions`, []);
			set(state, `${itemsPath}.${actualIndex}.groups`, []);
		},
		changeGroupForQuestionAnswerOption: (
			state,
			action: PayloadAction<{
				templatePath: string;
				formPath: string;
				groupsPath: string;
				newGroupIndex: number;
				oldGroupIndex: number;
				optionIndex: number;
			}>
		) => {
			const { formPath, groupsPath, newGroupIndex, oldGroupIndex, optionIndex, templatePath } = action.payload;
			if (newGroupIndex === oldGroupIndex) {
				return;
			}
			const newGroup = [...get(state, `${groupsPath}.${newGroupIndex}.answerOptions`)];
			const newItem = parseTemplate(
				get(state, formPath).templates[`${templatePath}`],
				`${groupsPath}.${newGroupIndex}.answerOptions.${newGroup.length}`,
				{
					defaultValueGetters
				}
			) as TestOnlyQuestionsFormAnswerGroupOptionInputs;
			const oldOptionPath = `${groupsPath}.${oldGroupIndex}.answerOptions.${optionIndex}`;
			newItem.text.value = get(state, `${oldOptionPath}.text.value`);
			newItem.order.value = 0;
			const newAnswerOptions = [newItem, ...newGroup];
			updateFormInputsPath(newAnswerOptions, `${groupsPath}.${newGroupIndex}.answerOptions`);
			set(state, `${groupsPath}.${newGroupIndex}.answerOptions`, newAnswerOptions);
			set(state, `${oldOptionPath}.deleted.value`, true);
			set(state, `${oldOptionPath}.text.validations`, undefined);
			if (get(state, `${oldOptionPath}.isCorrect`)) {
				set(state, `${oldOptionPath}.isCorrect.validations`, undefined);
			}
			utilsValidateFormAction(state, {
				payload: { formStatePath: formPath, markInputsAsDirty: true },
				type: ""
			});
		},
		createSingleMatrixQuestionAnswerOptions: (
			state,
			action: PayloadAction<{
				formName: string;
				initialData?: Record<string, unknown>;
				inputsPath: string;
				templatePath: string;
				setMaxLengthValidation?: boolean;
			}>
		) => {
			const { formName, inputsPath, templatePath, setMaxLengthValidation } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath);
			const firstItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.0`, {
				defaultValueGetters
			});
			const secondItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.1`, {
				defaultValueGetters
			});
			if (setMaxLengthValidation) {
				firstItem.text.validations.maxLength = MAX_GROUP_TEXT_LENGTH;
				secondItem.text.validations.maxLength = MAX_GROUP_TEXT_LENGTH;
			} else {
				firstItem.text.validations.maxLength = CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH;
				secondItem.text.validations.maxLength = CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH;
			}
			items!.push(firstItem);
			items!.push(secondItem);
			set(state, itemsPath, items);
		},
		reduceAnswerOptsLength: (state, action: PayloadAction<{ length: number; inputsPath: string }>) => {
			const { length, inputsPath } = action.payload;
			const optionsStatePath = `testOnlyQuestionsForm.${inputsPath}`;
			const answerOptions = get(state, optionsStatePath);
			answerOptions.length = length;
			set(state, optionsStatePath, answerOptions);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "testOnlyQuestionsForm", markInputsAsDirty: true },
				type: ""
			});
		},
		createQuestionGroup: (
			state,
			action: PayloadAction<{
				formName: string;
				statePath: string;
				start?: boolean;
				setMaxLengthValidation?: boolean;
			}>
		) => {
			const { formName, statePath, start, setMaxLengthValidation } = action.payload;
			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			const itemsPath = `${formName}.${fullPath}.groups`;
			const items = get(state, itemsPath);
			const newItem = parseTemplate(
				get(state, formName).templates["questions.groups"],
				`${itemsPath}.${items.length}`,
				{
					defaultValueGetters
				}
			);
			newItem.answerOptions = [];
			if (setMaxLengthValidation) newItem.text.validations.maxLength = CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH;
			items!.push(newItem);
			if (start) {
				const secondItem = parseTemplate(get(state, formName).templates["questions.groups"], `${itemsPath}.1`, {
					defaultValueGetters
				});
				secondItem.answerOptions = [];
				if (setMaxLengthValidation) secondItem.text.validations.maxLength = CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH;
				items!.push(secondItem);
			}
			set(state, itemsPath, items);
		},
		removeQuestionGroup: (state, action: PayloadAction<{ groupIndex: number; statePath: string }>) => {
			const { groupIndex, statePath } = action.payload;
			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			set(state, `testOnlyQuestionsForm.${fullPath}.groups.${groupIndex}.deleted.value`, true);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "testOnlyQuestionsForm", markInputsAsDirty: true },
				type: ""
			});
		},
		populateData: utilsPopulateData,
		populateInputs: utilsPopulateInputs,
		removeQuestionAnserOptionItem: (state, action: PayloadAction<{ formName: string; inputsPath: string }>) => {
			const { formName, inputsPath } = action.payload;
			const itemPath = `${formName}.${inputsPath}`;
			set(state, `${itemPath}.deleted.value`, true);
			set(state, `${itemPath}.text.validations`, undefined);
			if (get(state, `${itemPath}.isCorrect`)) {
				set(state, `${itemPath}.isCorrect.validations`, undefined);
			}
			utilsValidateFormAction(state, {
				payload: { formStatePath: formName, markInputsAsDirty: true },
				type: ""
			});
		},
		removeQuestionItem: (state, action: PayloadAction<{ formName: string; inputsPath: string }>) => {
			const { formName, inputsPath } = action.payload;
			const itemPath = `${formName}.${inputsPath}`;
			const item = get(state, itemPath) as TestOnlyQuestionsFormQuestionItemInputs;
			set(state, `${itemPath}.deleted.value`, true);
			set(state, `${itemPath}.typeId.validations`, undefined);
			set(state, `${itemPath}.difficultyLevelId.validations`, undefined);
			set(state, `${itemPath}.text.validations`, undefined);
			set(state, `${itemPath}.endOfQuestionSummary.validations`, undefined);
			item.answerOptions.forEach((_answerOptionInputs, answerOptionIndex) => {
				const innerItemPath = `${itemPath}.answerOptions.${answerOptionIndex}`;
				set(state, `${innerItemPath}.text.validations`, undefined);
				set(state, `${innerItemPath}.isCorrect.validations`, undefined);
			});
			utilsValidateFormAction(state, {
				payload: { formStatePath: formName, markInputsAsDirty: true },
				type: ""
			});
		},
		resetState: utilsResetState,
		setStateValue: utilsSetStateValue,
		toggleExpandAllTestOnlyQuestions: state => {
			const newOpenedState = !state.testOnlyQuestionsExpanded;
			state.testOnlyQuestionsExpanded = newOpenedState;
			state.testOnlyQuestionsForm.inputs.questions.forEach(({ _expanded }) => {
				set(state, `${_expanded.statePath}.value`, newOpenedState);
			});
		},
		updateOptionOrder: (
			state,
			action: PayloadAction<{ order: number; newOrder: number; parentPath: string; formPath: string }>
		) => {
			const { parentPath, order, newOrder, formPath } = action.payload;
			const { answerOptions } = get(state, parentPath) as TestOnlyQuestionsFormQuestionItemInputs;

			if (newOrder === order) {
				return;
			}

			const newAnswerOptions = [...answerOptions].sort((a, b) => {
				if (a.order?.value === undefined || b.order?.value === undefined) return 0;
				return a.order.value - b.order.value;
			});

			newAnswerOptions[order].order.value = newOrder;

			if (newOrder > order) {
				newAnswerOptions.slice(order + 1, newOrder).forEach(({ order }) => {
					order?.value !== undefined && order.value--;
				});
			} else {
				newAnswerOptions.slice(newOrder, order).forEach(({ order }) => {
					order?.value !== undefined && order.value++;
				});
			}

			newAnswerOptions
				.sort((a, b) => {
					if (a.order?.value === undefined || b.order?.value === undefined) return 0;
					return a.order.value - b.order.value;
				})
				.forEach(({ order }, index) => (order.value = index));
			updateFormInputsPath(newAnswerOptions, `${parentPath}.answerOptions`);
			set(state, `${parentPath}.answerOptions`, [...newAnswerOptions]);
			utilsValidateFormAction(state, {
				payload: { formStatePath: formPath, markInputsAsDirty: true },
				type: ""
			});
		},
		updateCorrectOption: (state, action: PayloadAction<{ itemIndex: number; parentPath: string }>) => {
			const { itemIndex, parentPath } = action.payload;
			const { answerOptions, typeId } = get(state, parentPath);
			if (typeId.value !== QuestionTypes.SingleChoice) {
				return;
			}
			answerOptions.forEach((answerOptionInputs, answerOptionIndex) => {
				if (answerOptionIndex === itemIndex) {
					return;
				}
				set(state, `${answerOptionInputs.isCorrect.statePath}.value`, false);
			});
		},
		updateQuestionAnswerOptionsInputType: (
			state,
			action: PayloadAction<{ typeId?: string; formPath; questionPath: string; templatePath: string }>
		) => {
			performQuestionAnswerOptionsInputTypeUpdate(state, action.payload);
		},
		validateForm: utilsValidateFormAction,
		editSequencingAnswerOptions: state => {
			const questions = get(state, "testOnlyQuestionsForm.inputs.questions");
			questions?.forEach(question => {
				if (question.typeId.value === QuestionTypes.Sequencing) {
					question?.answerOptions.forEach(answerOption => {
						answerOption.text.validations.maxLength = 40;
					});
				}
			});
			set(state, "testOnlyQuestionsForm.inputs.questions", questions);
		}
	}
});

export const {
	createQuestionAnswerOptionItem,
	createSubItem,
	changeGroupForQuestionAnswerOption,
	createSingleMatrixQuestionAnswerOptions,
	populateData,
	populateInputs,
	addOneMoreQuestionAnswerOption,
	removeQuestionAnserOptionItem,
	removeQuestionItem,
	resetState,
	setStateValue,
	toggleExpandAllTestOnlyQuestions,
	updateCorrectOption,
	updateQuestionAnswerOptionsInputType,
	createQuestionAttachment,
	removeQuestionAttachment,
	updateOptionOrder,
	validateForm,
	reduceAnswerOptsLength,
	removeQuestionGroup,
	updateMatrixQuestionGroup,
	createQuestionGroup,
	removeQuestionAnswerOptionItem,
	updateAnswerOptsFlag,
	removeGap,
	createQuestionGap,
	createGroupAnswerOptionItem,
	removeGapOption,
	updateCorrectFlag,
	clearArrayOfQuestionAnswerOption,
	setArrayOfQuestionAnswerOption,
	updateValidationCriteria,
	initBowtieGroups,
	removeQuestionData,
	initGroupingQuestion,
	removeTab,
	createTab,
	initCaseStudyQuestion,
	initSingleChoiceQuestion,
	editSequencingAnswerOptions
} = createLessonSlice.actions;

export default createLessonSlice.reducer;
