import React from "react";

import { Box, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { NotificationItem, dismiss, selectNotifications } from "store/features/notifications/notifications.slice";

const Notifications = () => {
	const items: NotificationItem[] = useSelector(selectNotifications);
	const dispatch = useDispatch();

	const handleClose = (id: number) => {
		dispatch(dismiss(id));
	};

	return (
		<Box position="fixed" bottom="0" right="0" style={{ zIndex: 14000 }}>
			<Box display="flex" flexDirection="column">
				{items.map(item => (
					<Snackbar
						key={item.id}
						open
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						style={{ position: "relative", margin: "6px 0" }}
					>
						<Alert elevation={6} variant="filled" onClose={() => handleClose(item.id)} severity={item.type}>
							{item.message}
						</Alert>
					</Snackbar>
				))}
			</Box>
			{/* <Button onClick={() => snackbar.enqueueSnackbar("=")}>+</Button> */}
		</Box>
	);
};

export default Notifications;
