import React, { useEffect } from "react";

import { Box, Breadcrumbs, Chip, Grid, Typography, useTheme } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";

import { FilesUploaded } from "@remar/shared/dist/components/FilesUploaded";
import { Header, HeaderContainer } from "@remar/shared/dist/components/HeaderContainer/styles";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { downloadURI } from "@remar/shared/dist/utils/serviceUtils";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import { getFullState } from "store/features/Course/course.slice";
import { getCurrentLesson, getLesson } from "store/features/Lesson/lesson.slice";

import { FileEditSvg } from "assets/icons";

import { routes } from "core/constants";

import { CardBlock } from "./styles";

import { Comments } from "../Comments";
import { CardContainer, CourseHeading, HeaderActions } from "../Courses/Chapters/styles";
import { Breadcrumb, BtnSecondary, Container } from "../Courses/styles";

const LessonDetails = () => {
	const theme = useTheme<IExtendedTheme>();
	const history = useHistory();
	const dispatch = useDispatch();
	const { lessonId } = useParams<{ lessonId: number }>();

	const lesson = useSelector(getCurrentLesson);
	const { activeCourse, activeChapter } = useSelector(getFullState);

	useEffect(() => {
		dispatch(getLesson({ lessonId }));
	}, [dispatch, lessonId]);

	return (
		<Container>
			<HeaderContainer>
				<Header>
					<CourseHeading>{lesson?.name || "-"}</CourseHeading>
					<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
						<Link to={`${routes.course.getPath()}`}>
							<Breadcrumb>Courses</Breadcrumb>
						</Link>

						{activeCourse && (
							<Link to={`${routes.course.getPath()}/${activeCourse.id}`}>
								<Breadcrumb>{activeCourse.name}</Breadcrumb>
							</Link>
						)}
						{activeChapter && activeCourse && (
							<Link to={`${routes.course.getPath()}/${activeCourse.id}/chapters/${activeChapter.id}`}>
								<Breadcrumb>{activeChapter.name}</Breadcrumb>
							</Link>
						)}
						{lesson && <Breadcrumb>Comments for {lesson.name}</Breadcrumb>}
					</Breadcrumbs>
				</Header>
				<HeaderActions>
					<BtnSecondary variant="contained" onClick={() => history.goBack()}>
						Back
					</BtnSecondary>
				</HeaderActions>
			</HeaderContainer>
			<CardContainer display="flex" gridGap={24}>
				<Grid item xs={9}>
					<Comments lessonId={lessonId} />
				</Grid>
				<Grid item xs={3} style={{ paddingTop: "23px", display: "flex", flexFlow: "column", gap: "24px" }}>
					<CardBlock title="Lesson Details">
						<Box className="card-info-block">
							<Typography className="card-info-block-title">Lesson Name</Typography>
							<Chip className="chip" label={lesson?.name} />
						</Box>
						<Box className="card-info-block">
							<Typography className="card-info-block-title">Section Name</Typography>
							{lesson?.sections?.map((s, i) => (
								<Chip className="chip" key={`section-${i}`} label={s} />
							))}
						</Box>
						<Box className="card-info-block">
							<Typography className="card-info-block-title">Chapter Name</Typography>
							{lesson?.chapters?.map((ch, i) => (
								<Chip className="chip" key={`chapter-${i}`} label={ch} />
							))}
						</Box>
						<Box className="card-info-block">
							<Typography className="card-info-block-title">Course Name</Typography>
							{lesson?.courses?.map((c, i) => (
								<Chip className="chip" key={`course-${i}`} label={c} />
							))}
						</Box>
					</CardBlock>

					<CardBlock
						title={
							<>
								<Chip
									label="1"
									style={{
										background: "hsl(240, 6%, 29%)",
										fontWeight: 900,
										color: theme.palette.text.primary
									}}
								/>
								<Typography className="card-title">Test Lesson</Typography>
							</>
						}
					>
						<Typography className="card-small-text">
							Banjo tote bag bicycle rights, High Life sartorial cray craft beer whatever street art fap. Hashtag
							typewriter banh mi, squid. Lorem ipsum short text
						</Typography>
						<Typography className="s-block-title">
							<FileEditSvg width="16px" height="18px" /> Contains Test
						</Typography>
						{lesson && (
							<FilesUploaded
								attachments={lesson?.attachments}
								isDownload={true}
								showTitle={false}
								borderColor={theme.palette.text.secondary}
								IconSvg={DownloadIconSvg}
								onClick={attachment => downloadURI(attachment.fileUrl, attachment.name)}
							/>
						)}
					</CardBlock>
				</Grid>
			</CardContainer>
		</Container>
	);
};

export default LessonDetails;
