import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../index";
import { locationsService } from "../../services/locations";

export interface manageLocationsState {
	loading: boolean;
	locations: [];
	totalItems: number;
}

const initialState: manageLocationsState = {
	loading: false,
	locations: [],
	totalItems: 0
};

export const fetchLocations = createAsyncThunk(
	"manageLocations/fetchLocations",
	async (
		options: { perPage: number; page: number; searchKeyword: string; filters?: Record<string, unknown> },
		{ dispatch }
	) => {
		dispatch(setLoading(true));
		const { perPage, page, searchKeyword, filters } = options;
		try {
			const locations = await locationsService.find({
				page,
				perPage,
				searchKeyword,
				filters
			});
			const { items, totalItems } = locations;
			dispatch(setLocations({ items, totalItems }));
		} catch {
			// todo handle error
			console.log("Error");
		} finally {
			dispatch(setLoading(false));
		}
	}
);

export const manageLocationsSlice = createSlice({
	name: "manageLocations",
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setLocations: (state, action) => {
			state.locations = action.payload.items;
			state.totalItems = action.payload.totalItems;
		}
	}
});

export const fullState = (state: RootState): manageLocationsState => state.manageLocations;

export const { setLocations, setLoading } = manageLocationsSlice.actions;

export default manageLocationsSlice.reducer;
