import React, { memo } from "react";

import { MenuItem } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import { logout } from "store/features/Auth/auth.slice";

import { Actions, Avatar, Container, Menu } from "./styles";

interface TopNavProps {}

const TopNav: React.FC<TopNavProps> = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const { user } = useSelector((state: RootState) => state.auth);
	const dispatch = useDispatch();
	const fullName = [user?.firstName, user?.lastName].join(" ");

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const itemActions = [
		{
			comp: "",
			name: "My Account",
			onClick: () => {}
		},
		{
			comp: "",
			name: "Logout",
			onClick: () => {
				dispatch(logout());
			}
		}
	];

	return (
		<Container>
			<Actions>
				<Avatar width={32} height={32} variant="rounded" style={{ color: "#1998d5" }} onClick={handleClick}>
					{fullName
						?.split(" ")
						.filter((x: string) => x)
						.map((i: string) => i[0])
						.join("") || "A"}
				</Avatar>
				<Menu id="user-menu" anchorEl={anchorEl} style={{ top: "40px" }} open={Boolean(anchorEl)} onClose={handleClose}>
					{itemActions.map(({ name, onClick }) => (
						<MenuItem key={name} onClick={onClick}>
							{name}
						</MenuItem>
					))}
				</Menu>
			</Actions>
		</Container>
	);
};

export default memo(TopNav);
