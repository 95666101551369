import React from "react";

import { Box, Checkbox } from "@material-ui/core";

import { QuizQuestionAnswerOption, StyledText, TestQuizQuestionText } from "./style";

const MultipleChoiceQuestionPreview = ({ question }) => {
	let { answerOptions } = question;
	answerOptions = answerOptions ?? question.data.answerOptions;

	return (
		<>
			<Box>
				<TestQuizQuestionText>{question?.text}</TestQuizQuestionText>
			</Box>
			{answerOptions?.map(({ id, text }) => (
				<QuizQuestionAnswerOption key={id}>
					<Checkbox color="primary" />
					<StyledText>{text}</StyledText>
				</QuizQuestionAnswerOption>
			))}
		</>
	);
};

export default MultipleChoiceQuestionPreview;
