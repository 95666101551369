import React from "react";

import { Box, Checkbox } from "@material-ui/core";

import {
	MatrixTableBody,
	MatrixTableHeader,
	MultipleChoiceOptionContainer,
	StyledText,
	TestQuizQuestionText
} from "./style";

const MultipleResponseGroupQuestionPreview = ({ question }) => {
	const { tableLabel, groups, answerOptionLabel } = question.data;
	return (
		<Box>
			<TestQuizQuestionText>{question?.text}</TestQuizQuestionText>
			<MatrixTableHeader display="flex" maxWidth={"650px"} flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<Box
						display="flex"
						flexDirection="row"
						width="50%"
						height={"100%"}
						alignItems="center"
						justifyContent="center"
						pr={"40px"}
					>
						<StyledText>{tableLabel}</StyledText>
					</Box>

					<Box
						height={"100%"}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="center"
						width="50%"
						borderLeft={"solid 1px rgba(151, 151, 151, 0.3)"}
					>
						<StyledText>{answerOptionLabel}</StyledText>
					</Box>
				</Box>
			</MatrixTableHeader>
			<MatrixTableBody>
				{groups.map(({ text, id: gId, answerOptions }) => (
					<Box
						key={gId}
						borderBottom="solid 1px rgba(151, 151, 151, 0.3)"
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%" height="100%">
							<Box display="flex" flexDirection="row" height={"100%"} width="50%" pl={"22px"} alignItems="center">
								<StyledText>{text}</StyledText>
							</Box>
							<Box
								display="flex"
								flexDirection="row"
								width="50%"
								flexWrap="wrap"
								borderLeft="solid 1px rgba(151, 151, 151, 0.3)"
								padding={1}
							>
								{answerOptions.map(({ id: optId, text }) => (
									<MultipleChoiceOptionContainer key={optId}>
										<Checkbox color="primary" />
										<StyledText>{text}</StyledText>
									</MultipleChoiceOptionContainer>
								))}
							</Box>
						</Box>
					</Box>
				))}
			</MatrixTableBody>
		</Box>
	);
};

export default MultipleResponseGroupQuestionPreview;
