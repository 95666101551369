import { Package } from "@remar/shared/dist/models";
import { CourseDataExternalIntegrationDataItem } from "store/features/CourseAddEdit/models";

export const DefaultLocationPackage = {
	id: 0,
	description: [],
	name: "",
	isActive: true,
	isDeleted: false,
	courseId: 0,
	locationPackageTypeId: 0,
	allowedUST: []
};

export interface LocationPackageData extends Package {
	externalIntegrationData: {
		initial: CourseDataExternalIntegrationDataItem;
		recurring: CourseDataExternalIntegrationDataItem;
		trial: CourseDataExternalIntegrationDataItem;
	};
}
