import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Breadcrumbs, Button, CircularProgress } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";
import { ReactComponent as DeleteIconSvg } from "@remar/shared/dist/assets/icons/icon-trash-filled.svg";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar/index";
import {
	ColumnHeader,
	Container,
	StyledCellText,
	StyledCellWrapper,
	THeaderTitle,
	THeaderWrapper
} from "@remar/shared/dist/components/Table/styles";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { formatAdminDate } from "@remar/shared/dist/utils/myAccountUtils";
import { downloadExternalFile } from "@remar/shared/dist/utils/serviceUtils/helpers";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import {
	deleteFileVaultFile,
	fetchFolders,
	fetchPersonalStorageFolderFiles,
	getFullState,
	resetModalForm
} from "store/features/FileVault/FileVault.slice";

import { routes } from "core/constants";

import AttachmentDeleteModal from "../../../../../Components/AttachmentDeleteModal";
import { FileUploadModal } from "../FileVaultSubFoldersFiles/FileUploadModal";
import { HeaderSplit, InvoiceDownloadIcon, Link, SectionHeader, SubFolderFilesTableSpacer } from "../styles";

const FileVaultPersonalStorageFiles = () => {
	const dispatch = useDispatch();

	const { folderId } = useParams<{ folderId: string }>();
	const {
		isLoading,
		personalStorageFolderFiles: { files, page, perPage, totalItems },
		content,
		isDeleteLoading
	} = useSelector(getFullState);
	const currentFolder = folderId && content?.find(({ id }) => id === +folderId);
	const [modal, setModal] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [attachment, setAttachment] = useState<Record<string, unknown>>({});
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

	useEffect(() => {
		content.length === 0 && dispatch(fetchFolders(""));
		dispatch(fetchPersonalStorageFolderFiles({ folderId: +folderId }));
	}, []);

	const fetchFiles = () => {
		dispatch(fetchPersonalStorageFolderFiles({ folderId: +folderId, page, searchText: inputValue }));
	};

	const onCloseModal = () => {
		if (!isLoading) {
			setModal(false);
			dispatch(resetModalForm());
		}
	};
	const handleDelete = (id: number, folderId: number): void => {
		dispatch(
			deleteFileVaultFile({
				id,
				folderId,
				sideEffect: () => {
					setShowDeleteModal(false);
					dispatch(fetchPersonalStorageFolderFiles({ folderId: +folderId }));
				}
			})
		);
	};
	const handleSearchBarChange = useCallback((searchText: string) => {
		dispatch(fetchPersonalStorageFolderFiles({ folderId: +folderId, searchText }));
	}, []);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Date</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => {
					return (
						<StyledCellWrapper>
							<StyledCellText>{formatAdminDate(createdAt)}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "date"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Files</ColumnHeader>,
				Cell: ({ rowData: { name } }) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "files"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Download Files</ColumnHeader>,
				Cell: ({ rowData: { fileUrl } }) => (
					<StyledCellWrapper>
						<InvoiceDownloadIcon>
							<DownloadIconSvg
								onClick={() => {
									downloadExternalFile(fileUrl);
								}}
							/>
						</InvoiceDownloadIcon>
					</StyledCellWrapper>
				),
				dataKey: "downloadFiles"
			},
			{
				Cell: ({ rowData }) => (
					<StyledCellWrapper>
						<InvoiceDownloadIcon>
							<DeleteIconSvg
								onClick={() => {
									setAttachment(rowData);
									setShowDeleteModal(true);
								}}
							/>
						</InvoiceDownloadIcon>
					</StyledCellWrapper>
				),
				dataKey: "delete"
			}
		],
		[currentFolder]
	);
	return (
		<>
			<Container>
				<HeaderSplit>
					<SubFolderFilesTableSpacer>
						<SectionHeader>FileVault</SectionHeader>
						<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
							<Link to="/">Dashboard</Link>
							<Link to={routes.fileVault.getPath()}>File Vault</Link>
							{currentFolder && (
								<Link to={`${routes.fileVault.getPath()}/personal/${currentFolder.id}`}>{currentFolder.name}</Link>
							)}
						</Breadcrumbs>
					</SubFolderFilesTableSpacer>
					<Box mr={6}>
						<Button
							variant={"contained"}
							color={"primary"}
							style={{ fontWeight: "bold" }}
							onClick={() => {
								return setModal(true);
							}}
						>
							Upload Files
						</Button>
					</Box>
					{!!currentFolder && (
						<FileUploadModal
							modal={modal}
							onCloseModal={onCloseModal}
							sideEffect={fetchFiles}
							folderId={currentFolder.id}
							isPersonalFolder={true}
						/>
					)}
				</HeaderSplit>
				<Box mr={4}>
					<THeaderWrapper>
						<THeaderTitle>File Vault</THeaderTitle>
						<SearchBarComponent
							inputValue={inputValue}
							setInputValue={setInputValue}
							onChange={handleSearchBarChange}
							isDebounced={true}
							debounceTime={500}
							placeHolder={"Type to filter by keywords"}
						/>
					</THeaderWrapper>
					{isLoading ? (
						<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
							<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
						</Box>
					) : (
						<>
							<MaterialTable
								columns={tableColumns as Array<IColumn<unknown>>} // todo: refactor - setup correct type
								data={files || []}
								height={800}
							/>
							<TablePagination
								count={totalItems}
								page={page}
								onChange={(_, page) =>
									dispatch(
										fetchPersonalStorageFolderFiles({
											page,
											folderId: +folderId,
											searchText: inputValue
										})
									)
								}
								rowsPerPage={perPage}
							/>
						</>
					)}
				</Box>
			</Container>
			<AttachmentDeleteModal
				isDeleteLoading={isDeleteLoading}
				text={`Are you sure you want to delete <span>${attachment?.name}</span> ?`}
				modalOpen={showDeleteModal}
				setModalOpen={setShowDeleteModal}
				onConfirm={() => handleDelete(attachment!.id as number, +folderId)}
			/>
		</>
	);
};

export default FileVaultPersonalStorageFiles;
