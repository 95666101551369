import React, { useEffect, useRef, useState } from "react";

import { Box, SvgIcon } from "@material-ui/core";
import { ReactComponent as IconEquals } from "@remar/shared/dist/assets/icons/icon-equals.svg";
import { getSanitizedHtmlText } from "@remar/shared/dist/utils/serviceUtils/helpers";
import isEmpty from "lodash/isEmpty";
import { DndProvider, useDrag, useDrop } from "react-dnd";

import { HTML5Backend } from "react-dnd-html5-backend";

import {
	AnswerDropZoneContainer,
	DragOptionContainer,
	DragOptionContainerText,
	DragOptionReadOnlyContainer,
	DragOptionText,
	StyledGap,
	TestQuizQuestionText,
	useBowTieStyles
} from "./style";

const groupTypes = ["Actions To Take", "Potential Conditions", "Parameters To Monitor"];
interface Option {
	id: string;
	text: string;
}

interface SelectedAnswer {
	option: Option;
	groupId?: string;
}

const StandaloneDragOption = ({ option, groupId, sideEffect }) => {
	const [, drag] = useDrag({
		item: { option, sideEffect },
		type: `standalone-${groupId}`,
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	});

	return (
		<DragOptionReadOnlyContainer ref={drag} id={option?.id}>
			<DragOptionText>{option?.text}</DragOptionText>
			<Box mt={1} display="flex">
				<SvgIcon style={{ cursor: "move" }} fontSize="large">
					<IconEquals />
				</SvgIcon>
			</Box>
		</DragOptionReadOnlyContainer>
	);
};

const GroupDragOptions = ({ option, groupId }) => {
	const [, drag] = useDrag({
		item: { option },
		type: `group-${groupId}`,
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	});
	return (
		<DragOptionContainer maxWidth={"270px"} id={option?.id} ref={drag}>
			<DragOptionContainerText>{option?.text}</DragOptionContainerText>
			<SvgIcon style={{ cursor: "move" }} fontSize="large">
				<IconEquals />
			</SvgIcon>
		</DragOptionContainer>
	);
};

const AnswerDropZone = ({ onDropped, groupId, userAnswer, zoneIndex }) => {
	const [selectedAnswer, setSelectedAnswer] = useState<SelectedAnswer>();
	const ref = useRef({
		selectedAnswer
	});
	useEffect(() => {
		if (!isEmpty(selectedAnswer)) {
			if (selectedAnswer?.option.id !== userAnswer?.option.id) {
				onDropped({ item: selectedAnswer, prevItem: ref.current.selectedAnswer, index: zoneIndex });
			}
			ref.current.selectedAnswer = selectedAnswer;
		}
	}, [selectedAnswer]);

	useEffect(() => {
		if (userAnswer && userAnswer.option.id !== selectedAnswer?.option.id) {
			setSelectedAnswer({
				groupId: userAnswer.option.groupId,
				option: { id: userAnswer.option.id, text: userAnswer.option.text }
			});
		}
	}, [userAnswer]);

	const [, drop] = useDrop(() => ({
		accept: `group-${groupId}`,
		drop: (item: SelectedAnswer) => {
			setSelectedAnswer({ ...item, groupId });
		},
		collect: monitor => ({
			isOver: monitor.isOver()
		})
	}));

	const cleanData = () => {
		ref.current.selectedAnswer = undefined;
		setSelectedAnswer(undefined);
	};

	return (
		<AnswerDropZoneContainer ref={drop}>
			{!isEmpty(selectedAnswer) ? (
				<StyledGap>
					<StandaloneDragOption sideEffect={cleanData} groupId={groupId} option={selectedAnswer?.option} />
				</StyledGap>
			) : (
				"Drag your answer here"
			)}
		</AnswerDropZoneContainer>
	);
};

const GroupDropZone = ({ groupId, index, onDropped, answerOptions, width }) => {
	const [{ isOver, canDrop }, drop] = useDrop(() => ({
		accept: `standalone-${groupId}`,
		drop: (item: { option: Record<string, unknown>; sideEffect: () => void }) => {
			onDropped({ option: item.option as Record<string, unknown>, groupId: groupId });
			item?.sideEffect();
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	}));

	const overStyles = {
		opacity: 0.7
	};

	return (
		<div ref={drop} style={isOver && canDrop ? { ...overStyles, width } : { width }}>
			<Box>{groupTypes[index]}</Box>
			{answerOptions.map((dragOption, i) => (
				<Box mt={2} key={i}>
					<GroupDragOptions groupId={groupId} option={dragOption} />
				</Box>
			))}
		</div>
	);
};

const BowTieQuestionPreview = ({ question }) => {
	const [userAnswers, setUserAnswers] = useState<{ groupId: string; text?: string; id: string; order: string }[]>([]);

	const classes = useBowTieStyles({ bgColor: "#fff", bg: "#dbdee4", padding: "16px" });
	const groups = question.data.groups;
	const [userAnswerToRemove, setUserAnswerToRemove] = useState<SelectedAnswer>();

	useEffect(() => {
		if (!isEmpty(userAnswerToRemove)) {
			const { groupId: _groupId, option } = userAnswerToRemove as SelectedAnswer;
			const arr = [...userAnswers];
			const index = arr.findIndex(({ id, groupId }) => id === option.id && groupId === _groupId);
			arr.splice(index, 1);
			setUserAnswers([...arr]);
		}
	}, [userAnswerToRemove]);

	const onDroppedHandle = ({ item, prevItem, index }) => {
		const {
			groupId,
			option: { id }
		} = item;
		const _item = { groupId, id, order: index };
		const existedAnswerOpts = [...userAnswers];
		const answers = prevItem
			? existedAnswerOpts.filter(
					x => !(x.id === prevItem.option.id && x.groupId === prevItem.groupId && x.order === index)
			  )
			: existedAnswerOpts;
		setUserAnswers([...answers, _item]);
	};

	const getAnswerOptions = (groupId, answerOptions) => {
		return answerOptions.filter(item => !userAnswers.some(x => x.groupId === groupId && x.id === item.id));
	};

	const getGroupAnswerOptions = (groupId, index, groups) => {
		const answer = userAnswers.find(answer => answer.groupId === groupId && answer.order === index);
		if (answer) {
			answer.text = groups.find(f => f.id === groupId).answerOptions.find(({ id }) => id === answer.id)?.text;
		}
		return answer ? { option: answer } : undefined;
	};

	return (
		<DndProvider backend={HTML5Backend}>
			<Box display={"flex"} flexDirection="column" width={"100%"} maxWidth={"860px"} ml={2}>
				<TestQuizQuestionText>{getSanitizedHtmlText(question?.text)}</TestQuizQuestionText>

				<div className={classes.schemaContainer}>
					<div className={classes.shortSchemaColumn}>
						<div className={classes.schemaBox}>
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[0].id}
								zoneIndex={0}
								userAnswer={getGroupAnswerOptions(groups[0].id, 0, groups)}
							/>
						</div>
						<div className={classes.schemaBox}>
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[0].id}
								zoneIndex={1}
								userAnswer={getGroupAnswerOptions(groups[0].id, 1, groups)}
							/>
						</div>
					</div>
					<div className={classes.schemaSeparator}>
						<div className={classes.schemaTopDiag}>
							<i></i>
							<i></i>
						</div>
						<div className={classes.schemaBottomDiag}>
							<i></i>
							<i></i>
						</div>
					</div>
					<div className={classes.longSchemaColumn}>
						<div className={classes.schemaBox}>
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[1].id}
								zoneIndex={0}
								userAnswer={getGroupAnswerOptions(groups[1].id, 0, groups)}
							/>
						</div>
					</div>
					<div className={classes.schemaSeparator}>
						<div className={classes.schemaBottomDiag}>
							<i></i>
							<i></i>
						</div>
						<div className={classes.schemaTopDiag}>
							<i></i>
							<i></i>
						</div>
					</div>
					<div className={classes.shortSchemaColumn}>
						<div className={classes.schemaBox}>
							{" "}
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[2].id}
								zoneIndex={0}
								userAnswer={getGroupAnswerOptions(groups[2].id, 0, groups)}
							/>
						</div>
						<div className={classes.schemaBox}>
							{" "}
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[2].id}
								zoneIndex={1}
								userAnswer={getGroupAnswerOptions(groups[2].id, 1, groups)}
							/>
						</div>
					</div>
				</div>
				<Box display={"flex"} className={classes.gap25} width="100%">
					{groups?.map((group, i) => {
						return (
							<GroupDropZone
								width="calc((100% - 50px) / 3)"
								onDropped={setUserAnswerToRemove}
								groupId={group.id}
								index={i}
								key={i}
								answerOptions={getAnswerOptions(group.id, group.answerOptions)}
							/>
						);
					})}
				</Box>
			</Box>
		</DndProvider>
	);
};

export default BowTieQuestionPreview;
