import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "@remar/shared/dist/models";
import { getResetState, setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { RootState } from "store";
import { studentsService } from "store/services";

interface StudentState {
	students: User[] | null;
	page: number;
	perPage: number;
	totalItems: number;
	isLoading: boolean;
	errorMessage: string;
	manageSubscriptionLoading: boolean;
	changeEmailLoading: boolean;
	addDaysLoading: boolean;
}

const initialState: StudentState = {
	students: null,
	isLoading: false,
	manageSubscriptionLoading: false,
	changeEmailLoading: false,
	addDaysLoading: false,
	errorMessage: "",
	page: 1,
	perPage: 10,
	totalItems: 0
};

const utilsResetState = getResetState<StudentState>(initialState);

export const studentSlice = createSlice({
	name: "student",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setManageSubscriptionLoading: (state, action: PayloadAction<boolean>) => {
			state.manageSubscriptionLoading = action.payload;
		},
		setChangeEmailLoading: (state, action: PayloadAction<boolean>) => {
			state.changeEmailLoading = action.payload;
		},
		setAddDaysLoading: (state, action: PayloadAction<boolean>) => {
			state.addDaysLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		resetState: utilsResetState,
		setStateValue: utilsSetStateValue
	}
});

export const getAllStudents = createAsyncThunk(
	"students/getAllStudents",
	async (options: { page?: number; perPage?: number; searchText?: string }, { dispatch, getState }) => {
		try {
			const { isLoading, page, perPage } = (getState() as { students: StudentState }).students;
			if (!isLoading) {
				dispatch(setLoading(true));
			}
			const { page: optPage, perPage: optPerPage, searchText } = options;
			const {
				page: newPage,
				perPage: newPerPage,
				items,
				totalItems
			} = await studentsService.find({
				page: optPage || page,
				perPage: optPerPage || perPage,
				orderBy: { createdAt: "DESC" },
				searchKeyword: searchText || ""
			});
			dispatch(setStateValue({ key: "page", value: newPage }));
			dispatch(setStateValue({ key: "perPage", value: newPerPage }));
			dispatch(setStateValue({ key: "students", value: items }));
			dispatch(setStateValue({ key: "totalItems", value: totalItems }));
		} catch (e) {
			return { error: e };
		}
		dispatch(setLoading(false));
	}
);

export const cancelSubscription = createAsyncThunk(
	"students/cancelSubscription",
	async (
		options: {
			subscriptionId: number;
			refund: boolean;
			amount?: number;
			// eslint-disable-next-line no-unused-vars
			sideEffect: (e: string | unknown, notificationType: string) => void;
		},
		{ dispatch, getState }
	) => {
		const { subscriptionId, amount, sideEffect, refund } = options;
		try {
			const { manageSubscriptionLoading } = (getState() as { students: StudentState }).students;
			if (!manageSubscriptionLoading) {
				dispatch(setManageSubscriptionLoading(true));
			}
			await studentsService.cancelSubscription({ subscriptionId, ...(refund && { amount: Number(amount) }) });
			dispatch(setManageSubscriptionLoading(false));
			sideEffect("Subscription Cancelled", "success");
		} catch (e) {
			dispatch(setManageSubscriptionLoading(false));
			sideEffect(e, "error");
			return { error: e };
		}
	}
);

export const assignCourseSubscription = createAsyncThunk(
	"students/assignCourse",
	async (
		options: {
			subscriptionTypeId: number;
			refund: boolean;
			userId: number;
			amount?: number;
			// eslint-disable-next-line no-unused-vars
			sideEffect: (e: string | unknown, notificationType: string) => void;
		},
		{ dispatch, getState }
	) => {
		const { subscriptionTypeId, amount, sideEffect, refund, userId } = options;
		const { manageSubscriptionLoading } = (getState() as { students: StudentState }).students;
		if (!manageSubscriptionLoading) {
			dispatch(setManageSubscriptionLoading(true));
		}
		studentsService
			.subscriptionAssignCourse({
				subscriptionTypeId,
				userId,
				...(refund && { amount: Number(amount) })
			})
			.then(() => {
				sideEffect("Course has been assigned", "success");
			})
			.catch(e => {
				sideEffect(e, "error");
			})
			.finally(() => {
				dispatch(setManageSubscriptionLoading(false));
			});
	}
);

export const changeEmail = createAsyncThunk(
	"students/changeEmail",
	async (
		options: {
			payload;
			// eslint-disable-next-line no-unused-vars
			sideEffect: (e: string | unknown, notificationType: string) => void;
		},
		{ dispatch, getState }
	) => {
		const { payload, sideEffect } = options;
		try {
			const { changeEmailLoading } = (getState() as { students: StudentState }).students;
			if (!changeEmailLoading) {
				dispatch(setChangeEmailLoading(true));
			}
			await studentsService.changeEmail(payload);
			dispatch(setChangeEmailLoading(false));
			sideEffect("Email Changed", "success");
			dispatch(setChangeEmailLoading(false));
		} catch (e) {
			dispatch(setChangeEmailLoading(false));
			sideEffect(e, "error");
			return { error: e };
		}
	}
);

export const addDaysInSubscription = createAsyncThunk(
	"students/addDaysInSubscription",
	async (
		options: {
			payload;
			// eslint-disable-next-line no-unused-vars
			sideEffect: (e: string | unknown, notificationType: string) => void;
		},
		{ dispatch, getState }
	) => {
		const { payload, sideEffect } = options;
		try {
			const { addDaysLoading } = (getState() as { students: StudentState }).students;
			if (!addDaysLoading) {
				dispatch(setAddDaysLoading(true));
			}
			await studentsService.addDays(payload);
			dispatch(setAddDaysLoading(false));
			sideEffect("Days added", "success");
			dispatch(setAddDaysLoading(false));
		} catch (e) {
			dispatch(setAddDaysLoading(false));
			sideEffect(e, "error");
			return { error: e };
		}
	}
);

export function getFullState(state: RootState): StudentState {
	return state.students;
}

export const {
	setLoading,
	setManageSubscriptionLoading,
	setChangeEmailLoading,
	setAddDaysLoading,
	failed,
	setStateValue,
	resetState
} = studentSlice.actions;

export default studentSlice.reducer;
