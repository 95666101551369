import React, { ReactNode } from "react";

import { Container, ContentValue, FlexContainer, Image, Key, NoOverflow, Subtitle, Title, TitleKey } from "./styles";

interface ContentProps {
	key: string;
	value: string | number;
}

interface TitleProps extends ContentProps {
	onClick?: () => void;
	subtitle?: string;
}

interface CardProps {
	imgSrc: string;
	width?: number;
	imgRounded?: boolean;
	title: TitleProps;
	content: ContentProps[];
	rightComponent?: ReactNode;
	bottomComponent?: ReactNode;
	bottomLeftComponent?: ReactNode;
}

const Card = ({
	width,
	imgSrc,
	imgRounded = false,
	title,
	content,
	rightComponent,
	bottomComponent,
	bottomLeftComponent
}: CardProps) => {
	const subtitle = title.subtitle;
	return (
		<Container width={width} style={{ borderRadius: "0 0 4px 4px" }}>
			<FlexContainer>
				<NoOverflow rightComponent={!!rightComponent}>
					<FlexContainer>
						<Image src={imgSrc} rounded={imgRounded} />
						<NoOverflow>
							<TitleKey>{title.key}</TitleKey>
							<Title>{title.value}</Title>
							{subtitle && subtitle.length ? <Subtitle>{subtitle}</Subtitle> : null}
							<FlexContainer>
								{content.map(el => (
									<div key={el.key}>
										<Key>{el.key}</Key>
										<ContentValue>{el.value}</ContentValue>
									</div>
								))}
							</FlexContainer>
						</NoOverflow>
					</FlexContainer>
					{bottomComponent}
					{bottomLeftComponent}
				</NoOverflow>
				{rightComponent}
			</FlexContainer>
		</Container>
	);
};

export default Card;
