import React, { useEffect, useRef, useState } from "react";

import { QuestionTypes } from "@remar/shared/dist/constants";

import ClozeDropDownQuestionPreview from "./ClozeDropDownQuestionPreview";
import DragAndDropQuestionPreview from "./DragAndDropQuestionPreview";
import DropDownTableQuestionPreview from "./DropDownTableQuestionPreview";
import HighlightTableQuestionPreview from "./HighlightTableQuestionPreview";
import HotspotHighlightQuestionPreview from "./HotspotHighlightQuestionPreview";
import MatrixSingleChoiceQuestionPreview from "./MatrixSingleChoiceQuestionPreview";
import MultipleChoiceQuestionPreview from "./MultipleChoiceQuestionPreview";
import MultipleResponseGroupQuestionPreview from "./MultipleResponseGroupQuestionPreview";
import SingleChoiceQuestionPreview from "./SingleChoiceQuestionPreview";
import {
	CaseStudyColumn,
	CaseStudyRow,
	CaseStudyTab,
	CaseStudyTabContent,
	CaseStudyTabs,
	Page,
	Pages,
	TestQuizQuestionText
} from "./style";

interface UserAnswerQuestionIdDto {
	[id: number]: UserQuestionAnswerDto[];
}

interface UserQuestionAnswerDto {
	id: string;
	groupId?: string;
	order?: number;
}

const CaseStudyQuestionPreview = ({ question }) => {
	const {
		data: { tabs },
		caseStudyQuestions: questions,
		description
	} = question;

	const [activeTab, setActiveTab] = useState(0);
	const [activeQuestion, setActiveQuestion] = useState(0);
	const [activeQuestionForChange, setActiveQuestionForChange] = useState(0);
	const currentQuestion = questions[activeQuestion];

	const ref = useRef<UserAnswerQuestionIdDto>({});
	const [userAnswers, setUserAnswers] = useState<UserQuestionAnswerDto[]>(ref.current[activeQuestion] ?? []);

	useEffect(() => {
		setUserAnswers(ref.current[activeQuestion] ?? []);
	}, [activeQuestion]);

	useEffect(() => {
		setActiveTab(0);
		setActiveQuestion(0);
	}, [question.id]);

	useEffect(() => {
		ref.current[activeQuestion] = userAnswers;
	}, [userAnswers]);

	const getQuestion = question => {
		const uniqueIdentifier = `case-study-question-${question.id}`;

		switch (question.typeId) {
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceQuestionPreview question={question} key={uniqueIdentifier} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return <MultipleChoiceQuestionPreview question={question} key={uniqueIdentifier} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupQuestionPreview question={question} key={uniqueIdentifier} />;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return <DragAndDropQuestionPreview question={question} key={uniqueIdentifier} />;
			case QuestionTypes.DropDownTable:
				return <DropDownTableQuestionPreview question={question} key={uniqueIdentifier} />;
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixSingleChoiceQuestionPreview question={question} key={uniqueIdentifier} />;
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return <ClozeDropDownQuestionPreview question={question} key={uniqueIdentifier} />;
			case QuestionTypes.SingleChoice:
				return <SingleChoiceQuestionPreview question={question} key={uniqueIdentifier} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightQuestionPreview question={question} key={uniqueIdentifier} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableQuestionPreview question={question} key={uniqueIdentifier} />;
			default:
				return null;
		}
	};

	useEffect(() => {
		if (activeQuestion !== activeQuestionForChange) {
			setUserAnswers([]);
			setActiveQuestion(activeQuestionForChange);
		}
	}, [activeQuestionForChange]);

	const handleChangeQuestion = questionIndex => {
		if (activeQuestion !== questionIndex) {
			setActiveQuestionForChange(questionIndex);
		}
	};

	return (
		<>
			<CaseStudyRow>
				<CaseStudyColumn>
					<TestQuizQuestionText dangerouslySetInnerHTML={{ __html: description }}></TestQuizQuestionText>

					<CaseStudyTabs>
						{tabs.map(({ title, id }, tabIndex) => (
							<CaseStudyTab key={`tab-${id}`} active={tabIndex === activeTab} onClick={() => setActiveTab(tabIndex)}>
								{title}
							</CaseStudyTab>
						))}
					</CaseStudyTabs>
					{activeTab !== undefined && (
						<CaseStudyTabContent dangerouslySetInnerHTML={{ __html: tabs[activeTab].text }}></CaseStudyTabContent>
					)}
				</CaseStudyColumn>
				<CaseStudyColumn>{currentQuestion && getQuestion(currentQuestion)}</CaseStudyColumn>
			</CaseStudyRow>
			<Pages>
				<Page onClick={() => handleChangeQuestion(Math.max(activeQuestion - 1, 0))}>&lt;</Page>
				{questions?.map((_, questionIndex) => (
					<Page
						active={activeQuestion === questionIndex}
						key={`question-${questionIndex}`}
						onClick={() => handleChangeQuestion(questionIndex)}
					>
						{questionIndex + 1}
					</Page>
				))}
				<Page onClick={() => handleChangeQuestion(Math.min(activeQuestion + 1, questions.length - 1))}>&gt;</Page>
			</Pages>
		</>
	);
};

export default CaseStudyQuestionPreview;
