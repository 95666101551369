import React, { FC, memo, useEffect } from "react";

import { getJwt } from "@remar/shared/dist/api/jwt";
import { useDispatch, useSelector } from "react-redux";

import { Route, RouteProps, useHistory } from "react-router-dom";

import { RootState } from "store";
import { logout } from "store/features/Auth/auth.slice";

import { routes } from "core/constants";

const AuthRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
	const { isLoggedIn } = useSelector((state: RootState) => state.auth);
	const history = useHistory();
	const dispatch = useDispatch();

	const RenderComponent = Component;

	useEffect(() => {
		const tokens = getJwt();
		const pathname = history.location.pathname;
		const signInRoute = routes.signIn.getPath();
		if (isLoggedIn) {
			if (!tokens) {
				dispatch(logout());
				history.push(signInRoute);
				return;
			}
			if (pathname === signInRoute) {
				history.push("/");
				return;
			}
		} else if (pathname !== signInRoute) {
			history.push(signInRoute);
			return;
		}
	}, [isLoggedIn, history.location.pathname]);

	return <Route {...rest} component={RenderComponent} />;
};

export default memo(AuthRoute);
