import React, { useEffect, useState } from "react";

import { Box, Typography } from "@material-ui/core";
import HotspotHighlightInput from "@remar/shared/dist/components/HotspotHighlightInput/HotspotHighlightInput";
import { MAX_ANSWER_OPTIONS_TEXT_LENGTH } from "@remar/shared/dist/constants";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import CommonQuestionFields from "./CommonQuestionFields";

import { CustomInput as StyledInput, useStyles } from "./styles";

import { _emit } from "../../../../../../store/features/notifications/notifications.slice";
import theme from "../../../../../../theme/default";
import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

export const HotspotHighlightQuestion = ({
	clearArrayOfQuestionAnswerOption,
	getFullState,
	removeQuestionAttachment,
	setArrayOfQuestionAnswerOption,
	setStateValue,
	updateValidationCriteria,
	uploadQuestionMaterial,
	validateForm,
	templatePath = "answerOptions",
	formName = "addNewQuestionForm",
	statePath = ""
}) => {
	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const { text, _expanded, description, attachments, endOfQuestionSummary, mainImageKey, answerOptions } = questionData;
	const [value, setValue] = useState<
		{
			highlighted: boolean;
			text: string;
		}[]
	>([]);

	const ansOpsArray = answerOptions.map(answer => ({
		text: answer.text.value,
		highlighted: answer.isCorrect.value
	}));

	const handleUpdate = neweValue => {
		setValue(neweValue);

		dispatch(
			clearArrayOfQuestionAnswerOption({
				formName,
				inputsPath: `${fullStatePath}.answerOptions`
			})
		);
		dispatch(
			setArrayOfQuestionAnswerOption({
				formName,
				inputsPath: `${fullStatePath}.answerOptions`,
				templatePath,
				lengthOfOptions: neweValue.length,
				optionsArray: neweValue
			})
		);
		dispatch(validateForm({ formStatePath: formName, markInputsAsDirty: true }));
	};
	useEffect(() => {
		if (answerOptions.length > 0) {
			setValue([...ansOpsArray]);
			handleUpdate(ansOpsArray);
		}
	}, []);
	useEffect(() => {
		dispatch(updateValidationCriteria({ statePath }));

		return () => {
			dispatch(updateValidationCriteria({ statePath, newLength: MAX_ANSWER_OPTIONS_TEXT_LENGTH }));
		};
	}, [answerOptions]);

	return (
		<CommonQuestionFields
			description={description}
			attachments={attachments}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			statePath={statePath}
			key={statePath}
			setStateValue={setStateValue}
			expanded={_expanded?.value}
			validateForm={validateForm}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
		>
			<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
			<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
			<Typography className={classes.addOptionHeader}>TEXT</Typography>
			<Box className={classes.hotspotHighlightTextBox}>
				<HotspotHighlightInput value={value} onChange={handleUpdate} />
			</Box>
			{answerOptions
				.filter(({ text: { error } }) => error)
				.map(({ text: { error } }, errIndex) => (
					<Typography key={`error-${errIndex}`} className={classes.error}>
						{error}
					</Typography>
				))}
		</CommonQuestionFields>
	);
};
