import React from "react";

import { Box, Button, CircularProgress } from "@material-ui/core";

import { ReactComponent as ClipboardIconSvg } from "@remar/shared/dist/assets/icons/icon-clipboard-list-check-filled.svg";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";

import classnames from "classnames";

import theme from "theme/default";

import { useStyles } from "./styles";

const AttachmentDeleteModal = ({ modalOpen, setModalOpen, onConfirm, text, isDeleteLoading = false }) => {
	const classes = useStyles();

	const onClose = () => {
		if (!isDeleteLoading) {
			setModalOpen(false);
		}
	};
	return (
		<SimpleModal
			theme={theme}
			open={modalOpen}
			onClose={onClose}
			text={text}
			IconSvg={ClipboardIconSvg}
			footer={
				<>
					<Button
						className={classnames(classes.keepBtn, classes.button)}
						size="medium"
						disabled={isDeleteLoading}
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						className={classnames(classes.cancelBtn, classes.button)}
						disabled={isDeleteLoading}
						size="medium"
						onClick={() => onConfirm()}
					>
						{isDeleteLoading ? (
							<Box width={50}>
								<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
							</Box>
						) : (
							<>Delete File</>
						)}
					</Button>
				</>
			}
		/>
	);
};

export default AttachmentDeleteModal;
