import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	CustomInputType,
	DefaultValueGetter,
	DefaultValueGettersObject
} from "@remar/shared/dist/components/CustomInput/customInput.model";
import {
	CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH,
	MAX_ANSWER_OPTIONS_TEXT_LENGTH,
	MAX_GROUP_TEXT_LENGTH,
	MAX_TAB_CHARACTER_LENGTH,
	QuestionTypes
} from "@remar/shared/dist/constants";
import { Course, QuestionTypeOptions } from "@remar/shared/dist/models";
import { FormInputItemsModel, FormStateModel } from "@remar/shared/dist/utils/form/form.model";
import {
	createForm,
	getPopulateInputsAction,
	parseTemplate,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";
import { getSelectOptions } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { performFileUpload } from "@remar/shared/dist/utils/serviceUtils/uploaders";
import { getResetState, setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { get, omit, set, update } from "lodash";
import { RootState } from "store";
import {
	QuestionCreateQuestionAnswerOptionsItemDto,
	coursesService,
	questionTypesService,
	questionsService,
	subjectService
} from "store/services";

import { difficultyLevelsService } from "store/services/difficultyLevels";
import { v4 as uuid } from "uuid";

import { AddQuestionFormInputs, AddQuestionFormRawData, DifficultyLevels, Subjects } from "./models";

import { emit } from "../notifications/notifications.slice";

export interface AddQuestionState {
	isLoading: boolean;
	errorMessage: string;
	addNewQuestionForm: FormStateModel<AddQuestionFormInputs, AddQuestionFormRawData, {}>;
	courses: Course[];
	questionTypeOptions: QuestionTypeOptions[];
	subjects: Subjects[];
	difficultyLevels: DifficultyLevels[];
}

const getQuestionForm = (nested = false) => ({
	description: {
		label: "Type question description",
		placeholder: "Type question description",
		type: CustomInputType.Editor,
		defaultValue: "",
		validations: { maxLength: 5000, required: true }
	},
	text: {
		label: "Type your question here",
		placeholder: "Type your question here",
		type: CustomInputType.Text,
		defaultValue: "",
		validations: { maxLength: 1000, ...(nested ? { required: true } : undefined) }
	},
	typeId: {
		selectOptions: [],
		label: "Select Question Type",
		placeholder: "Select Question Type",
		type: CustomInputType.Select,
		validations: { required: true }
	},
	endOfQuestionSummary: {
		label: "Type rationale here",
		placeholder: "Type rationale here",
		type: CustomInputType.Editor,
		defaultValue: "",
		validations: { maxLength: 5000, required: true }
	},
	mainImageKey: {
		imageExtensionName: "png",
		imageFileType: "image/png",
		isImageFile: true,
		label: "Upload Photo",
		placeholder: "Upload Photo",
		type: CustomInputType.File,
		uploaderIconName: "cloud-upload",
		uploaderStateLoaderKey: "fileIsUploading"
	},
	tableLabel: {
		label: "Type your label here",
		placeholder: "Type your label here",
		type: CustomInputType.Text,
		validations: { maxLength: 100 }
	},
	answerOptionLabel: {
		label: "Option Label",
		placeholder: "Enter Option Label",
		type: CustomInputType.Text,
		validations: { maxLength: 100 }
	},
	answerOptions: [
		{
			id: { type: CustomInputType.Text, useDefaultValueGetterName: "uuidV4" },
			text: {
				label: "Option",
				placeholder: "Enter Option",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: MAX_ANSWER_OPTIONS_TEXT_LENGTH }
			},
			order: { defaultValue: 1, type: CustomInputType.Number },
			isCorrect: {
				type: CustomInputType.Checkbox,
				selectOptions: [{ text: "", value: true }],
				validations: { required: true }
			},
			deleted: {
				defaultValue: false,
				type: CustomInputType.Checkbox
			}
		}
	],
	attachments: [
		{
			id: { type: CustomInputType.Number, default: undefined },
			fileType: { type: CustomInputType.Text },
			name: { type: CustomInputType.Text },
			fileName: { type: CustomInputType.Text },
			fileSizeInBytes: { type: CustomInputType.Number }
		}
	],
	groups: [
		{
			id: {
				type: CustomInputType.Text,
				useDefaultValueGetterName: "uuidV4"
			},
			text: {
				type: CustomInputType.Multiline,
				defaultValue: "",
				placeholder: "Enter text",
				validations: { required: true, maxLength: MAX_GROUP_TEXT_LENGTH }
			},
			order: { defaultValue: 1, type: CustomInputType.Number },
			deleted: { defaultValue: false, type: CustomInputType.Checkbox },
			selectedAnswerOptions: { type: CustomInputType.Text, defaultValue: [] },
			answerOptions: [
				{
					id: { type: CustomInputType.Text, useDefaultValueGetterName: "uuidV4" },
					text: {
						label: "Option",
						placeholder: "Enter Option",
						defaultValue: "",
						type: CustomInputType.Text,
						validations: { required: true, maxLength: MAX_ANSWER_OPTIONS_TEXT_LENGTH }
					},
					isCorrect: { type: CustomInputType.Checkbox },
					order: { defaultValue: 1, type: CustomInputType.Number },
					deleted: {
						defaultValue: false,
						type: CustomInputType.Checkbox
					}
				}
			]
		}
	]
});

const initialState: AddQuestionState = {
	isLoading: false,
	errorMessage: "",
	courses: [],
	questionTypeOptions: [],
	difficultyLevels: [],
	subjects: [],
	addNewQuestionForm: createForm<AddQuestionFormInputs, AddQuestionFormRawData, {}>({
		defaultValueGetters: {},
		generateTemplatesFromPaths: [
			"tabs",
			"answerOptions",
			"attachments",
			"groups",
			"groups.0.answerOptions",
			"questions"
		],
		statePath: "addNewQuestionForm",
		inputs: {
			courseIds: {
				selectOptions: [],
				label: "Select Course",
				placeholder: "Select Course",
				type: CustomInputType.Chips,
				validations: { required: true, isArray: true }
			},
			subjectId: {
				selectOptions: [],
				label: "Select Subject",
				placeholder: "Select Subject",
				type: CustomInputType.Select,
				validations: { required: true }
			},
			lessonSubjectId: {
				selectOptions: [],
				label: "Select Lesson",
				placeholder: "Select Lesson",
				type: CustomInputType.Select,
				validations: { required: true }
			},
			difficultyLevelId: {
				selectOptions: [],
				label: "Select Difficulty Level",
				placeholder: "Select Difficulty Level",
				type: CustomInputType.Select,
				validations: { isNumber: true, required: true }
			},
			forCAT: {
				label: "Add this question to CAT",
				type: CustomInputType.Checkbox
			},
			...getQuestionForm(),
			questions: [
				{ id: { type: CustomInputType.Text, useDefaultValueGetterName: "uuidV4" }, ...getQuestionForm(true) }
			],
			tabs: [
				{
					id: { type: CustomInputType.Text, useDefaultValueGetterName: "uuidV4" },
					text: { defaultValue: "", type: CustomInputType.Editor, validations: { required: true, maxLength: 5000 } },
					title: {
						defaultValue: "New tab",
						type: CustomInputType.Text,
						validations: { required: true, maxLength: MAX_TAB_CHARACTER_LENGTH }
					},
					order: { defaultValue: 1, type: CustomInputType.Number },
					deleted: { defaultValue: false, type: CustomInputType.Checkbox }
				}
			]
		}
	})
};

const defaultValueGetters: DefaultValueGettersObject = {
	uuidV4: (() => uuid()) as DefaultValueGetter
};
const utilsResetState = getResetState<AddQuestionState>(initialState);

export const save = createAsyncThunk(
	"addQuestion/save",
	async ({ questionId, success }: { success: () => void; questionId?: string }, { dispatch, getState }) => {
		dispatch(validateForm({ formStatePath: "addNewQuestionForm", markInputsAsDirty: true }));

		const { rawData, inputs } = (getState() as { addQuestion: AddQuestionState }).addQuestion.addNewQuestionForm;
		let data = {
			...rawData
		};

		const prepareQuestion = (question, questionIndex = undefined) => {
			const data = { ...question, ...(questionIndex !== undefined ? { order: questionIndex } : undefined) };
			switch (question.typeId) {
				case QuestionTypes.HotspotHighlight: {
					set(data, "data", {
						text: question.answerOptions.map(answerOption => answerOption.text).join(""),
						answerOptions: question.answerOptions
							.filter(({ deleted }) => !deleted)
							.map(answerOption => omit(answerOption, "deleted") as QuestionCreateQuestionAnswerOptionsItemDto)
					});
					const {
						text = "",
						data: { text: highlightedText, answerOptions }
					} = data;
					const highlightedAnswer = answerOptions.some(({ isCorrect }) => isCorrect);
					if (!highlightedAnswer) {
						throw new Error("Please select the highlighted text");
					}
					if (!text.match(/\S/)) {
						throw new Error("Please fill in the question sentence");
					}
					if (!highlightedText) {
						throw new Error("Please fill in the text");
					}
					break;
				}
				case QuestionTypes.MultipleChoiceSN:
				case QuestionTypes.SingleChoice:
				case QuestionTypes.MultipleChoiceSATA: {
					set(data, "data", {
						answerOptions: question.answerOptions
							.filter(({ deleted }) => !deleted)
							.map(answerOption => omit(answerOption, "deleted") as QuestionCreateQuestionAnswerOptionsItemDto)
					});
					if (!data.text) {
						throw new Error("Please fill question text");
					}
					if (data.answerOptions.length < 2) {
						throw new Error(
							`Please add at least 2 answer options${
								questionIndex !== undefined ? ` to question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					if (!data.answerOptions.find(({ isCorrect }) => isCorrect)) {
						throw new Error(
							`Please select at least one correct answerOption${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					break;
				}
				case QuestionTypes.MultipleResponseGroup: {
					const existingGroups = question.groups.filter(({ deleted }) => !deleted);

					set(data, "data", {
						tableLabel: data.tableLabel,
						answerOptionLabel: data.answerOptionLabel,
						groups: existingGroups.map((group, groupIndex) => ({
							...omit(group, ["deleted", "answerOptions"]),
							text: group.text || ".",
							order: groupIndex,
							answerOptions: group.answerOptions
								.filter(({ deleted }) => !deleted)
								.map(answerOption => omit(answerOption, "deleted"))
						}))
					});
					const { text = "" } = data;
					if (!text.match(/\S/)) {
						throw new Error("Please fill question text");
					}
					if (!data.data.tableLabel) {
						throw new Error("Please add table label");
					}

					if (!data.data.answerOptionLabel) {
						throw new Error("Please add answer option label");
					}
					if (data.data.groups.length < 2) {
						throw new Error(
							`Please add groups${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
						);
					}
					if (data.data.groups.length > 5) {
						throw new Error(
							`Please remove the extra group only 5 are allowed${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					if (data.data.groups.find(({ answerOptions }) => !answerOptions.find(({ isCorrect }) => isCorrect))) {
						throw new Error(
							`Please add correct answer for each group${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					break;
				}
				case QuestionTypes.HighlightTable: {
					const existingGroups = question.groups.filter(({ deleted }) => !deleted);

					set(data, "data", {
						groups: existingGroups.map((group, groupIndex) => ({
							...omit(group, ["deleted", "answerOptions"]),
							title: group.text || ".",
							text: group.answerOptions.map(({ text }) => text).join(""),
							order: groupIndex,
							answerOptions: group.answerOptions
								.filter(({ deleted, isCorrect }) => !deleted && isCorrect)
								.map(answerOption => omit(answerOption, "deleted"))
						})),
						tableLabel: data.tableLabel,
						answerOptionLabel: data.answerOptionLabel
					});
					const {
						text = "",
						data: { groups }
					} = data;
					const rowWithoutText = groups.findIndex(({ text }) => !text);
					const rowWithoutSelectedText = groups.findIndex(({ answerOptions }) => !answerOptions.length);
					if (rowWithoutText != -1) {
						throw new Error(
							`Please fill in the text for row no ${rowWithoutText + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					if (rowWithoutSelectedText != -1) {
						throw new Error(
							`Please select the highlighted text for row no ${rowWithoutSelectedText + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					if (!text.match(/\S/)) {
						throw new Error("Please fill in the question sentence");
					}
					break;
				}
				case QuestionTypes.ClozeDropDown:
				case QuestionTypes.RationalScoringDropDown: {
					const existingGroups = question.groups.filter(({ deleted }) => !deleted);
					const text = existingGroups.map(({ text }) => text).join(" ");
					set(data, "text", text);

					if (!text.match(/\S/)) {
						throw new Error(
							`Please fill question text${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}

					set(data, "data", {
						groups: existingGroups.map((group, groupIndex) => ({
							...omit(group, ["deleted", "answerOptions"]),
							text: group.text || ".",
							order: groupIndex,
							...(groupIndex !== existingGroups.length - 1 && {
								answerOptions: group.answerOptions
									.filter(({ deleted }) => !deleted)
									.map(answerOption => omit(answerOption, "deleted"))
							})
						}))
					});

					if (question.typeId === QuestionTypes.RationalScoringDropDown && data.data.groups.length < 3) {
						throw new Error(`Question ${(questionIndex ?? 0) + 1} must have at least three sentences.`);
					}

					if (data.data.groups.length < 2) {
						throw new Error(
							`Please add gaps${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
						);
					}
					const singleAnswerOptionGroupIndex = data.data.groups
						.filter(g => !g.deleted)
						.findIndex(
							({ answerOptions }, groupIndex) => groupIndex < existingGroups.length - 1 && answerOptions.length < 2
						);

					if (singleAnswerOptionGroupIndex !== -1) {
						throw new Error(
							`Please add at least two answer options to the gap ${singleAnswerOptionGroupIndex + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}

					const gapWithoutCorrectAnswer = data.data.groups
						.filter(g => !g.deleted)
						.findIndex(
							({ answerOptions }, groupIndex) =>
								groupIndex < existingGroups.length - 1 && !answerOptions.find(({ isCorrect }) => isCorrect)
						);

					if (gapWithoutCorrectAnswer !== -1) {
						throw new Error(
							`Please select correct option for the gap ${gapWithoutCorrectAnswer + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}`
						);
					}
					break;
				}
				case QuestionTypes.DragAndDrop:
				case QuestionTypes.RationalScoringDragAndDrop: {
					const existingGroups = question.groups.filter(({ deleted }) => !deleted);
					const existingAnswerOptions = question.answerOptions.filter(({ deleted }) => !deleted);
					const text = existingGroups.map(({ text }) => text).join(" ");
					set(data, "text", text);

					if (!text.match(/\S/)) {
						throw new Error(
							`Please fill question text${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}

					set(data, "data", {
						groups: existingGroups.map((group, groupIndex) => ({
							...omit(group, ["deleted", "answerOptions", "selectedAnswerOptions"]),
							text: group.text || ".",
							order: groupIndex,
							...(groupIndex !== existingGroups.length - 1 && {
								selectedAnswerOptions: group.selectedAnswerOptions
							})
						})),
						answerOptions: existingAnswerOptions.map(
							(answerOption, answerOptionIndex) =>
								({
									...omit(answerOption, "deleted"),
									order: answerOptionIndex + 1
								} as QuestionCreateQuestionAnswerOptionsItemDto)
						)
					});

					if (question.typeId === QuestionTypes.RationalScoringDragAndDrop && data.data.groups.length < 3) {
						throw new Error(`Question ${(questionIndex ?? 0) + 1} must have at least three gaps.`);
					}

					if (data.data.groups.length < 2) {
						throw new Error(
							`Please add gaps${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
						);
					}

					const gapWithoutCorrectAnswer = data.data.groups
						.filter(g => !g.deleted)
						.findIndex(
							({ selectedAnswerOptions }, groupIndex) =>
								groupIndex < existingGroups.length - 1 && selectedAnswerOptions.length == 0
						);

					if (gapWithoutCorrectAnswer !== -1) {
						throw new Error(
							`Please select correct option for the gap ${gapWithoutCorrectAnswer + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}`
						);
					}

					break;
				}
				case QuestionTypes.MatrixSingleChoice: {
					const existingGroups = question.groups.filter(({ deleted }) => !deleted);
					set(data, "data", {
						tableLabel: question.tableLabel,
						answerOptions: question.answerOptions
							.filter(({ deleted }) => !deleted)
							.map(
								(answerOption, answerOptionIndex) =>
									({
										...omit(answerOption, "deleted"),
										order: answerOptionIndex + 1
									} as QuestionCreateQuestionAnswerOptionsItemDto)
							),
						groups: existingGroups.map(group => ({
							...omit(group, ["deleted", "answerOptions"])
						}))
					});
					const { text = "" } = data;
					if (!text.match(/\S/)) {
						throw new Error("Please fill question text");
					}
					if (!data.data.tableLabel) {
						throw new Error(
							`Please add a table label${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					if (data.data.groups.length < 2) {
						throw new Error(
							`Please add at least 2 rows${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					const rowWithoutCorrectAnswer = data.data.groups.findIndex(
						({ selectedAnswerOptions }) => !selectedAnswerOptions.length
					);
					if (rowWithoutCorrectAnswer != -1) {
						throw new Error(
							`Please select at least one response option for row no ${rowWithoutCorrectAnswer + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					break;
				}
				case QuestionTypes.BowTie: {
					const { text = "" } = data;
					if (!text.match(/\S/)) {
						throw new Error(
							`Please fill question text${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}

					set(data, "data", {
						groups: data.groups.map((group, groupIndex) => ({
							...omit(group, ["deleted"]),
							text: group.text || ".",
							order: groupIndex,
							answerOptions: group.answerOptions
								.filter(({ deleted }) => !deleted)
								.map(answerOption => omit(answerOption, "deleted"))
						}))
					});

					const gapWithoutCorrectAnswer = data.data.groups.findIndex(({ answerOptions }, groupIndex) => {
						const correctOptions = answerOptions.filter(({ isCorrect }) => isCorrect).length;

						return (groupIndex === 1 && correctOptions !== 1) || (groupIndex !== 1 && correctOptions !== 2);
					});

					const groups = ["Actions To Take", "Conditions Most Likely Experiencing", "Parameters To Monitor"];

					if (gapWithoutCorrectAnswer !== -1) {
						throw new Error(
							`Only ${gapWithoutCorrectAnswer === 1 ? 1 : 2} correct options allowed for group "${
								groups[gapWithoutCorrectAnswer]
							}"${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}`
						);
					}

					break;
				}
				case QuestionTypes.MatrixMultipleChoice: {
					const existingGroups = question.groups.filter(({ deleted }) => !deleted);
					set(data, "data", {
						tableLabel: question.tableLabel,
						answerOptions: question.answerOptions
							.filter(({ deleted }) => !deleted)
							.map(
								(answerOption, answerOptionIndex) =>
									({
										...omit(answerOption, ["deleted"]),
										order: answerOptionIndex + 1
									} as QuestionCreateQuestionAnswerOptionsItemDto)
							),
						groups: existingGroups.map(group => ({
							...omit(group, ["deleted", "answerOptions"])
						}))
					});
					const { text = "" } = data;
					if (!text.match(/\S/)) {
						throw new Error("Please fill question text");
					}
					if (!data.data.tableLabel) {
						throw new Error(
							`Please add a table label${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					if (data.data.groups.length < 2) {
						throw new Error(
							`Please add at least 2 rows${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					const rowWithoutText = data.data.answerOptions.findIndex(({ text }) => !text);
					if (rowWithoutText != -1) {
						throw new Error(
							`Please fill the required row no ${rowWithoutText + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					const columnWithoutText = data.data.groups.findIndex(({ text }) => !text);
					if (columnWithoutText != -1) {
						throw new Error(
							`Please fill the required column no ${columnWithoutText + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					const columnWithoutCorrectAnswer = data.data.groups.findIndex(
						({ selectedAnswerOptions }) => !selectedAnswerOptions || !selectedAnswerOptions.length
					);
					if (columnWithoutCorrectAnswer != -1) {
						throw new Error(
							`Please select at least one response option for column no ${columnWithoutCorrectAnswer + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					break;
				}

				case QuestionTypes.DropDownTable: {
					const existingGroups = question.groups.filter(({ deleted }) => !deleted);
					const { text = "" } = data;
					if (!text.match(/\S/)) {
						throw new Error(
							`Please fill question text${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}
					set(data, "data", {
						tableLabel: data.tableLabel,
						answerOptionLabel: data.answerOptionLabel,
						groups: existingGroups.map((group, groupIndex) => ({
							...omit(group, ["deleted", "answerOptions", "selectedAnswerOptions"]),
							text: group.text || ".",
							order: groupIndex,
							answerOptions: group.answerOptions
								.filter(({ deleted }) => !deleted)
								.map(answerOption => omit(answerOption, "deleted"))
						}))
					});
					if (data.data.groups.length < 2) {
						throw new Error(
							`Please add rows${questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""}.`
						);
					}

					if (!data.data.tableLabel) {
						throw new Error("Please add table label");
					}

					if (!data.data.answerOptionLabel) {
						throw new Error("Please add answer option label");
					}

					const singleAnswerOptionGroupIndex = data.data.groups
						.filter(g => !g.deleted)
						.findIndex(({ answerOptions }) => answerOptions.length < 2);

					if (singleAnswerOptionGroupIndex !== -1) {
						throw new Error(
							`Please add at least two answer options to the row ${singleAnswerOptionGroupIndex + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}.`
						);
					}

					const rowWithoutCorrectAnswer = data.data.groups
						.filter(g => !g.deleted)
						.findIndex(({ answerOptions }) => !answerOptions.find(({ isCorrect }) => isCorrect));

					if (rowWithoutCorrectAnswer !== -1) {
						throw new Error(
							`Please select correct option for the row ${rowWithoutCorrectAnswer + 1}${
								questionIndex !== undefined ? ` for question ${(questionIndex ?? 0) + 1}` : ""
							}`
						);
					}
					break;
				}
			}

			set(
				data,
				"attachments",
				question.attachments.filter(({ deleted }) => !deleted).map(file => ({ ...file, questionId: 0 }))
			);

			return data;
		};

		if (rawData.typeId === QuestionTypes.CaseStudy) {
			try {
				set(data, "data", {
					tabs: inputs.tabs
						.filter(({ deleted }) => !deleted?.value)
						.map((tab, tabIndex) => ({
							id: tab.id.value,
							text: tab.text.value,
							order: tabIndex + 1,
							title: tab.title.value
						}))
				});
				set(
					data,
					"caseStudyQuestions",
					data.questions
						.map(prepareQuestion)
						.map(question => ({ ...question, difficultyLevelId: data.difficultyLevelId }))
				);
				set(data, "text", get(data, "caseStudyQuestions.0.text"));

				omit(data, "answerOptions");
			} catch (e) {
				await dispatch(emit({ message: (e as Error).message, color: "error" }));

				return;
			}
		} else {
			try {
				data = prepareQuestion(rawData);
			} catch (e) {
				dispatch(emit({ message: (e as Error).message, color: "error" }));

				return;
			}
		}
		dispatch(setStateValue({ key: "isLoading", value: true }));
		set(data, "subjectLessonIds", [{ value: data.lessonSubjectId }]);
		try {
			if (questionId) {
				await questionsService.update({
					filters: { id: questionId },
					data: {
						...update(
							omit(data, ["answerOptions", "tabs", "groups", "subjectId", "lessonSubjectId", "tableLabel"]),
							"courseIds",
							courseIds => courseIds.map(value => ({ value }))
						)
					}
				});
			} else {
				await questionsService.create(
					update(
						omit(data, ["answerOptions", "tabs", "groups", "subjectId", "lessonSubjectId", "tableLabel"]),
						"courseIds",
						courseIds => courseIds.map(value => ({ value }))
					)
				);
			}
		} catch (e) {
			console.log(e);
			dispatch(setStateValue({ key: "isLoading", value: false }));
			dispatch(
				emit({
					message: `Backend error occured ${(e as Error).message}`,
					color: "error"
				})
			);
			return;
		}

		dispatch(setStateValue({ key: "isLoading", value: false }));

		success();
	}
);

export const uploadQuestionMaterial = createAsyncThunk(
	"addQuestion/uploadMedia",
	async (
		data: {
			file: Partial<File>;
			fullStatePath: string;
			options: {
				onError: () => void;
				onProgress: ({}: { loaded: number; total: number }) => void;
				onUploaded: () => void;
			};
		},
		{ dispatch }
	) => {
		const { file, options, fullStatePath } = data;
		const attachmentLoaderStatePath = "addNewQuestionForm.isLoading";
		try {
			dispatch(setStateValue({ key: attachmentLoaderStatePath, value: true }));
			const { key } = await performFileUpload({ file }, options);

			await dispatch(
				createQuestionAttachment({
					fullStatePath,
					name: file.name!,
					fileName: key,
					fileType: file.type,
					fileSizeInBytes: file.size
				})
			);

			options.onUploaded();
		} catch (e) {
			console.error(e);
			options.onError();
			dispatch(emit({ message: "An error has occured while uploading.", color: "error" }));
		} finally {
			dispatch(setStateValue({ key: attachmentLoaderStatePath, value: false }));
		}
	}
);

const utilsPopulateInputs = getPopulateInputsAction<AddQuestionState>({ defaultValueGetters });
export const addQuestionSlice = createSlice({
	name: "addQuestion",
	initialState,
	reducers: {
		populateInputs: utilsPopulateInputs,
		updateValidationCriteria: (state, action: PayloadAction<{ statePath: string; newLength?: number }>) => {
			const { statePath, newLength } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";

			const items = get(state, `${fullPath}.answerOptions`);

			items?.forEach((_, index) => {
				set(state, `${fullPath}.answerOptions.${index}.validations.maxLength`, newLength);
			});
		},
		initBowtieGroups: (state, action: PayloadAction<{ statePath: string }>) => {
			const { statePath } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";

			const groups = get(state, `addNewQuestionForm.${fullPath}.groups`);
			for (let i = 0; i < 3; i++) {
				const group = parseTemplate(get(state, "addNewQuestionForm").templates["groups"], `${statePath}.${i}`, {
					defaultValueGetters
				});

				group.answerOptions = [];
				group.text.validations.required = false;
				const optionsAmount = i == 1 ? 2 : 3;

				for (let option = 0; option < optionsAmount; option++) {
					const newOption = parseTemplate(
						get(state, "addNewQuestionForm").templates["groups.answerOptions"],
						`addNewQuestionForm.${fullPath}.groups.${i}.answerOptions.${group.answerOptions.length}`,
						{
							defaultValueGetters
						}
					);

					group.answerOptions.push(newOption);
				}

				groups.push(group);
				group?.answerOptions.forEach(answerOption => {
					if (answerOption.text.validations) {
						answerOption.text.validations.maxLength = 25;
					}
				});
			}
			set(state, `addNewQuestionForm.${fullPath}.groups`, groups);
		},
		editBowtieGroups: (state, action: PayloadAction<{ statePath: string }>) => {
			const { statePath } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";

			const groups = get(state, `addNewQuestionForm.${fullPath}.groups`);
			groups?.forEach(group => {
				group?.answerOptions.forEach(answerOption => {
					if (answerOption.text.validations) {
						answerOption.text.validations.maxLength = 25;
					}
				});
			});
			set(state, `addNewQuestionForm.${fullPath}.groups`, groups);
		},

		initCaseStudyQuestion: (
			state,
			action: PayloadAction<{
				formName: string;
				statePath: string;
			}>
		) => {
			const { statePath: _statePath, formName } = action.payload;

			const statePath = `${formName}.${_statePath}.questions`;
			const questions: FormInputItemsModel[] = [];

			const selectValues = get(state, "questionTypeOptions").filter(
				({ value }) => value !== QuestionTypes.CaseStudy && value !== QuestionTypes.BowTie
			);

			for (let i = 0; i < 6; i++) {
				const newItem = parseTemplate(get(state, formName).templates["questions"], `${statePath}.${i}`, {
					defaultValueGetters
				});
				newItem.typeId.selectOptions = selectValues;
				newItem.answerOptions = [];
				newItem.attachments = [];
				newItem.groups = [];
				questions.push(newItem);
			}

			set(state, statePath, questions);
		},
		createTab: (
			state,
			action: PayloadAction<{
				formName: string;
				statePath: string;
			}>
		) => {
			const { formName, statePath: _statePath } = action.payload;

			const statePath = `${formName}.${_statePath}.tabs`;
			const items = get(state, statePath);
			const newItemPath = `${statePath}.${items.length}`;

			const newItem = parseTemplate(get(state, formName).templates["tabs"], newItemPath, {
				defaultValueGetters
			});

			items.push(newItem);

			set(state, statePath, items);
		},
		removeTab: (
			state,
			action: PayloadAction<{
				formName: string;
				statePath: string;
				tabIndex: number;
			}>
		) => {
			const { tabIndex, formName, statePath } = action.payload;

			set(state, `${formName}.${statePath}.tabs.${tabIndex}.deleted.value`, true);
		},
		removeQuestionData: (state, action: PayloadAction<{ statePath: string }>) => {
			const { statePath } = action.payload;
			set(state, `${statePath}.text.value`, "");
			set(state, `${statePath}.tableLabel.value`, "");
			set(state, `${statePath}.answerOptionLabel.value`, "");
			set(state, `${statePath}.description.value`, "");
			set(state, `${statePath}.answerOptions`, []);
			set(state, `${statePath}.groups`, []);
			set(state, `${statePath}.tabs`, []);
			set(state, `${statePath}.questions`, []);
		},
		removeGapOption: (
			state,
			action: PayloadAction<{ gapIndex: number; answerOptionIndex: number; statePath: string }>
		) => {
			const { gapIndex, answerOptionIndex, statePath } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			set(
				state,
				`addNewQuestionForm.${fullPath}.groups.${gapIndex}.answerOptions.${answerOptionIndex}.deleted.value`,
				true
			);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "addNewQuestionForm", markInputsAsDirty: true },
				type: ""
			});
		},
		updateCorrectFlag: (
			state,
			action: PayloadAction<{ groupIndex: number; answerOptionIndex: number; statePath: string }>
		) => {
			const { groupIndex, answerOptionIndex, statePath } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";

			const optionsStatePath = `addNewQuestionForm.${fullPath}.groups.${groupIndex}.answerOptions`;
			const items = get(state, optionsStatePath);

			items.forEach(({ isCorrect }, index) => (isCorrect.value = index === answerOptionIndex));

			set(state, optionsStatePath, items);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "addNewQuestionForm", markInputsAsDirty: true },
				type: ""
			});
		},
		reduceAnswerOptsLength: (state, action: PayloadAction<{ length: number; inputsPath: string }>) => {
			const { length, inputsPath } = action.payload;
			const optionsStatePath = `addNewQuestionForm.${inputsPath}`;
			const answerOptions = get(state, optionsStatePath);
			answerOptions.length = length;
			set(state, optionsStatePath, answerOptions);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "addNewQuestionForm", markInputsAsDirty: true },
				type: ""
			});
		},
		removeGap: (
			state,
			action: PayloadAction<{
				formName: string;
				gapIndex: number;
				statePath: string;
			}>
		) => {
			const { gapIndex, statePath, formName } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";

			set(state, `${formName}.${fullPath}.groups.${gapIndex}.deleted.value`, true);
			utilsValidateFormAction(state, {
				payload: { formStatePath: formName, markInputsAsDirty: true },
				type: ""
			});
		},
		createGroupAnswerOptionItem: (
			state,
			action: PayloadAction<{ index: number; statePath: string; questionType?: number }>
		) => {
			const { index, statePath, questionType } = action.payload;

			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			const optionsStatePath = `addNewQuestionForm.${fullPath}.groups.${index}.answerOptions`;

			const items = get(state, optionsStatePath);

			const newOption = parseTemplate(
				get(state, "addNewQuestionForm").templates["groups.answerOptions"],
				`${optionsStatePath}.${items.length}`,
				{
					defaultValueGetters
				}
			);
			if (QuestionTypes.BowTie === questionType) {
				newOption.text.validations.maxLength = 25;
			}
			items.push(newOption);
			set(state, optionsStatePath, items);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "addNewQuestionForm", markInputsAsDirty: true },
				type: ""
			});
		},
		removeQuestionAttachment: (state, action: PayloadAction<{ index: number; fullStatePath: string }>) => {
			const { index, fullStatePath } = action.payload;

			const attachments = get(state, `addNewQuestionForm.${fullStatePath}.attachments`) ?? [];

			set(state, `addNewQuestionForm.${fullStatePath}.attachments`, [
				...attachments.slice(0, index),
				...attachments.slice(index + 1)
			]);
		},
		createQuestionAttachment: (
			state,
			action: PayloadAction<{
				fullStatePath: string;
				name: string;
				fileName: string;
				fileType?: string;
				fileSizeInBytes?: number;
			}>
		) => {
			const { fullStatePath, ...attachment } = action.payload;

			const question = get(state, `addNewQuestionForm.${fullStatePath}`);
			const attachments = question.attachments ?? [];
			const newAttachment = parseTemplate(
				get(state, "addNewQuestionForm").templates["attachments"],
				`addNewQuestionForm.${fullStatePath}.attachments.${attachments.length}`,
				{
					defaultValueGetters
				}
			) as unknown;

			const index = attachments.length;
			attachments.push(newAttachment);
			set(state, `addNewQuestionForm.${fullStatePath}.attachments`, attachments);
			const attachmentPath = `addNewQuestionForm.${fullStatePath}.attachments.${index}`;
			set(state, `${attachmentPath}.fileName.value`, attachment.fileName);
			set(state, `${attachmentPath}.name.value`, attachment.name);
			set(state, `${attachmentPath}.fileType.value`, attachment.fileType);
			set(state, `${attachmentPath}.fileSizeInBytes.value`, attachment.fileSizeInBytes);
		},
		removeQuestionAnswerOptionItem: (
			state,
			action: PayloadAction<{
				statePath?: string;
				formName: string;
				inputsPath: string;
				removeSelectedOpts?: boolean;
				id?: string;
			}>
		) => {
			const { formName, inputsPath, removeSelectedOpts, id, statePath } = action.payload;
			const itemPath = `${formName}.${inputsPath}`;
			set(state, `${itemPath}.deleted.value`, true);
			set(state, `${itemPath}.text.validations`, undefined);
			if (get(state, `${itemPath}.isCorrect`)) {
				set(state, `${itemPath}.isCorrect.validations`, undefined);
			}
			if (removeSelectedOpts) {
				const groups = get(state, `${formName}.${statePath}.groups`);
				groups.forEach(group => {
					if (Array.isArray(group.selectedAnswerOptions.value)) {
						set(
							state,
							`${group.selectedAnswerOptions.statePath}.value`,
							group.selectedAnswerOptions.value.filter(y => y !== id)
						);
					}
				});
			}
			utilsValidateFormAction(state, {
				payload: { formStatePath: formName, markInputsAsDirty: true },
				type: ""
			});
		},
		createQuestionGap: (
			state,
			action: PayloadAction<{
				formName: string;
				statePath: string;
				start?: boolean;
			}>
		) => {
			const { start, formName, statePath } = action.payload;
			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			const itemsPath = `${formName}.${fullPath}.groups`;
			const items = get(state, itemsPath);
			const newItem = parseTemplate(get(state, formName).templates["groups"], `${itemsPath}.${items.length}`, {
				defaultValueGetters
			}) as { order: { value: number }; deleted: { value: boolean }; answerOptions: [] };
			newItem.answerOptions = [];

			if (!start) {
				items!.push(newItem);
			} else {
				const lastItem = parseTemplate(get(state, formName).templates["groups"], `${itemsPath}.1`, {
					defaultValueGetters
				}) as { order: { value: number }; deleted: { value: boolean }; answerOptions: [] };
				lastItem.answerOptions = [];

				items!.push(newItem, lastItem);
			}

			const existingItems = items.filter(i => !i.deleted.value);
			newItem.order.value = existingItems.length ? existingItems[existingItems.length - 1].order.value + 1 : 0;

			set(state, itemsPath, items);
		},

		updateAnswerOptsFlag: (
			state,
			action: PayloadAction<{
				formName: string;
				inputsPath: string;
				answerOptionIndex: number;
			}>
		) => {
			const { formName, inputsPath, answerOptionIndex } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const answerOptions = get(state, itemsPath);
			answerOptions.forEach((answerOptionItem, i) => {
				const { statePath } = answerOptionItem.isCorrect;
				if (answerOptionIndex === i) {
					set(state, `${statePath}.value`, true);
				} else {
					set(state, `${statePath}.value`, false);
				}
			});

			set(state, itemsPath, answerOptions);
		},

		createQuestionAnswerOptionItem: (
			state,
			action: PayloadAction<{
				formName: string;
				initialData?: Record<string, unknown>;
				inputsPath: string;
				templatePath: string;
				type?: string;
			}>
		) => {
			const { formName, initialData, inputsPath, templatePath, type } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath) as { order: { value: number }; deleted: { value: boolean } }[];
			const newItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.${items.length}`, {
				defaultValueGetters
			}) as { order: { value: number }; deleted: { value: boolean }; isCorrect: { type: string } };
			if (type) {
				newItem.isCorrect.type = type;
			}

			if (items.length > 0) {
				const existingItems = items.filter(i => !i.deleted.value);
				newItem.order.value = existingItems.length ? existingItems[existingItems.length - 1].order.value + 1 : 0;
			}
			if (initialData) {
				for (const key in initialData) {
					set(newItem as Record<string, unknown>, key, initialData[key]);
				}
			}
			items!.push(newItem);
			set(state, itemsPath, items);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "addNewQuestionForm", markInputsAsDirty: true },
				type: ""
			});
		},

		createSingleMatrixQuestionAnswerOptions: (
			state,
			action: PayloadAction<{
				formName: string;
				initialData?: Record<string, unknown>;
				inputsPath: string;
				templatePath: string;
				setMaxLengthValidation?: boolean;
			}>
		) => {
			const { formName, inputsPath, templatePath, setMaxLengthValidation } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath);
			const firstItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.${items.length}`, {
				defaultValueGetters
			});
			const secondItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.1`, {
				defaultValueGetters
			});
			if (setMaxLengthValidation) {
				firstItem.text.validations.maxLength = MAX_GROUP_TEXT_LENGTH;
				secondItem.text.validations.maxLength = MAX_GROUP_TEXT_LENGTH;
			} else {
				firstItem.text.validations.maxLength = CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH;
				secondItem.text.validations.maxLength = CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH;
			}
			items!.push(firstItem);
			items!.push(secondItem);
			set(state, itemsPath, items);
		},

		createQuestionGroup: (
			state,
			action: PayloadAction<{
				formName: string;
				statePath: string;
				start?: boolean;
				setMaxLengthValidation?: boolean;
			}>
		) => {
			const { formName, statePath, start, setMaxLengthValidation } = action.payload;
			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			const itemsPath = `${formName}.${fullPath}.groups`;
			const items = get(state, itemsPath);
			const newItem = parseTemplate(get(state, formName).templates["groups"], `${itemsPath}.${items.length}`, {
				defaultValueGetters
			});

			newItem.answerOptions = [];
			if (setMaxLengthValidation) newItem.text.validations.maxLength = CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH;
			items!.push(newItem);
			if (start) {
				const secondItem = parseTemplate(get(state, formName).templates["groups"], `${itemsPath}.1`, {
					defaultValueGetters
				});
				secondItem.answerOptions = [];
				if (setMaxLengthValidation) secondItem.text.validations.maxLength = CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH;
				items!.push(secondItem);
			}
			set(state, itemsPath, items);
		},

		removeQuestionGroup: (state, action: PayloadAction<{ groupIndex: number; statePath: string }>) => {
			const { groupIndex, statePath } = action.payload;
			const fullPath = statePath ? `inputs.${statePath}` : "inputs";
			set(state, `addNewQuestionForm.${fullPath}.groups.${groupIndex}.deleted.value`, true);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "addNewQuestionForm", markInputsAsDirty: true },
				type: ""
			});
		},

		updateMatrixQuestionGroup: (
			state,
			action: PayloadAction<{
				key: string;
				value: boolean;
				optId: string;
			}>
		) => {
			const { key, value, optId } = action.payload;
			const item = get(state, key);
			let values: string[] = [];
			values = value ? [...item.value, optId] : item.value.filter(x => x !== optId);
			set(state, `${key}.value`, values);
		},

		addOneMoreQuestionAnswerOption: (
			state,
			action: PayloadAction<{
				formName: string;
				initialData?: Record<string, unknown>;
				inputsPath: string;
				templatePath: string;
				setMaxLengthValidation?: boolean;
			}>
		) => {
			const { formName, inputsPath, templatePath, setMaxLengthValidation } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath);
			const firstItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.${items.length}`, {
				defaultValueGetters
			});
			if (setMaxLengthValidation) firstItem.text.validations.maxLength = MAX_GROUP_TEXT_LENGTH;
			items!.push(firstItem);
			set(state, itemsPath, items);
			utilsValidateFormAction(state, {
				payload: { formStatePath: "addNewQuestionForm", markInputsAsDirty: true },
				type: ""
			});
		},

		setArrayOfQuestionAnswerOption: (
			state,
			action: PayloadAction<{
				formName: string;
				initialData?: Record<string, unknown>;
				inputsPath: string;
				templatePath: string;
				lengthOfOptions?: number;
				optionsArray: {
					highlighted: boolean;
					text: string;
				}[];
			}>
		) => {
			const { formName, inputsPath, templatePath, optionsArray } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath);
			while (items.length < optionsArray.length) {
				const newItem = parseTemplate(get(state, formName).templates[templatePath], `${itemsPath}.${items.length}`, {
					defaultValueGetters
				});

				items.push(newItem);
			}
			optionsArray.forEach(({ text, highlighted }, index) => {
				const item = items[index];

				item.text.value = text;
				item.order.value = index;
				item.isCorrect.value = highlighted;
				item.deleted.value = false;
				item.text.validations.maxLength = 5000;
			});
			set(state, itemsPath, items);
		},
		clearArrayOfQuestionAnswerOption: (
			state,
			action: PayloadAction<{
				formName: string;
				inputsPath: string;
			}>
		) => {
			const { formName, inputsPath } = action.payload;
			const itemsPath = `${formName}.${inputsPath}`;
			const items = get(state, itemsPath);

			items.forEach(item => (item.deleted.value = true));

			set(state, itemsPath, items);
		},

		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		resetState: utilsResetState
	}
});

export const fetchQuestion = createAsyncThunk(
	"addQuestion/fetchQuestion",
	async (questionId: string, { dispatch, getState }) => {
		const data = await questionsService.findOne(+questionId, {
			include: ["attachments", "courses", "subjectLessons.subject", "caseStudyQuestions"]
		});

		if (data === null) {
			return;
		}

		const prepareQuestion = question => {
			const { data, caseStudyQuestions, ...formattedData } = question;

			switch (formattedData.typeId) {
				case QuestionTypes.HotspotHighlight: {
					formattedData.answerOptions = data.answerOptions;
					break;
				}
				case QuestionTypes.MultipleChoiceSN:
				case QuestionTypes.SingleChoice:
				case QuestionTypes.MultipleChoiceSATA: {
					formattedData.answerOptions = data.answerOptions;
					formattedData.answerOptions?.forEach(item => {
						if (typeof item.order == "string") {
							item.order = null;
						}
					});
					break;
				}
				case QuestionTypes.MatrixMultipleChoice:
				case QuestionTypes.MatrixSingleChoice: {
					formattedData.tableLabel = data.tableLabel;
					formattedData.answerOptions = data.answerOptions;
					formattedData.groups = data.groups;
					break;
				}
				case QuestionTypes.HighlightTable: {
					formattedData.groups = data.groups.map(group => {
						let content = group.text;

						const answerOptions: { text: string; isCorrect: boolean }[] = [];

						group.answerOptions.forEach(answerOption => {
							const startIndex = content.indexOf(answerOption.text);

							if (startIndex === -1) {
								throw new Error(`Answer option '${answerOption.text}' not found in group text '${group.text}`);
							}

							const start = content.substr(0, startIndex);

							if (start) {
								answerOptions.push({ text: start, isCorrect: false });
							}

							answerOptions.push({ text: answerOption.text, isCorrect: true });

							content = content.substr(startIndex + answerOption.text.length);
						});

						if (content.length > 0) answerOptions.push({ text: content, isCorrect: false });

						return {
							...group,
							text: group.title,
							answerOptions
						};
					});

					formattedData.tableLabel = data.tableLabel;
					formattedData.answerOptionLabel = data.answerOptionLabel;

					break;
				}
				case QuestionTypes.BowTie:
				case QuestionTypes.DragAndDrop:
				case QuestionTypes.RationalScoringDragAndDrop: {
					formattedData.groups = data.groups;
					formattedData.answerOptions = data.answerOptions;
					break;
				}
				case QuestionTypes.MultipleResponseGroup: {
					formattedData.tableLabel = data.tableLabel;
					formattedData.answerOptionLabel = data.answerOptionLabel;
					formattedData.groups = data.groups;
					break;
				}
				case QuestionTypes.ClozeDropDown:
				case QuestionTypes.RationalScoringDropDown: {
					formattedData.groups = data.groups;
					break;
				}
				case QuestionTypes.CaseStudy: {
					formattedData.tabs = data.tabs;
					formattedData.questions = caseStudyQuestions.map(prepareQuestion);
					break;
				}

				case QuestionTypes.DropDownTable: {
					formattedData.tableLabel = data.tableLabel;
					formattedData.answerOptionLabel = data.answerOptionLabel;
					formattedData.groups = data.groups;
					break;
				}
			}

			return formattedData;
		};

		const courseIds = data.courses?.length ? data.courses.map(({ id }) => id) : [];
		const subjectId = data.subjectLessons?.length ? data.subjectLessons[0].subjectId : "";
		const lessonSubjectId = data.subjectLessons?.length ? data.subjectLessons[0].id : "";

		try {
			dispatch(
				populateInputs({
					inputsStatePath: "addNewQuestionForm.inputs",
					templatesStatePath: "addNewQuestionForm.templates",
					values: { ...prepareQuestion(data), courseIds, subjectId, lessonSubjectId }
				})
			);
		} catch (e) {
			console.error(e);
		}

		dispatch(
			setStateValue({
				key: "addNewQuestionForm.inputs.mainImageKey.imageUrl",
				value: data.mainImageUrl
			})
		);

		dispatch(validateForm({ formStatePath: "addNewQuestionForm", markInputsAsDirty: false }));
		if (data.typeId === QuestionTypes.CaseStudy) {
			data.caseStudyQuestions?.forEach((question, questionIndex) => {
				dispatch(
					setStateValue({
						key: `addNewQuestionForm.inputs.questions.${questionIndex}.mainImageKey.imageUrl`,
						value: question.mainImageUrl
					})
				);
			});

			const selectValues = get(getState(), "addQuestion.questionTypeOptions").filter(
				({ value }) => value !== QuestionTypes.CaseStudy
			);

			data.caseStudyQuestions?.forEach((_, index) =>
				dispatch(
					setStateValue({
						key: `addNewQuestionForm.inputs.questions.${index}.typeId.selectOptions`,
						value: selectValues
					})
				)
			);
		}
	}
);

export const fetchQuestionFormData = createAsyncThunk(
	"addQuestion/fetchQuestionFormData",
	async (success: () => void, { dispatch, getState }) => {
		try {
			const { isLoading } = (getState() as { addQuestion: AddQuestionState }).addQuestion;
			if (!isLoading) {
				dispatch(setLoading(true));
			}
			const courses = await coursesService.find({
				findAll: true,
				orderBy: { createdAt: "ASC" }
			});
			const questionTypeOptions = (
				await questionTypesService.find({ findAll: true, filters: { forQuestionBank: true } })
			).items
				.map(item => ({ text: item.name, value: item.id }))
				.sort((a, b) => (a.text.toLowerCase()[0] > b.text.toLowerCase()[0] ? 1 : -1));

			const subjects = await subjectService.find({
				findAll: true,
				orderBy: { name: "ASC" },
				include: ["lessons"]
			});

			const difficultyLevels = await difficultyLevelsService.find({ findAll: true });

			dispatch(setStateValue({ key: "courses", value: courses.items }));
			dispatch(
				setStateValue({
					key: "addNewQuestionForm.inputs.courseIds.selectOptions",
					value: courses.items
				})
			);
			dispatch(setStateValue({ key: "questionTypeOptions", value: questionTypeOptions }));
			dispatch(
				setStateValue({
					key: "addNewQuestionForm.inputs.typeId.selectOptions",
					value: questionTypeOptions
				})
			);
			dispatch(setStateValue({ key: "subjects", value: subjects.items }));
			dispatch(
				setStateValue({
					key: "addNewQuestionForm.inputs.subjectId.selectOptions",
					value: getSelectOptions(subjects.items, "id", "name")
				})
			);
			dispatch(setStateValue({ key: "difficultyLevels", value: difficultyLevels.items }));
			dispatch(
				setStateValue({
					key: "addNewQuestionForm.inputs.difficultyLevelId.selectOptions",
					value: getSelectOptions(difficultyLevels.items, "id", "name")
				})
			);
		} catch (e) {
			success && success();
			return { error: e };
		}
		success && success();
	}
);

export function getFullState(state: RootState): AddQuestionState {
	return state.addQuestion;
}

export const {
	setLoading,
	failed,
	setStateValue,
	validateForm,
	createQuestionAnswerOptionItem,
	removeQuestionAttachment,
	createQuestionAttachment,
	removeQuestionAnswerOptionItem,
	createGroupAnswerOptionItem,
	createSingleMatrixQuestionAnswerOptions,
	removeQuestionGroup,
	createQuestionGroup,
	updateCorrectFlag,
	reduceAnswerOptsLength,
	createQuestionGap,
	updateAnswerOptsFlag,
	removeGap,
	createTab,
	removeTab,
	removeQuestionData,
	removeGapOption,
	initCaseStudyQuestion,
	resetState,
	updateMatrixQuestionGroup,
	populateInputs,
	initBowtieGroups,
	editBowtieGroups,
	addOneMoreQuestionAnswerOption,
	setArrayOfQuestionAnswerOption,
	updateValidationCriteria,
	clearArrayOfQuestionAnswerOption
} = addQuestionSlice.actions;

export default addQuestionSlice.reducer;
