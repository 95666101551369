import { Box, FormControl, Card as MuiCard, Typography } from "@material-ui/core";
import { Pagination as MuiPagination } from "@material-ui/lab";

import { ReactComponent as RNIconSvg } from "@remar/shared/dist/assets/icons/icon-RN.svg";
import styled from "styled-components";

export const Container = styled.div`
	margin: 60px;
`;

export const Card = styled(MuiCard)`
	display: flex;
	color: white;
	border-radius: 0;
	padding: 18px;

	div: first-child {
		width: 60%;
		padding: 0;
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: 12px;
		width: 15%;
		padding: 0;
	}
`;

export const Pagination = styled(MuiPagination)`
	li {
		button {
			color: white;
		}
	}
	margin-bottom: 36px;
`;

export const THeaderWrapper = styled(Box)`
	display: flex;
	align-items: center;
	background-color: #22252d;
	padding: 20px;
	border-radius: 4px;
`;

export const StyledSVG = styled(RNIconSvg)`
	circle {
		fill: ${props => `${props.bgColor.split(",").splice(0, 1).join(",")},65%,40%)`};
	}
	g {
		#user-nurse {
			fill: ${props => `${props.bgColor.split(",").splice(0, 1).join(",")},100%,65%)`};
		}
	}
`;

export const LabelText = styled(Typography)`
	color: #ffffff60;
	margin-bottom: 10px;
`;

export const FormControlContainer = styled(FormControl)`
	width: 100%;
`;
