import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { School } from "@remar/shared/dist/models";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { AppThunk, RootState } from "store";

import { schoolsService } from "store/services";

import { emit } from "../notifications/notifications.slice";

export interface ManageSchoolsRequestState {
	isRequestLoading: boolean;
	page: number;
	perPage: number;
	schoolRequests: School[];
	totalItems: number;
	errorMessage: string;
}

const initialState: ManageSchoolsRequestState = {
	isRequestLoading: false,
	page: 1,
	perPage: 10,
	totalItems: 0,
	schoolRequests: [],
	errorMessage: ""
};

export const manageSchoolsRequestSlice = createSlice({
	name: "schoolRequests",
	initialState,
	reducers: {
		setRequestLoading: (state, action: PayloadAction<boolean>) => {
			state.isRequestLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setStateValue: utilsSetStateValue
	}
});

export const fetchSchoolRequests = createAsyncThunk(
	"manageSchools/fetchSchools",
	async (options: { page?: number; perPage?: number }, { dispatch, getState }) => {
		try {
			const { isRequestLoading, page, perPage } = (
				getState() as {
					schoolRequests: ManageSchoolsRequestState;
				}
			).schoolRequests;
			if (!isRequestLoading) {
				dispatch(setRequestLoading(true));
			}
			const { page: optPage, perPage: optPerPage } = options;
			const filters = {};
			filters["approved"] = false;

			const {
				page: newPage,
				perPage: newPerPage,
				items,
				totalItems
			} = await schoolsService.find({
				page: optPage || page,
				perPage: optPerPage || perPage,
				orderBy: { id: "DESC" },
				filters
			});
			dispatch(setStateValue({ key: "page", value: newPage }));
			dispatch(setStateValue({ key: "perPage", value: newPerPage }));
			dispatch(setStateValue({ key: "schoolRequests", value: items }));
			dispatch(setStateValue({ key: "totalItems", value: totalItems }));
		} catch (e) {
			dispatch(setRequestLoading(false));
			return { error: e };
		}
		dispatch(setRequestLoading(false));
	}
);

export const deleteMergeRequest =
	(id: number): AppThunk =>
	async dispatch => {
		await dispatch(setStateValue({ key: "isRequestLoading", value: true }));
		await schoolsService.delete({ filters: { id } });
		dispatch(emit({ message: "School request has been deleted.", color: "success" }));

		dispatch(fetchSchoolRequests({}));
	};

export function getRequestFullState(state: RootState): ManageSchoolsRequestState {
	return state.schoolRequests;
}

export const { setRequestLoading, failed, setStateValue } = manageSchoolsRequestSlice.actions;

export default manageSchoolsRequestSlice.reducer;
