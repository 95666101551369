import { buildRouteTree } from "@remar/shared/dist/utils/routeUtils";

export const routes = buildRouteTree({
	signUp: null,
	home: null,
	signIn: null,
	CreateLesson: null,
	questionbank: null,
	manageSubjects: null,
	course: null,
	courseAddEdit: null,
	students: null,
	reports: null,
	newReports: null,
	settings: null,
	mediaLibrary: null,
	manageStudents: null,
	fileVault: null,
	manageTutorial: null,
	manageSchools: null,
	catAvailability: null,
	manageLocations: null,
	location: null,
	communityPool: null,
	lesson: null
});
