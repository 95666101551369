import { Box, makeStyles } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled from "styled-components";

export const FixErrorPosition = styled.div`
	p[class*="errorMessage"] {
		position: static !important;
	}
`;
export const useTestOnlyQuestionStyles = makeStyles((theme: IExtendedTheme) => ({
	fileName: {
		fontSize: 12,
		maxWidth: 500,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	},
	fileSize: {
		fontSize: 10,
		color: "#8f9bb3"
	},
	cursorPointer: {
		cursor: "pointer"
	},
	addOptionButtonContainer: {
		cursor: "pointer",
		width: "fit-content",
		"& button": {
			height: "20px",
			marginRight: "8px",
			minWidth: "20px",
			padding: "0px"
		}
	},
	addOptionHeader: {
		color: "#ffffff",
		fontSize: "12px",
		fontWeight: "bold",
		lineHeight: 1.33,
		opacity: 0.38
	},
	addOptionLabel: {
		color: "rgba(255, 255, 255, 0.6)",
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: 1.6
	},
	button: {
		width: 40,
		height: 40,
		borderRadius: 50,
		backgroundColor: "#393e4b",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		border: "1px solid #555862",
		transition: "all 0.2s",
		"&:hover": {
			transform: "scale(1.08)"
		}
	},
	divider: {
		width: "100%",
		height: 2,
		backgroundColor: "#393e4b",
		margin: theme.spacing(5, 0, 4, 0),
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	expandButtonContainer: {
		"& button": {
			backgroundColor: "initial",
			color: "rgba(255, 255, 255, 0.6)"
		}
	},
	fullHeight: {
		height: "100%"
	},
	header: {
		opacity: 0.87,
		fontSize: "18px",
		fontWeight: "bold",
		paddingRight: "16px",
		lineHeight: 1.33,
		color: "#ffffff"
	},
	passingThresholdInput: {
		"& input": {
			paddingTop: "11px",
			paddingBottom: "10px"
		}
	},
	questionsCount: {
		fontSize: theme.typography.pxToRem(22),
		fontFamily: "var(--poppins)",
		fontWeight: 900,
		lineHeight: 1
	},
	questionsCountSub: {
		fontSize: theme.typography.pxToRem(13),
		color: "white",
		opacity: 0.6
	},
	questionTypeInput: {
		"& div[role=button]": {
			paddingTop: "14px",
			paddingBottom: "15px"
		}
	},
	hotspotHighlightTextBox: {
		backgroundColor: "#2a2e37",
		margin: "16px 0",
		padding: "16px",
		fontSize: "16px"
	}
}));

export const StyledBoxContainer = styled(Box)<{ isDisabled: boolean }>`
	${({ isDisabled }) => (isDisabled ? "cursor: not-allowed" : "")}
`;
