import { Button, List, ListItem, CircularProgress as Progress, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import styled from "styled-components";

import { ReactComponent as PencilIconSvg } from "assets/icons/icon-pencil.svg";
import { ReactComponent as PlusIconSvg } from "assets/icons/icon-plus.svg";

export const Header = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: ${props => props.theme.palette.colors.basic[100]};
`;

export const PackagesContainer = styled.div`
	margin-top: 32px;
	display: flex;
	gap: 50px;
	margin-right: 15px;
`;

export const PackageContainer = styled.div`
	max-width: 424px;
	border-radius: 4px;
	background: ${props => props.theme.palette.colors.basic[800]};
`;

export const PackageArea = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
	align-items: center;
	flex-wrap: wrap;
`;

export const PackageName = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: ${props => props.theme.palette.colors.basic[100]};
	opacity: 87%;
	font-family: var(--poppins);
	word-break: break-word;
	margin-right: 10px;
`;

export const PackageSubHeading = styled.div`
	display: inline-block;
	font-size: 15px;
	line-height: 1.6;
	color: ${props => props.theme.palette.colors.basic[100]};
	opacity: 87%;
`;
export const SubHeader = styled(Typography)`
	font-size: 12px;
	font-family: var(--openSans);
	color: ${({ theme }) => theme.palette.colors.basic[100] + 61};
	padding: 10px 20px;
}
`;

export const PackagePrice = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: ${props => props.theme.palette.colors.basic[100]};
	opacity: 87%;
	font-family: var(--poppins);
	margin-top: 6px;
`;

export const PackagePriceContainer = styled.div`
	gap: 15px;
`;

export const Spacer = styled.div`
	height: 1px;
	border: solid 1px ${props => props.theme.palette.colors.basic[750]};
`;

export const CheckedList = styled(List)`
	background: inherit;
`;

export const CheckedListItem = styled(ListItem)`
	opacity: 0.87;
	font-weight: normal;
	font-size: 13px;
	color: ${props => props.theme.palette.colors.basic[100]};
	align-items: center;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	padding-bottom: 5px;
`;

export const ItemName = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
`;

export const CheckedIcon = styled(CheckCircleIcon)`
	margin-right: 4px;
	color: ${({ theme }) => theme.palette.colors.primary[1000]};
`;

export const PackageStatus = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
	padding: 15px;
`;

export const StatusText = styled.div`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: ${props => props.theme.palette.colors.basic[100]};
	opacity: 87%;
	margin-right: 10px;
`;

export const Indicator = styled.span`
	background: ${props =>
		props.isActive ? props.theme.palette.colors.success[600] : props.theme.palette.colors.danger[600]};
	width: 10px;
	height: 10px;
	border-radius: 8px;
`;

export const DeletePackage = styled.div`
	background: ${props => props.theme.palette.colors.basic[900]};
	max-width: 424px;
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-weight: 700;
	line-height: 1.25;
	opacity: 87%;
	cursor: pointer;
	padding: 15px;
`;

export const AddPackageContainer = styled.div`
	width: 350px;
	border-radius: 4px;
	background: ${props => props.theme.palette.colors.primary[950]};
	height: auto;
	min-height: 600px;
	margin-bottom: 15px;
	border: 1px dashed ${({ theme }) => theme.palette.colors.primary[1000]};
`;

export const AddPackage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

export const AddPackageText = styled.div`
	font-size: 22px;
	font-weight: 700;
	font-family: var(--poppins);
	line-height: 1.455;
	color: ${({ theme }) => theme.palette.colors.primary[1000]};
	margin-top: 10px;
	opacity: 87%;
	text-align: center;
`;

export const AddPackageIcon = styled(PlusIconSvg)`
	path {
		fill: ${({ theme }) => theme.palette.colors.primary[1000]};
	}
`;

export const EditPackageIcon = styled(PencilIconSvg)`
	path {
		fill: ${props => props.theme.palette.colors.basic[100]};
	}
`;

export const EditPackageContainer = styled.div`
	background: ${props => props.theme.palette.colors.primary[500]};
	width: 32px;
	display: flex;
	border-radius: 4px;
	height: 32px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const PublishOrUnpublishButton = styled(Button)`
	color: ${props => props.theme.palette.colors.basic[100]};
	background-color: ${props =>
		props.isActive ? props.theme.palette.colors.danger[600] : props.theme.palette.colors.primary[600]};
`;

export const ConfirmButton = styled(Button)`
	background: ${props => props.theme.palette.colors.danger[900]};
	color: ${props => props.theme.palette.colors.danger[500]};
	font-family: var(--poppins);
	font-size: 16px;
	font-weight: bold;
	line-height: 1.25;
	width: ${props => (props.isDelete ? "120px" : "100px")};
	height: ${props => (props.isDelete ? "55px" : "45px")};
	box-sizing: border-box;
`;

export const CancelButton = styled(Button)`
	background: transparent;
	border-radius: 4px;
	border: solid 1px ${props => props.theme.palette.colors.basic[500]};
	color: ${props => props.theme.palette.colors.basic[500]};
	margin-right: 10px;
	width: ${props => (props.isDelete ? "120px" : "100px")};
	height: ${props => (props.isDelete ? "55px" : "45px")};
`;

export const CircularProgress = styled(Progress)`
	margin-left: 50%;
	margin-top: 15%;
`;

export const DropdownContainer = styled.div`
	> div {
		min-width: 200px;
	}
`;
