import React from "react";

import { Box, Button, TextField, Typography } from "@material-ui/core";
import { StyledCheckbox, TransparentButton } from "@remar/shared/dist/ui/Buttons/styles";

import { validatePositiveNumber } from "@remar/shared/dist/utils/serviceUtils/validators";

import { ButtonContainer } from "./styles";

const EditFields = (
	updateVal,
	name,
	calculateTimeLengthAutomatically,
	progressTrackingEnabled,
	updateChapterLength,
	chapterLength,
	disabledSaveBtn,
	cancel,
	save,
	updatedChapterLength?,
	excludedFromCourseRequirement?
) => (
	<>
		<Box display="flex" alignItems="center">
			<Box width={120} mr={3}>
				<Typography align="right">Chapter Title</Typography>
			</Box>
			<Box width={350}>
				<TextField
					fullWidth
					hiddenLabel
					InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
					inputProps={{ maxLength: 140 }}
					color="primary"
					onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
						updateVal(event.target.value, "name");
					}}
					variant="filled"
					size="small"
					placeholder="Enter Chapter Title"
					value={name}
				/>
			</Box>
		</Box>
		<Box display="flex" alignItems="center" mt={4} mb={2}>
			<Box width={120} mr={3}>
				<Typography align="right">Chapter Length</Typography>
			</Box>
			<Box width={167} mr={2}>
				<TextField
					hiddenLabel
					type="number"
					InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
					color="primary"
					disabled={calculateTimeLengthAutomatically}
					onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
						if ((event.target.value as number) <= 1000) updateChapterLength(event.target.value, "hours");
					}}
					onKeyPress={(event: React.KeyboardEvent) => {
						if (!validatePositiveNumber(event.key)) {
							event.preventDefault();
						}
					}}
					variant="filled"
					size="small"
					placeholder="Hours"
					value={chapterLength.hours === "" && updatedChapterLength ? updatedChapterLength.hours : chapterLength.hours}
				/>
			</Box>
			<Box width={167}>
				<TextField
					hiddenLabel
					disabled={calculateTimeLengthAutomatically}
					type="number"
					InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
					color="primary"
					onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
						if ((event.target.value as number) < 60) updateChapterLength(event.target.value, "mins");
					}}
					variant="filled"
					size="small"
					onKeyPress={(event: React.KeyboardEvent) => {
						if (!validatePositiveNumber(event.key)) {
							event.preventDefault();
						}
					}}
					value={chapterLength.mins === "" && updatedChapterLength ? updatedChapterLength.mins : chapterLength.mins}
					placeholder="Minutes"
				/>
			</Box>
		</Box>
		<Box ml={18}>
			<StyledCheckbox
				checked={calculateTimeLengthAutomatically}
				onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
					updateVal(event.target.checked, "calculateTimeLengthAutomatically")
				}
			/>
			<span>Calculate Chapter Length Automatically</span>
		</Box>

		<Box ml={18}>
			<StyledCheckbox
				checked={progressTrackingEnabled}
				onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
					updateVal(event.target.checked, "progressTrackingEnabled")
				}
			/>
			<span>Enable Progress Tracking</span>
		</Box>
		<Box display={"flex"} alignItems={"center"} mb={7}>
			<Box width={120} mr={3}>
				<Typography align="right">Extra</Typography>
			</Box>
			<Box width={350}>
				<StyledCheckbox
					checked={excludedFromCourseRequirement}
					onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
						updateVal(event.target.checked, "excludedFromCourseRequirement")
					}
				/>
				<span>Exclude from course requirement </span>
			</Box>
		</Box>
		<ButtonContainer>
			<TransparentButton variant="text" onClick={cancel}>
				Cancel
			</TransparentButton>
			<Button
				variant={"contained"}
				color={"primary"}
				style={{ fontWeight: "bold" }}
				disabled={disabledSaveBtn || !!(name.trim().length < 2)}
				onClick={save}
			>
				Save
			</Button>
		</ButtonContainer>
	</>
);

export default EditFields;
