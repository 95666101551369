import { Box, ButtonBase, Card, Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as CloseIconSvg } from "@remar/shared/dist/assets/icons/icon-close-circle.svg";
import { ReactComponent as DeleteIconSvg } from "@remar/shared/dist/assets/icons/icon-trash-filled.svg";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled from "styled-components";

import { ReactComponent as PlusIconSvg } from "assets/icons/icon-plus.svg";

export const useCourseAddEditStyles = makeStyles((theme: IExtendedTheme) => ({
	breadcrumb: {
		color: "rgba(255, 255, 255, 0.6)",
		fontSize: theme.typography.pxToRem(13)
	},
	breadcrumbActive: {
		color: theme.palette.colors.info[750],
		fontSize: theme.typography.pxToRem(13)
	},
	cancelButton: {
		backgroundColor: theme.palette.colors.basic[900],
		// backgroundColor: "#22252d",
		color: "rgba(255, 255, 255, 0.6)"
	},
	iconButton: {
		cursor: "pointer"
	},
	stripeSummaryBox: {
		border: "1px solid #393e4b",
		borderRadius: theme.typography.pxToRem(4),
		justifyContent: "center",
		padding: theme.typography.pxToRem(25),
		"& .row": {
			justifyContent: "space-between",
			"& .row-title": {
				maxWidth: "45%"
			},
			"& .row-content": {
				fontWeight: "bold",
				textAlign: "end"
			}
		}
	},
	title: {
		fontFamily: "var(--poppins)",
		fontSize: theme.typography.pxToRem(18)
	},
	uploadedFiles: {
		fontSize: "12px",
		fontWeight: "bold",
		lineHeight: 1.33,
		color: "rgba(255, 255, 255, 0.6)",
		marginBottom: "16px",
		textTransform: "uppercase"
	}
}));

export const CourseFeatures = styled(Box)`
	width: 600px;
	height: 48px;
	display: flex;
	margin: 12px 0 8px;
	padding: 12px 16px;
	border-radius: 4px;
	background-color: #2a2e37;
	color: white;
	opacity: 0.87;
	> span {
		flex: 1;
	}
`;

export const FeatureText = styled.span`
	width: calc(100% - 70px);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export const StyledCard = styled(Card)`
	padding: 0;
	margin: 20px 0;
`;
export const BookFormWrapper = styled(Box)`
	padding: 0 20px;
`;

export const AddBookWrapper = styled(ButtonBase)`
	display: flex;
	justify-content: center;
	padding: 20px;
	width: 100%;
	& p {
		margin-left: 5px;
	}
`;
export const BooksHeader = styled(Typography)`
	font-size: 15px;
	font-family: var(--openSans);
	margin: 20px;
`;

export const AddIcon = styled(PlusIconSvg)`
	path {
		fill: ${({ theme }) => theme.palette.colors.basic[1300]};
	}
`;
export const RemoveIcon = styled(DeleteIconSvg)`
	path {
		fill: ${({ theme }) => theme.palette.colors.basic[1300]};
	}
`;
export const CourseFeaturesSubHeading = styled(Typography)`
	font-weight: 500;
	opacity: 0.38;
	color: #fff;
`;
export const DeleteFeature = styled(CloseIconSvg)`
	cursor: pointer;
	margin-left: 10px;
`;

export const DragFeatureContainer = styled(Box)`
	cursor: auto !important;
	width: fit-content;
`;

export const MarginTopZeroContainer = styled(Box)`
	display: flex;
	align-items: center;
	flex-direction: row;
`;

export const MarginTopOneContainer = styled(Box)`
	display: flex;
	margin-top: 8px;
	align-items: center;
	flex-direction: row;
`;

export const MarginTopTwoContainer = styled(Box)`
	display: flex;
	margin-top: 16px;
	align-items: center;
	flex-direction: row;
`;

export const CourseItemTitleContainer = styled(Box)`
	width: 120px;
	margin-right: 24px;
`;
