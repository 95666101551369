import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, IconButton, Modal, Typography, useTheme } from "@material-ui/core";

import {
	Assignment,
	Check,
	ChevronLeft,
	ChevronRight,
	Close,
	Computer,
	People,
	Person,
	Today
} from "@material-ui/icons";
import { BarChart, Doughnut } from "@remar/shared/dist/components/Charts";
import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import InfoBlock from "@remar/shared/dist/components/InfoBlock";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import convertMinsToHrsMins from "@remar/shared/dist/utils/convertMinsToHrsMins";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import { fetchAllCourses, getFullState as getCoursesState } from "store/features/Course/course.slice";
import {
	getFullState,
	getSignInReport,
	getSummaryReport,
	getTestReport,
	getTrainingReport
} from "store/features/Reports/Reports.slice";

import { AverageIconSvg, HalfFilledCircle, TimeIconSvg, TotalIconSvg } from "assets/icons";

import {
	CheckboxContainer,
	Container,
	Header,
	HeaderContainer,
	Heading,
	IconWrapper,
	LabelText,
	ModuleContainer,
	ModuleWrapper,
	TitleText,
	TotalText
} from "./styles";

import { StyledCheckbox } from "../Courses/Chapters/styles";
import { CheckboxLabel } from "../FileVault/styles";

type BlockDataType = {
	title: string;
	icon: React.ReactNode;
	value: number;
};
type AggregateType = {
	title: string;
	totalTitle: string;
	barData: {
		labels: string[];
		datasets: Array<{
			backgroundColor: string;
			borderColor: string;
			data: number[];
		}>;
	};
	donutData: {
		labels: string[];
		datasets: Array<{
			data: number[];
			backgroundColor: string[];
		}>;
	};
	blockData: Array<BlockDataType>;
	totalIcon: React.ReactNode;
	total: number;
};

const Reports = () => {
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useDispatch();

	const { courses } = useSelector(getCoursesState);
	const { summaryReport, loginChart, testChart, trainingChart } = useSelector(getFullState);

	const SummaryStat = useMemo(
		() => [
			{
				title: "Total Sign Ins",
				value: summaryReport.loginCount,
				icon: TotalIconSvg,
				bgColor: "hsl(216, 41%, 13%)"
			},
			{
				title: "Average Passing Percentage",
				value: summaryReport.quizPassingPercentage + "%",
				icon: AverageIconSvg,
				bgColor: "hsl(307, 32%, 15%)"
			},
			{
				title: "Total Time Spent Training",
				value: convertMinsToHrsMins(summaryReport.timeSpentTrainingInMinutes),
				icon: TimeIconSvg,
				bgColor: "hsl(154, 90%, 8%)"
			}
		],
		[summaryReport]
	);

	const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() - 1);
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [openCourseSelectionModal, setOpenCourseSelectionModal] = useState(false);
	const [showDatePickerModal, setShowDatePickerModal] = useState(false);
	const [checkedCourses, setCheckedCourses] = useState<number[]>([]);
	const [date, setDate] = useState(new Date());

	useEffect(() => {
		dispatch(fetchAllCourses());
	}, [dispatch]);

	useEffect(() => {
		if (checkedCourses) {
			dispatch(getSummaryReport({ courseId: checkedCourses![0], startDate: date.toDateString(), endDate: "" }));
		}
	}, [checkedCourses, date, dispatch]);

	useEffect(() => {
		dispatch(getSignInReport({ month: currentMonth + 1, year: currentYear }));
		dispatch(getTrainingReport({ month: currentMonth + 1, year: currentYear }));
		dispatch(getTestReport({ month: currentMonth + 1, year: currentYear }));
	}, [currentMonth, currentYear, dispatch]);

	const monthName = useMemo(() => {
		return format(new Date(currentYear, currentMonth - 1, 1), "MMM");
	}, [currentMonth, currentYear]);

	const dataSource = useMemo(() => {
		const stats = [
			{
				title: "Sign In",
				dataPoints: loginChart.dataPoints,
				dataSets: [
					{
						name: "Signed In",
						icon: (
							<IconWrapper bgColor="#2ed14c">
								<Check style={{ fill: "#2ed14c" }} />
							</IconWrapper>
						),
						value: loginChart.summary.active
					},
					{
						name: "Not Signed In",
						icon: (
							<IconWrapper bgColor="#ed5466">
								<Close style={{ fill: "#ed5466" }} />
							</IconWrapper>
						),
						value: loginChart.summary.inactive
					}
				],
				totalIcon: (
					<IconWrapper bgColor="#4898f9">
						<People style={{ fill: "#4898f9" }} />
					</IconWrapper>
				),
				totalValue: loginChart.summary.total,
				totalTitle: "Total Sign In"
			},
			{
				title: "Training Summary",
				dataPoints: trainingChart.dataPoints,
				dataSets: [
					{
						name: "Trained",
						icon: (
							<IconWrapper bgColor="#2ed14c">
								<Check style={{ fill: "#2ed14c" }} />
							</IconWrapper>
						),
						value: trainingChart.summary.active
					},
					{
						name: "Didn’t Train",
						icon: (
							<IconWrapper bgColor="#ed5466">
								<Close style={{ fill: "#ed5466" }} />
							</IconWrapper>
						),
						value: trainingChart.summary.inactive
					}
				],
				totalIcon: (
					<IconWrapper bgColor="#4898f9">
						<People style={{ fill: "#4898f9" }} />
					</IconWrapper>
				),
				totalValue: trainingChart.summary.total,
				totalTitle: "Total Trained"
			},
			{
				title: "Test Summary",
				dataPoints: testChart.dataPoints,
				dataSets: [
					{
						name: "Passed",
						icon: (
							<IconWrapper bgColor="#2ed14c">
								<Check style={{ fill: "#2ed14c" }} />
							</IconWrapper>
						),
						value: testChart.summary.passed
					},
					{
						name: "Failed",
						icon: (
							<IconWrapper bgColor="#ed5466">
								<Close style={{ fill: "#ed5466" }} />
							</IconWrapper>
						),
						value: testChart.summary.failed
					},
					{
						name: "In-Progress",
						icon: (
							<IconWrapper bgColor="#FFD044">
								<HalfFilledCircle fill="#FFD044" />
							</IconWrapper>
						),
						value: testChart.summary.inProgress
					}
				],
				totalIcon: (
					<IconWrapper bgColor="#4898f9">
						<Assignment style={{ fill: "#4898f9" }} />
					</IconWrapper>
				),
				totalValue: testChart.summary.total,
				totalTitle: "Total Test Attempts"
			}
		];

		return stats.map(({ dataSets, dataPoints, title, totalTitle, totalIcon, totalValue }) => {
			const range = [...Array(dataPoints.length).keys()];
			const labels = range.map(i => `${monthName} ${i + 1}`);
			const dataValues = dataPoints;

			const blockData: BlockDataType[] = dataSets.map(d => ({
				title: d.name,
				icon: d.icon,
				value: d.value
			}));

			return {
				title,
				totalTitle,
				totalIcon,
				barData: {
					labels,
					datasets: [
						{
							backgroundColor: "hsl(213, 94%, 63%)",
							borderColor: "hsl(223, 14%, 26%)",
							data: dataValues
						}
					]
				},
				donutData: {
					labels: dataSets.map(({ name }) => name),
					datasets: [
						{
							data: blockData.map(b => b.value),
							backgroundColor: ["hsl(131, 64%, 50%)", "hsl(353, 81%, 63%)", "hsl(45, 100%, 63%)"]
						}
					]
				},
				blockData,
				total: totalValue
			};
		}) as AggregateType[];
	}, [
		loginChart.dataPoints,
		loginChart.summary.active,
		loginChart.summary.inactive,
		loginChart.summary.total,
		monthName,
		testChart.dataPoints,
		testChart.summary.failed,
		testChart.summary.inProgress,
		testChart.summary.passed,
		testChart.summary.total,
		trainingChart.dataPoints,
		trainingChart.summary.active,
		trainingChart.summary.inactive,
		trainingChart.summary.total
	]);

	return (
		<Container>
			<HeaderContainer>
				<Header>
					<Heading>Reports</Heading>
				</Header>
			</HeaderContainer>
			<ModuleContainer container>
				<ModuleWrapper item xs={12} spacing={2}>
					<Box className="report-card" display="flex" gridGap={16} justifyContent="space-between">
						<Box className="filter-container" gridGap={16}>
							<Person />
							<Box className="filter-selector">
								<TotalText>All</TotalText>
								<TitleText className="filter-title">Students</TitleText>
							</Box>
						</Box>
						<Box className="filter-container">
							<Today />
							<Box className="filter-selector">
								<TotalText className="filter-selected">{format(date, "dd LLL yyyy")}</TotalText>
								<TitleText className="filter-title">Report Dates</TitleText>
							</Box>
							<Button variant="outlined" color="primary" onClick={() => setShowDatePickerModal(true)}>
								Change
							</Button>
						</Box>
						<Box className="filter-container">
							<Computer />
							<Box className="filter-selector">
								<TotalText className="filter-selected">All Courses</TotalText>
								<TitleText className="filter-title">Student’s Course</TitleText>
							</Box>
							<Button variant="outlined" color="primary" onClick={() => setOpenCourseSelectionModal(true)}>
								Change
							</Button>
						</Box>
					</Box>
				</ModuleWrapper>
				<ModuleWrapper item xs={12} spacing={2}>
					<Box display="flex" gridGap={16} justifyContent="space-between">
						{SummaryStat.map(({ title, value, icon, bgColor }) => (
							<InfoBlock
								key={title}
								containerCustomStyle={{ maxWidth: "100%" }}
								text={value}
								title={title}
								IconSvg={icon}
								customBgColor={bgColor}
							/>
						))}
					</Box>
				</ModuleWrapper>
				{dataSource.map(({ title, donutData, barData, blockData, total, totalTitle, totalIcon }) => (
					<ModuleWrapper key={title} item xs={12} spacing={2}>
						<Box className="report-card" display="flex">
							<Box width="50%" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
								<Typography>{title}</Typography>
								<Box my={2}>
									<Doughnut
										options={{ cutout: 90, plugins: { legend: { display: false }, title: { display: false } } }}
										data={donutData}
									/>
								</Box>
								<Box
									display="flex"
									padding={2}
									borderRadius={4}
									justifyContent="space-between"
									width="100%"
									bgcolor={theme.palette.common.black}
								>
									{blockData.map(b => (
										<Box key={b.title} display="flex" alignItems="center" gridGap={16}>
											{b.icon}
											<Box>
												<Box display="flex" gridGap={8} alignItems="center">
													<TotalText>{b.value}</TotalText>
													<TitleText>{Math.round((b.value / total) * 100)}%</TitleText>
												</Box>
												<TitleText>{b.title}</TitleText>
											</Box>
										</Box>
									))}
									<Box display="flex" alignItems="center" gridGap={16}>
										{totalIcon}
										<Box display="flex" flexDirection="column">
											<TotalText>{total}</TotalText>
											<TitleText>{totalTitle}</TitleText>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box width="50%">
								<Box padding="0 24px 20px 24px" bgcolor={theme.palette.background.default} mx={2} borderRadius={2}>
									<Box height={64} display="flex" justifyContent="space-between" alignItems="center">
										<Typography>{title}</Typography>
										<Box display="flex" maxWidth={200} justifyContent="space-around" alignItems="center">
											<Typography style={{ marginRight: "20px" }}>
												{montName} {currentYear}
											</Typography>
											<ChevronLeft
												cursor="pointer"
												onClick={() => {
													if (currentMonth < 1) {
														setCurrentMonth(11);
														setCurrentYear(s => s - 1);
													} else {
														setCurrentMonth(s => s - 1);
													}
												}}
											/>
											<ChevronRight
												cursor="pointer"
												onClick={() => {
													if (currentMonth > 10) {
														setCurrentYear(s => s + 1);
														setCurrentMonth(0);
													} else {
														setCurrentMonth(s => s + 1);
													}
												}}
											/>
										</Box>
									</Box>
									<BarChart
										options={{ plugins: { legend: { display: false }, title: { display: false } } }}
										data={barData}
									/>
								</Box>
							</Box>
						</Box>
					</ModuleWrapper>
				))}
			</ModuleContainer>
			{showDatePickerModal && (
				<Modal open={showDatePickerModal} onClose={() => setShowDatePickerModal(false)}>
					<Box display="flex" justifyContent="center" alignItems="center" height="100%">
						<Box margin="auto" width={400} minHeight={426} bgcolor={theme.palette.background.default} padding={4}>
							<Box position="relative" display="flex" justifyContent="space-between">
								<Heading>Select Date(s) For Report</Heading>
								<IconButton size="small" onClick={() => setShowDatePickerModal(false)}>
									<Close />
								</IconButton>
							</Box>
							<Box mt={4}>
								<DatePicker
									autoOk
									open={false}
									disableToolbar
									orientation="portrait"
									variant="static"
									openTo="date"
									value={date}
									onChange={setDate}
								/>
							</Box>
							<Box display="flex" justifyContent="space-between" width={300} mt={4} mx="auto">
								<Button variant="outlined" color="primary">
									Cancel
								</Button>
								<Button variant="contained" color="primary" onClick={() => setShowDatePickerModal(false)}>
									Approve
								</Button>
							</Box>
						</Box>
					</Box>
				</Modal>
			)}
			{openCourseSelectionModal && (
				<Modal open={openCourseSelectionModal} onClose={() => setOpenCourseSelectionModal(false)}>
					<Box display="flex" justifyContent="center" alignItems="center" height="100%">
						<Box margin="auto" width={400} minHeight={426} bgcolor={theme.palette.background.default} padding={4}>
							<Box position="relative" display="flex" justifyContent="space-between">
								<Heading>Select Course(s)</Heading>
								<IconButton size="small" onClick={() => setOpenCourseSelectionModal(false)}>
									<Close />
								</IconButton>
							</Box>
							<Box mt={4}>
								<TotalText>Courses</TotalText>
								<Box mt={2}>
									<CheckboxContainer>
										<StyledCheckbox
											color="primary"
											checked={checkedCourses.length === courses!.length}
											onChange={() =>
												checkedCourses.length === courses!.length
													? setCheckedCourses([])
													: setCheckedCourses(courses!.map(({ id }) => id))
											}
										/>
										<CheckboxLabel>
											<LabelText>All</LabelText>
										</CheckboxLabel>
									</CheckboxContainer>
									<hr style={{ width: "40px", float: "left", marginLeft: "10px" }} />
									<br />
									{courses?.map(c => (
										<CheckboxContainer key={c.id}>
											<StyledCheckbox
												color="primary"
												checked={checkedCourses.includes(c.id)}
												onChange={() =>
													checkedCourses.includes(c.id)
														? setCheckedCourses(s => s.filter(i => i !== c.id))
														: setCheckedCourses(s => [...s, c.id])
												}
												theme={theme}
												options={{ inputData: { value: true, checked: true } }}
											/>
											<CheckboxLabel>
												<LabelText>{c.name}</LabelText>
											</CheckboxLabel>
										</CheckboxContainer>
									))}
								</Box>
							</Box>
						</Box>
					</Box>
				</Modal>
			)}
		</Container>
	);
};

export default Reports;
