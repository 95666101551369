import React from "react";

import { Modal } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import { QuestionTypes } from "@remar/shared/dist/constants";

import BowTieQuestionPreview from "./Components/BowTieQuestionPreview";
import CaseStudyQuestionPreview from "./Components/CaseStudyQuestionPreview";
import ClozeDropDownQuestionPreview from "./Components/ClozeDropDownQuestionPreview";
import DragAndDropQuestionPreview from "./Components/DragAndDropQuestionPreview";
import DropDownTableQuestionPreview from "./Components/DropDownTableQuestionPreview";
import HighlightTableQuestionPreview from "./Components/HighlightTableQuestionPreview";
import HotspotHighlightQuestionPreview from "./Components/HotspotHighlightQuestionPreview";
import MatrixMultipleChoicePreview from "./Components/MatrixMultipleChoicePreview";
import MatrixSingleChoiceQuestionPreview from "./Components/MatrixSingleChoiceQuestionPreview";
import MultipleChoiceQuestionPreview from "./Components/MultipleChoiceQuestionPreview";
import MultipleResponseGroupQuestionPreview from "./Components/MultipleResponseGroupQuestionPreview";
import SingleChoiceQuestionPreview from "./Components/SingleChoiceQuestionPreview";
import { useStyles } from "./styles";

const PreviewQuestion = ({ question, onClose }) => {
	const classes = useStyles();

	const getQuestionType = question => {
		switch (question.typeId) {
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixMultipleChoicePreview question={question} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return <MultipleChoiceQuestionPreview question={question} />;
			case QuestionTypes.SingleChoice:
				return <SingleChoiceQuestionPreview question={question} />;
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceQuestionPreview question={question} />;
			case QuestionTypes.CaseStudy:
				return <CaseStudyQuestionPreview question={question} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightQuestionPreview question={question} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableQuestionPreview question={question} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupQuestionPreview question={question} />;
			case QuestionTypes.BowTie:
				return <BowTieQuestionPreview question={question} />;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return <DragAndDropQuestionPreview question={question} />;
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return <ClozeDropDownQuestionPreview question={question} />;
			case QuestionTypes.DropDownTable:
				return <DropDownTableQuestionPreview question={question} />;
			default:
				return null;
		}
	};

	return (
		<Modal open onClose={onClose} className={classes.modal}>
			<div className={classes.scroll}>
				<div className={classes.content}>
					<div className={classes.header}>
						<div className={classes.title}>Question Preview</div>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</div>
					<div className={classes.questionContainer}>
						<div className={classes.row}>
							<div className={classes.col}>
								<div className={classes.questionNumber}>Question</div>
								{getQuestionType(question)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default PreviewQuestion;
