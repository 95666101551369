import { Avatar as DefaultAvatar, Menu as DefaultMenu } from "@material-ui/core";

import styled from "styled-components";

export const Menu = styled(DefaultMenu)`
	.MuiPaper-root {
		color: white;
		background-color: ${({ theme }) => theme.palette.colors.basic[800]};
		.MuiListItem-gutters {
			display: flex;
			justify-content: start;
			padding: 6px 16px;
		}
	}
`;

export const Container = styled.div`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	background-color: ${({ theme }) => theme.palette.colors.basic[1100]};
	padding: 12px 0 12px 128px;
	z-index: 3;
	width: 100%;

	.search-icon {
		margin-left: 0px;
		padding-left: 10px;
		background-color: ${({ theme }) => theme.palette.colors.basic[1000]};
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	.MuiInputBase-root {
		color: white;
	}
`;

export const Avatar = styled(DefaultAvatar)`
	background-color: #172e4b;
	cursor: pointer;
`;

export const Actions = styled.div`
	margin-left: auto;
	display: flex;
	margin-right: 32px;
`;

export const MessageIcon = styled.div`
	margin: 8px 16px 8px 0;
`;

export const NotificationIcon = styled.div`
	margin: 6px 24px 8px 0;
`;
