import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { request } from "@remar/shared/dist/api/request";
import { LocationsModel } from "@remar/shared/dist/models/locations.model";

import { ChartData, ChartReportParams, GetSummaryReportParams, ReportSummaryDto, TestChartData } from "./dto";

import { URLS } from "../services.constants";

export class LocationsService extends BaseApiService<
	LocationsModel,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getSummaryReport(data: GetSummaryReportParams): Promise<ReportSummaryDto> {
		return request(
			`${this.baseUrl}/report?courseId=${data.courseId}&startDate=${data.startDate}&endDate=${data.endDate}`,
			"GET"
		) as Promise<ReportSummaryDto>;
	}

	getLoginChartData(data: ChartReportParams): Promise<ChartData> {
		return request(`${this.baseUrl}/login-chart?month=${data.month}&year=${data.year}`, "GET") as Promise<ChartData>;
	}

	getTrainingChartData(data: ChartReportParams): Promise<ChartData> {
		return request(`${this.baseUrl}/training-chart?month=${data.month}&year=${data.year}`, "GET") as Promise<ChartData>;
	}

	getTestChartData(data: ChartReportParams): Promise<TestChartData> {
		return request(`${this.baseUrl}/test-chart?month=${data.month}&year=${data.year}`, "GET") as Promise<TestChartData>;
	}
}

export const locationsService = new LocationsService(URLS.LOCATIONS);
