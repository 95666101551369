import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import {
	createForm,
	getPopulateInputsAction,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { AppThunk, RootState } from "store";

import { SubjectState, subjectLessonService, subjectService } from "store/services";

import { SubjectFormInputs, SubjectFormRawData, SubjectLessonFormInputs, SubjectLessonFormRawData } from "./models";

import { emit } from "../notifications/notifications.slice";

export const fetchSubjects = createAsyncThunk(
	"subjects/fetchSubjects",
	async (options: { page?: number; perPage?: number }, { dispatch, getState }) => {
		try {
			const { isLoading, page, perPage } = (getState() as RootState).subjects;
			if (!isLoading) {
				await dispatch(setStateValue({ key: "isLoading", value: true }));
			}
			const { page: optPage, perPage: optPerPage } = options;
			const {
				page: newPage,
				perPage: newPerPage,
				items,
				totalItems
			} = await subjectService.find({
				page: optPage || page,
				perPage: optPerPage || perPage,
				orderBy: { id: "DESC" },
				include: ["lessons"]
			});
			dispatch(setStateValue({ key: "page", value: newPage }));
			dispatch(setStateValue({ key: "perPage", value: newPerPage }));
			dispatch(setStateValue({ key: "subjects", value: items }));
			dispatch(setStateValue({ key: "totalItems", value: totalItems }));
		} catch (e) {
			dispatch(emit({ message: "An error has occured.", color: "error" }));
		} finally {
			await dispatch(setStateValue({ key: "isLoading", value: false }));
		}
	}
);

export const createSubject = createAsyncThunk(
	"subjects/createSubject",
	async (onClose: () => void, { dispatch, getState }) => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		try {
			const data = (getState() as RootState).subjects.subjectForm.rawData;
			if (data.minimumPercentage >= data.maximumPercentage) {
				throw new Error("Maximum percentage must be greater than minimum");
			} else {
				onClose();
			}
			if (data.id) {
				const { id, ...rest } = data;
				await subjectService.update({ filters: { id }, data: { ...rest } });
			} else await subjectService.create({ ...data });
			await dispatch(setStateValue({ key: "subjectForm", value: initialState.subjectForm }));
			dispatch(fetchSubjects({}));
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			await dispatch(setStateValue({ key: "isLoading", value: false }));
		}
	}
);

export const createLesson = createAsyncThunk("subjects/createLesson", async (_, { dispatch, getState }) => {
	await dispatch(setStateValue({ key: "isLoading", value: true }));
	try {
		const data = (getState() as RootState).subjects.lessonForm.rawData;
		if (data.id) {
			const { id, ...rest } = data;
			await subjectLessonService.update({ filters: { id }, data: { ...rest } });
		} else await subjectLessonService.create({ ...data });
	} catch (e) {
		dispatch(emit({ message: e.message, color: "error" }));
	} finally {
		await dispatch(setStateValue({ key: "lessonForm", value: initialState.lessonForm }));
		dispatch(fetchSubjects({}));
	}
});

export const initialState: SubjectState = {
	isLoading: false,
	path: [],
	subjects: [],
	page: 1,
	perPage: 10,
	totalItems: 0,
	subjectForm: createForm<SubjectFormInputs, SubjectFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "subjectForm",
		inputs: {
			id: { type: CustomInputType.Number },
			name: {
				label: "Enter Subject Name",
				placeholder: "Enter Subject Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 150 }
			},
			description: {
				label: "Description for CAT",
				placeholder: "Enter Description",
				type: CustomInputType.Editor,
				validations: { required: true }
			},
			minimumPercentage: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isNumber: true, max: 100, min: 0, required: true }
			},
			maximumPercentage: {
				label: "Max % for CAT",
				defaultValue: 100,
				type: CustomInputType.Number,
				validations: { isNumber: true, max: 100, min: 0, required: true }
			},
			isAvailableForTrial: {
				defaultValue: false,
				label: "Free Trial Only",
				type: CustomInputType.Checkbox
			}
		}
	}),
	lessonForm: createForm<SubjectLessonFormInputs, SubjectLessonFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "lessonForm",
		inputs: {
			id: { type: CustomInputType.Number },
			subjectId: { type: CustomInputType.Number },
			description: { type: CustomInputType.Text },
			name: {
				label: "Enter Lesson Name",
				placeholder: "Enter Lesson Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 140 }
			}
		}
	})
};

export const deleteSubject =
	(id: number): AppThunk =>
	async dispatch => {
		try {
			dispatch(setStateValue({ key: "isLoading", value: true }));
			await subjectService.delete({ filters: { id } });
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			dispatch(fetchSubjects({}));
		}
	};

export const deleteLesson =
	(id: number): AppThunk =>
	async dispatch => {
		try {
			dispatch(setStateValue({ key: "isLoading", value: true }));
			await subjectLessonService.delete({ filters: { id } });
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			dispatch(fetchSubjects({}));
		}
	};

export const getFullState = ({ subjects }: RootState): SubjectState => subjects;
const utilsPopulateInputs = getPopulateInputsAction<SubjectState>({});

export const subjectSlice = createSlice({
	name: "subject",
	initialState,
	reducers: {
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		populateInputs: utilsPopulateInputs
	}
});

export const { validateForm, setStateValue, populateInputs } = subjectSlice.actions;

export default subjectSlice.reducer;
