import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled from "styled-components";

export const useStyles = makeStyles((theme: IExtendedTheme) => ({
	chips: {
		padding: "5px 10px",
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: 2,
		background: `${theme.palette.colors.primary[500]}`
	},
	multiSelectBox: {
		maxWidth: "400px"
	},
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}
}));

export const THeaderWrapper = styled(Box)`
	display: flex;
	align-items: center;
	background-color: #22252d;
	padding: 20px;
	border-radius: 4px;
`;

export const VoteText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.basic[1250]};
	font-family: "Open Sans", "Roboto", sans-serif;
	line-height: 1.846;
	font-size: 13px;
	font-weight: 600;
	padding: 10px 0px;
`;
