import React, { useState } from "react";

import { Box, Button, CircularProgress } from "@material-ui/core";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";
import { LocationPackagesTypeEnum } from "@remar/shared/dist/constants";
import { Package as LocationPackage } from "@remar/shared/dist/models";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import {
	deletePackage,
	getFullState,
	publishPackage,
	resetState,
	setStateValue,
	validateForm
} from "store/features/ManagePackage/managePackage.slice";
import { _emit } from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";
import theme from "theme/default";

import { CustomInput } from "../../FileVault/styles";
import {
	CancelButton,
	CheckedIcon,
	CheckedList,
	CheckedListItem,
	ConfirmButton,
	DeletePackage,
	EditPackageContainer,
	EditPackageIcon,
	Indicator,
	ItemName,
	PackageArea,
	PackageContainer,
	PackageName,
	PackagePrice,
	PackageStatus,
	PackageSubHeading,
	PublishOrUnpublishButton,
	Spacer,
	StatusText,
	SubHeader
} from "../PackageOverview/styles";

interface IAddons {
	loader: boolean;
	name: string;
}
interface Package {
	description: string[];
	showStatus?: boolean;
	showDeletePackage?: boolean;
	showActionButtons?: boolean;
	showEdit?: boolean;
	saveAction?: () => void;
	initialIsLoading?: boolean;
	recurringIsLoading?: boolean;
	initialIsLoadingPerSeat?: boolean;
	recurringIsLoadingPerSeat?: boolean;
	initialPrice?: string;
	recurringPrice?: string;
	initialPricePerSeat?: string;
	recurringPricePerSeat?: string;
	packageName?: string;
	packageId?: number;
	status?: boolean;
	locationPackageTypeId?: number;
	courseId?: number;
	locationPackage?: LocationPackage;
	canSave?: boolean;
	addons?: IAddons[];
	initialDuration: string;
	recurringDuration: string;
	initialDurationPerSeat: string;
	recurringDurationPerSeat: string;
}

const externalIntegrationDataRowLoader = (
	<CircularProgress size="1.4rem" color="primary" thickness={5} variant="indeterminate" />
);

const Package = ({
	description,
	showStatus = true,
	showActionButtons = false,
	showEdit = true,
	showDeletePackage = true,
	saveAction,
	locationPackage,
	locationPackageTypeId,
	courseId,
	initialIsLoading,
	recurringIsLoading,
	initialIsLoadingPerSeat,
	recurringIsLoadingPerSeat,
	initialPrice,
	recurringPrice,
	initialPricePerSeat,
	recurringPricePerSeat,
	packageName,
	packageId,
	status,
	initialDuration,
	initialDurationPerSeat,
	addons
}: Package) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { isDeleteLoading, isPublishLoading, isSaveLoading, userSubscriptionTypesForm } = useSelector(getFullState);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const showTrialSwitch = locationPackageTypeId === LocationPackagesTypeEnum.Package12 && !showEdit;
	return (
		<>
			<Box>
				<PackageContainer>
					<PackageArea>
						<PackageName>{packageName}</PackageName>
						{showEdit && (
							<EditPackageContainer
								onClick={() => {
									history.push({
										pathname: `${routes.manageLocations.getPath()}/package-overview/manage-package/${courseId}/${locationPackageTypeId}/edit`,
										state: locationPackage
									});
								}}
							>
								<EditPackageIcon width="15px" height="15px" />
							</EditPackageContainer>
						)}
					</PackageArea>
					<Spacer />
					<Box p={"20px"}>
						<Box>
							<PackageSubHeading>Package Price</PackageSubHeading>
							{initialIsLoading ? (
								<PackagePrice>{externalIntegrationDataRowLoader}</PackagePrice>
							) : (
								<PackagePrice>{initialPrice || "-"}</PackagePrice>
							)}
						</Box>
						<Box mt={2}>
							<PackageSubHeading>Reccuring monthly package price</PackageSubHeading> After {initialDuration || "-"}
							{recurringIsLoading ? (
								<PackagePrice>{externalIntegrationDataRowLoader}</PackagePrice>
							) : (
								<PackagePrice>{recurringPrice || "-"}</PackagePrice>
							)}
						</Box>
					</Box>
					<Spacer />
					<Box p={"20px"}>
						<Box>
							<PackageSubHeading>Price per seat</PackageSubHeading>
							{initialIsLoadingPerSeat ? (
								<PackagePrice>{externalIntegrationDataRowLoader}</PackagePrice>
							) : (
								<PackagePrice>{initialPricePerSeat || "-"}</PackagePrice>
							)}
						</Box>
						<Box mt={2}>
							<PackageSubHeading>Recurring monthly seat price</PackageSubHeading> After {initialDurationPerSeat || "-"}
							{recurringIsLoadingPerSeat ? (
								<PackagePrice>{externalIntegrationDataRowLoader}</PackagePrice>
							) : (
								<PackagePrice>{recurringPricePerSeat || "-"}</PackagePrice>
							)}
						</Box>
					</Box>
					{description.length > 0 && (
						<>
							<Spacer />
							<CheckedList>
								{description.map((point, i) => (
									<CheckedListItem key={i}>
										<CheckedIcon fontSize="small" />
										<ItemName title={point}> {point} </ItemName>
									</CheckedListItem>
								))}
							</CheckedList>
						</>
					)}
					{addons && addons.length > 0 && (
						<>
							<Spacer />
							<SubHeader>Books</SubHeader>
							<CheckedList>
								{addons?.map(({ name, loader }, i) => (
									<CheckedListItem key={i}>
										<CheckedIcon fontSize="small" />
										<ItemName title={name}>{loader ? externalIntegrationDataRowLoader : name}</ItemName>
									</CheckedListItem>
								))}
							</CheckedList>
						</>
					)}

					{showTrialSwitch && (
						<>
							<Spacer />
							<Box p={"20px"}>
								<Box>
									<CustomInput
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: userSubscriptionTypesForm.inputs.hasTrial }}
									/>
								</Box>
							</Box>
							<Spacer />
						</>
					)}
					{showStatus && (
						<>
							<Spacer />
							<PackageStatus>
								<StatusText>Package Status:</StatusText>
								<Indicator isActive={status} />
								<StatusText>{status ? "Published" : "Unpublished"}</StatusText>
								<PublishOrUnpublishButton
									variant="contained"
									size="small"
									isActive={status}
									disabled={isPublishLoading}
									onClick={() => dispatch(publishPackage({ id: packageId as number, isActive: status as boolean }))}
								>
									{isPublishLoading ? (
										<Box width={50} mt={1}>
											<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
										</Box>
									) : !status ? (
										"Publish"
									) : (
										"Unpublish"
									)}
								</PublishOrUnpublishButton>
							</PackageStatus>
						</>
					)}
					{showActionButtons && (
						<Box display={"flex"} justifyContent="center" gridGap={"10px"} p={2}>
							<Button variant="contained" color="secondary" onClick={() => setShowCancelModal(true)}>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() => saveAction && dispatch(saveAction())}
								disabled={isSaveLoading}
							>
								{isSaveLoading ? (
									<Box width={50}>
										<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
									</Box>
								) : (
									"Save"
								)}
							</Button>
						</Box>
					)}
				</PackageContainer>
				{showDeletePackage && <DeletePackage onClick={() => setShowDeleteModal(true)}>Delete Package</DeletePackage>}
			</Box>
			<SimpleModal
				open={showCancelModal}
				theme={theme}
				title={""}
				onClose={() => {
					setShowCancelModal(false);
				}}
				footer={
					<Box>
						<CancelButton onClick={() => setShowCancelModal(false)}>Cancel</CancelButton>
						<ConfirmButton
							onClick={() => {
								dispatch(resetState());
								history.push(`${routes.manageLocations.getPath()}/package-overview`);
							}}
						>
							Confirm
						</ConfirmButton>
					</Box>
				}
				text={"Are you sure you want to cancel and discard the changes?"}
			/>
			<SimpleModal
				open={showDeleteModal}
				theme={theme}
				title={"Delete Package"}
				onClose={() => {
					setShowDeleteModal(false);
				}}
				footer={
					<Box>
						<CancelButton onClick={() => setShowDeleteModal(false)} isDelete>
							Cancel
						</CancelButton>
						<ConfirmButton
							onClick={() => dispatch(deletePackage(+packageId! as number))}
							isDelete
							disabled={isDeleteLoading}
						>
							{isDeleteLoading ? (
								<Box width={50}>
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								</Box>
							) : (
								"Delete Package"
							)}
						</ConfirmButton>
					</Box>
				}
				text={"Are you sure you want to delete the package?"}
			/>
		</>
	);
};

export default Package;
