import { Box, Card, Avatar as DefaultAvatar, Input as MuiInput, Typography, makeStyles } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled from "styled-components";

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;

	.breadcrum-container {
		margin-top: 6px;

		div {
			font-size: 13px;
			line-height: 1.23;
			color: rgba(255, 255, 255, 0.6);
		}

		div:last-child {
			color: #ffffff;
		}
	}
`;

export const HeaderContainer = styled.div`
	margin: 24px 32px 0 24px;
	display: flex;
`;

export const Heading = styled(Typography)`
	opacity: 0.87;
	font-size: 18px;
	font-weight: bold;
	font-family: var(--poppins);
	line-height: 1.33;
	color: #ffffff;
`;

export const Header = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
`;

export const THeaderWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #22252d;
	padding: 20px;
	border-radius: 4px;
`;

export const StyledCellText = styled(Typography)`
	word-break: break-all;
	padding: 0 0 0 15px;
	align-self: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #ffffff;
	font-size: 13px;
`;

export const Avatar = styled(DefaultAvatar)`
	background-color: #172e4b;
	cursor: pointer;
`;

export const Input = styled(MuiInput)`
	input {
		color: white;
		padding: 12px;
	}

	padding: 10px;
	width: 100%;
	&:before {
		display: none;
	}
	&:after {
		display: none;
	}
`;

export const StyledCard = styled(Card)`
	width: 550px;
	height: 464px;
`;

export const ChangeEmailHeading = styled(Typography)`
	line-height: 1.85;
	letter-spacing: normal;
	font-weight: bold;
	color: rgba(255, 255, 255, 0.6);
`;

export const EmailInput = styled(MuiInput)`
	input {
		color: white;
		padding: 12px;
		border-radius: 5px;
		background-color: #2a2e37;
	}
	fieldset {
		border-color: #22252d !important;
	}
	padding-top: 10px;
	width: 100%;
	&:before {
		display: none;
	}
	&:after {
		display: none;
	}
`;

export const SubscriptionStatusIcon = styled.div`
	height: 7px;
	width: 7px;
	border-radius: 50%;
	background: ${props => props.theme.palette.colors[props.color][500]};
	margin: 0 4px;
`;

export const SubscriptionStatusWrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	.status {
		font-size: 13px;
	}
	.subText {
		font-size: 12px;
		color: ${props => props.theme.palette.text.secondary};
		margin-left: 15px;
	}
`;

export const useStyles = makeStyles((theme: IExtendedTheme) => ({
	title: {
		fontFamily: "var(--poppins)",
		fontSize: theme.typography.pxToRem(18),
		fontWeight: "bolder"
	},
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	card: {
		width: "auto",
		maxWidth: "88vw",
		overflowX: "auto"
	},
	save: {
		marginLeft: "10px"
	},
	course: {
		margin: "10px",
		padding: "15px",
		borderRadius: "4px",
		backgroundColor: "#2a2e37",
		width: "296px",
		minHeight: "170px"
	},
	text: {
		fontFamily: "var(--poppins)",
		fontSize: "16px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.45,
		letterSpacing: "normal",
		color: "#fff",
		opacity: 0.87,
		textTransform: "uppercase",
		whiteSpace: "initial",
		wordWrap: "break-word"
	},
	notAllowedCoursesScroll: {
		overflowX: "auto"
	},
	secondaryText: {
		fontSize: "12px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.35,
		letterSpacing: "normal",
		color: props => `${props["disabled"] ? "rgba(255, 255, 255, 0.6)" : "#4a99f9"}`,
		"&>span": {
			cursor: props => `${props["disabled"] ? "auto" : "pointer"}`
		}
	},
	secondaryTextForAssignCourse: {
		fontSize: "12px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.35,
		letterSpacing: "normal",
		color: "#4a99f9",
		"&>span": {
			cursor: "pointer"
		}
	},
	smallHeading: {
		fontFamily: "var(--poppins)",
		fontSize: theme.typography.pxToRem(11),
		fontWeight: "bold",
		color: "rgba(255, 255, 255, 0.6)",
		marginTop: "10px"
	},
	input: {
		margin: "12px 17px 30px 22px",
		padding: "10px 2px 0 0",
		borderRadius: "4px",
		backgroundColor: "#2a2e37"
	},
	cancelBtn: {
		backgroundColor: "transparent",
		color: "rgba(255, 255, 255, 0.6)",
		borderColor: "#6b6e75;",
		borderWidth: "1px",
		borderStyle: "solid",
		marginRight: "10px",
		lineHeight: 1.25,
		fontSize: "16px",
		fontWeight: 900,
		padding: "12px 18px 7px",
		"&>span": {
			marginBottom: "4px",
			fontSize: "16px"
		}
	},
	amountCheckbox: {
		marginBottom: "10px"
	},
	refundText: {
		color: "#898f9e",
		fontWeight: 600,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.67,
		letterSpacing: "normal"
	}
}));
