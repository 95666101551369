import React, { FC, useState } from "react";

import { useDrag } from "react-dnd";

import {
	SequenceAnswerOptionContainer,
	SequenceDrag,
	SequenceIndex,
	SequenceText
} from "../../PreviewQuestion/Components/style";

import AnswerOptionDropZone from "../SequenceAnswerOptionDropZone";
const SequenceAnswerOption: FC<{
	text: string;
	// eslint-disable-next-line no-unused-vars
	handleDrop?: (options: { oldOptionIndex: number; newOptionIndex: number; id: number }) => void;
	id: number;
	index: number;
}> = ({ text, id, index, handleDrop = () => {} }) => {
	const [canDrag, setCanDrag] = useState(false);

	const [, drag] = useDrag({
		item: { id, index },
		type: "box",
		canDrag: () => canDrag,
		end: () => setCanDrag(false)
	});
	return (
		<AnswerOptionDropZone
			canAlwaysDrop
			onDrop={data => {
				const { index: _index, id } = data;
				const payload = {
					oldOptionIndex: _index,
					newOptionIndex: index,
					id
				};
				handleDrop!(payload);
			}}
			index={index}
		>
			<SequenceAnswerOptionContainer ref={drag}>
				<SequenceIndex>{index + 1}</SequenceIndex>
				<SequenceText>{text}</SequenceText>
				<SequenceDrag onMouseDown={() => setCanDrag(true)}>=</SequenceDrag>
			</SequenceAnswerOptionContainer>
		</AnswerOptionDropZone>
	);
};

export default SequenceAnswerOption;
