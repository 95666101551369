import React, { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TestOnlyQuestionsFormAnswerOptionRawData } from "store/features/CreateLesson/models";

import { TestQuizQuestionText } from "./style";

import SequenceAnswerOption from "../../components/SequenceAnswerOption";
import AnswerOptionDropZone from "../../components/SequenceAnswerOptionDropZone";

const SequencingQuestionPreview = ({ question }) => {
	const [answerOptions, setAnswerOptions] = useState<TestOnlyQuestionsFormAnswerOptionRawData[]>([]);

	useEffect(() => {
		const _answerOptions = [...question.answerOptions].sort((a, b) => {
			if (!a.order || !b.order) return 0;
			return a.order - b.order;
		});
		setAnswerOptions(_answerOptions);
	}, []);

	const reorder = (startIndex: number, endIndex: number, list?: TestOnlyQuestionsFormAnswerOptionRawData[]) => {
		const result = Array.from(list!);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const handleDrop = payload => {
		const { oldOptionIndex, newOptionIndex } = payload;
		const __answerOptions = reorder(oldOptionIndex, newOptionIndex, answerOptions);
		setAnswerOptions(__answerOptions);
	};

	return (
		<>
			<TestQuizQuestionText>{question?.text}</TestQuizQuestionText>
			<DndProvider backend={HTML5Backend}>
				<AnswerOptionDropZone
					canAlwaysDrop
					onDrop={data => {
						const { index: _index, id } = data;
						const payload = {
							oldOptionIndex: _index,
							newOptionIndex: 0,
							id
						};
						handleDrop!(payload);
					}}
					index={0}
				>
					<Box style={{ minHeight: 10 }} />
				</AnswerOptionDropZone>
				{answerOptions.map((answerOptionInputs, answerOptionIndex) => {
					const { deleted, text } = answerOptionInputs;
					if (deleted) {
						return null;
					}
					return (
						<SequenceAnswerOption
							text={text ?? ""}
							index={answerOptionIndex}
							handleDrop={payload => handleDrop!(payload)}
							id={answerOptionIndex}
							key={answerOptionIndex}
						/>
					);
				})}
			</DndProvider>
		</>
	);
};

export default SequencingQuestionPreview;
