import React, { useEffect, useState } from "react";

import {
	Box,
	Breadcrumbs,
	Button,
	CircularProgress,
	IconButton,
	InputAdornment,
	Modal,
	Typography
} from "@material-ui/core";
import { Close, NavigateNext } from "@material-ui/icons";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { LessonTypes } from "@remar/shared/dist/constants";
import { format } from "date-fns";
import { cloneDeep, findIndex } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory, useParams } from "react-router-dom";

import { CreateLessonSteps } from "store/features/CreateLesson/createLesson.constants";
import {
	deleteLesson,
	fetchData,
	getCTAButtonData,
	getFullState,
	openTestOnlyPreview,
	resetState,
	save,
	setStateValue,
	updatePublishedState,
	validateForm
} from "store/features/CreateLesson/createLesson.slice";
import { _emit } from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";
import TriangleBreadcrumbs from "modules/Components/TriangleBreadcrumbs";

import theme from "theme/default";

import LessonDetails from "./LessonDetails";
import { LessonMaterials } from "./LessonMaterials";
import TestOnlyPreview from "./TestOnlyPreview";
import { TestOnlyQuestions } from "./TestOnlyQuestions";
import VitCreation from "./VitCreation";
import VitPreview from "./VitPreview";
import { BreadcrumbText, useCreateLessonStyles } from "./createLesson.styles";

import { DeleteModalContent } from "../Courses/Chapters/DeleteModalContent";
import { Breadcrumb, BreadcrumbsTitle, ButtonsContainer, CancelButton, useStyles } from "../Courses/styles";

const CreateLesson = () => {
	const classes = useStyles();
	const createLessonClasses = useCreateLessonStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<{ currentStep?: CreateLessonSteps; lessonId?: string }>();
	const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);
	const _breadcrumb_ = [
		{
			label: CreateLessonSteps.LessonDetails,
			onClick: () => {
				dispatch(save({ newStep: CreateLessonSteps.LessonDetails }));
			}
		},
		{
			label: CreateLessonSteps.LessonMaterials,
			onClick: () => {
				dispatch(save({ newStep: CreateLessonSteps.LessonMaterials }));
			}
		}
	];
	const [breadcrumb, setBreadcrumb] = useState<
		{
			label: string;
			onClick?: () => void;
		}[]
	>(_breadcrumb_);
	const [deleteModal, setDeleteModal] = useState(false);
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const state = useSelector(getFullState);
	const {
		currentStep,
		initialDataLoaded,
		isIntro,
		isLoading,
		lesson,
		lessonDetailsForm,
		lessonDraft,
		lessonId,
		saveIsLoading,
		selectedSection,
		testOnlyPreviewQuestions,
		testOnlyQuestionsForm,
		vitPreviewIsOpen,
		videoLoaded
	} = state;
	const { inputs: lessonDetailsFormInputs } = lessonDetailsForm;
	const isActive = lesson && lesson.isActive;
	const lessonType = lessonDetailsFormInputs.typeId.value;
	const CTAButtonData = useSelector(getCTAButtonData);

	useEffect(() => {
		const _breadcrumb = cloneDeep(breadcrumb);
		const lessonTypeId = lessonDetailsFormInputs.typeId.value;
		const vitBreadcrumbIndex = findIndex(_breadcrumb, el => el.label === CreateLessonSteps.LessonVIT);
		const lessonTestBreadcrumbIndex = findIndex(_breadcrumb, el => el.label === CreateLessonSteps.LessonTest);
		if (lessonTypeId === LessonTypes.Interactive) {
			if (lessonTestBreadcrumbIndex > -1) {
				_breadcrumb.splice(lessonTestBreadcrumbIndex, 1);
			}
			if (vitBreadcrumbIndex < 0) {
				_breadcrumb.push({
					label: CreateLessonSteps.LessonVIT,
					onClick: () => {
						dispatch(save({ newStep: CreateLessonSteps.LessonVIT }));
					}
				});
			}
			setBreadcrumb(_breadcrumb);
		} else if (lessonTypeId === LessonTypes.TestOnly) {
			if (vitBreadcrumbIndex > -1) {
				_breadcrumb.splice(vitBreadcrumbIndex, 1);
			}
			if (lessonTestBreadcrumbIndex < 0) {
				_breadcrumb.push({
					label: CreateLessonSteps.LessonTest,
					onClick: () => {
						dispatch(save({ newStep: CreateLessonSteps.LessonTest }));
					}
				});
			}
			setBreadcrumb(_breadcrumb);
		} else {
			if (lessonTestBreadcrumbIndex > -1) {
				_breadcrumb.splice(lessonTestBreadcrumbIndex, 1);
			}
			if (vitBreadcrumbIndex > -1) {
				_breadcrumb.splice(
					lessonTestBreadcrumbIndex > -1 && lessonTestBreadcrumbIndex < vitBreadcrumbIndex
						? vitBreadcrumbIndex - 1
						: vitBreadcrumbIndex,
					1
				);
			}
			setBreadcrumb(_breadcrumb);
		}
	}, [lessonDetailsFormInputs.typeId.value, currentStep]);

	useEffect(() => {
		if (isLoading) {
			return;
		}
		const { search } = location;
		let isIntro = false;
		let previewOpen = false;
		let selectedSectionId: number | undefined;
		if (search.length) {
			const searchData = search.replace("?", "").split("&");
			for (const i in searchData) {
				const [key, value] = searchData[i].split("=");
				if (key === "selectedSectionId") {
					selectedSectionId = parseInt(value, 10);
					continue;
				}
				if (key === "isIntro") {
					isIntro = value === "true";
					continue;
				}
				if (key === "previewOpen") {
					previewOpen = value === "true";
					window.history.replaceState(
						null,
						"",
						`${window.location.pathname}${location.search.replace("previewOpen=true", "")}`
					);
				}
			}
		}

		if (params.lessonId && (!lessonId || lessonId !== +params.lessonId)) {
			dispatch(
				fetchData({
					currentStep: params.currentStep,
					isIntro,
					lessonId: +params.lessonId,
					previewOpen,
					selectedSectionId
				})
			);
			return;
		}
		if (!initialDataLoaded) {
			dispatch(fetchData({ currentStep: params.currentStep, isIntro, previewOpen, selectedSectionId }));
		}
	}, [history.location.pathname, lessonId, initialDataLoaded]);

	useEffect(() => {
		setShowBreadcrumbs(!!lessonDetailsFormInputs.sectionIds.value!.length || !!selectedSection);
	}, [initialDataLoaded]);

	useEffect(() => {
		let _breadcrumb = cloneDeep(breadcrumb);
		if (isIntro) {
			if (_breadcrumb[0].label === CreateLessonSteps.LessonDetails) {
				_breadcrumb.splice(0, 1);
			}
		} else {
			if (_breadcrumb[0].label !== CreateLessonSteps.LessonDetails) {
				_breadcrumb = [
					{
						label: CreateLessonSteps.LessonDetails,
						onClick: () => {
							dispatch(save({ newStep: CreateLessonSteps.LessonDetails }));
						}
					}
				].concat(_breadcrumb as []);
			}
		}
		setBreadcrumb(_breadcrumb);
	}, [isIntro]);
	useEffect(() => {
		if (lessonId && !params.lessonId) {
			window.history.replaceState(null, "", `${routes.CreateLesson.getPath()}/${lessonId}`);
		}
	}, [lessonId]);

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, []);

	const actionsRow = (
		<ButtonsContainer>
			{(!isIntro || (isIntro && currentStep !== CreateLessonSteps.LessonMaterials)) && (
				<CancelButton
					variant="outlined"
					disabled={saveIsLoading}
					onClick={() => {
						if (currentStep === CreateLessonSteps.LessonMaterials) {
							dispatch(save({ newStep: CreateLessonSteps.LessonDetails }));
						} else if (currentStep === CreateLessonSteps.LessonTest) {
							dispatch(save({ newStep: CreateLessonSteps.LessonMaterials }));
						} else if (currentStep === CreateLessonSteps.LessonVIT) {
							dispatch(setStateValue({ key: "currentStep", value: CreateLessonSteps.LessonMaterials }));
						} else {
							history.goBack();
						}
					}}
				>
					{currentStep !== CreateLessonSteps.LessonDetails ? "Back" : "Cancel"}
				</CancelButton>
			)}
			{!isActive && !isIntro && (
				<Button
					variant="contained"
					disabled={!lessonId || saveIsLoading}
					className={classes.discard}
					onClick={() => setDeleteModal(true)}
				>
					Delete lesson
				</Button>
			)}
			{currentStep === CreateLessonSteps.LessonTest && (
				<Button
					variant="contained"
					color="primary"
					onClick={() => dispatch(openTestOnlyPreview({}))}
					disabled={saveIsLoading || isLoading}
				>
					{saveIsLoading ? (
						<Box width={50}>
							<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
						</Box>
					) : (
						"Preview"
					)}
				</Button>
			)}
			<Button
				variant="contained"
				color="primary"
				onClick={() => dispatch(save({ emitSuccessMessage: true }))}
				disabled={saveIsLoading || isLoading || (currentStep === CreateLessonSteps.LessonVIT && !videoLoaded)}
			>
				{saveIsLoading ? (
					<Box width={50}>
						<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
					</Box>
				) : (
					"Save"
				)}
			</Button>
			<Button
				variant="contained"
				color="primary"
				onClick={() => {
					if (currentStep === CreateLessonSteps.LessonDetails) {
						dispatch(save({ newStep: CreateLessonSteps.LessonMaterials }));
					} else if (currentStep === CreateLessonSteps.LessonMaterials) {
						if (lessonType === LessonTypes.Interactive) {
							dispatch(save({ newStep: CreateLessonSteps.LessonVIT }));
						} else if (lessonType === LessonTypes.TestOnly) {
							dispatch(save({ newStep: CreateLessonSteps.LessonTest }));
						} else {
							dispatch(updatePublishedState({ newPublishedState: !isActive }));
						}
					} else if (currentStep === CreateLessonSteps.LessonVIT || currentStep === CreateLessonSteps.LessonTest) {
						dispatch(updatePublishedState({ newPublishedState: !isActive }));
					}
				}}
				disabled={CTAButtonData.disabled || (currentStep === CreateLessonSteps.LessonVIT && !videoLoaded)}
			>
				{saveIsLoading ? (
					<Box width={50}>
						<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
					</Box>
				) : (
					CTAButtonData.label
				)}
			</Button>
		</ButtonsContainer>
	);

	const activeBreadcrumbIndex = findIndex(breadcrumb, el => {
		return el.label === currentStep;
	});

	const handleClosePreview = () => dispatch(setStateValue({ key: "vitPreviewIsOpen", value: false }));

	return (
		<>
			<Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
				<Box>
					{isIntro ? (
						<BreadcrumbsTitle>Tutorial</BreadcrumbsTitle>
					) : !lesson || !lesson.id ? (
						<BreadcrumbsTitle>Create New Lesson</BreadcrumbsTitle>
					) : (
						<BreadcrumbsTitle>
							Edit Lesson{" "}
							<Breadcrumb variant="overline">
								{lessonDraft &&
									!lessonDraft.isApplied &&
									lessonDraft.updatedAt &&
									`draft state, last update - ${format(new Date(lessonDraft.updatedAt), "LLL dd, yyyy - HH:mm")}`}
							</Breadcrumb>
						</BreadcrumbsTitle>
					)}

					{showBreadcrumbs && (
						<Box mt={1} mb={2}>
							{isIntro ? (
								<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
									<Link to={`${routes.settings.getPath()}`}>
										<Breadcrumb>Settings</Breadcrumb>
									</Link>
									<Typography className={classes.breadcrumbActive}>Tutorial</Typography>
								</Breadcrumbs>
							) : (
								<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
									<Link to={`${routes.course.getPath()}`}>
										<Breadcrumb>Courses</Breadcrumb>
									</Link>
									{selectedSection && [
										<Link
											to={`${routes.course.getPath()}/${selectedSection.chapter!.course!.id}`}
											key="createLesson-breadCrumb-0"
										>
											<Breadcrumb>{selectedSection.chapter!.course!.name}</Breadcrumb>
										</Link>,
										<Link
											to={`${routes.course.getPath()}/${selectedSection.chapter!.course!.id}/chapters/${
												selectedSection.chapter!.id
											}`}
											key="createLesson-breadCrumb-1"
										>
											<Breadcrumb>{selectedSection.chapter!.name}</Breadcrumb>
										</Link>,
										<Link
											to={`${routes.course.getPath()}/${selectedSection.chapter!.course!.id}/chapters/${
												selectedSection.chapter!.id || ""
											}`}
											key="createLesson-breadCrumb-2"
										>
											<Breadcrumb>{selectedSection.name}</Breadcrumb>
										</Link>
									]}
									<BreadcrumbText>{`${lesson && lesson.id ? "Edit" : "Create"} Lesson`}</BreadcrumbText>
								</Breadcrumbs>
							)}
						</Box>
					)}
				</Box>
				{!isLoading && actionsRow}
			</Box>
			<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" pr={4}>
				<TriangleBreadcrumbs breadcrumb={breadcrumb} activeIndex={activeBreadcrumbIndex} />
				{!isLoading && currentStep === CreateLessonSteps.LessonTest && (
					<Box display="flex" flexDirection="row" alignItems="center">
						<Typography>Duration</Typography>
						<Box display="flex" flexDirection="row" ml={6} className={createLessonClasses.durationInputsContainer}>
							<CustomInput
								theme={theme}
								width={125}
								InputProps={{
									endAdornment: <InputAdornment position="end">hour(s)</InputAdornment>
								}}
								options={{ ...defaultCustomInputOptions, inputData: testOnlyQuestionsForm.inputs.durationHour }}
							/>
							<CustomInput
								theme={theme}
								width={140}
								ml={1}
								options={{ ...defaultCustomInputOptions, inputData: testOnlyQuestionsForm.inputs.durationMin }}
								InputProps={{
									endAdornment: <InputAdornment position="end">minute(s)</InputAdornment>
								}}
							/>
						</Box>
					</Box>
				)}
			</Box>
			<Box pb={5}>
				{currentStep === CreateLessonSteps.LessonDetails && <LessonDetails />}
				{currentStep === CreateLessonSteps.LessonMaterials && <LessonMaterials />}
				{currentStep === CreateLessonSteps.LessonVIT && <VitCreation />}
				{currentStep === CreateLessonSteps.LessonTest && <TestOnlyQuestions />}
			</Box>
			<Box display="flex" flexDirection="row" justifyContent="right" pb={5}>
				{!isLoading && actionsRow}
			</Box>
			{lesson && lesson.type && (
				<Modal
					className={classes.modal}
					disableEnforceFocus
					disableAutoFocus
					open={deleteModal}
					onClose={() => setDeleteModal(false)}
				>
					<DeleteModalContent
						lessonType={lesson!.type!.name}
						onClose={() => setDeleteModal(false)}
						onDelete={(deleteVideo: boolean) => {
							(dispatch(deleteLesson(deleteVideo)) as unknown as Promise<void>).then(() =>
								history.push(
									selectedSection
										? `${routes.course.getPath()}/${selectedSection.chapter!.courseId}/chapters/${
												selectedSection.chapterId
										  }`
										: routes.course.getPath()
								)
							);
							setDeleteModal(false);
						}}
					/>
				</Modal>
			)}
			{testOnlyPreviewQuestions.length ? (
				<Modal
					disableEnforceFocus
					disableAutoFocus
					open={!!testOnlyPreviewQuestions.length}
					onClose={() => dispatch(setStateValue({ key: "testOnlyPreviewQuestions", value: [] }))}
				>
					<Box m="auto" width="85%">
						<TestOnlyPreview testOnlyPreviewQuestions={testOnlyPreviewQuestions} />
					</Box>
				</Modal>
			) : null}
			{vitPreviewIsOpen && (
				<Modal disableEnforceFocus disableAutoFocus open={vitPreviewIsOpen} onClose={handleClosePreview}>
					<Box m="auto" width="85%">
						<VitPreview onComplete={handleClosePreview} />
						<IconButton
							size="small"
							onClick={handleClosePreview}
							style={{ position: "fixed", right: "2vw", top: "2vh" }}
						>
							<Close />
						</IconButton>
					</Box>
				</Modal>
			)}
		</>
	);
};

export default CreateLesson;
