import { Box, RadioGroup, Select, Typography, createStyles, makeStyles } from "@material-ui/core";
import { basic } from "@remar/shared/dist/colors";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled, { css } from "styled-components";

export const useBowTieStyles = makeStyles(() =>
	createStyles({
		schemaContainer: {
			display: "flex",
			flexFlow: "row nowrap",
			padding: props => `${props["padding"]}`,
			borderRadius: "4px",
			backgroundColor: props => `${props["bgColor"]}`,
			marginBottom: "16px"
		},
		schemaBox: {
			background: props => `${props["bg"]}`,
			margin: "5px 0",
			padding: "0 14px",
			width: "100%",
			height: "48px",
			borderRadius: "4px",
			opacity: "0.87",
			fontFamily: "var(--openSans)",
			fontSize: "13px",
			fontWeight: 600,
			color: "#4d5058",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			flexGrow: 0,
			flexShrink: 1,
			display: "flex",
			alignItems: "center"
		},
		flexTop: {
			alignItems: "start !important",
			justifyContent: "start !important"
		},
		gap25: {
			gap: "25px"
		},
		shortSchemaColumn: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			flexBasis: "304px",
			flexShrink: 1,
			flexGrow: 1
		},
		longSchemaColumn: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			flexBasis: "320px",
			flexShrink: 1,
			flexGrow: 1
		},
		schemaSeparator: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			flexBasis: "114px",
			flexShrink: 1,
			flexGrow: 1
		},
		schemaTopDiag: {
			height: "25%",
			width: "100%",
			position: "relative",
			background: `linear-gradient(to top right,
					transparent 0%,
					transparent calc(50% - 1.3px),
					#dbdee4 calc(50% - 1px),
					#dbdee4 calc(50% + 1px),
					transparent calc(50% + 1.3px),
					transparent 100%
				)`,
			"& > i": {
				display: "block",
				borderRadius: "50%",
				position: "absolute",
				background: "#dbdee4",
				border: "2px solid #fff",
				width: "8px",
				height: "8px"
			},
			"& > i:first-child": {
				bottom: 0,
				right: 0,
				transform: "translate(50%, 50%)"
			},
			"& > i:last-child": {
				top: 0,
				left: 0,
				transform: "translate(-50%, -50%)"
			}
		},
		schemaBottomDiag: {
			height: "25%",
			width: "100%",
			position: "relative",
			background: `linear-gradient(to bottom right,
					transparent 0%,
					transparent calc(50% - 1.3px),
					#dbdee4 calc(50% - 1px),
					#dbdee4 calc(50% + 1px),
					transparent calc(50% + 1.3px),
					transparent 100%
				)`,
			"& > i": {
				display: "block",
				borderRadius: "50%",
				position: "absolute",
				background: "#dbdee4",
				border: "2px solid #fff",
				width: "8px",
				height: "8px"
			},
			"& > i:first-child": {
				top: 0,
				right: 0,
				transform: "translate(50%, -50%)"
			},
			"& > i:last-child": {
				bottom: 0,
				left: 0,
				transform: "translate(-50%, 50%)"
			}
		},
		groupsContainer: {
			display: "flex",
			flexFlow: "row nowrap"
		},
		addOptionHeader: {
			color: "#ffffff",
			fontSize: "12px",
			fontWeight: "bold",
			lineHeight: 1.33,
			opacity: 0.38,
			marginTop: 16
		},
		alignSelf: {
			alignSelf: "center"
		}
	})
);

export const useStyles = makeStyles((theme: IExtendedTheme) => ({
	video: {
		width: "100% !important",
		display: "block",
		height: "auto !important",
		paddingTop: "0 !important",
		"& > video": {
			width: "100%",
			display: "block"
		}
	},
	back: {
		backgroundColor: theme.palette.colors.basic[900],
		color: "rgba(255, 255, 255, 0.6)"
	},
	breadcrumb: {
		color: "rgba(255, 255, 255, 0.6)",
		fontSize: theme.typography.pxToRem(13)
	},
	breadcrumbActive: {
		color: "rgba(255, 255, 255, 0.8)",
		fontSize: theme.typography.pxToRem(13)
	},
	expandableLesson: {
		cursor: "pointer"
	},
	infoBox: {
		width: 350,
		height: 350,
		color: "#cfcfcf",
		position: "absolute",
		top: "calc(50% - 160px);",
		left: "calc(50% - 180px)"
	},
	infoBoxIcon: {
		color: "#4c69ba",
		width: "100px",
		height: "100px"
	},
	lessonAttachmentsItem: {
		display: "flex",
		flexDirection: "row"
	},
	lessonAttachmentsItemColumn: {
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	lessonAttachmentsItemRow: {
		paddingLeft: "10px",
		paddingRight: "15px",
		paddingTop: "4px"
	},
	lessonDescription: {
		display: "block",
		flexDirection: "row",
		fontSize: theme.typography.pxToRem(13),
		paddingLeft: "13px",
		paddingTop: "15px",
		paddingRight: "15px",
		paddingBottom: "10px"
	},
	lessonDetails: {
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
		fontSize: theme.typography.pxToRem(12),
		color: "rgb(119, 122, 130)",
		paddingLeft: "10px",
		paddingRight: "15px",
		paddingTop: "5px",
		paddingBottom: "10px"
	},
	lessonLocked: {
		margin: "0 0 5px 0",
		fontWeight: 500,
		lineHeight: 1.33,
		letterSpacing: "normal",
		color: "rgba(255, 255, 255, 0.38)"
	},
	lessonImage: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		filter: "blur(4px)"
	},
	lessonSection: {
		backgroundColor: "rgb(25, 29, 37)",
		borderRadius: "3px",
		marginBottom: "15px"
	},
	lessonSectionControl: {
		fontSize: theme.typography.pxToRem(40)
	},
	lessonSectionControlContainer: {
		display: "flex",
		flexDirection: "column",
		marginLeft: "auto",
		marginRight: "15px"
	},
	cancelButton: {
		position: "fixed",
		right: "16vw",
		top: "8vh"
	},
	lessonSectionIndex: {
		flexShrink: 0,
		flexGrow: 0,
		color: "rgb(255, 254, 254)",
		backgroundColor: "rgb(69, 68, 79)",
		fontSize: theme.typography.pxToRem(18),
		fontWeight: "bold",
		borderRadius: "50%",
		marginLeft: "10px",
		marginRight: "10px",
		width: "35px",
		height: "35px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	lessonSectionLocked: {
		fontSize: theme.typography.pxToRem(14)
	},
	lessonSectionTitle: {
		color: "rgb(90, 94, 101)",
		backgroundColor: "rgb(34, 36, 44)",
		borderRadius: "3px",
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 540,
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
		paddingTop: "8px",
		paddingBottom: "8px"
	},
	lessonSectionTitleSelected: {
		color: "rgb(153, 155, 159)"
	},
	playableLesson: {
		cursor: "pointer",
		"& > div[data-vjs-player='true']": {
			position: "relative !important"
		}
	},
	title: {
		fontSize: theme.typography.pxToRem(18)
	},
	unlockedInfoBox: {
		color: "#cfcfcf",
		position: "absolute",
		top: "calc(50% - 50px);",
		left: "calc(50% - 50px)"
	},
	infoBoxText: {
		fontSize: "28px",
		fontWeight: "bolder",
		marginTop: "30px",
		marginBottom: "5px"
	},
	infoBoxDescription: {
		fontSize: "18px",
		marginTop: "5px",
		marginBottom: "40px",
		textAlign: "center",
		marginInline: "30px"
	},
	notesHeader: {
		backgroundColor: "#1e48a5",
		color: "#fff",
		backdropFilter: "blur(16px)",
		padding: 8,
		borderRadius: "4px 4px 0 0"
	},
	notesBody: {
		backdropFilter: "blur(16px)",
		border: "solid 1px rgba(77, 80, 88, 0.16)",
		backgroundColor: "#fff",
		padding: 10,
		borderRadius: "0 0 4px 4px"
	},
	trailerProgress: {
		fontSize: "16px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "1.25",
		letterSpacing: "normal",
		textAlign: "center",
		color: "#fff",
		float: "right",
		margin: "4px 3px 0 0"
	},
	trailerSubscription: {
		fontSize: "16px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "1.25",
		letterSpacing: "normal",
		textAlign: "center",
		color: "#fff"
	},
	dragItem: {
		color: "#4d5058",
		fontSize: "15px",
		opacity: 0.87,
		fontFamily: "var(--poppins)",
		fontWeight: 600,
		lineHeight: 1.6
	},
	discussionBoard: {
		width: "100%",
		marginTop: "20px !important",
		backgroundColor: "#2a2e37",
		color: "#fff",
		marginBlock: "16px",
		borderRadius: "6px",
		padding: "10px",
		"&:before": {
			height: 0
		}
	},
	commentPost: {
		padding: "8px 16px 5px",
		"& a": {
			textDecoration: "underline"
		}
	},
	avatar: {
		margin: "12px",
		marginTop: "28px"
	},
	commentThumbUp: {
		width: "0.6em",
		height: "0.6em",
		marginLeft: "4px",
		cursor: "pointer",
		fill: props =>
			`${props["totalLike"] === 0 ? "#586179" : props["isLiked"] && props["totalLike"] > 0 ? "#4a99f9" : "#898f9e"}`
	},
	commentThumbDown: {
		width: "0.6em",
		height: "0.6em",
		marginLeft: "4px",
		cursor: "pointer",
		fill: props =>
			`${
				props["totalDislike"] === 0
					? "#586179"
					: props["isDisliked"] && props["totalDislike"] > 0
					? "#4a99f9"
					: "#898f9e"
			}`
	},
	selectCommentFilter: {
		"& > div": {
			fontSize: "14px",
			opacity: "0.87",
			fontWeight: "normal",
			color: "#fff"
		}
	},
	iconCommentExclamation: {
		margin: "12px",
		marginTop: "28px"
	},
	avatarUserReplied: {
		margin: "12px"
	}
}));

export const AnswerDropZoneContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const HotspotHighlightQuestionBox = styled(Box)`
	border-radius: 4px;
	background-color: ${basic[100]};
	font-size: 16px;
	width: 90%;
	margin-top: 16px;
	letter-spacing: normal;
	text-align: justify;
	padding: 12px;
	color: #4d5058;
`;

export const HighlightTable = styled.table`
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;

	> tbody > tr:not(:last-child) > td {
		border-bottom: 1px solid rgba(151, 151, 151, 0.1);
	}

	> tbody > tr > td {
		background: rgba(255, 255, 255, 0.5);
		vertical-align: top;
		color: #6d7078;
		font-weight: normal;

		&:first-child {
			padding: 20px;
			font-weight: 600;
			border-right: 1px solid rgba(151, 151, 151, 0.1);
		}

		${HotspotHighlightQuestionBox} {
			width: auto;
		}
	}
	> thead > tr > td {
		padding: 20px 10px;
		background: #fff;
		opacity: 0.87;
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		letter-spacing: normal;
		color: #4d5058;
		text-align: center;

		&:first-child {
			border-right: 1px solid rgba(151, 151, 151, 0.1);
			width: 40%;
		}
	}
`;

export const HightlightTableContainer = styled.div`
	width: 100%;
	border-radius: 3px;
	margin-top: 17px;
	overflow: hidden;
`;

export const StyledGap = styled(Box)`
	border-radius: 4px;
	background-color: #dbdee4;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const DragOptionReadOnlyContainer = styled(Box)`
	height: 40px;
	width: 187px;
	padding: 12px 8px 12px 12px;
	border-radius: 4px;
	background-color: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const DragOptionText = styled.span`
	width: 100%;
	opacity: 0.87;
	font-family: var(--openSans);
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #4d5058;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	letter-spacing: normal;
`;

export const Gap = styled(Box)`
	width: 370px;
	height: 60px;
	margin: 0 10px 0 15px;
	border-radius: 4px;
	background-color: #dbdee4;
	display: flex;
	align-items: center;
	justify-content: center;

	&.filled {
		padding: 4px;
	}
`;

export const GroupContainer = styled.span`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background: white;
	border-radius: 4px;
	padding: 8px 16px;
	padding-right: 0;
	width: fit-content;
`;

export const DragOptionContainerText = styled(Typography)`
	height: 24px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	opacity: 0.87;
	font-family: var(--openSans);
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #4d5058;
`;

export const QuestionRationalInfo = styled.div`
	font-family: var(--openSans);
	font-size: 13px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #4d5058;
	a,
	a:visited,
	a:focus,
	a:hover {
		text-decoration: underline;
		color: blue;
	}

	p:first-child {
		margin: 0;
	}
`;

export const DragOptionContainer = styled(Box)`
	height: 48px;
	width: 400px;
	padding: 12px 8px 12px 12px;
	border-radius: 4px;
	background-color: #fff;
	display: flex;
	flex-direction: row;
`;

export const SequenceAnswerOptionContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	width: 50%;
	margin: 8px 0;
`;

export const SequenceIndex = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--openSans);
	font-size: 13px;
	color: #4d5058;
	background: #fff;
	margin-right: 8px;
`;

export const SequenceText = styled.div`
	height: 48px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: var(--openSans);
	display: flex;
	flex-grow: 1;
	padding-left: 16px;
	align-items: center;
	font-size: 13px;
	color: #4d5058;
	background: #fff;
`;

export const SequenceDrag = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--openSans);
	font-size: 34px;
	font-weight: 600;
	color: #4d5058;
	background: #fff;
	cursor: grabbing;
`;

export const QuizQuestionAnswerOption = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-flow: row wrap;
	${({ isChecked, isCorrect }) =>
		isCorrect && isChecked
			? css`
	background: rgba(35, 179, 74, .32);
	margin: 2px 0;
	> div:first-child  { color: #23b34a; {}
	`
			: isCorrect
			? css`
					background: #c0c9e5;
					margin: 2px 0;
					> div:first-child {
						color: #264fae;
					}
			  `
			: isChecked
			? `
	margin: 2px 0;
	background: #d3d6e0;
	> div:first-child  { color: #4d5058; }
			`
			: ""}
`;

export const StyledText = styled.span`
	word-break: break-all;
	font-family: var(--openSans);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
	padding: 0 10px;
`;

export const MRTableHeader = styled(Box)`
	height: 64px;
	border-radius: 3px;
	background-color: #fff;
`;

export const MRTableBody = styled(Box)`
	max-width: 595px;
	background-color: #dbdee4;
	border-right: solid 1px rgba(151, 151, 151, 0.3);
	border-left: solid 1px rgba(151, 151, 151, 0.3);
`;

export const CheckBoxWrapper = styled(Box)`
	flex-direction: row;
	width: 100%;
	display: flex;
`;

export const CaseStudyTabs = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: start;
	margin: 0 -2px;
`;

export const CaseStudyTab = styled.div`
	height: 48px;
	flex-flow: row wrap;
	opacity: 0.87;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #4d5058;
	background: ${({ active }) => (active ? "#fff" : "#ccc")};
	padding: 0 11px;
	border-radius: 4px 4px 0 0;
	margin: 0 2px;
	cursor: pointer;
	display: inline-block;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	padding-top: 12px;
`;

export const CaseStudyTabContent = styled.div`
	background: #fff;
	padding: 15px;
	border-radius: 0 4px 4px 4px;

	> p {
		margin: 8px 0;
	}
`;

export const CaseStudyRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: 50px;
`;

export const CaseStudyColumn = styled.div`
	flex: 50% 0 0;
	padding: 10px 0;

	&:first-child {
		padding-right: 18px;
		border-right: 1px solid #979797;
	}

	&:last-child {
		padding-left: 18px;
	}
`;

export const TestQuizQuestionText = styled.div`
	line-height: 1.85;
	font-size: 13px;
	margin: 8px 0;
`;

export const HighlightTestQuizQuestionText = styled.div`
	font-family: var(--openSans);
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #4d5058;
`;

export const MatrixTableHeader = styled(Box)`
	height: 64px;
	border-radius: 3px 3px 0 0;
	background-color: #fff;
`;

export const StyledDropdown = styled(Select)`
	color: #fff;
	width: ${({ inTable }) => (inTable ? "auto" : "220px")};
	line-height: 1.66;
	background: white;
	margin: 10px;
	border-radius: 4px;
	font-size: 15px;

	> div {
		background: white;
		border-radius: 4px;
		color: grey;
		font-weight: 500;
		margin-left: 6px;
	}

	> svg {
		color: grey;
	}
`;

export const MatrixTableBody = styled(Box)`
	max-width: 650px;
	border-radius: 0 0 3px 3px;
	background-color: #${({ isinresults }) => (isinresults ? "f5f7f9" : "edeef1")};
	${({ isinresults }) =>
		!isinresults
			? "border-right: solid 1px rgba(151, 151, 151, 0.3);	border-left: solid 1px rgba(151, 151, 151, 0.3);"
			: ""}
`;

export const RadioWrapper = styled(RadioGroup)`
	flex-direction: row;
	width: 100%;
	display: flex;
`;

export const MultipleChoiceOptionContainer = styled(Box)`
	height: 48px;
	width: 100%;
	padding: 8px 8px 8px 8px;
	border-radius: 4px;
	background-color: #fff;
	display: flex;
	margin-bottom: 6px;
	flex-direction: row;
	align-items: center;
`;

export const Pages = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: end;
	margin: 0 -2px;
`;

export const Page = styled.div`
	width: 32px;
	height: 32px;
	line-height: 32px;
	font-size: 13px;
	font-weight: 600;
	margin: 0 2px;
	cursor: pointer;
	text-align: center;
	border-radius: 4px;

	${({ active }) =>
		active
			? css`
					background: #264fae;
					color: #fff;
			  `
			: css`
					color: #a6a7aa;
			  `}
`;

export const GroupingQuestionPreviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 16px;
	width: 100%;
`;

export const DragIconContainer = styled.div`
	color: rgb(77, 80, 88);
	font-size: 40px;
	width: 48px;
	line-height: 48px;
	cursor: grabbing;
	text-align: center;
	background: rgb(255, 255, 255);
	border-radius: 4px;
	margin: 0 7px:
`;

export const AnswerOptionText = styled.div`
	background: #fff;
	border-radius: 4px;
	height: 48px;
	width: 48px;
	font-size: 13px;
	color: #4d5058;
	line-height: 48px;
	margin-right: 7px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: var(--openSans);
	display: flex;
	flex-grow: 1;
	padding-left: 16px;
	align-items: center;
`;

export const AnswerOptionTextContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	background: #fff;
	color: #4d5058;
`;

export const GroupsContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 8px;
	gap: 60px;
`;

export const GroupsTextContainer = styled.div`
	width: 100%;
	padding: 8px 8px 10px 5px;
	border-radius: 5px;
	background-color: #d8d8d8;
	border-radius: 4px;
	align-items: center;
`;

export const GroupsTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	min-height: 40px;
	border-radius: 4px;
	background-ccolor: #fff;
	padding: 0 17px 0 13px;
`;
