import React, { useState } from "react";

import { Box, Button, IconButton, Modal } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { QuestionTypes } from "@remar/shared/dist/constants";

import { useDispatch } from "react-redux";

import { setStateValue } from "store/features/CreateLesson/createLesson.slice";

import { useTestOnlyPreviewStyles } from "./testOnlyPreview.styles";

import BowTieQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/BowTieQuestionPreview";
import CaseStudyQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/CaseStudyQuestionPreview";
import ClozeDropDownQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/ClozeDropDownQuestionPreview";
import DragAndDropQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/DragAndDropQuestionPreview";
import DropDownTableQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/DropDownTableQuestionPreview";
import GroupingQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/GroupingQuestionPreview";
import HighlightTableQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/HighlightTableQuestionPreview";
import HotspotHighlightQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/HotspotHighlightQuestionPreview";
import MatrixMultipleChoicePreview from "../../QuestionBank/PreviewQuestion/Components/MatrixMultipleChoicePreview";
import MatrixSingleChoiceQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/MatrixSingleChoiceQuestionPreview";
import MultipleChoiceQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/MultipleChoiceQuestionPreview";
import MultipleResponseGroupQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/MultipleResponseGroupQuestionPreview";
import SequencingQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/SequencingQuestionPreview";
import SingleChoiceQuestionPreview from "../../QuestionBank/PreviewQuestion/Components/SingleChoiceQuestionPreview";
import { useStyles } from "../../QuestionBank/PreviewQuestion/styles";

const TestOnlyPreview = ({ testOnlyPreviewQuestions }) => {
	const testOnlyPreviewClasses = useTestOnlyPreviewStyles();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [questionIndex, setQuestionIndex] = useState<number>(0);
	const questions = testOnlyPreviewQuestions;
	const question = questions![questionIndex];

	const getQuestionType = question => {
		switch (question.typeId) {
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixMultipleChoicePreview question={question} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return <MultipleChoiceQuestionPreview question={question} />;
			case QuestionTypes.SingleChoice:
				return <SingleChoiceQuestionPreview question={question} />;
			case QuestionTypes.Sequencing:
				return <SequencingQuestionPreview question={question} />;
			case QuestionTypes.Grouping:
				return <GroupingQuestionPreview question={question} />;
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceQuestionPreview question={question} />;
			case QuestionTypes.CaseStudy:
				return <CaseStudyQuestionPreview question={question} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightQuestionPreview question={question} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableQuestionPreview question={question} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupQuestionPreview question={question} />;
			case QuestionTypes.BowTie:
				return <BowTieQuestionPreview question={question} />;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return <DragAndDropQuestionPreview question={question} />;
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return <ClozeDropDownQuestionPreview question={question} />;
			case QuestionTypes.DropDownTable:
				return <DropDownTableQuestionPreview question={question} />;
			default:
				return null;
		}
	};

	if (!question) {
		return <></>;
	}

	return (
		<Modal
			open
			onClose={() => dispatch(setStateValue({ key: "testOnlyPreviewQuestions", value: [] }))}
			className={classes.modal}
		>
			<div className={classes.scroll}>
				<div className={classes.content}>
					<div className={classes.header}>
						<div className={classes.title}>Test Preview</div>
						<IconButton onClick={() => dispatch(setStateValue({ key: "testOnlyPreviewQuestions", value: [] }))}>
							<Close />
						</IconButton>
					</div>
					<div className={classes.questionContainer}>
						<div className={classes.row}>
							<div className={classes.col}>
								<div className={classes.questionNumber}>Question {questionIndex + 1}</div>
								{getQuestionType(question)}
							</div>
						</div>
					</div>

					<Box className={testOnlyPreviewClasses.buttonsRow}>
						{questionIndex > 0 && (
							<Button
								className={testOnlyPreviewClasses.previousQuestionButton}
								variant="contained"
								onClick={() => setQuestionIndex(questionIndex - 1)}
							>
								Previous Question
							</Button>
						)}
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								questionIndex === questions.length - 1
									? dispatch(setStateValue({ key: "testOnlyPreviewQuestions", value: [] }))
									: setQuestionIndex(questionIndex + 1);
							}}
						>
							{questionIndex === questions.length - 1 ? "Finish" : "Next Question"}
						</Button>
					</Box>
				</div>
			</div>
		</Modal>
	);
};

export default TestOnlyPreview;
