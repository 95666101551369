import React, { useEffect, useRef, useState } from "react";

import { Box } from "@material-ui/core";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { CreateLessonSteps } from "store/features/CreateLesson/createLesson.constants";
import { getFullState, setStateValue, validateForm } from "store/features/CreateLesson/createLesson.slice";
import { emit } from "store/features/notifications/notifications.slice";
import { lessonVideosService } from "store/services";

import "video.js/dist/video-js.min.css";
import videojs from "video.js";

import { MessageContainer, VideoContainerDiv } from "./styles";

const VideoContainer = () => {
	const dispatch = useDispatch();
	const { selectedMainLessonVideo } = useSelector(getFullState);
	const player = useRef<videojs.Player>();
	const videoRef = useRef<HTMLVideoElement>(null);

	const [videoTranscoded, setVideoTranscoded] = useState(false);

	const registerVideoPlayer = () => {
		// TODO: need to discuss and will remove the eslint warning and change the return type of videoPlayer from "any"
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const videoPlayer = getVideoPlayer(videoRef!.current!, selectedMainLessonVideo.videoUrl, {
			fluid: false
		});
		videoPlayer.play();

		videoPlayer.tech_.on("retryplaylist", () => {
			dispatch(
				emit({
					message: "The video transcoding process is still loading, please wait.",
					color: "info"
				})
			);
			dispatch(setStateValue({ key: "videoLoaded", value: false }));
		});

		player.current = videoPlayer;

		return videoPlayer;
	};

	useEffect(() => {
		setTimeout(() => {
			const duration = player.current?.duration() as number;
			if (duration > 0) {
				dispatch(setStateValue({ key: "interactiveLessonFormGroup.inputs.videoDuration.value", value: duration }));
				dispatch(
					validateForm({
						formStatePath: "interactiveLessonFormGroup",
						markInputsAsDirty: true
					})
				);
			}
		}, 1200);
	}, [dispatch, player]);

	useEffect(() => {
		const canPlayThrough = () => {
			dispatch(setStateValue({ key: "videoLoaded", value: true }));
		};
		videoRef.current?.addEventListener("canplaythrough", canPlayThrough);
		return () => {
			videoRef.current?.removeEventListener("canplaythrough", canPlayThrough);
		};
	}, [videoRef.current, dispatch, videoTranscoded]);

	useEffect(() => {
		let videoPlayer;
		if (selectedMainLessonVideo && videoTranscoded) {
			videoPlayer = registerVideoPlayer();
		}
		return () => videoPlayer && videoPlayer.dispose();
	}, [videoTranscoded]);

	useEffect(() => {
		if (!videoTranscoded && selectedMainLessonVideo) {
			let timer: NodeJS.Timeout;
			lessonVideosService
				.validate(selectedMainLessonVideo.videoUrl)
				.then(() => {
					setVideoTranscoded(true);
				})
				.catch(() => {
					timer = setInterval(() => {
						lessonVideosService
							.validate(selectedMainLessonVideo.videoUrl)
							.then(() => {
								setVideoTranscoded(true);
								clearInterval(timer);
							})
							.catch(() => {
								console.log("Video still transcoding...");
							});
					}, 10000);
				});
			return () => {
				timer && clearInterval(timer);
			};
		}
	}, [videoTranscoded, selectedMainLessonVideo]);

	if (!selectedMainLessonVideo) {
		return <Box>Please Select a video from the {CreateLessonSteps.LessonMaterials} section to proceed</Box>;
	}

	return (
		<Box>
			{!videoTranscoded ? (
				<MessageContainer>
					<h4>Your video is currently being transcoded. It will appear here once completed</h4>
					<span>We will auto-retry every 10 seconds to check on the progress</span>
				</MessageContainer>
			) : (
				<VideoContainerDiv>
					<video ref={videoRef} className="video-js vjs-big-play-centered" />
				</VideoContainerDiv>
			)}
		</Box>
	);
};

export default VideoContainer;
