import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IBaseState } from "@remar/shared/dist/models";
import { validateFormAction as utilsValidateFormAction } from "@remar/shared/dist/utils/form/form.utils";
import { downloadURI } from "@remar/shared/dist/utils/serviceUtils";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { hasTextUrl } from "@remar/shared/dist/utils/textUtils";
import { RootState } from "store";

import {
	ChartData,
	ChartReportParams,
	GetSummaryReportParams,
	ReportSummaryDto,
	TestChartData,
	locationsService
} from "store/services";

import { Module, ModulesResponseDto, reportService } from "store/services/reports";

import { emit } from "../notifications/notifications.slice";

const range = [...Array(31).keys()];

interface ReportsState extends IBaseState {
	module: {
		isLoading: boolean;
		page: number;
		perPage: number;
		items: Module[];
		totalItems: number;
	};
	duration: {
		isLoading: boolean;
		page: number;
		perPage: number;
		items: Module[];
		totalItems: number;
	};
	summaryReport: ReportSummaryDto;
	loginChart: ChartData;
	trainingChart: ChartData;
	testChart: TestChartData;
}

export const initialState: ReportsState = {
	isLoading: false,
	error: "",
	summaryReport: {
		loginCount: 29,
		quizPassingPercentage: 78,
		timeSpentTrainingInMinutes: 2348
	},
	module: {
		isLoading: false,
		page: 1,
		perPage: 10,
		items: [],
		totalItems: 0
	},
	duration: {
		isLoading: false,
		page: 1,
		perPage: 10,
		items: [],
		totalItems: 0
	},
	loginChart: {
		dataPoints: range.map(() => Math.round(Math.random() * 100)),
		summary: {
			active: 10,
			inactive: 20,
			total: 30
		}
	},
	trainingChart: {
		dataPoints: range.map(() => Math.round(Math.random() * 100)),
		summary: {
			active: 30,
			inactive: 20,
			total: 50
		}
	},
	testChart: {
		dataPoints: range.map(() => Math.round(Math.random() * 100)),
		summary: {
			passed: 10,
			failed: 20,
			inProgress: 30,
			total: 60
		}
	}
};

export const getSummaryReport = createAsyncThunk(
	"reports/getSummaryReport",
	async (data: GetSummaryReportParams, { rejectWithValue }) => {
		return await locationsService.getSummaryReport(data).catch(rejectWithValue);
	}
);

export const getSignInReport = createAsyncThunk(
	"reports/getSignInReport",
	async (data: ChartReportParams, { rejectWithValue }) => {
		return await locationsService.getLoginChartData(data).catch(rejectWithValue);
	}
);

export const getTrainingReport = createAsyncThunk(
	"reports/getTrainingReport",
	async (data: ChartReportParams, { rejectWithValue }) => {
		return await locationsService.getTrainingChartData(data).catch(rejectWithValue);
	}
);

export const getTestReport = createAsyncThunk(
	"reports/getTestReport",
	async (data: ChartReportParams, { rejectWithValue }) => {
		return await locationsService.getTestChartData(data).catch(rejectWithValue);
	}
);

export const reportsSlice = createSlice({
	name: "reports",
	initialState,
	extraReducers: {
		[getSummaryReport.pending.type]: state => {
			state.isLoading = true;
		},
		[getSummaryReport.fulfilled.type]: (state, action: PayloadAction<ReportSummaryDto>) => {
			state.summaryReport = action.payload;
			state.isLoading = false;
		},
		[getSummaryReport.rejected.type]: (state, action: PayloadAction<Error>) => {
			state.isLoading = false;
			state.error = action.payload.message;
		},
		[getSignInReport.pending.type]: state => {
			state.isLoading = true;
		},
		[getSignInReport.fulfilled.type]: (state, action: PayloadAction<ChartData>) => {
			state.loginChart = action.payload;
			state.isLoading = false;
		},
		[getSignInReport.rejected.type]: (state, action: PayloadAction<Error>) => {
			state.isLoading = false;
			state.error = action.payload.message;
		},
		[getTrainingReport.pending.type]: state => {
			state.isLoading = true;
		},
		[getTrainingReport.fulfilled.type]: (state, action: PayloadAction<ChartData>) => {
			state.trainingChart = action.payload;
			state.isLoading = false;
		},
		[getTrainingReport.rejected.type]: (state, action: PayloadAction<Error>) => {
			state.isLoading = false;
			state.error = action.payload.message;
		},
		[getTestReport.pending.type]: state => {
			state.isLoading = true;
		},
		[getTestReport.fulfilled.type]: (state, action: PayloadAction<TestChartData>) => {
			state.testChart = action.payload;
			state.isLoading = false;
		},
		[getTestReport.rejected.type]: (state, action: PayloadAction<Error>) => {
			state.isLoading = false;
			state.error = action.payload.message;
		}
	},
	reducers: {
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		setModuleOrDuration: (
			state,
			action: PayloadAction<{
				key: "module" | "duration";
				response: ModulesResponseDto;
			}>
		) => {
			const { key, response } = action.payload;
			const { page, perPage, items, totalItems } = response;
			state[key].page = page || 1;
			state[key].perPage = perPage || 10;
			state[key].items = items || [];
			state[key].totalItems = totalItems || 0;
		}
	}
});

export const generateReport = createAsyncThunk(
	"reports/generateReport",
	async (
		options: { period: { key: string; value: number; durationLabel: string }; report: number; cb: () => void },
		{ dispatch }
	) => {
		try {
			const response = await reportService.generateReport(options.report, options.period.value);
			if (!hasTextUrl(response)) throw Error();
			downloadURI(response, `${options.period.key} - ${options.period.durationLabel}`);
		} catch (_) {
			dispatch(emit({ message: "An error has occurred.", color: "error" }));
		} finally {
			options.cb();
		}
	}
);

export const fetchModules = createAsyncThunk("reports/fetchModules", async (_, { dispatch, getState }) => {
	try {
		const { isLoading } = (getState() as RootState).reports.module;
		if (!isLoading) dispatch(setStateValue({ key: "module.isLoading", value: true }));

		const response = await reportService.getModules();
		dispatch(setModuleOrDuration({ key: "module", response }));
	} catch (_) {
		dispatch(emit({ message: "An error has occurred.", color: "error" }));
	} finally {
		dispatch(setStateValue({ key: "module.isLoading", value: false }));
	}
});

export const fetchDurations = createAsyncThunk("reports/fetchDurations", async (_, { dispatch, getState }) => {
	try {
		const { isLoading } = (getState() as RootState).reports.duration;
		if (!isLoading) dispatch(setStateValue({ key: "duration.isLoading", value: true }));

		const response = await reportService.getDuration();
		dispatch(setModuleOrDuration({ key: "duration", response }));
	} catch (_) {
		dispatch(emit({ message: "An error has occurred.", color: "error" }));
	} finally {
		dispatch(setStateValue({ key: "duration.isLoading", value: false }));
	}
});

export const { validateForm, setStateValue, setModuleOrDuration } = reportsSlice.actions;

export const getFullState = (state: RootState): ReportsState => state.reports;

export default reportsSlice.reducer;
