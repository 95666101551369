import React from "react";

import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router";

import { routes } from "core/constants";

const Settings = () => {
	const history = useHistory();
	return (
		<Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
			<Button variant="contained" onClick={() => history.push(`${routes.CreateLesson.getPath()}?isIntro=true`)}>
				Manage Tutorial
			</Button>
		</Box>
	);
};

export default Settings;
