import React, { ReactNode, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

import { whoami } from "store/features/Auth/auth.slice";
import styled from "styled-components";

import SideNav from "./SideNav";
import TopNav from "./TopNav";

interface BaseLayoutProps {
	children: ReactNode;
}

const Container = styled.div`
	background-color: ${({ theme }) => theme.palette.colors.basic[1100]};
	width: 100%;
	min-height: 100vh;
	display: flex;
`;

const LeftContainer = styled.div`
	width: 72px;
	flex-shrink: 0;
`;

const RightContainer = styled.div`
	height: 100%;
	${({ loggedIn }) => {
		if (loggedIn)
			return `
		overflow: hidden;
		flex-grow: 1
		`;
		return "width: 100%;";
	}};
	/* hide left gap if user is logged in */
	margin-left: ${({ loggedIn }) => {
		if (loggedIn) return "64px";
		return "0";
	}};
	padding-top: ${({ loggedIn }) => {
		if (loggedIn) return "64px";
		return "0";
	}};
`;

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
	const dispatch = useDispatch();
	const { isLoggedIn, user } = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		!user && dispatch(whoami());
	}, [user]);

	return (
		<Container>
			<LeftContainer>{isLoggedIn && <SideNav />}</LeftContainer>
			<RightContainer loggedIn={isLoggedIn}>
				{isLoggedIn && <TopNav />}
				{children}
			</RightContainer>
		</Container>
	);
};

export default BaseLayout;
