import React from "react";

import { Box, Checkbox } from "@material-ui/core";

import { CheckBoxWrapper, MRTableBody, MRTableHeader, StyledText, TestQuizQuestionText } from "./style";

const MatrixMultipleChoicePreview = ({ question }) => {
	const { answerOptions, tableLabel, groups } = question.data;

	return (
		<Box>
			<TestQuizQuestionText>{question?.text}</TestQuizQuestionText>
			<MRTableHeader display="flex" maxWidth={"595px"} flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<Box
						display="flex"
						borderRight={"solid 1px rgba(151, 151, 151, 0.3)"}
						flexDirection="row"
						width="50%"
						height={"100%"}
						alignItems="center"
						pl={"22px"}
					>
						<StyledText>{tableLabel}</StyledText>
					</Box>

					<Box height={"100%"} display="flex" flexDirection="row" width="50%">
						{groups.map(({ text }, answerOptionIndex) => (
							<Box
								key={answerOptionIndex}
								justifyContent="center"
								display="flex"
								alignItems="center"
								height={"100%"}
								flexDirection="row"
								width={`${100 / groups.length}%`}
								{...(answerOptionIndex === 0 && {
									borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
								})}
							>
								<StyledText>{text}</StyledText>
							</Box>
						))}
					</Box>
				</Box>
			</MRTableHeader>
			<MRTableBody>
				{answerOptions.map(({ text }, gIndex) => (
					<Box
						key={gIndex}
						borderBottom="solid 1px rgba(151, 151, 151, 0.3)"
						height={"51px"}
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%">
							<Box
								display="flex"
								alignItems="center"
								flexDirection="row"
								borderRight="solid 1px rgba(151, 151, 151, 0.3)"
								height={"100%"}
								width="50%"
								pl={"22px"}
							>
								<StyledText>{text}</StyledText>
							</Box>
							<Box display="flex" flexDirection="row" width="50%">
								<CheckBoxWrapper>
									{groups.map(({}, _index) => (
										<Box
											justifyContent="center"
											key={_index}
											height={"51px"}
											display="flex"
											width={`${100 / groups.length}%`}
											{...(_index === 0 && {
												borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
											})}
										>
											<Checkbox color="primary" />
										</Box>
									))}
								</CheckBoxWrapper>
							</Box>
						</Box>
					</Box>
				))}
			</MRTableBody>
		</Box>
	);
};

export default MatrixMultipleChoicePreview;
