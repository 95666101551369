import React, { useEffect, useRef, useState } from "react";

import { Box, Breadcrumbs, Button, CircularProgress, Modal, useTheme } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import { DragHandle, NavigateNext } from "@material-ui/icons";

import { CourseChapterSection } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { TransparentButton } from "@remar/shared/dist/ui/Buttons/styles";
import convertMinsToHrsMins from "@remar/shared/dist/utils/convertMinsToHrsMins";
import { validatePositiveNumber } from "@remar/shared/dist/utils/serviceUtils/validators";
import { uploadFile } from "@remar/shared/dist/utils/stateUtils";

import { cloneDeep } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import {
	createSection,
	deleteChapter,
	editChapter,
	editSection,
	fetchChapter,
	fetchCourse,
	getFullState,
	setStateValue
} from "store/features/Course/course.slice";
import { _emit } from "store/features/notifications/notifications.slice";
import { CourseChapterSectionCreateDto, CourseChapterSectionUpdateDataDto, genericService } from "store/services";

import { routes } from "core/constants";

import Card from "modules/Components/Card";
import EditCard from "modules/Components/EditCard";
import EmptyViewCard from "modules/Components/EmptyViewCard";

import useScrollTo from "modules/Hooks/useScrollTo";

import { DeleteModalContent } from "./DeleteModalContent";

import EditFields from "./EditFields";

import LessonReordering from "./LessonReordering";

import {
	ButtonContainer,
	CardContainer,
	Container,
	CourseHeading,
	Header,
	HeaderActions,
	HeaderContainer
} from "./styles";

import EditFieldsChapter from "../IndividualCourse/EditFields";

import { intialEditData } from "../IndividualCourse/constant";

import { Breadcrumb, BtnPublish, BtnSecondary, useStyles } from "../styles";
import { CourseViewInterface } from "../types";

const Content = () => {
	const dispatch = useDispatch();
	const theme = useTheme<IExtendedTheme>();
	const { activeCourse, activeChapter, editChapterData, editSectionData, addSectionData, isLoading, uploadingImage } =
		useSelector(getFullState);
	const sections = activeChapter ? activeChapter.sections! : [];
	const params = useParams<{ id: string; courseId: string }>();
	const history = useHistory();
	const chapterId = Number(params.id);
	const defaultAddSectionFields: CourseChapterSectionCreateDto = {
		isAvailableForTrial: false,
		chapterId,
		name: "",
		mainImageKey: undefined
	};
	const [editSectionIndex, setEditSectionIndex] = useState<number | null>(null);
	const [showAddNewSection, setShowAddNewSection] = useState<boolean>(false);
	const [chapterLength, setChapterLength] = useState({ hours: "", mins: "" });
	const [SectionView, setSectionView] = useState<CourseViewInterface[]>([]);
	const [deleteModal, setDeleteModal] = useState(false);
	const [ChapterView, setChapterView] = useState<CourseViewInterface>({
		id: 0,
		title: { key: "", value: "" },
		content: [],
		imgSrc: ""
	});
	const classes = useStyles();
	const elementRef = useRef<HTMLDivElement>(null);
	useScrollTo(elementRef, showAddNewSection);

	useEffect(() => {
		const { id, courseId } = params;
		dispatch(fetchCourse(Number(courseId)));
		dispatch(fetchChapter(Number(id)));
		dispatch(setStateValue({ key: "editChapterData", value: null }));
		if (!editSectionData) {
			dispatch(setStateValue({ key: "editSectionData", value: intialEditData }));
		}
	}, []);

	const handleAddLesson = (sectionId: number) => {
		history.push(`${routes.CreateLesson.getPath()}?selectedSectionId=${sectionId}`);
	};

	const handleSectionEdit = (sectionIndex: number) => {
		const {
			id,
			name,
			mainImageUrl,
			totalTimeLengthInMinutes,
			calculateTimeLengthAutomatically,
			isAvailableForTrial = false
		} = activeChapter!.sections![sectionIndex];
		const minHrs = convertMinsToHrsMins(totalTimeLengthInMinutes).split("h");
		const hours = Number(minHrs[0]);
		const mins = Number(minHrs[1].split("min")[0]);
		const payload = {
			data: {
				name,
				mainImageUrl,
				totalTimeLengthInMinutes,
				hours,
				mins,
				calculateTimeLengthAutomatically,
				isAvailableForTrial
			},
			filters: { id }
		};
		setEditSectionIndex(sectionIndex);
		dispatch(setStateValue({ key: "editSectionData", value: payload }));
	};

	const populateData = (sections: CourseChapterSection[]) => {
		if (sections.length) {
			const data: CourseViewInterface[] = (sections || []).map((el, i) => {
				const { id, name, totalLessons, mainImageUrl } = el;
				return {
					id,
					title: {
						key: "Section Title",
						value: name
					},
					content: [{ key: "Lessons", value: totalLessons }],
					imgSrc: mainImageUrl ?? "https://www.pngkey.com/png/detail/435-4358756_nurse-nurses-movies.png",
					bottomComponent: (
						<ButtonContainer>
							<Button color={"primary"} variant="contained" onClick={() => handleAddLesson(id)}>
								Add Lesson
							</Button>
							<TransparentButton variant="text" onClick={() => handleSectionEdit(i)}>
								Edit Section
							</TransparentButton>
						</ButtonContainer>
					),
					rightComponent: <LessonReordering id={id} />
				};
			});
			setSectionView(data);
		} else {
			setSectionView([]);
		}
	};

	useEffect(() => {
		if (activeChapter) {
			const { name, totalSections, totalLessons, totalTimeLengthInMinutes, mainImageUrl, isActive, id } = activeChapter;
			const chapterData = {
				id: id,
				title: {
					key: "Chapter Title",
					subtitle: isActive ? "Published" : "Unpublished",
					value: name
				},
				content: [
					{ key: "Sections", value: totalSections },
					{ key: "Total Lessons", value: totalLessons },
					{ key: "Chapter Length", value: convertMinsToHrsMins(totalTimeLengthInMinutes) }
				],
				imgSrc: mainImageUrl ?? "https://www.pngkey.com/png/detail/435-4358756_nurse-nurses-movies.png"
			};
			setChapterView(chapterData);
			populateData(sections);
		} else {
			populateData([]);
		}
	}, [activeChapter]);

	const handlePublishUnPublish = (id: number, isActive: boolean) => {
		const payload = {
			data: {
				isActive
			},
			filters: { id }
		};
		dispatch(editChapter(payload));
		dispatch(setStateValue({ key: "editChapterData", value: null }));
	};
	const handleChapterEditSave = () => {
		const { name, totalTimeLengthInMinutes, calculateTimeLengthAutomatically, hours, mins, progressTrackingEnabled } =
			editChapterData!.data;
		const payload = {
			data: {
				name,
				totalTimeLengthInMinutes,
				calculateTimeLengthAutomatically,
				progressTrackingEnabled,
				...(editChapterData!.data.mainImageKey && { mainImageKey: editChapterData!.data.mainImageKey })
			},
			filters: editChapterData!.filters
		};
		if (!calculateTimeLengthAutomatically) {
			payload.data.totalTimeLengthInMinutes = Number(hours) * 60 + Number(mins);
		}
		dispatch(editChapter(payload));
		dispatch(setStateValue({ key: "editChapterData", value: null }));
	};

	const updateChapterLength = (val: string, key: string) => {
		if (validatePositiveNumber(val)) {
			const _chapterLength = { ...chapterLength };
			_chapterLength[key] = val;
			setChapterLength(_chapterLength);
			updateChapterEditData(val, key);
		}
	};

	const updateChapterEditData = (val: string | boolean | number, key: string) => {
		const _editData = cloneDeep(editChapterData);
		_editData!.data[key] = val;
		dispatch(setStateValue({ key: "editChapterData", value: _editData }));
	};

	const addNewSection = () => {
		dispatch(setStateValue({ key: "addSectionData", value: intialEditData }));
		setShowAddNewSection(true);
	};

	const handleChapterEditCancel = () => {
		dispatch(setStateValue({ key: "editChapterData", value: null }));
	};

	const handleChapterEdit = () => {
		const { id, name, totalTimeLengthInMinutes, calculateTimeLengthAutomatically, progressTrackingEnabled } =
			activeChapter!;
		const minHrs = convertMinsToHrsMins(totalTimeLengthInMinutes).split("h");
		const hours = Number(minHrs[0]);
		const mins = Number(minHrs[1].split("min")[0]);
		const payload = {
			data: {
				name,
				totalTimeLengthInMinutes,
				hours,
				mins,
				calculateTimeLengthAutomatically,
				progressTrackingEnabled
			},
			filters: { id }
		};
		dispatch(setStateValue({ key: "editChapterData", value: payload }));
	};

	const resetEditData = () => {
		setShowAddNewSection(false);
		dispatch(setStateValue({ key: "editSectionData", value: { data: defaultAddSectionFields, filters: { id: 0 } } }));
	};

	const resetAddSectionData = () => {
		setShowAddNewSection(false);
		dispatch(setStateValue({ key: "addSectionData", value: { data: defaultAddSectionFields, filters: { id: 0 } } }));
	};

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	const createNewSection = () => {
		dispatch(createSection({ ...addSectionData!.data, chapterId: activeChapter!.id } as CourseChapterSectionCreateDto));
		resetEditData();
	};

	const handleCancel = () => {
		setEditSectionIndex(null);
	};

	const updateEditSecData = (val: unknown, key) => {
		dispatch(setStateValue({ key: `editSectionData.data.${key}`, value: val }));
	};

	const handleUpload = (
		img: Partial<File>,
		options: {
			// eslint-disable-next-line no-unused-vars
			handleProgres?: ({ progress: number, uploading: boolean }) => void;
			imageKeyPath: string;
			imageUrlPath?: string;
		}
	) => {
		options.handleProgres && options.handleProgres({ progress: 0, uploading: true });
		dispatch(
			uploadFile(
				{ file: img },
				{
					_emit,
					genericApiService: genericService,
					imageUrlStatePath: options.imageUrlPath,
					loaderStatePath: "uploadingImage",
					s3KeyStatePath: options.imageKeyPath,
					setStateValue,
					onProgress: ({ loaded, total }) => {
						options.handleProgres && options.handleProgres({ progress: (loaded / total) * 100, uploading: true });
					},
					uploadCb: () => {
						options.handleProgres && options.handleProgres({ progress: 0, uploading: false });
					}
				}
			)
		);
	};

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}
		const { index: destinationIndex } = result.destination;
		const { index: sourceIndex } = result.source;
		const sections = [...SectionView];
		const sourceId = sections[sourceIndex]?.id;
		const [removed] = sections.splice(sourceIndex, 1);
		sections.splice(destinationIndex, 0, removed);
		setSectionView(sections);
		const payload = {
			data: {
				order: destinationIndex
			},
			filters: {
				id: sourceId
			}
		};
		dispatch(editSection(payload, activeChapter!.id));
	};

	const updateData = (val: unknown, key) => {
		dispatch(setStateValue({ key: `addSectionData.data.${key}`, value: val }));
	};

	const ShowEditView = () => {
		const { name, calculateTimeLengthAutomatically, progressTrackingEnabled, hours, mins } = editChapterData!.data;
		const editChapterLength = { hours, mins };
		const disabledSaveBtn =
			uploadingImage ||
			(!calculateTimeLengthAutomatically
				? !(editChapterLength.hours !== "" && editChapterLength.mins !== "" && name)
				: !name);

		return (
			<Box mb={4}>
				<EditCard
					rightComponent={EditFieldsChapter(
						updateChapterEditData,
						name,
						calculateTimeLengthAutomatically,
						progressTrackingEnabled,
						updateChapterLength,
						chapterLength,
						disabledSaveBtn,
						handleChapterEditCancel,
						handleChapterEditSave,
						editChapterLength
					)}
					imageUrl={activeChapter?.mainImageUrl}
					handleImageUpdate={(file, handleProgres) => {
						handleUpload(file, {
							handleProgres: handleProgres,
							imageKeyPath: "editChapterData.data.mainImageKey",
							imageUrlPath: "activeChapter.mainImageUrl"
						});
					}}
					width={800}
				/>
			</Box>
		);
	};

	const handleSave = () => {
		const {
			name,
			mainImageKey,
			totalTimeLengthInMinutes,
			calculateTimeLengthAutomatically,
			isAvailableForTrial,
			progressTrackingEnabled
		} = editSectionData!.data;
		const payload = {
			data: {
				name,
				mainImageKey,
				totalTimeLengthInMinutes,
				calculateTimeLengthAutomatically,
				isAvailableForTrial,
				progressTrackingEnabled
			},
			filters: editSectionData!.filters
		};
		dispatch(editSection(payload, activeChapter!.id, true));
		setEditSectionIndex(null);
	};

	const { name, mainImageKey } = addSectionData ? addSectionData.data : ({} as CourseChapterSectionUpdateDataDto);
	const disabledSaveBtn = uploadingImage || !name || !mainImageKey;

	return (
		<Container>
			<HeaderContainer>
				<Header>
					<CourseHeading>{!!activeCourse ? activeCourse.name : ""}</CourseHeading>
					<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
						<Link to={`${routes.course.getPath()}`}>
							<Breadcrumb>Courses</Breadcrumb>
						</Link>

						{activeCourse && (
							<Link to={`${routes.course.getPath()}/${activeCourse.id}`}>
								<Breadcrumb>{activeCourse.name}</Breadcrumb>
							</Link>
						)}
						{activeChapter && activeCourse && (
							<Link to={`${routes.course.getPath()}/${activeCourse.id}/chapters/${activeChapter.id}`}>
								<Breadcrumb>{activeChapter.name}</Breadcrumb>
							</Link>
						)}
					</Breadcrumbs>
				</Header>
				<HeaderActions>
					{!!sections?.length && (
						<BtnSecondary variant={"contained"} onClick={handleChapterEdit}>
							Edit Chapter
						</BtnSecondary>
					)}
					{!!activeChapter && (
						<BtnSecondary variant={"contained"} onClick={() => setDeleteModal(true)}>
							Delete Chapter
						</BtnSecondary>
					)}
					{!!activeChapter && (
						<BtnPublish
							color={"primary"}
							variant={"contained"}
							mr={5}
							onClick={() => handlePublishUnPublish(activeChapter.id, !activeChapter.isActive)}
						>
							{activeChapter.isActive ? "Unpublish" : "Publish"}
						</BtnPublish>
					)}
					<Button color={"primary"} variant="contained" onClick={addNewSection}>
						Add New Section
					</Button>
				</HeaderActions>
			</HeaderContainer>
			<Modal
				className={classes.modal}
				disableEnforceFocus
				disableAutoFocus
				open={deleteModal}
				onClose={() => setDeleteModal(false)}
			>
				<DeleteModalContent
					onClose={() => setDeleteModal(false)}
					title="Delete Chapter"
					message="Are you sure you want to delete this chapter?"
					deleteAllMessage="Yes, delete all if possible"
					onDelete={(deleteVideo: boolean) => {
						dispatch(
							deleteChapter({ chapterId: activeChapter?.id as number, deleteVideo }, () => {
								history.push(`${routes.course.getPath()}/${activeCourse?.id}`);
							})
						);
						setDeleteModal(false);
					}}
				/>
			</Modal>
			{!!editChapterData && editSectionIndex === null ? (
				ShowEditView()
			) : (
				<CardContainer>
					<Card
						width={552}
						title={ChapterView.title}
						content={ChapterView.content}
						imgSrc={ChapterView.imgSrc}
						imgRounded={true}
					/>
				</CardContainer>
			)}
			{SectionView.length > 0 ? (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{provided => (
							<Box {...provided.droppableProps} ref={provided.innerRef}>
								{SectionView.map((el, i) => {
									if (i === editSectionIndex) {
										const { name, isAvailableForTrial, mainImageKey, mainImageUrl } = editSectionData!.data;
										const disabledSaveBtn = uploadingImage || !name || !(mainImageKey || mainImageUrl);
										return (
											<Box key={el.id} mb={4}>
												<EditCard
													rightComponent={EditFields(
														name,
														updateEditSecData,
														handleCancel,
														handleSave,
														disabledSaveBtn,
														isAvailableForTrial
													)}
													imageUrl={activeChapter!.sections![i].mainImageUrl}
													handleImageUpdate={(img, handleProgres) => {
														handleUpload(img, {
															handleProgres: handleProgres,
															imageKeyPath: "editSectionData.data.mainImageKey",
															imageUrlPath: `activeChapter.sections.${i}.mainImageUrl`
														});
													}}
													width={800}
												/>
											</Box>
										);
									}
									return (
										<Draggable key={el.id} draggableId={`${el.id}`} index={i}>
											{provided => (
												<Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<Box
														display="flex"
														justifyContent="center"
														style={{ backgroundColor: theme.palette.colors.basic[800] }}
														mr={3}
													>
														<SvgIcon fontSize="large" color="disabled">
															<DragHandle />
														</SvgIcon>
													</Box>
													<CardContainer>
														<Card
															title={el.title}
															content={el.content}
															imgSrc={el.imgSrc}
															bottomComponent={el.bottomComponent}
															rightComponent={el.rightComponent}
														/>
													</CardContainer>
												</Box>
											)}
										</Draggable>
									);
								})}
							</Box>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				!showAddNewSection && (
					<CardContainer>
						<EmptyViewCard title="This Chapter does not have any Sections yet" />
					</CardContainer>
				)
			)}
			{showAddNewSection && (
				<div ref={elementRef}>
					<EditCard
						rightComponent={EditFields(
							name,
							updateData,
							resetAddSectionData,
							createNewSection,
							disabledSaveBtn,
							addSectionData!.data.isAvailableForTrial
						)}
						handleImageUpdate={(img, handleProgres) => {
							handleUpload(img, {
								handleProgres: handleProgres,
								imageKeyPath: "addSectionData.data.mainImageKey"
							});
						}}
						width={800}
					/>
				</div>
			)}
		</Container>
	);
};

export default Content;
