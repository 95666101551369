import React from "react";

import { Box, Grid, IconButton, Typography } from "@material-ui/core";

import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";

import { useDispatch } from "react-redux";

import { PAYMENT_CONSTANTS } from "store/features/CourseAddEdit/courseAddEdit.constants";

import {
	createAddonItem,
	fetchShipStationData,
	removeAddonItem,
	setStateValue,
	validateForm
} from "store/features/ManagePackage/managePackage.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import { genericService } from "store/services";

import theme from "theme/default";

import {
	AddBookWrapper,
	AddIcon,
	BookFormWrapper,
	BooksHeader,
	RemoveIcon,
	StyledCard
} from "../../CourseAddEdit/courseAddEdit.styles";

import { Spacer } from "../PackageOverview/styles";

const AddBook = ({ onClick }) => (
	<AddBookWrapper onClick={onClick}>
		<AddIcon />
		<Typography>Add Book</Typography>
	</AddBookWrapper>
);

const BookFrom = ({ addons }) => {
	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	let deletedCount = 0;
	return (
		<>
			{addons.map((addonItem, index) => {
				const {
					digitalAssetItem: addonDigitalAssetItem,
					shipStationItem: addonShipStationItem,
					isDigital,
					mainImageKey,
					mainImageUrl
				} = addonItem;
				if ((isDigital.value ? addonDigitalAssetItem.deleted.value : addonShipStationItem.deleted.value) == true) {
					deletedCount++;
					return null;
				}
				return (
					<Grid container spacing={2} key={`books-${index}`} style={{ margin: "10px 0" }}>
						<Grid container spacing={3} item xs={8}>
							<Grid container item xs={12}>
								<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
									<Typography style={{ width: "100px" }}>Book {index - deletedCount + 1}</Typography>
									<IconButton
										onClick={() =>
											dispatch(
												removeAddonItem({
													addonParent: PAYMENT_CONSTANTS.INITIAL,
													addonIndex: index
												})
											)
										}
									>
										<RemoveIcon />
									</IconButton>
								</Grid>
							</Grid>
							{isDigital.value && (
								<Grid container item xs={12}>
									<Grid item xs={4}>
										<Typography>Digital Asset</Typography>
									</Grid>
									<Grid item xs={4}>
										<CustomInput
											theme={theme}
											onChange={e => {
												const files = e.target.files;
												if (files && files[0]) {
													dispatch(
														setStateValue({
															key: `userSubscriptionTypesFormPerSeat.inputs.initial.addons.${index}.digitalAssetItem.name.value`,
															value: files[0].name
														})
													);
												}
											}}
											options={{
												...defaultCustomInputOptions,
												genericApiService: genericService,
												inputData: addonDigitalAssetItem.productKey
											}}
										/>
									</Grid>
								</Grid>
							)}
							{!isDigital.value && (
								<Grid container item xs={12}>
									<Grid item xs={4}>
										<Typography>SKU</Typography>
									</Grid>
									<Grid item xs={8}>
										<CustomInput
											width={400}
											theme={theme}
											inputProps={{
												onChange: e => {
													dispatch(
														fetchShipStationData(
															e.target.value,
															`initial.addons.${index}`,
															"userSubscriptionTypesFormPerSeat"
														)
													);
												},
												onKeyPress: e => e?.preventDefault()
											}}
											options={{ ...defaultCustomInputOptions, inputData: addonShipStationItem.productSKU }}
										/>
									</Grid>
								</Grid>
							)}
							<Grid container item xs={12}>
								<Grid item xs={4}>
									<Typography>Digital</Typography>
								</Grid>
								<Grid item xs={8}>
									<CustomInput
										width={400}
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: isDigital }}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							<Box display={"flex"} justifyContent={"flex-end"}>
								<CustomInput
									loading={false}
									theme={theme}
									inputProps={{
										onChange: e => {
											if (!e.target.value) {
												dispatch(
													setStateValue({
														key: `userSubscriptionTypesFormPerSeat.inputs.initial.addons.${index}.mainImageKey.imageUrl`,
														value: ""
													})
												);
												dispatch(
													setStateValue({
														key: `userSubscriptionTypesFormPerSeat.inputs.initial.addons.${index}.mainImageUrl`,
														value: ""
													})
												);
											}
										},
										uploaderWidth: 400,
										fileUploaderDescription: <>Drag and Drop File Here or Browse to Choose a File</>
									}}
									options={{
										...defaultCustomInputOptions,
										genericApiService: genericService,
										inputData: { ...mainImageKey, imageUrl: mainImageUrl?.value || mainImageKey.imageUrl }
									}}
								/>
							</Box>
						</Grid>
					</Grid>
				);
			})}
			{addons?.length > 0 && <Spacer />}
			<AddBook onClick={() => dispatch(createAddonItem(PAYMENT_CONSTANTS.INITIAL))} />
		</>
	);
};

const Books = ({ addons }) => (
	<StyledCard>
		<BooksHeader>Book in package</BooksHeader>
		<Spacer />
		<BookFormWrapper>
			<BookFrom addons={addons} />
		</BookFormWrapper>
	</StyledCard>
);

export default Books;
