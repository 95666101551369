import { Typography } from "@material-ui/core";
import styled from "styled-components";

interface ImageProps {
	rounded?: boolean;
}

interface ContainerProps {
	width: number;
}

export const NoOverflow = styled.div`
	overflow: hidden;
	${props => props.rightComponent && "margin-right: 24px;"}
`;

export const Container = styled.div<ContainerProps>`
	padding: 32px;
	position: relative;
	background-color: ${({ theme }) => theme.palette.colors.basic[900]};
	border-radius: 4px;
	.explorer-uploader {
		background-color: ${({ theme }) => theme.palette.colors.basic[800]};
		.uploader-content {
			color: rgba(255, 255, 255, 0.61);
		}
	}
	${props => props.width && `width: ${props.width}px;`}
`;

export const FlexContainer = styled.div`
	display: flex;
`;

export const ContentValue = styled(Typography)`
	opacity: 0.87;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #ffffff;
`;

export const Image = styled.img<ImageProps>`
	width: 140px;
	height: 200px;
	border-radius: 4px;
	margin-right: 24px;
	object-fit: cover;
	flex-grow: 0;
	flex-shrink: 0;
	${props => {
		if (props.rounded) {
			return `
				width: 180px;
				height: 180px;
				border-radius: 50%;
			`;
		}
	}}
`;

export const Key = styled(Typography)`
	font-size: 12px;
	line-height: 1.33;
	margin-right: 24px;
	color: rgba(255, 255, 255, 0.6);
`;

export const TitleKey = styled(Key)`
	font-size: 15px;
	margin-top: 8px;
	overflow: hidden;
`;

export const Title = styled(Typography)`
	opacity: 0.87;
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	margin-bottom: 16px;
	color: #ffffff;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

export const Subtitle = styled.p`
	margin-top: -16px;
	margin-bottom: 16px;
	font-size: 14px;
	font-weight: normal;
	font-style: italic;
`;
